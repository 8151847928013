import React, { Component } from 'react';
import { RichText, Link } from 'components/Prismic/Prismic';
import Form, {
  Checkbox,
  CheckboxPartenaire,
  CheckboxPartenaireGroup,
  Submit,
  validator,
} from 'components/Form/Form';
import { postEstimateFormStep4, getPartners } from 'services/cygest';
import DevisContext from '../DevisContext';

class Etape3 extends Component {
  static contextType = DevisContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      confirm: {
        status: false,
        type: 'success',
        success: {
          title: 'Demande envoyée !',
          message:
            'Votre demande a été transmise aux partenaires qui vous recontacterons rapidement.',
        },
        fail: {
          title: "Votre demande n'a pas pu être envoyée.",
          message: 'Merci de réessayer ultérieurement',
          element: (
            <button
              type="button"
              onClick={() => {
                const { confirm } = this.state;
                this.setState({
                  confirm: { ...confirm, status: false },
                });
              }}
            >
              Fermer
            </button>
          ),
        },
      },
      PAR_N_ID: [],
    };
  }

  async componentDidMount() {
    const partners = await getPartners();

    this.setState({ PAR_N_ID: partners.data });
  }

  render() {
    const { confirm, loading, PAR_N_ID } = this.state;
    const { datas } = this.props;
    const { step4_title: step4Title } = datas.data;
    const { form } = this.context;
    const updateForm = formState => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'Demande de devis - step 4',
      });

      const finalForm = {
        ...formState,
        PAR_N_ID: formState.PAR_N_ID.reduce((accumulator, value, i) => {
          if (value) accumulator.push(`${i}`);
          return accumulator;
        }, []),
        DDE_N_ID: form.demandeId,
        DDE_CH_NOM: form.DDE_CH_NOM,
        DDE_CH_PRENOM: form.DDE_CH_PRENOM,
        DDE_CH_CH_ADR1: form.DDE_CH_CH_ADR1,
        DDE_CH_CH_CP: form.DDE_CH_CH_CP,
        DDE_CH_CH_VILLE: form.DDE_CH_CH_VILLE,
        DDE_CH_EMAIL: form.DDE_CH_EMAIL,
        DDE_CH_TEL: form.DDE_CH_TEL,
      };

      postEstimateFormStep4(finalForm)
        .then(() => {
          this.setState({
            loading: false,
            confirm: {
              ...confirm,
              status: true,
              type: 'success',
            },
          });
        })
        .catch(err => {
          this.setState({
            loading: false,
            confirm: {
              ...confirm,
              status: true,
              type: 'fail',
            },
          });
          console.error(err);
        });
    };

    return (
      <div>
        <div className="Devis__form__step4">
          <RichText className="Devis__form__end__title">{step4Title}</RichText>
          <div className="Devis__form__text">
            Vous pouvez aller plus loin dans le détail de votre projet pour nous
            aider à mieux définir votre besoin et bénéficier d’un devis plus
            détaillé.
          </div>

          <Link
            to={
              form.token
                ? `/votre-dossier/inscription/etape-5/${form.token}/${form.uid}`
                : '/votre-dossier/'
            }
            className="Devis__form__button"
          >
            Votre dossier personnel
          </Link>
        </div>
        <Form
          onSubmit={updateForm}
          confirm={confirm}
          className="Devis__form__step4"
        >
          <div className="Devis__form__text">
            Nous voulons également vous présentez quelques partenaires qui
            peuvent vous intéresser, si c’est le cas cliquez sur leur bouton,
            vos coordonnées leurs seront transmises.
          </div>

          <CheckboxPartenaireGroup
            field="CheckboxPartenaireGroup"
            validate={validator.CheckboxPartenaireValidator}
          >
            {PAR_N_ID.map((partner, i) => (
              <CheckboxPartenaire
                field={`PAR_N_ID[${partner.partnerId}]`}
                partnerName={partner.partnerName}
                src={partner.partnerLogo}
                key={i}
              />
            ))}
          </CheckboxPartenaireGroup>

          <Checkbox
            field="acceptation"
            validate={validator.required}
            validateOnChange
            className="Devis__form__checkbox"
          >
            J’accepte la transmission de mes données personnelles au(x)
            partenaire(s) sélectionné(s)*
          </Checkbox>

          <Submit field="captcha" color="transGreen" disabled={loading}>
            Envoyer
          </Submit>
        </Form>
      </div>
    );
  }
}

export default Etape3;
