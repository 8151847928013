import React, { Component } from 'react';

const DevisContext = React.createContext();
const FormBase = {
  // demandeId: '123456789',
  // token: '12345',
  // uid: 'azerty',
  /* etape 1 */
  // DDE_CH_TYPE: 'particulier',
  // DDE_CH_TYPE: 'professionnel',
  // DDE_CH_MUTATION_ENTREPRISE: 'MB corp.',
  // DDE_CH_NOM: 'Bruno',
  // DDE_CH_PRENOM: 'Mathieu',
  // DDE_CH_TEL: '0687265896',
  // DDE_CH_EMAIL: `${Math.round(Math.random() * 1000000000000)}@matdev.fr`,
  // DDE_CH_CH_CP: '69005',
  // DDE_CH_LI_CP: '05100',
  // acceptation: true,
  /* etape 2 */
  // DDE_CH_CH_ADR1: '7 avenue adolphe max',
  // DDE_CH_CH_VILLE: 'lyon',
  // DDE_CH_CH_TYPE_LOGEMENT: 'appartement',
  // DDE_CH_CH_LOCAPROPRIO: 'locataire',
  // DDE_N_CH_SURFACE: '97',
  // DDE_CH_SPEC: 'Lorem ipsum dolor si amet',
  /* etape 3 */
  // DDE_N_PAS_DE_LOGEMENT: false,
  // DDE_CH_LI_ADR1: '40 chemin du panorama',
  // DDE_CH_LI_VILLE: 'Saint-Remy-en-Bouzemont-Saint-Genest-et-Isson',
  // DDE_CH_LI_TYPE_LOGEMENT: 'appartement',
};

class DevisProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: FormBase,
      update: params =>
        new Promise(resolve => {
          this.setState(
            state => ({
              ...state,
              form: {
                ...state.form,
                ...params,
              },
            }),
            () => {
              const { form } = this.state;
              return resolve(form);
            }
          );
        }),
      reset: () =>
        new Promise(resolve => {
          this.setState(
            {
              form: FormBase,
            },
            () => {
              const { form } = this.state;
              return resolve(form);
            }
          );
        }),
    };
  }

  render() {
    const { children } = this.props;
    return (
      <DevisContext.Provider value={{ ...this.state }}>
        {children}
      </DevisContext.Provider>
    );
  }
}

export default DevisContext;
export { DevisProvider };
