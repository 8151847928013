import React, { Component } from 'react';
import { Img, RichText, Link } from '../../../components/Prismic/Prismic';
import './PrivatePro.scss';

class PrivatePro extends Component {
  render() {
    const datas = this.props;
    return (
      <div className="PrivatePro">
        <div className="PrivatePro__content -private">
          <div
            className="PrivatePro__header"
            style={{
              backgroundImage: `url('${datas.private_photo.url}')`,
            }}
          >
            <div className="PrivatePro__container">
              <RichText className="PrivatePro__title">
                {datas.private_title}
              </RichText>
              <Link to={datas.private_link} className="PrivatePro__link">
                {datas.private_button}
              </Link>
            </div>
          </div>
          <div className="Home__list">
            <div className="PrivatePro__container">
              <ul className="Home__list__ul">
                {datas.private_list.map((list, i) => (
                  <li key={`privatelist-${i}`} className="Home__list__li">
                    <Link to={list.link} className="Home__list__link">
                      <Img src={list.icon} className="Home__list__img" />
                      {/* <img
                        src={list.icon.url}
                        alt={list.icon.alt}
                        width={list.icon.dimensions.width}
                        height={list.icon.dimensions.height}
                        className="Home__list__img"
                      /> */}
                      <RichText className="Home__list__label">
                        {list.label}
                      </RichText>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="PrivatePro__content -pro">
          <div
            className="PrivatePro__header"
            style={{ backgroundImage: `url('${datas.pro_photo.url}')` }}
          >
            <div className="PrivatePro__container">
              <RichText className="PrivatePro__title">
                {datas.pro_title}
              </RichText>
              <Link to={datas.pro_link} className="PrivatePro__link">
                {datas.pro_button}
              </Link>
            </div>
          </div>
          <div className="Home__list">
            <div className="PrivatePro__container">
              <ul className="Home__list__ul">
                {datas.pro_list.map((list, i) => (
                  <li key={`privatelist-${i}`} className="Home__list__li">
                    <Link to={list.link} className="Home__list__link">
                      {/* <Img src={list.icon} className="Home__list__img" /> */}
                      <img
                        src={list.icon.url}
                        alt={list.icon.alt}
                        width={list.icon.dimensions.width}
                        height={list.icon.dimensions.height}
                        className="Home__list__img"
                      />
                      <RichText className="Home__list__label">
                        {list.label}
                      </RichText>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivatePro;
