import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CubageMethod from './CubageMethod/CubageMethod';
import CubageMakerRouter from './CubageMaker/CubageMakerRouter';

const Router = ({ homeFolderPath }) => (
  <Switch>
    <Route
      exact
      path={`${homeFolderPath}/liste-mobilier`}
      render={props => (
        <CubageMethod homeFolderPath={homeFolderPath} {...props} />
      )}
    />
    <Route
      path={`${homeFolderPath}/liste-mobilier/`}
      render={props => (
        <CubageMakerRouter homeFolderPath={homeFolderPath} {...props} />
      )}
    />
  </Switch>
);
export default Router;
