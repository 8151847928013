import React from 'react';
import Phase from './Phase';
import Steps from './Steps';
import Pending from './Pending';
import './MonProjet.scss';

export default ({ homeFolderPath, projet = {}, agence = {} }) => {
  const dateRDV = projet.phase2 ? !!projet.phase2.date : false;
  const phase3Active = projet.phase3 ? !!projet.phase3.phaseActive : false;
  const dateDem = projet.phase4 ? !!projet.phase4.date : false;

  const phase1 = {
    path: dateRDV,
    enable: !phase3Active,
  };
  const phase2 = {
    path: phase3Active,
    enable: dateRDV === true && phase3Active === false && dateDem === false,
  };
  const phase3 = {
    path: dateDem,
    devisActive: projet.phase3 ? !!projet.phase3.devisActive : false,
    arrhesActive: projet.phase3 ? !!projet.phase3.arrhesActive : false,
    dateActive: projet.phase3 ? !!projet.phase3.dateActive : false,
    paymentActive: projet.phase3 ? !!projet.phase3.paymentActive : false,
  };
  const phase4 = {
    path: false,
    enable: projet.type2 === 'reference',
  };
  const phase5 = {
    path: false,
    enable: dateRDV === true && phase3Active === true && dateDem === true,
    dateChargement:
      projet.phase5 && projet.phase5.date && projet.phase5.date.chargement,
    dateLivraison:
      projet.phase5 && projet.phase5.date && projet.phase5.date.livraison,
  };
  const phase6 = {
    path: false,
    enable: projet.phase5 && (!projet.phase5.survey1 || !projet.phase5.survey2),
  };

  const orderIndex = () => {
    if (!phase4.enable && !agence.biniou) {
      return 2;
    }
    if (agence.biniou) {
      return 4;
    }
    return 3;
  };

  return (
    <div className="MonProjet">
      <h2 className="MonProjet__title">Mon projet de déménagement</h2>

      <p className="MonProjet__text">
        Vous pouvez ici gérer et retrouvez toutes les informations concernant
        les différentes étapes de votre projet de déménagement. (informations
        personnelles, règlements, date de visites, etc…)
      </p>

      <Phase
        index="1"
        title="Mon compte"
        path={phase1.path}
        enabled={phase1.enable}
        style={{ zIndex: 6 }}
      >
        <Steps
          datas={[
            {
              classOption: '-infoPerso',
              title: 'Informations personnelles',
              disabled: false,
              to: `${homeFolderPath}/informations-personnelles`,
            },
            {
              classOption: '-presta',
              title: 'Prestations',
              disabled: false,
              to: `${homeFolderPath}/Prestations`,
            },
            {
              classOption: '-listeMobilier',
              title: 'Liste mobilier',
              disabled: false,
              to: `${homeFolderPath}/liste-mobilier`,
            },
            {
              classOption: '-declarationValeur',
              title: 'Déclaration de valeur',
              disabled: false,
              to: `${homeFolderPath}/declaration-de-valeur`,
            },
          ]}
          disabled={!phase1.enable}
        />
      </Phase>

      <Phase
        title="Ma visite"
        subtitle="Date de la visite de mon déménageur et de la préparation de mon devis"
        style={{ zIndex: 5 }}
        path={phase2.path}
        enabled={phase2.enable}
        hide={!agence.biniou}
      >
        {projet.phase2 && projet.phase2.date && (
          <Pending
            date={projet.phase2.date}
            text={
              !phase3Active && (
                <>
                  <p>Ce rendez-vous ne vous convient pas ?</p>
                  <p>
                    Contactez votre déménageur pour convenir d’un nouveau
                    rendez-vous
                  </p>
                </>
              )
            }
            button={
              !phase3Active && {
                link: agence.biniou
                  ? `${homeFolderPath}/messagerie`
                  : `mailto: ${agence.email}`,
                label: 'Contacter mon déménageur',
              }
            }
          />
        )}
      </Phase>

      <Phase
        index="2"
        title="Mes prestations et Mes modes de paiement"
        style={{ zIndex: 4 }}
        path={phase3.path}
        enabled={phase3Active}
        hide={!agence.biniou}
      >
        <Steps
          datas={[
            {
              classOption: '-choixPresta',
              title: 'Choix de mes prestations et signature de mon devis',
              disabled: !phase3.devisActive,
              to: `${homeFolderPath}/choix-de-mes-prestations-et-signature-de-mon-devis`,
            },
            {
              classOption: '-paiementArrhes',
              title: 'Paiement de mes arrhes',
              disabled: !phase3.arrhesActive,
              to: `${homeFolderPath}/paiement-de-mes-arrhes`,
            },
            {
              classOption: '-paiementSolde',
              title: 'Paiement de mon solde',
              disabled: !phase3.paymentActive,
              to: `${homeFolderPath}/paiement-de-mon-solde`,
            },
          ]}
        />
      </Phase>

      <Phase
        index={agence.biniou ? 3 : 2}
        title="Dépôt de documents"
        path={phase4.path}
        enabled={phase4.enable}
        style={{ zIndex: 3 }}
        hide={!phase4.enable}
      >
        <Steps
          datas={[
            {
              classOption: '-devis',
              title: 'Devis',
              disabled: false,
              to: `${homeFolderPath}/devis`,
            },
            {
              classOption: '-lettre-voiture',
              title: 'Lettre de voiture',
              disabled: false,
              to: `${homeFolderPath}/lettre-de-voiture`,
            },
            {
              classOption: '-facture-acquittée',
              title: 'Facture acquittée',
              disabled: false,
              to: `${homeFolderPath}/facture-acquittée`,
            },
          ]}
          disabled={!phase4.enable}
          doc
          files={projet}
        />
      </Phase>

      <Phase
        title="Mon déménagement"
        style={{ zIndex: 2 }}
        path={phase5.path}
        enabled={phase5.enable}
        hide={!agence.biniou}
      >
        {phase5.enable && (
          <Pending
            chargement={phase5.dateChargement}
            livraison={phase5.dateLivraison}
            text={
              <>
                <small>
                  * Toutes les dates indiquées sont succeptibles de modification
                  sans préavis.
                  <br />
                  seules les dates validées par votre agence (email ou
                  téléphone) seront définitives.
                </small>
                <p>Ce rendez-vous ne vous convient pas ?</p>
                <p>
                  Contactez votre déménageur pour convenir d’un nouveau
                  rendez-vous
                </p>
              </>
            }
            button={{
              link: agence.biniou
                ? `${homeFolderPath}/messagerie`
                : `mailto: ${agence.email}`,
              label: 'Contacter mon déménageur',
            }}
          />
        )}
      </Phase>

      <Phase
        index={orderIndex()}
        title="Mon avis"
        style={{ zIndex: 1 }}
        path={phase6.path}
        enabled={phase6.enable}
      >
        <Steps
          datas={[
            {
              classOption: '-enqueteSatisfation',
              title: 'Enquête satisfaction',
              disabled: projet.phase5 ? projet.phase5.survey1 : true,
              completed: false,
              to: `${homeFolderPath}/enquete-satisfaction`,
            },
            {
              classOption: '-enqueteComplementaire',
              title: (
                <span>
                  Enquête complémentaire et <b>tentez de gagner 1000€</b>
                </span>
              ),
              disabled: projet.phase5
                ? !projet.phase5.survey1 || projet.phase5.survey2
                : true,
              completed: false,
              to: `${homeFolderPath}/enquete-complementaire-avec-jeux-concours`,
              info:
                'Détendez-vous grâce à l’enquête complémentaire « Mieux vous connaitre ». (Répondez et recevez automatiquement 2x15€ sur wonderbox.fr / wonderweekend.com pour toute participation)',
            },
          ]}
          // disabled={!phase5.enable}
        />
      </Phase>
    </div>
  );
};
