import React, { useEffect, useRef } from 'react';
import { asField, useFormApi } from 'informed';

const TextArea = ({ fieldState, fieldApi, ...props }) => {
  const formApi = useFormApi();
  const refContentEditable = useRef();
  const { setValue } = fieldApi;
  const { error, value } = fieldState;
  const {
    label,
    forwardedRef,
    disabled,
    type,
    field,
    debug,
    className,
    onChange,
    ...etc
  } = props;
  const uniqid = `checkbox-${Math.round(Math.random() * 100000)}`;

  function listener(e) {
    if (onChange) onChange(e.target.wholeText);
    setValue(e.target.wholeText);
  }

  function submitForm(e) {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      formApi.submitForm();
    }
  }

  useEffect(() => {
    const $contentEditable = refContentEditable.current;
    if ($contentEditable.addEventListener) {
      $contentEditable.addEventListener(
        'DOMCharacterDataModified',
        listener,
        false
      );
      $contentEditable.addEventListener('keydown', submitForm, false);
    }

    return () => {
      $contentEditable.removeEventListener(
        'DOMCharacterDataModified',
        listener,
        false
      );
      $contentEditable.removeEventListener('keydown', submitForm, false);
    };
  }, [listener, submitForm, formApi]);

  useEffect(() => {
    if (!value) {
      const $contentEditable = refContentEditable.current;
      $contentEditable.innerText = '';
    }
  });

  return (
    <div
      className={`Form__group -superTextarea ${error ? '-error' : ''} ${
        disabled ? '-disabled' : ''
      } ${className || ''}`}
    >
      <label htmlFor={uniqid}>
        {label ? <span className="Form__label">{label}</span> : null}
        <div
          contentEditable
          className="Form__group__contentEditable"
          ref={refContentEditable}
          {...etc}
        />
      </label>
      {error ? <small className="Form__messageError">{error}</small> : null}
    </div>
  );
};

export default asField(TextArea);
