import React, { Component } from 'react';
import Head from '../../../components/Head/Head';
import Loading from '../../../components/Loading/Loading';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { getPageByType } from '../../../services/prismic';
import DevisContext from '../DevisContext';
import NotFound from '../../../layout/NotFound/NotFound';
import Etape1 from './Etape1';
import Etape2 from './Etape2';
import Etape3 from './Etape3';
import Etape4 from './Etape4';
import SummaryList from './SummaryList';
import Video1 from '../../../img/AnimPart01.mov';
import Video2 from '../../../img/AnimPart02.mov';
import Video3 from '../../../img/AnimPart03.mov';
import Video4 from '../../../img/AnimPart04.mov';

class Devis extends Component {
  static contextType = DevisContext;

  constructor(props) {
    super(props);

    this.state = {
      datas: null,
    };

    getPageByType('devis-page').then(datas => {
      this.setState({ datas: datas[0] });
    });
  }

  render() {
    const { datas } = this.state;
    const { match, history } = this.props;
    const step = match.params.step || 'etape-1';
    const { form } = this.context;
    if (datas) {
      const { data } = datas;
      let index = 0;
      let formSteps = null;
      const titles = [data.step1_title, data.step2_title, data.step3_title];
      const { step4_text: step4Text } = data;

      let videoUrl = null
      switch (step) {
        case 'etape-1':
          index = 1;
          formSteps = (
            <Etape1
              history={history}
              title={titles[0]}
              data={data}
              next="/devis/etape-2"
            />
          );
          videoUrl = Video1
          break;

        case 'etape-2':
          if (!form.demandeId) history.push(`/devis/etape-1`);
          index = 2;
          formSteps = (
            <Etape2 history={history} title={titles[1]} next="/devis/etape-3" />
          );
          videoUrl = Video2
          break;

        case 'etape-3':
          if (!form.demandeId) history.push(`/devis/etape-1`);
          index = 3;
          formSteps = (
            <Etape3
              history={history}
              title={titles[2]}
              prev="/devis/etape-2"
              next="/devis/etape-4"
            />
          );
          videoUrl = Video3
          break;

        case 'etape-4':
          if (!form.demandeId) history.push(`/devis/etape-1`);
          index = 4;
          formSteps = <Etape4 history={history} datas={datas} />;
          videoUrl = Video4
          break;

        default:
          return <NotFound />;
      }

      return (
        <div className="Devis">
          <Head title={data.seo_title} description={data.seo_title} />
          <Breadcrumb
            topic={{ link: 'devis/', name: data.seo_title }}
            title={`Étape ${index}`}
          />
          <div className="Devis__content">
            <div className="Devis__leftCol">
              <SummaryList
                form={form}
                titles={titles}
                index={index}
                photoUrl={videoUrl}
                step4Text={step4Text}
              />
            </div>
            <div className="Devis__rightCol">
              <div>
                <div
                  className={`Devis__status ${index === 4 ? '-hidden' : ''}`}
                >
                  <div className="Devis__status__progress">
                    <div
                      className="Devis__status__progress__bar"
                      style={{
                        transform: `translateX(${index * 33.33333 - 100}%)`,
                      }}
                    />
                  </div>

                  <div className="Devis__steps">
                    <div
                      className={`Devis__step ${index === 1 ? '-current' : ''}`}
                    >
                      <span className="Devis__step__label">Étape 1</span>
                      <span className="Devis__step__info">
                        Vos informations personnelles
                      </span>
                    </div>

                    <div
                      className={`Devis__step ${index === 2 ? '-current' : ''}`}
                    >
                      <span className="Devis__step__label">Étape 2</span>
                      <span className="Devis__step__info">
                        Chargement - départ
                      </span>
                    </div>

                    <div
                      className={`Devis__step ${index === 3 ? '-current' : ''}`}
                    >
                      <span className="Devis__step__label">Étape 3</span>
                      <span className="Devis__step__info">
                        Livraison - arrivée
                      </span>
                    </div>
                  </div>
                </div>

                {formSteps}
              </div>
            </div>
          </div>

          {/* <div className="chatBot">
            <div className="chatBot__pils">
              <div className="chatBot__perso" />
              <p className="chatBot__help">Aide</p>
            </div>
          </div> */}
        </div>
      );
    }

    return <Loading />;
  }
}

export default Devis;
