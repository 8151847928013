import React, { useState, useEffect, useContext } from 'react';
import Col from 'components/Col';
import { ModalContext } from 'components/Modal/Modal';
import { format } from 'date-fns';
import Form, {
  Input,
  Radio,
  RadioGroup,
  Submit,
  validator,
} from 'components/Form/Form';
import { Switch, Route } from 'react-router-dom';
import { getQuotationsList, getSigningUrl, getDevis } from 'services/cygest';
import MonDemenageur from '../../layout/MonDemenageur/MonDemenageur';
import MonDossierContext from '../../MonDossierContext';
import Header from '../Header/Header';

import logoYousign from '../../img/yousign-logo.png';

import './Signing.scss';

const numberWithSpace = number =>
  number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '0';

const Quotation = ({ datas, quotationStatus }) => {
  const [url, setUrl] = useState(null);
  const isExpirate = new Date(datas.expirationDate).getTime() < Date.now();

  useEffect(() => {
    getDevis(datas.devisId).then(data => {
      const blob = new Blob([data.data], { type: 'application/pdf' });
      const resurl = window.URL.createObjectURL(blob);
      setUrl(resurl);
    });
  }, []);

  return (
    <div
      className={`Quotation ${
        quotationStatus || datas.status === 'refused' || isExpirate
          ? '-disabled'
          : ''
        } ${datas.status === 'signed' ? '-signed' : ''}`}
    >
      <div className="Quotation__grid1">
        <Col className="Quotation__grid1__left">
          <div className="Quotation__grid1__title">{datas.name}</div>
        </Col>
        <Col className="Quotation__grid1__right">
          {numberWithSpace(datas.price)}€
        </Col>
      </div>
      <div className="Quotation__block">
        <div className="Quotation__block__text">N° Devis : {datas.reference}</div>
        <div className="Quotation__block__text">
          valable jusqu’au{' '}
          {format(new Date(datas.expirationDate), 'DD/MM/YYYY')}
        </div>
      </div>
      <div className="Quotation__grid2">
        <Col className="Quotation__grid2__left">
          {datas.signStatus === '' && (
            <a
              href={url}
              download={`devis-${datas.reference}.pdf`}
              className="Quotation__grid2__link"
            >
              Télécharger le devis contrat
            </a>
          )}
        </Col>
        <Col className="Quotation__grid2__right">
          <img src={logoYousign} alt="" className="Quotation__grid2__logo" />
          {datas.signStatus === 'signed' && 'Vous avez signé ce devis'}
          {datas.signStatus === 'refused' &&
            'Vous avez refusé de signer ce devis'}
          {!quotationStatus && datas.signStatus === '' && !isExpirate && (
            <Radio
              value={{ devisId: datas.devisId, yousignUrl: datas.yousignUrl }}
            >
              Choisir et signer ce devis
            </Radio>
          )}
        </Col>
      </div>
    </div>
  );
};

let formApi = null;

export default ({ currentPath, homeFolderPath }) => {
  const { modal } = useContext(ModalContext);
  const { error, getUserInfo } = useContext(MonDossierContext);
  const [pending, setPending] = useState(true);
  const [quotationStatus, setQuotationStatus] = useState();
  const [quotations, setQuotations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [signingUrl, setSigningUrl] = useState(false);

  useEffect(() => {
    getUserInfo().then(userInfo => {
      formApi.setValue('phone', userInfo.tel);
      getQuotationsList().then(data => {
        setPending(false);
        setQuotationStatus(
          data.reduce((a, v) => (v.signStatus === 'signed' ? true : a), false)
        );
        setQuotations(data);
      });
    });
  }, []);

  function onSubmit(formState) {
    setLoading(true);
    if (formState.quotation.yousignUrl) {
      setSigningUrl(formState.quotation.yousignUrl);
    } else {
      getSigningUrl(formState.quotation.devisId, formState.phone)
        .then(e => {
          setSigningUrl(e);
          setLoading(false);
        })
        .catch(err => {
          if (err.response.status === 400) {
            setLoading(false);
            modal.new({
              autoSize: true,
              title: 'Numéro de téléphone incorrecte.',
              datas: (
                <div className="ModalPhone">
                  <div className="ModalPhone__title">
                    Merci de saisir un numéro de téléphone valide :
                  </div>
                  <Form
                    onSubmit={e => {
                      formApi.setValue('phone', e.phone);
                      formApi.submitForm();
                      modal.close();
                    }}
                  >
                    <Input
                      type="text"
                      field="phone"
                      initialValue={formState.phone}
                    />
                    <Submit>Valider</Submit>
                  </Form>
                </div>
              ),
            });
          } else {
            error(err);
            setLoading(false);
          }
        });
    }
  }

  return (
    <div className="Signing">
      <Header title="Choix de mes prestations et signature de mon devis" />
      <div className="Signing__container">
        <div className="Signing__content">
          <div className="Signing__grid">
            <Col className="Signing__grid__left">
              <MonDemenageur />
            </Col>
            <Col className="Signing__grid__right">
              <Switch>
                <Route
                  exact
                  path={`${currentPath}/accepte`}
                  render={props => (
                    <div className="Signing__status">
                      <div className="Signing__status__title">Merci, </div>
                      <div className="Signing__status__subtitle">
                        Nous avons bien réceptionné votre devis signé par vos
                        soins.
                      </div>
                      <button
                        type="button"
                        className="Signing__home"
                        onClick={() => props.history.push(homeFolderPath)}
                      >
                        Votre dossier
                      </button>
                    </div>
                  )}
                />
                <Route
                  exact
                  path={`${currentPath}/refuse`}
                  render={props => (
                    <div className="Signing__status">
                      <div className="Signing__status__title">
                        Vous avez refusé de signer le devis.
                      </div>
                      <div className="Signing__status__subtitle">
                        Merci de contacter votre déménageur.
                      </div>
                      <button
                        type="button"
                        className="Signing__home"
                        onClick={() => props.history.push(homeFolderPath)}
                      >
                        Votre dossier
                      </button>
                    </div>
                  )}
                />
                <Route
                  exact
                  path={`${currentPath}/erreur`}
                  render={props => (
                    <div className="Signing__status">
                      <div className="Signing__status__title">
                        Oups, il y a eu une erreur lors de la signature de votre
                        devis,
                      </div>
                      <div className="Signing__status__subtitle">
                        merci de contacter votre déménageur.
                      </div>
                      <button
                        type="button"
                        className="Signing__home"
                        onClick={() => props.history.push(homeFolderPath)}
                      >
                        Votre dossier
                      </button>
                    </div>
                  )}
                />
                <Route
                  render={props => {
                    if (signingUrl) {
                      return (
                        <iframe
                          src={signingUrl}
                          title="yousign.com"
                          className="Signing__iframe"
                        />
                      );
                    }
                    return (
                      <Form
                        onSubmit={onSubmit}
                        pending={pending}
                        getApi={e => {
                          formApi = e;
                        }}
                      >
                        <Input type="hidden" field="phone" />
                        <RadioGroup
                          field="quotation"
                          validate={validator.required}
                          validateOnChange
                        >
                          {quotations.map((quotation, i) => (
                            <Quotation
                              datas={quotation}
                              quotationStatus={quotationStatus}
                              key={i}
                            />
                          ))}
                        </RadioGroup>

                        <div className="Signing__buttons">
                          <button
                            type="button"
                            className="Signing__back"
                            onClick={() => props.history.goBack()}
                          >
                            Retour
                          </button>
                          {!quotationStatus && (
                            <Submit
                              className="Signing__save"
                              disabled={loading}
                            >
                              Valider
                            </Submit>
                          )}
                        </div>
                      </Form>
                    );
                  }}
                />
              </Switch>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
};
