import React from 'react';
import './Phase.scss';

export default ({
  index = '',
  title,
  subtitle,
  path = false,
  enabled = false,
  hide = false,
  children,
  ...etc
}) =>
  hide ? null : (
    <div
      className={`Phase ${index ? '-indexed' : ''} ${
        enabled ? '-enabled' : ''
      } ${path ? '-path' : ''}`}
      {...etc}
    >
      <div className="Phase__title">
        <div className="Phase__title__index">{index}</div>
        <div className="Phase__title__label">
          {title}
          <div className="Phase__title__sublabel">{subtitle}</div>
        </div>
      </div>

      <div className="Phase__content">{children}</div>
    </div>
  );
