import React from 'react';
import { Radio as InRadio } from 'informed';

export default props => {
  const { className, children, resetId, ...rest } = props;
  const uniqid = `radio-${Math.round(Math.random() * 100000)}`;

  return (
    <label className={className} htmlFor={uniqid}>
      <InRadio id={uniqid} {...rest} />
      <div className={`${className}__content`}>{children}</div>
    </label>
  );
};
