import React, { Component } from 'react';
import { RichText } from '../Prismic/Prismic';
import ModalContext from './ModalContext';
import ModalProvider from './ModalProvider';
import './Modal.scss';

class Modal extends Component {
  static contextType = ModalContext;

  render() {
    const { modal } = this.context;
    return (
      <div
        className={`Modal ${modal.params.status ? '-show' : ''} ${
          modal.params.autoSize ? '-autoSize' : ''
        }`}
      >
        <div
          className="Modal__back"
          onClick={() => modal.close()}
          role="button"
          tabIndex="-1"
        />
        <div className="Modal__center">
          <div className="Modal__content" style={modal.params.style}>
            <div className="Modal__header">
              <button
                onClick={() => modal.close()}
                className="Modal__header__close"
                type="button"
                title="Fermer"
              />
              <RichText className="Modal__header__title">
                {modal.params.title}
              </RichText>
            </div>
            <div className="Modal__container">{modal.params.datas}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
export { ModalContext, ModalProvider };
