import React, { Component } from 'react';
import { asField } from 'informed';

class TextArea extends Component {
  render() {
    const { fieldState, fieldApi, ...props } = this.props;
    const { setValue, setTouched } = fieldApi;
    const { error, value } = fieldState;
    const { label, forwardedRef, disabled, type, field, debug, ...etc } = props;
    const uniqid = `checkbox-${Math.round(Math.random() * 100000)}`;

    return (
      <div
        className={`Form__group -textarea ${error ? '-error' : ''} ${
          disabled ? '-disabled' : ''
          }`}
      >
        <label htmlFor={uniqid}>
          {label ? <span className="Form__label">{label}</span> : null}
          <textarea
            name={field}
            value={!value && value !== 0 ? '' : value}
            onChange={e => {
              setValue(e.target.value);
            }}
            onBlur={e => {
              if (e.target.value) setTouched();
            }}
            rows="7"
            disabled={disabled}
            {...etc}
          />
        </label>
        {error ? <small className="Form__messageError">{error}</small> : null}
      </div>
    );
  }
}

export default asField(TextArea);
