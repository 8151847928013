import React, { Component } from 'react';
import { asField } from 'informed';

class CheckboxPrestation extends Component {
  render() {
    const { fieldState, fieldApi, src, ...props } = this.props;
    const { value } = fieldState;
    const { setValue, setTouched } = fieldApi;
    const {
      children,
      disabled,
      forwardedRef,
      label,
      help,
      type,
      field,
      onChange,
      ...rest
    } = props;
    const uniqid = `checkbox-${Math.round(Math.random() * 100000)}`;

    return (
      <div className="Form__group -checkboxPrestation">
        <label className="checkboxPrestation__content" htmlFor={uniqid}>
          <input
            type="checkbox"
            name={field}
            checked={!value && value !== 0 ? '' : value}
            onChange={e => {
              setValue(e.target.checked);
              setTouched();
              if (onChange) onChange(e);
            }}
            id={uniqid}
            disabled={disabled}
            {...rest}
          />
          <div className="checkboxPrestation__group">
            <div className="checkboxPrestation__label">{label}</div>
            <div className="checkboxPrestation__checkbox" />
          </div>
        </label>
        <div className="checkboxPrestation__help">
          ? <div className="checkboxPrestation__help__modal">{help}</div>
        </div>
      </div>
    );
  }
}

export default asField(CheckboxPrestation);
