import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import arrow from './img/arrow_breadcrumb.svg';

import './Breadcrumb.scss';

const xmlnsV = { 'xmlns:v': 'http://rdf.data-vocabulary.org/#' };

export default class BreadCrumb extends Component {
  render() {
    const { white = '', minimal = '', topic, title, h1 } = this.props;
    return (
      <ul
        className={`Breadcrumb ${white && '-white'} ${minimal && '-minimal'}`}
        {...xmlnsV}
      >
        <li typeof="v:Breadcrumb">
          <Link to="/" rel="v:url" property="v:title">
            Accueil
            <SVG src={arrow} />
          </Link>
        </li>
        {topic ? (
          <li typeof="v:Breadcrumb">
            <Link to={`/${topic.link}`} rel="v:url" property="v:title">
              {topic.name}
              <SVG src={arrow} />
            </Link>
          </li>
        ) : null}
        <li typeof="v:Breadcrumb">
          {h1 ? <h1>{title}</h1> : <span>{title}</span>}
        </li>
      </ul>
    );
  }
}
