import React, { Component } from 'react';
import { RichText, Link } from '../../Prismic/Prismic';
import './DoubleCol.scss';

class DoubleCol extends Component {
  render() {
    const { datas } = this.props;
    return (
      <div className="DoubleCol">
        <div className="DoubleCol__row">
          <div>
            <div className="DoubleCol__row__col">
              <RichText className="DoubleCol__row__title">
                {datas.primary.left_title}
              </RichText>
              <RichText className="DoubleCol__row__content">
                {datas.primary.left_content}
              </RichText>
            </div>
          </div>
          <div>
            <div className="DoubleCol__row__col">
              <RichText className="DoubleCol__row__title">
                {datas.primary.right_title}
              </RichText>
              <RichText className="DoubleCol__row__content">
                {datas.primary.right_content}
              </RichText>
            </div>
          </div>
        </div>
        <Link to={datas.primary.link} className="DoubleCol__row__link">
          {datas.primary.link_name}
        </Link>
      </div>
    );
  }
}

export default DoubleCol;
