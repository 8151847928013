import React, { Component } from 'react';
import Form, {
  Input,
  TextArea,
  Select,
  Option,
  File,
  Submit,
  validator,
} from '../../../components/Form/Form';
import { RichText } from '../../../components/Prismic/Prismic';
import { postEstimateFormStep2 } from '../../../services/cygest';
import DevisContext from '../DevisContext';

class Etape2 extends Component {
  static contextType = DevisContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  render() {
    const { history, title, next } = this.props;
    const { loading } = this.state;
    const { update, form } = this.context;
    const updateForm = async formState => {
      formState.AGE_N_ID = this.props.data && this.props.data.id_agence;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'Demande de devis Réference - step 2',
      });

      const finalForm = await update(formState);
      const filteredForm = {
        type: 'devisReference',
        DDE_CH_TYPE2: 'reference',
        demandeId: finalForm.demandeId,
        DDE_CH_TYPE: finalForm.DDE_CH_TYPE,
        DDE_CH_MUTATION_ENTREPRISE: finalForm.DDE_CH_MUTATION_ENTREPRISE,
        DDE_CH_NOM: finalForm.DDE_CH_NOM,
        DDE_CH_PRENOM: finalForm.DDE_CH_PRENOM,
        DDE_CH_TEL: finalForm.DDE_CH_TEL,
        DDE_CH_EMAIL: finalForm.DDE_CH_EMAIL,
        DDE_CH_CH_CP: finalForm.DDE_CH_CH_CP,
        DDE_CH_LI_CP: finalForm.DDE_CH_LI_CP,
        acceptation: finalForm.acceptation,
        captcha: finalForm.captcha,
        AGE_N_ID: finalForm.AGE_N_ID,
        DDE_CH_CH_ADR1: finalForm.DDE_CH_CH_ADR1,
        DDE_CH_CH_VILLE: finalForm.DDE_CH_CH_VILLE,
        DDE_CH_CH_TYPE_LOGEMENT: finalForm.DDE_CH_CH_TYPE_LOGEMENT,
        DDE_CH_CH_LOCAPROPRIO: finalForm.DDE_CH_CH_LOCAPROPRIO,
        DDE_N_CH_SURFACE: finalForm.DDE_N_CH_SURFACE,
        DDE_CH_SPEC: finalForm.DDE_CH_SPEC,
        files: finalForm.files,
      };

      postEstimateFormStep2(filteredForm)
        .then(() => {
          history.push(next);
        })
        .catch(err => {
          console.error(err.response, finalForm);
          if (err.response && typeof err.response.data === 'object') {
            const errors = [];
            Object.keys(err.response.data).forEach(e => {
              errors.push(`${e}: ${err.response.data[e]}`);
            });
            alert(errors.join('\n'));
          } else {
            alert(
              "Impossible d'envoyer ce formulaire.\nMerci de réessayer ultérieurement."
            );
          }
          this.setState({ loading: false });
        });
    };

    return (
      <div>
        <RichText className="Devis__form__title">{title}</RichText>
        <Form onSubmit={updateForm}>
          <div className="Devis__form__maxwidth">
            <Input
              type="hidden"
              field="demandeId"
              initialValue={form.demandeId || ''}
            />
            <Input
              type="text"
              field="DDE_CH_CH_ADR1"
              label="Adresse*"
              placeholder="Adresse"
              initialValue={form.DDE_CH_CH_ADR1 || ''}
              validate={validator.required}
              validateOnBlur
            />

            <div className="Devis__form__2colFixed">
              <div>
                <Input
                  type="text"
                  field="DDE_CH_CH_CP"
                  label="Code postal*"
                  placeholder="Code postal"
                  initialValue={form.DDE_CH_CH_CP || ''}
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
              <div>
                <Input
                  type="text"
                  field="DDE_CH_CH_VILLE"
                  label="Ville*"
                  placeholder="Ville"
                  initialValue={form.DDE_CH_CH_VILLE || ''}
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
            </div>

            <div className="Devis__form__3col">
              <div>
                <Select
                  field="DDE_CH_CH_TYPE_LOGEMENT"
                  label="Type de logement*"
                  initialValue={form.DDE_CH_CH_TYPE_LOGEMENT || ''}
                  validate={validator.required}
                  validateOnChange
                >
                  <Option value="" disabled>
                    Choisissez...
                  </Option>
                  <Option value="appartement">Appartement</Option>
                  <Option value="maison">Maison</Option>
                </Select>
              </div>
              <div>
                <Select
                  field="DDE_CH_CH_LOCAPROPRIO"
                  label="Vous êtes ?*"
                  initialValue={form.DDE_CH_CH_LOCAPROPRIO || ''}
                  validate={validator.required}
                  validateOnChange
                >
                  <Option value="" disabled>
                    Choisissez...
                  </Option>
                  <Option value="locataire">Locataire</Option>
                  <Option value="propriétaire">Propriétaire</Option>
                </Select>
              </div>
              <div>
                <Input
                  type="number"
                  field="DDE_N_CH_SURFACE"
                  label="Superficie*"
                  placeholder="Superficie"
                  initialValue={form.DDE_N_CH_SURFACE || ''}
                  validate={validator.number}
                  validateOnBlur
                />
              </div>
            </div>
            <TextArea
              field="DDE_CH_SPEC"
              label="Vos spécificités"
              initialValue={form.DDE_CH_SPEC || ''}
              placeholder="Merci d’indiquer tout élément que vous pensez important pour votre déménagement"
            />

            <File
              field="files"
              initialValue={form.files || ''}
              validate={validator.upload}
              validateOnChange
            />

            <div className="Devis__form__mandatory">*Champ obligatoire</div>
          </div>

          <Submit className="Devis__form__next" disabled={loading}>
            Suivant
          </Submit>
          <div style={{ clear: 'both' }} />
        </Form>
      </div>
    );
  }
}

export default Etape2;
