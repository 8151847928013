import React from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { Link as LinkPrismic, RichText as Text } from 'prismic-reactjs';
import config from '../../config';

const linkResolver = doc => {
  if (!doc) return '/404';
  if (doc.type === 'home-page') return `/`;
  if (doc.type === 'particulier-home') return `/particulier/${doc.uid}`;
  if (doc.type === 'particulier-content') return `/particulier/${doc.uid}`;
  if (doc.type === 'professionnel-home') return `/professionnel/${doc.uid}`;
  if (doc.type === 'professionnel-content') return `/professionnel/${doc.uid}`;
  if (doc.type === 'know-how-page') return `/notre-savoir-faire/${doc.uid}`;
  if (doc.type === 'devis-partenaire-page') return `/devis/${doc.uid}`;
  if (doc.type === 'devis-page') return `/devis`;
  if (doc.type === 'news-home') return `/actualites`;
  if (doc.type === 'sitemap-page') return `/plan-du-site`;
  if (doc.type === 'faq-home') return `/foire-aux-questions`;
  if (doc.type === 'faq-page') return `/foire-aux-questions/${doc.uid}`;
  if (!doc.uid) return '/404';
  return `/${doc.uid}`;
};

const Media = props => {
  const { src, children, ...attr } = props;

  if (!src.url) return null;

  return (
    <a href={src.url} {...attr} target="_blank" rel="noopener noreferrer">
      {src.name}
    </a>
  );
};

const Img = props => {
  const { src, children, ...attr } = props;
  const { alt, copyright, dimensions, url, ...other } = src;
  console.log(url, alt, dimensions.width, dimensions.height);

  if (!url || !dimensions.width || !Object.keys(src).length) return null;

  if (Object.keys(other).length) {
    // const srcset = Object.keys(other).map(
    //   e => `${other[e].url} ${other[e].dimensions.width}w`
    // );
    // srcset.push(`${url} ${dimensions.width}w`);
    return (
      <>
        <img
          src={url}
          // srcSet={srcset}
          alt={alt || ''}
          width={dimensions.width}
          height={dimensions.height}
          {...attr}
        />
      </>
    );
  }
  return (
    <img
      src={url}
      alt={alt || ''}
      width={dimensions.width}
      height={dimensions.height}
      {...attr}
    />
  );
};

const RawText = props => {
  const { children, excerpt, ...other } = props;
  if (!children) return null;
  const text =
    excerpt && Text.asText(children).length
      ? `${Text.asText(children).substr(0, excerpt)}...`
      : Text.asText(children);
  return <span {...other}>{text}</span>;
};

const RichText = props => {
  const { children, ...other } = props;
  if (!children) return null;
  if (typeof children === 'string') return <div {...other}>{children}</div>;
  return <div {...other}>{Text.render(children)}</div>;
};

const Link = props => {
  const { to, noRedirect, children, noChildren, ...other } = props;

  if ((!noChildren && !children) || !to) return null;

  if (other.disabled) return <span {...other}>{children}</span>;

  if (/^mailto.*$/.test(to))
    return (
      <a href={to} role="button" tabIndex="0" {...other}>
        {children}
      </a>
    );

  if (typeof to === 'string' && /^http/.test(to))
    return (
      <a href={to} role="button" tabIndex="0" {...other}>
        {children}
      </a>
    );

  if (to.link_type === 'Any') return <span {...other}>{children}</span>;

  if (to.link_type === 'Web') {
    const rel = { rel: 'noopener' };
    return (
      <a href={to.url} target={to.target} {...rel} {...other}>
        {children}
      </a>
    );
  }

  if (config.isMinisite() && !noRedirect) {
    return (
      <a
        href={`${config.url}${
          typeof to === 'string' ? to : LinkPrismic.url(to, linkResolver)
        }`}
        role="button"
        tabIndex="0"
        {...other}
      >
        {children}
      </a>
    );
  }

  return (
    <LinkRouter
      to={typeof to === 'string' ? to : LinkPrismic.url(to, linkResolver)}
      {...other}
    >
      {children}
    </LinkRouter>
  );
};

export { Img, RichText, RawText, Link, Media, Text };
