import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Form, {
  Input,
  Checkbox,
  SubmitCaptcha,
  Fusion,
  validator,
  Select,
  Option,
} from '../Form/Form';
import { postQuickEstimateForm } from '../../services/cygest';
import { country } from '../../country';

import './DevisInternationalForm.scss';

class DevisInternationalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      confirm: {
        status: false,
        type: 'success',
        success: {
          title: 'Demande de devis envoyée !',
          message:
            'Votre demande de devis a été transmise à l’agence Les déménageurs bretons qui vous recontactera rapidement.',
        },
        fail: {
          title: "Votre demande de devis n'a pas pu être envoyée.",
          message: 'Merci de réessayer ultérieurement',
          element: (
            <button
              type="button"
              onClick={() => {
                const { confirm } = this.state;
                this.setState({
                  confirm: { ...confirm, status: false },
                });
              }}
            >
              Fermer
            </button>
          ),
        },
      },
    };
  }

  setFormApi(formApi) {
    this.formApi = formApi;
  }

  updateForm(formState) {
    const { confirm } = this.state;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Devis rapide',
    });

    this.setState({ loading: true });

    postQuickEstimateForm(formState)
      .then(() => {
        this.setState({
          loading: false,
          confirm: {
            ...confirm,
            status: true,
            type: 'success',
          },
        });
        this.formApi.reset();
        setTimeout(() => {
          const { confirm: conf } = this.state;
          this.setState({ confirm: { ...conf, status: false } });
        }, 5000);
      })
      .catch(err => {
        this.setState({
          loading: false,
          confirm: {
            ...confirm,
            status: true,
            type: 'fail',
          },
        });
        console.error(err);
      });
  }

  render() {
    const { ddeChType } = this.props;
    const { loading, confirm } = this.state;
    const { match } = this.props;
    const uid = match && match.params.uid;
    let currentCountry = uid
      .replace('demenagement-international-', '')
      .replace('-vers-la-france', '')
      .toUpperCase();

    if (currentCountry === 'ANGLETERRE') currentCountry = 'ROYAUME-UNI';

    return (
      <div className="DevisInternationalForm">
        <p className="DevisInternationalForm__title">
          <strong>
            Pour recevoir un devis gratuit rapidement, merci de renseigner les
            éléments suivants :
          </strong>
        </p>

        <Form
          onSubmit={e => this.updateForm(e)}
          getApi={e => this.setFormApi(e)}
          confirm={confirm}
        >
          <Input
            type="hidden"
            field="DDE_CH_TYPE"
            initialValue={ddeChType || 'particulier'}
          />
          <Input type="hidden" field="DDE_CH_EXPRESS" initialValue="true" />
          <Fusion>
            {ddeChType === 'inter_in' ? (
              <Input
                type="text"
                field="DDE_CH_CH_CP"
                placeholder="Code postal de départ"
                icon="start"
                validateOnBlur
              />
            ) : (
              <Input
                type="text"
                field="DDE_CH_CH_CP"
                placeholder="Code postal de départ"
                icon="start"
                validateOnBlur
              />
            )}
            <Input
              type="text"
              field="DDE_CH_LI_CP"
              placeholder="Code postal d’arrivée"
              icon="pin"
            />
          </Fusion>

          <div className="DevisInternationalForm__row">
            <Select
              type="select"
              field="DDE_CH_CH_PAYS"
              label="Pays de départ*"
              placeholder="Pays de départ"
              validateOnBlur
              initialValue={country.find(
                e => e.toUpperCase() === currentCountry
              )}
            >
              <Option value="" disabled>
                Choisissez...
              </Option>
              {country.map((c, i) => (
                <Option value={c} key={i}>
                  {c}
                </Option>
              ))}
            </Select>
            <Select
              type="select"
              field="DDE_CH_LI_PAYS"
              label="Pays d’arrivée*"
              placeholder="Pays d’arrivée"
              validateOnBlur
              initialValue={
                country.some(e => e.toUpperCase() === currentCountry) &&
                'France'
              }
            >
              <Option value="" disabled>
                Choisissez...
              </Option>
              {country.map((c, i) => (
                <Option value={c} key={i}>
                  {c}
                </Option>
              ))}
            </Select>
            <Input
              type="text"
              field="DDE_CH_NOM"
              label="Nom*"
              placeholder="Nom"
              validate={validator.required}
              validateOnBlur
            />
            <Input
              type="text"
              field="DDE_CH_PRENOM"
              label="Prénom*"
              placeholder="Prénom"
              validate={validator.required}
              validateOnBlur
            />
            <Input
              type="tel"
              field="DDE_CH_TEL"
              label="Téléphone*"
              placeholder="Téléphone"
              validate={validator.required}
              validateOnBlur
            />
            <Input
              type="email"
              field="DDE_CH_EMAIL"
              label="Email*"
              placeholder="Email"
              validate={validator.email}
              validateOnBlur
            />
            <Input
              type="number"
              field="DDE_N_CH_SURFACE"
              label="Surface habitable*"
              placeholder="Surface habitable"
              validate={validator.number}
              validateOnBlur
            />
          </div>

          <Checkbox
            field="acceptation"
            validate={validator.required}
            validateOnChange
          >
            J’accepte d’être recontacté par un membre du réseau Les déménageurs
            bretons afin de traiter ma demande*
          </Checkbox>
          <div className="DevisInternationalForm__mandatory">
            *Champ obligatoire
          </div>

          <div className="DevisInternationalForm__submit">
            <SubmitCaptcha
              field="captcha"
              color="orange"
              disabled={loading}
              submitForm={() => this.formApi.submitForm()}
            >
              Valider
            </SubmitCaptcha>
          </div>
        </Form>
      </div>
    );
  }
}

export default withRouter(DevisInternationalForm);
