import React, { useState, useEffect, useContext } from 'react';
import Form, { Input, TextArea, Submit, validator } from 'components/Form/Form';
import { getUserInformations, postComplementarySurvey } from 'services/cygest';
import Header from '../Header/Header';
import MonDossierContext from '../../MonDossierContext';
import { FormContext } from './EnqueteComplementaireRouter';
import './Enquetes.scss';

let formApi = null;

export default ({
  history,
  match,
  homeFolderPath,
  homeEnquetePath,
  // idAgence,
}) => {
  const { params } = match;
  const stepKey = params.step;
  const { error } = useContext(MonDossierContext);
  const { steps, formState, addFields } = useContext(FormContext);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState({
    status: false,
    type: 'success',
    success: {
      title:
        'Merci pour votre temps nous vous souhaitons bonne chance pour le tirage au sort !',
      // message:
      //   'Merci pour votre temps, vous allez recevoir vos codes cadeaux de 2x15€ valables sur wonderbox.fr et wonderweekend.com Nous vous souhaitons bonne chance pour le tirage au sort !',
    },
    fail: {
      title: "Impossible d'envoyer vos réponses",
      message: 'Merci réessayer ultérieurement',
      element: (
        <button
          type="button"
          onClick={() => {
            setConfirm({ ...confirm, type: 'fail', status: false });
          }}
        >
          Fermer
        </button>
      ),
    },
  });

  useEffect(() => {
    formApi.reset();
    formApi.setValues(formState || {});
    getUserInformations()
      .then(data => {
        formApi.setValues({ email: data.email || '' });
      })
      .catch(error);
  }, [stepKey]);

  const form = steps.filter(e => e.url === stepKey)[0];
  const index = steps.reduce((a, v, i) => (v.url === stepKey ? i : a), 0);
  const isFirst = index === 0;
  const isLast = index === steps.length - 1;
  const nextUrl = isLast ? null : steps[index + 1].url;

  const submitted = () => {
    if (isLast) {
      setLoading(true);

      const reponses = Object.keys(formState)
        .filter(key => /^q-.*$/.test(key))
        .map(key => {
          if (formState[key].reponseId1) {
            return {
              questionId: formState[key].questionId,
              reponseId: `${formState[key].reponseId1}/${formState[key].reponseId2}/${formState[key].reponseId3}`,
            };
          }
          return formState[key];
        });

      postComplementarySurvey({
        reponses,
        commentaire: formState.commentaire,
        email: formState.email,
      })
        .then(() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({ event: 'dossierEnqueteAvecJeux' });

          setLoading(false);
          setConfirm({ ...confirm, type: 'success', status: true });
          setTimeout(() => {
            history.push(homeFolderPath);
          }, 7000);
        })
        .catch(err => {
          error(err);
          setLoading(false);
        });
    } else {
      history.push(`${homeEnquetePath}/${nextUrl}`);
    }
  };

  return (
    <div className="Enquetes">
      <Header
        title={form.title}
        description={<span dangerouslySetInnerHTML={{ __html: form.text }} />}
      />
      <div className="Enquetes__container">
        <div className="Enquetes__content">
          {!form ? (
            <div className="Enquetes__loading" />
          ) : (
            <Form
              form={form.fields}
              onSubmit={submitted}
              onChange={e => addFields(e.values)}
              confirm={confirm}
              getApi={e => {
                formApi = e;
              }}
            >
              {isLast && (
                <div>
                  <TextArea
                    field="commentaire"
                    label="commentaire (limite de 300 caractères)"
                    maxLength="300"
                  />
                  <Input
                    type="email"
                    field="email"
                    label="email * "
                    validate={validator.email}
                    validateOnBlur
                  />
                </div>
              )}
              <div className="Devis__form__mandatory">* champ obligatoire</div>
              <div className="Enquetes__buttons">
                <button
                  type="button"
                  className={`Enquetes__cancel ${isFirst ? '' : '-back'}`}
                  onClick={() =>
                    isFirst ? history.push(homeFolderPath) : history.goBack()
                  }
                  disabled={loading}
                >
                  {isFirst ? 'Annuler' : 'Retour'}
                </button>
                <Submit className="Enquetes__submit" disabled={loading}>
                  {isLast ? 'Valider' : 'Suivant'}
                </Submit>
              </div>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};
