const config = {
  dataBaseName: 'ldbDataBase',
  version: 1,
};

const indexedDB = objectName => ({
  init: async () => {
    if (window.indexedDB) {
      return new Promise(resolve => {
        const request = window.indexedDB.open(
          config.dataBaseName,
          config.version
        );
        request.onupgradeneeded = event => resolve(event.target.result);
      });
    }
    throw new Error('IndexedDB not supported');
  },
  add: datas => {
    if (window.indexedDB) {
      const request = window.indexedDB.open(
        config.dataBaseName,
        config.version
      );
      request.onsuccess = event => {
        const db = event.target.result;
        const objectStore = db
          .transaction(objectName, 'readwrite')
          .objectStore(objectName);
        datas.forEach(data => {
          objectStore.put(data);
        });
      };
    }
  },
  getBy2Indexes: async (indexName, value, indexName2, value2) => {
    if (window.indexedDB) {
      return new Promise(resolve => {
        const request = window.indexedDB.open(
          config.dataBaseName,
          config.version
        );
        request.onsuccess = event => {
          const db = event.target.result;
          const objectStore = db
            .transaction(objectName, 'readwrite')
            .objectStore(objectName);
          const result = [];
          objectStore
            .index(indexName)
            .openKeyCursor(IDBKeyRange.only(value)).onsuccess = e => {
              const cursor = e.target.result;
              if (cursor) {
                objectStore.get(cursor.primaryKey).onsuccess = ev =>
                  result.push(ev.target.result);
                cursor.continue();
              } else {
                resolve(result.filter(res => res[indexName2] === value2));
              }
            };
        };
      });
    }

    throw new Error('IndexedDB not supported');
  },
  getByIndex: async (indexName, values) => {
    if (window.indexedDB) {
      return new Promise(resolve => {
        const request = window.indexedDB.open(
          config.dataBaseName,
          config.version
        );
        request.onsuccess = event => {
          const db = event.target.result;
          const objectStore = db
            .transaction(objectName, 'readwrite')
            .objectStore(objectName);

          const result = [];
          let index = 0;
          const { length } = values;
          values.forEach(value => {
            objectStore
              .index(indexName)
              .openKeyCursor(IDBKeyRange.only(value)).onsuccess = e => {
                const cursor = e.target.result;
                if (cursor) {
                  objectStore.get(cursor.primaryKey).onsuccess = ev =>
                    result.push(ev.target.result);
                  cursor.continue();
                } else {
                  index += 1;
                  if (index === length) resolve(result);
                }
              };
          });
        };
      });
    }

    throw new Error('IndexedDB not supported');
  },
  getAll: async () => {
    if (window.indexedDB) {
      return new Promise(resolve => {
        const request = window.indexedDB.open(
          config.dataBaseName,
          config.version
        );
        request.onsuccess = event => {
          const db = event.target.result;
          const objectStore = db
            .transaction(objectName, 'readwrite')
            .objectStore(objectName);

          objectStore.getAll().onsuccess = e => {
            resolve(e.target.result);
          };
        };
      });
    }

    throw new Error('IndexedDB not supported');
  },
});

export default indexedDB;
