import React, { Component } from 'react';
import './Loading.scss';

export default class Loading extends Component {
  render() {
    return <div className="Loading" />;
  }
}

const LoadingSuspense = () => <div className="Loading">loading...</div>;

export { LoadingSuspense };
