import React, { Component } from 'react';
import ModalContext from './ModalContext';

class ModalProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        id: null,
        style: {},
        autoSize: false,
        status: false,
        title: null,
        datas: null,
      },
      closeEvent: callback => {
        document.addEventListener(
          'closeModal',
          () => {
            const { params } = this.state;
            if (callback) callback(params.id);
          },
          false
        );
      },
      close: () => {
        document.dispatchEvent(new CustomEvent('closeModal'));

        this.setState(state => ({
          ...state,
          params: {
            ...state.params,
            status: false,
          },
        }));
      },
      new: params => {
        this.setState(state => ({
          ...state,
          params: {
            id: params.id || null,
            style: params.style || {},
            autoSize: params.autoSize,
            status: !state.params.status,
            title: params.title,
            datas: { ...params.datas },
          },
        }));
      },
      toggle: params => {
        this.setState(state => ({
          ...state,
          params: {
            ...state.params,
            id: params.id || null,
            style: params.style || {},
            autoSize: params ? params.autoSize : state.params.autoSize,
            status: !state.params.status,
            title: params ? params.title : state.params.title,
            datas: params ? params.datas : state.params.datas,
          },
        }));
      },
    };
  }

  render() {
    const { children } = this.props;
    return (
      <ModalContext.Provider value={{ modal: { ...this.state } }}>
        {children}
      </ModalContext.Provider>
    );
  }
}

export default ModalProvider;
