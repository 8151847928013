import React, { useState, useContext, useEffect } from 'react';
import Form, {
  RadioGroup,
  RadioBasic,
  Input,
  Submit,
} from 'components/Form/Form';
import {
  getUserMethodeType,
  postUserMethodeType,
  postUserVolume,
} from 'services/cygest';
import MonDossierContext from '../../../MonDossierContext';
import Header from '../../Header/Header';
import './CubageMethod.scss';

export default ({ homeFolderPath, history }) => {
  let formApi = null;
  const { error } = useContext(MonDossierContext);
  const [pending, setPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState({
    status: false,
    type: 'success',
    success: {
      title: 'Votre volume a bien été enregistré',
    },
    fail: {
      title: 'Impossible de sauvegarder vos modifications.',
      message: 'Merci réessayer ultérieurement',
      element: (
        <button
          type="button"
          onClick={() => setConfirm({ ...confirm, status: false })}
        >
          Fermer
        </button>
      ),
    },
  });

  useEffect(() => {
    setPending(true);
    getUserMethodeType()
      .then(data => {
        setPending(false);
        formApi.setValues(data);
      })
      .catch(error);
  }, []);

  function setFormApi(e) {
    formApi = e;
  }

  function submitted(formState) {
    setLoading(true);

    postUserMethodeType({ method: formState.method })
      .then(() => {
        setLoading(false);
        if (formState.method === 'maison3d') {
          history.push(`${history.location.pathname}/maison3d`);
        } else if (formState.method === 'liste') {
          history.push(`${history.location.pathname}/liste`);
        } else if (formState.method === 'volume') {
          postUserVolume({ volume: formState.volume })
            .then(() => {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({ event: 'dossierListeMobilier' });

              setConfirm({ ...confirm, type: 'success', status: true });
              setTimeout(() => {
                history.push(homeFolderPath);
              }, 2000);
            })
            .catch(error);
        }
      })
      .catch(err => {
        error(err);
        setLoading(false);
      });
  }

  return (
    <div className="CubageMethod">
      <Header
        title="Détail de vos biens"
        description="Paramétrez le cubage de vos biens. Plusieurs possibilités s’offrent à vous :"
      />
      <div className="CubageMethod__content">
        <Form
          onSubmit={submitted}
          getApi={setFormApi}
          pending={pending}
          confirm={confirm}
        >
          {({ formState }) => (
            <>
              <RadioGroup
                field="method"
                className="CubageMethod__radioGroup"
                validate={e =>
                  !e ? 'Merci de choisir une methode de remplissage' : undefined
                }
              >
                <RadioBasic className="CubageMethod__radio" value="maison3d">
                  <div className="CubageMethod__radio__label">
                    <span className="CubageMethod__radio__label__status" />
                    <span className="CubageMethod__radio__label__text">
                      soit vous faites l'inventaire de vos biens mobiliers pièce
                      par pièce à l’aide de notre logement interactive,
                    </span>
                  </div>
                  <div className="CubageMethod__radio__group">
                    <div className="CubageMethod__radio__img -maison3d" />
                    <div className="CubageMethod__radio__status" />
                  </div>
                </RadioBasic>
                <RadioBasic className="CubageMethod__radio" value="liste">
                  <div className="CubageMethod__radio__label">
                    <span className="CubageMethod__radio__label__status" />
                    <span className="CubageMethod__radio__label__text">
                      soit vous listez vos pièces et vos biens,
                    </span>
                  </div>
                  <div className="CubageMethod__radio__group">
                    <div className="CubageMethod__radio__img -liste" />
                    <div className="CubageMethod__radio__status" />
                  </div>
                </RadioBasic>
                <RadioBasic className="CubageMethod__radio" value="volume">
                  <div className="CubageMethod__radio__label">
                    <span className="CubageMethod__radio__label__status" />
                    <span className="CubageMethod__radio__label__text">
                      soit vous connaissez déjà le m3 total des biens de votre
                      logement.
                    </span>
                  </div>
                  <div className="CubageMethod__radio__group">
                    <div className="CubageMethod__radio__img">
                      <Input
                        type="number"
                        field="volume"
                        label="Volume en m3"
                        placeholder="Volume"
                        disabled={formState.values.method !== 'volume'}
                        className="CubageMethod__radio__inputText"
                        validate={e =>
                          !e && formState.values.method === 'volume'
                            ? 'Merci de renseigner ce champs'
                            : undefined
                        }
                      />
                    </div>
                    <div className="CubageMethod__radio__status" />
                  </div>
                </RadioBasic>
              </RadioGroup>
              <div className="CubageMethod__buttons">
                <button
                  type="button"
                  className="CubageMethod__back"
                  onClick={() => history.push(homeFolderPath)}
                  disabled={loading}
                >
                  Retour
                </button>
                <Submit className="CubageMethod__submit" disabled={loading}>
                  {formState.values.method !== 'volume'
                    ? 'Suivant'
                    : 'Enregistrer'}
                </Submit>
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};
