import React, { Component } from 'react';
import Table from './Table/Table';
import ListServices from './ListServices/ListServices';
import LinkCarousel from './LinkCarousel/LinkCarousel';
import Paragraph from './Paragraph/Paragraph';
import Paragraph1 from './Paragraph1/Paragraph1';
import International from './International/International';
import DoubleCol from './DoubleCol/DoubleCol';
import DoubleColumns from './DoubleColumns/DoubleColumns';
import TwoColumns from './TwoColumns/TwoColumns';
import MuliColumns from './MuliColumns/MuliColumns';
import QuickEstimate from './QuickEstimate/QuickEstimate';
import Video from './Video/Video';
import FormMutationPro from './FormMutationPro/FormMutationPro';
import FormRelocation from './FormRelocation/FormRelocation';
import FormGardeMeubles from './FormGardeMeubles/FormGardeMeubles';
import FaqSlice from './FaqSlice/FaqSlice';
import StoreLocator from './StoreLocator/StoreLocator';
import Logo from './Logo/Logo';
import DevisInternational from './DevisInternational/DevisInternational';

import GlobalModule from './GlobalModule/GlobalModule';

export default class Slice extends Component {
  render() {
    const { slice, history } = this.props;

    return (
      <div>
        {slice.map((e, i) => {
          switch (e.slice_type) {
            case 'tableau':
              return <Table key={i} table={e} />;

            case 'liste_service':
              return <ListServices key={i} list={e} />;

            case 'carrousel_de_liens':
              return <LinkCarousel key={i} list={e} />;

            case 'paragraphe':
              return <Paragraph key={i} datas={e} />;

            case 'paragraphe1':
              return <Paragraph1 key={i} datas={e} />;

            case 'international':
              return <International key={i} datas={e} />;

            case 'double_colonne':
              return <DoubleCol key={i} datas={e} />;

            case '2_colonnes':
              return <TwoColumns key={i} datas={e} />;

            case 'double_colonnes':
              return <DoubleColumns key={i} datas={e} />;

            case 'multiples_colonnes':
              return <MuliColumns key={i} datas={e} />;

            case 'devis_rapide':
              return <QuickEstimate key={i} datas={e} />;

            case 'devis_international':
              return <DevisInternational key={i} datas={e} />;

            case 'logo':
              return <Logo key={i} datas={e} />;

            case 'modules_globaux':
              return <GlobalModule key={i} datas={e} />;

            case 'video':
              return <Video key={i} datas={e} />;

            case 'formulaire_mutation_pro':
              return <FormMutationPro key={i} datas={e} />;

            case 'formulaire_relocation':
              return <FormRelocation key={i} datas={e} />;

            case 'formulaire_garde-meubles':
              return <FormGardeMeubles key={i} datas={e} />;

            case 'faq':
              return <FaqSlice key={i} datas={e} />;

            case 'store_locator':
              return <StoreLocator key={i} datas={e} history={history} />;

            default:
              return null;
          }
        })}
      </div>
    );
  }
}
