import React, { Component } from 'react';
import Form, {
  Input,
  Checkbox,
  Select,
  Option,
  File,
  Submit,
  validator,
} from '../../../components/Form/Form';
import { postEstimateFormStep3 } from '../../../services/cygest';
import { Link, RichText } from '../../../components/Prismic/Prismic';
import DevisContext from '../DevisContext';

class Etape3 extends Component {
  static contextType = DevisContext;

  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      required: true,
      loading: false,
    };
  }

  render() {
    const { history, title, prev, next } = this.props;
    const { disabled, required, loading } = this.state;
    const { form, update } = this.context;
    const updateForm = async formState => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'Demande de devis - step 3',
      });
      let formUpdated = {};
      if (formState.DDE_N_PAS_DE_LOGEMENT) {
        formUpdated = {
          demandeId: formState.demandeId,
          DDE_N_PAS_DE_LOGEMENT: formState.DDE_N_PAS_DE_LOGEMENT,
        };
      } else {
        formUpdated = { ...formState };
      }
      this.setState({ loading: true });
      const finalForm = await update(formUpdated);
      const filteredForm = {
        type: 'devis',
        demandeId: finalForm.demandeId,
        DDE_CH_TYPE: finalForm.DDE_CH_TYPE,
        DDE_CH_MUTATION_ENTREPRISE: finalForm.DDE_CH_MUTATION_ENTREPRISE,
        DDE_CH_NOM: finalForm.DDE_CH_NOM,
        DDE_CH_PRENOM: finalForm.DDE_CH_PRENOM,
        DDE_CH_TEL: finalForm.DDE_CH_TEL,
        DDE_CH_EMAIL: finalForm.DDE_CH_EMAIL,
        DDE_CH_CH_CP: finalForm.DDE_CH_CH_CP,
        DDE_CH_LI_CP: finalForm.DDE_CH_LI_CP,
        acceptation: finalForm.acceptation,
        captcha: finalForm.captcha,

        DDE_CH_CH_ADR1: finalForm.DDE_CH_CH_ADR1,
        DDE_CH_CH_VILLE: finalForm.DDE_CH_CH_VILLE,
        DDE_CH_CH_TYPE_LOGEMENT: finalForm.DDE_CH_CH_TYPE_LOGEMENT,
        DDE_CH_CH_LOCAPROPRIO: finalForm.DDE_CH_CH_LOCAPROPRIO,
        DDE_N_CH_SURFACE: finalForm.DDE_N_CH_SURFACE,
        DDE_CH_SPEC: finalForm.DDE_CH_SPEC,

        DDE_N_PAS_DE_LOGEMENT: false,
        DDE_CH_LI_ADR1: finalForm.DDE_CH_LI_ADR1,
        DDE_CH_LI_VILLE: finalForm.DDE_CH_LI_VILLE,
        DDE_CH_LI_TYPE_LOGEMENT: finalForm.DDE_CH_LI_TYPE_LOGEMENT,
        fileNew: finalForm.fileNew,
      };

      postEstimateFormStep3(filteredForm)
        .then(() => {
          history.push(next);
        })
        .catch(err => {
          console.error(err.response, finalForm);
          if (err.response && typeof err.response.data === 'object') {
            const errors = [];
            Object.keys(err.response.data).forEach(e => {
              errors.push(`${e}: ${err.response.data[e]}`);
            });
            alert(errors.join('\n'));
          } else {
            alert(
              "Impossible d'envoyer ce formulaire.\nMerci de réessayer ultérieurement."
            );
          }
          this.setState({ loading: false });
        });
    };
    const onChange = formState => {
      update(formState.values);
    };

    return (
      <div>
        <RichText className="Devis__form__title">{title}</RichText>
        <Form onSubmit={updateForm} onChange={onChange}>
          <Input
            type="hidden"
            field="demandeId"
            initialValue={form.demandeId || ''}
          />
          <Checkbox
            field="DDE_N_PAS_DE_LOGEMENT"
            initialValue={form.DDE_N_PAS_DE_LOGEMENT || ''}
            onChange={({ target }) => {
              this.setState({
                disabled: target.checked,
                required: !target.checked,
              });
            }}
            validateOnChange
          >
            Je n&apos;ai pas encore de logement à l&apos;arrivée
          </Checkbox>

          <Input
            type="text"
            field="DDE_CH_LI_ADR1"
            label="Nouvelle adresse*"
            placeholder="Nouvelle adresse"
            initialValue={form.DDE_CH_LI_ADR1 || ''}
            validate={formState =>
              required ? validator.required(formState) : undefined
            }
            disabled={disabled}
            validateOnBlur
          />

          <div className="Devis__form__2colFixedNew">
            <div>
              <Input
                type="text"
                field="DDE_CH_LI_CP"
                label="Nouveau code postal*"
                placeholder="Nouveau code postal"
                initialValue={form.DDE_CH_LI_CP || ''}
                validate={formState =>
                  required ? validator.number(formState) : undefined
                }
                disabled={disabled}
                validateOnBlur
              />
            </div>
            <div>
              <Input
                type="text"
                field="DDE_CH_LI_VILLE"
                label="Nouvelle ville*"
                placeholder="Nouvelle ville"
                initialValue={form.DDE_CH_LI_VILLE || ''}
                validate={formState =>
                  required ? validator.required(formState) : undefined
                }
                disabled={disabled}
                validateOnBlur
              />
            </div>
          </div>

          <div className="Devis__form__2col">
            <div>
              <Select
                field="DDE_CH_LI_TYPE_LOGEMENT"
                label="Nouveau type de logement*"
                initialValue={form.DDE_CH_LI_TYPE_LOGEMENT || ''}
                validate={formState =>
                  required ? validator.required(formState) : undefined
                }
                validateOnChange
                disabled={disabled}
              >
                <Option value="" disabled>
                  Choisissez...
                </Option>
                <Option value="appartement">Appartement</Option>
                <Option value="maison">Maison</Option>
              </Select>
            </div>
          </div>

          <File
            field="fileNew"
            initialValue={form.fileNew || ''}
            validate={validator.upload}
            validateOnChange
            disabled={disabled}
          />

          <div className="Devis__form__mandatory">*Champ obligatoire</div>

          <Link to={prev} className="Devis__form__back" disabled={loading}>
            Retour
          </Link>
          <Submit className="Devis__form__submit" disabled={loading}>
            Valider
          </Submit>
        </Form>
      </div>
    );
  }
}

export default Etape3;
