import React, { useState, useEffect } from 'react';
import Loading from 'components/Loading/Loading';
import { Switch, Route, Redirect } from 'react-router-dom';
import { getComplementarySurvey } from 'services/cygest';

import NotFound from 'layout/NotFound/NotFound';
import EnqueteComplementaire from './EnqueteComplementaire';

const FormContext = React.createContext();

const useForm = () => {
  const [state, setState] = useState({});

  function setForm(value) {
    const NewValue = JSON.parse(JSON.stringify(value));
    setState({ ...state, ...NewValue });
  }

  return [state, setForm];
};

export default ({ homeFolderPath, homeEnquetePath, location, match }) => {
  const { idAgence } = match.params;
  const [steps, setSteps] = useState([]);
  const [formState, setForm] = useForm();
  const urls = steps.map(e => e.url);
  const addFields = fields => setForm(fields);

  useEffect(() => {
    getComplementarySurvey()
      .then(setSteps)
      .catch(() => {});
  }, []);

  return (
    <FormContext.Provider value={{ steps, formState, addFields }}>
      {steps.length ? (
        <Switch>
          {location.pathname === `${homeEnquetePath}` && (
            <Redirect
              from={`${homeEnquetePath}`}
              to={`${homeEnquetePath}/${steps[0].url}`}
            />
          )}
          <Route
            exact
            path={`${homeEnquetePath}/:step(${urls.join('|')})`}
            render={props => (
              <EnqueteComplementaire
                homeFolderPath={homeFolderPath}
                homeEnquetePath={homeEnquetePath}
                idAgence={idAgence}
                {...props}
              />
            )}
          />
          <Route status={404} render={props => <NotFound back {...props} />} />
        </Switch>
      ) : (
        <Loading />
      )}
    </FormContext.Provider>
  );
};

export { FormContext };
