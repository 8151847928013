import React, { Component } from 'react';
import { RichText, Link } from '../../Prismic/Prismic';
import './Paragraph.scss';

class Paragraph extends Component {
  render() {
    return (
      <div className="Paragraph">
        <RichText className="Paragraph__title">
          {this.props.datas.primary.title}
        </RichText>
        <RichText className="Paragraph__text">
          {this.props.datas.primary.text}
        </RichText>
        <Link to={this.props.datas.primary.link} className="Paragraph__link">
          {this.props.datas.primary.button_name}
        </Link>
      </div>
    );
  }
}

export default Paragraph;
