import React, { Component } from 'react';

class ImgUploaded extends Component {
  constructor(props) {
    super(props);
    this.state = { src: null };
  }

  componentDidMount() {
    const { file } = this.props;

    this.base64(file);
  }

  componentDidUpdate({ file: oldFile }) {
    const { file } = this.props;

    if (oldFile.name !== file.name) {
      this.base64(file);
    }
  }

  async base64(file) {
    if (['image/gif', 'image/png', 'image/jpeg'].includes(file.type)) {
      const reader = new FileReader();
      reader.onload = e => this.setState({ src: e.target.result });
      reader.readAsDataURL(file);
    } else {
      this.setState({ src: null });
    }
  }

  render() {
    const { src } = this.state;
    const style = src ? { backgroundImage: `url(${src})` } : {};

    return <div className="files__item__img" style={style} />;
  }
}

export default ImgUploaded;
