import React, { useState } from 'react';
import Col from 'components/Col';
import { Switch, Route } from 'react-router-dom';
import { postFiles, deleteFile } from 'services/cygest';
import Header from '../Header/Header';

import './FileUpload.scss';

export default ({
  currentPath,
  homeFolderPath,
  type,
  userDashboard,
  props,
}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const dashboard =
    userDashboard.projet &&
    userDashboard.projet.reference &&
    userDashboard.projet.reference.documents;

  const onFileUpload = async () => {
    setLoading(true);
    await postFiles({ files: file }, type);
    setLoading(false);
    props.history.goBack();
  };

  const handleTitle = type => {
    switch (type) {
      case 'devis':
        return 'Devis';
      case 'facture':
        return 'Facture acquittée';
      case 'lettre':
        return 'Lettre de voiture';
      default:
        return null;
    }
  };

  const handleDesc = type => {
    switch (type) {
      case 'devis':
        return null;
      case 'facture':
        return null;
      case 'lettre':
        return 'Votre lettre de voiture livraison signée';
      default:
        return null;
    }
  };

  const handleFile = event => {
    setFile(event.target.files[0]);
  };

  const handleNameFile = () => {
    switch (type) {
      case 'devis':
        if (file) {
          return <div className="File">{file.name}</div>;
        }
        if (
          !file &&
          dashboard &&
          dashboard.devis &&
          dashboard.devis.length !== 0
        ) {
          return (
            <div className="File">
              {dashboard &&
                dashboard.devis &&
                dashboard.devis[0] &&
                dashboard.devis[0].filename.substr(5)}
            </div>
          );
        }
        return null;

      case 'facture':
        if (file) {
          return <div className="File">{file.name}</div>;
        }
        if (
          !file &&
          dashboard &&
          dashboard.facture &&
          dashboard.facture.length !== 0
        ) {
          return (
            <div className="File">
              {dashboard &&
                dashboard.facture &&
                dashboard.facture[0] &&
                dashboard.facture[0].filename.substr(5)}
            </div>
          );
        }
        return null;

      case 'lettre':
        if (file) {
          return <div className="File">{file.name}</div>;
        }
        if (
          !file &&
          dashboard &&
          dashboard.lettre &&
          dashboard.lettre.length !== 0
        ) {
          return (
            <div className="File">
              {dashboard &&
                dashboard.lettre &&
                dashboard.lettre[0] &&
                dashboard.lettre[0].filename.substr(5)}
            </div>
          );
        }
        return null;

      default:
        return null;
    }
  };

  const reset = async () => {
    switch (type) {
      case 'devis':
        if (
          dashboard &&
          dashboard.devis &&
          dashboard.devis[0] &&
          dashboard.devis[0].id
        ) {
          setLoading(true);
          await deleteFile(
            type,
            dashboard &&
              dashboard.devis &&
              dashboard.devis[0] &&
              dashboard.devis[0].id
          );
          setLoading(false);
          props.history.goBack();
        } else if (
          file &&
          dashboard &&
          dashboard.devis &&
          dashboard.devis.length === 0
        ) {
          setFile(null);
          document.getElementById('imageUpload').value = '';
        }
        break;
      case 'facture':
        if (
          dashboard &&
          dashboard.facture &&
          dashboard.facture[0] &&
          dashboard.facture[0].id
        ) {
          setLoading(true);
          await deleteFile(
            type,
            dashboard &&
              dashboard.facture &&
              dashboard.facture[0] &&
              dashboard.facture[0].id
          );
          setLoading(false);
          props.history.goBack();
        } else if (
          file &&
          dashboard &&
          dashboard.facture &&
          dashboard.facture.length === 0
        ) {
          setFile(null);
          document.getElementById('imageUpload').value = '';
        }
        break;
      case 'lettre':
        if (
          dashboard &&
          dashboard.lettre &&
          dashboard.lettre[0] &&
          dashboard.lettre[0].id
        ) {
          setLoading(true);
          await deleteFile(
            type,
            dashboard &&
              dashboard.lettre &&
              dashboard.lettre[0] &&
              dashboard.lettre[0].id
          );
          setLoading(false);
          props.history.goBack();
        } else if (
          file &&
          dashboard &&
          dashboard.lettre &&
          dashboard.lettre.length === 0
        ) {
          setFile(null);
          document.getElementById('imageUpload').value = '';
        }
        break;
      default:
        return null;
    }
  };

  const backNav = props => {
    props.history.goBack();
  };

  return (
    <div className={`Container__File ${loading ? '-loading' : ''}`}>
      <Header title={handleTitle(type)} description={handleDesc(type)} />
      <div className="File__container">
        <div className="File__content">
          <div className="File__grid">
            <Col className="File__grid__right">
              <Switch>
                <Route
                  render={props => (
                    <div>
                      <Col className="File__grid__left">
                        Choisissez un fichier depuis votre ordinateur via le
                        bouton ci-dessous.
                      </Col>
                      <input
                        type="file"
                        name="imageUpload"
                        id="imageUpload"
                        className="hide"
                        onChange={handleFile}
                        accept="image/jpeg,image/png,application/pdf"
                      />
                      <label htmlFor="imageUpload" className="btn">
                        Choisir un fichier
                      </label>
                      <div className="File__file">
                        {handleNameFile()}
                        {handleNameFile() !== null && (
                          <div>
                            <button
                              type="reset"
                              name="reset"
                              id="reset"
                              onClick={reset}
                              className="hide"
                            >
                              Supprimer fichier
                            </button>
                            <label
                              htmlFor="reset"
                              name="reset"
                              id="reset"
                              className="btnDelete"
                            >
                              Supprimer fichier
                            </label>
                          </div>
                        )}
                      </div>
                      <div className="File__buttons">
                        <button
                          type="button"
                          className="File__back"
                          onClick={() => backNav(props)}
                        >
                          Retour
                        </button>
                        <button
                          type="button"
                          className="File__submit"
                          onClick={onFileUpload}
                          disabled={!file}
                        >
                          Valider
                        </button>
                      </div>
                    </div>
                  )}
                />
              </Switch>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
};
