import React from 'react';
import { MonDossierProvider } from './MonDossierContext';
import MonDossierRouter from './MonDossierRouter';

const MonDossier = props => (
  <MonDossierProvider>
    <MonDossierRouter {...props} />
  </MonDossierProvider>
);

export default MonDossier;
