import React, { Component } from 'react';
import Animate from '../Animate/Animate';
import './Scrolling.scss';

class Scrolling extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { children } = this.props;
    const { current } = this.myRef;
    const { length } = children;
    const distance = 146 * length;
    const duration = 2000 * length;

    Animate(
      {
        fromTo: [0, distance],
        loop: true,
        duration,
        easing: 'linear',
      },
      res => {
        current.style.transform = `translate3d(-${res}px, 0, 0)`;
      }
    );
  }

  render() {
    const { children } = this.props;

    return (
      <div className="Scrolling">
        <div className="Scrolling__content" ref={this.myRef}>
          {children}
          {children}
        </div>
      </div>
    );
  }
}

const ScrollingItem = ({ children }) => (
  <div className="Scrolling__item">{children}</div>
);

export default Scrolling;
export { ScrollingItem };
