import React, { Component } from 'react';
import { RichText, Link } from 'components/Prismic/Prismic';
import DevisContext from '../DevisContext';

class Etape3 extends Component {
  static contextType = DevisContext;

  render() {
    const { form } = this.context;
    const { datas } = this.props;
    const { step4_title: step4Title } = datas.data;

    return (
      <div>
        <RichText className="Devis__form__end__title">{step4Title}</RichText>
        <div className="Devis__form__text">
          Vous pouvez aller plus loin dans le détail de votre projet pour nous
          aider à mieux définir votre besoin et bénéficier d’un devis plus
          détaillé.
        </div>
        <div className="Devis__form__center">
          {form.registerToken ? (
            <Link
              to={`/votre-dossier/inscription/etape-5/${form.registerToken}/${
                form.uid
              }`}
              className="Devis__form__button"
            >
              Créer votre mot de passe
            </Link>
          ) : (
            <div>
              Votre compte a déja été créé.{' '}
              <Link to="/votre-dossier/">Accéder a mon compte</Link>.
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Etape3;
