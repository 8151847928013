import React from 'react';
import { Radio as InRadio } from 'informed';

const Radio = props => {
  const {
    fieldState,
    fieldApi,
    children,
    forwardedRef,
    disabled,
    ...rest
  } = props;
  const uniqid = `radio-${Math.round(Math.random() * 100000)}`;
  return (
    <label
      className={`Form__radio ${disabled ? '-disabled' : ''}`}
      htmlFor={uniqid}
    >
      <InRadio id={uniqid} disabled={disabled} {...rest} />
      <span className="Form__radio__children">
        <span>{children}</span>
      </span>
    </label>
  );
};

export default Radio;
