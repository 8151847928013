import React, { useState, useEffect, useContext } from 'react';
import Form, { Input, validator, SubmitCaptcha } from 'components/Form/Form';
import { Link } from 'components/Prismic/Prismic';
import {
  getLoggedOutSatisfactionSurvey,
  postLoggedOutSatisfactionSurvey,
} from 'services/cygest';
import Header from '../Header/Header';
import './EnquetesLoggedOut.scss';

export default ({ history, homeFolderPath, match, userMailContext }) => {
  const { setUserMail } = useContext(userMailContext);
  let formApi = null;
  const { agenceId } = match.params;
  const [form, setForm] = useState([]);

  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState({
    status: false,
    type: 'success',
    success: {
      title: 'Merci, vos réponses ont bien été enregistrées.',
      element: (
        <div>
          <p className="Enquetes__next__info">
            Remplissez notre enquête complémentaire « mieux vous connaître » et{' '}
            <strong>tentez de gagner 1000€</strong> de chèque cadeau TirGroupé
            by Sodexo.
          </p>
          <Link
            to={`${homeFolderPath}/enquete-complementaire-avec-jeux-concours/${agenceId}`}
            className="Enquetes__next__button"
          >
            Enquête complémentaire
          </Link>
        </div>
      ),
    },
    fail: {
      title: "Impossible d'envoyer vos réponses",
      message: 'Merci réessayer ultérieurement',
      element: (
        <button
          type="button"
          onClick={() => {
            setConfirm({ ...confirm, type: 'fail', status: false });
          }}
        >
          Fermer
        </button>
      ),
    },
  });

  useEffect(() => {
    getLoggedOutSatisfactionSurvey()
      .then(data => {
        setForm(data);
      })
      .catch(() => {});
  }, []);

  const submitted = formState => {
    setLoading(true);

    setUserMail(formState.email);

    const reponses = Object.keys(formState)
      .filter(key => /^q-.*$/.test(key))
      .map(key => formState[key]);

    postLoggedOutSatisfactionSurvey({
      reponses,
      agenceId,
      email: formState.email,
      captcha: formState.captcha,
    })
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'dossierEnqueteAvecJeux' });

        setLoading(false);
        setConfirm({ ...confirm, type: 'success', status: true });
      })
      .catch(err => {
        console.error(err.response);
        setLoading(false);
        setConfirm({ ...confirm, type: 'fail', status: true });
      });
  };

  function setFormApi(e) {
    formApi = e;
  }

  return (
    <div className="Enquetes">
      <Header
        title="Enquête satisfaction"
        description="Vous avez récemment déménagé et dans le cadre de l’amélioration permanente de nos services, nous souhaitons recueillir votre satisfaction globale en quelques clics."
      />
      <div className="Enquetes__container">
        <div className="Enquetes__content">
          {!form.length ? (
            <div className="Enquetes__loading" />
          ) : (
            <Form
              form={form}
              onSubmit={submitted}
              confirm={confirm}
              getApi={setFormApi}
            >
              <Input
                type="email"
                field="email"
                label="email * "
                validate={validator.email}
                validateOnBlur
              />
              <div className="Devis__form__mandatory">* champ obligatoire</div>
              <div className="Enquetes__buttons">
                <button
                  type="button"
                  className="Enquetes__back"
                  onClick={() => history.goBack()}
                  disabled={loading}
                >
                  Retour
                </button>
                <SubmitCaptcha
                  className="Enquetes__submit"
                  field="captcha"
                  color="green"
                  disabled={loading}
                  submitForm={() => formApi.submitForm()}
                >
                  Valider
                </SubmitCaptcha>
              </div>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};
