import React, { useState, useLayoutEffect } from 'react';
import { getCodeINSEEByCP, getCPByGeolocalisation } from 'services/cygest';
import './Meteo.scss';

export default props => {
  const [code, setCode] = useState(null);

  async function getPosition() {
    const cp = await getCPByGeolocalisation();
    const codeINSEE = await getCodeINSEEByCP(cp || props.meteo_cp || 75001);
    setCode(
      codeINSEE.data[0].code === '75056' ? 75101 : codeINSEE.data[0].code
    );
  }

  useLayoutEffect(() => {
    getPosition();
  }, []);

  if (!code) return null;

  return (
    <div>
      <h2 className="MeteoTitle">Votre<strong>météo</strong></h2>
      <iframe
        className="MeteoHome"
        id="widget_autocomplete_preview"
        width="300"
        height="150"
        frameBorder="0"
        src={`https://meteofrance.com/widget/prevision/${code}0`}
        title="météo"
      />
    </div>
  );
};
