import React, { Component } from 'react';
import './Ratio.scss';

class Ratio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
    };

    this.element = React.createRef();
  }

  componentDidMount() {
    this.calc();
    window.onload = () => {
      requestAnimationFrame(() => this.calc());
    };
    window.addEventListener('resize', () => {
      requestAnimationFrame(() => this.calc());
    });
    window.addEventListener('scroll', () => {
      requestAnimationFrame(() => this.calc());
    });
  }

  calc() {
    const { ratio } = this.props;
    const { current } = this.element;
    if (!current) return;
    const width = current.offsetWidth;
    const height = width / ratio;

    this.setState({ height });
  }

  render() {
    const { height } = this.state;
    const { children, className, ...rest } = this.props;
    return (
      <div
        className={`Ratio ${className}`}
        {...rest}
        ref={this.element}
        style={{ height }}
      >
        {children}
      </div>
    );
  }
}

export default Ratio;
