import React from 'react';
import Modules from '../../Modules/Modules';

class GlobalModule extends React.Component {
  render() {
    const { datas } = this.props;
    return <Modules module={datas.primary.relationship_field} />;
  }
}

export default GlobalModule;
