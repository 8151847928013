import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { postPushNotification } from 'services/cygest';

const initializedFirebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_PROJECTBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
});

let token = null;

export default {
  init: () =>
    new Promise((resolve, reject) => {
      if (!token && process.env.REACT_APP_MEASUREMENTID) {
        try {
          const messaging = initializedFirebaseApp.messaging();
          messaging.usePublicVapidKey(process.env.REACT_APP_PUBLICKEY);
          messaging
            .requestPermission()
            .then(async () => {
              token = await messaging.getToken();
              postPushNotification(token);
              resolve(token);
            })
            .catch(err => {
              console.log('Unable to get permission to notify.', err);
            });
        } catch (error) {
          reject(error);
        }
      }
    }),
};
