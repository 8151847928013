import React, { Component } from 'react';
import { ModalContext } from 'components/Modal/Modal';
import {
  postLogout,
  getUserDashboard,
  getCodeINSEEByCP,
  getUserInformations,
} from 'services/cygest';
import pushNotifications from '../../services/PushNotifications';

const MonDossierContext = React.createContext();
let userMeteo = false;
let userInfo = null;

class MonDossierProvider extends Component {
  static contextType = ModalContext;

  constructor(props) {
    super(props);

    const userRaw = localStorage.getItem('user');
    const user = JSON.parse(userRaw);

    const userDashboardRaw = localStorage.getItem('userDashboard');
    const userDashboard = JSON.parse(userDashboardRaw) || false;

    this.state = {
      user,
      userInfo: null,
      connect: userData => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'dossierConnexion',
        });

        this.setState({ user: userData });
        localStorage.setItem('user', JSON.stringify(userData));

        const { userDashboard: ud, getUserDashboard: gud } = this.state;
        if (!ud) gud().then(() => pushNotifications.init());
      },
      disconnect: () => {
        postLogout();
        localStorage.removeItem('user');
        this.setState({ user: null, userDashboard: null });
      },
      error: (err = {}) => {
        const { modal } = this.context;
        const { disconnect } = this.state;
        const { response = {} } = err;

        console.log('error ---> ', response);

        if (response.status === 401) {
          disconnect();
          modal.toggle({ autoSize: true, title: 'Vous êtes deconnecté' });
        } else {
          modal.toggle({
            autoSize: true,
            title: `Erreur ${response.status || 'inconnue'}`,
            datas: (
              <div style={{ padding: '30px' }}>
                <p style={{ fontWeight: '500' }}>
                  Il semblerait que le serveur rencontre quelques difficultés.
                </p>
                <p>Merci de réessayer ultérieurement.</p>
              </div>
            ),
          });
        }
      },
      token: '',
      userDashboard,
      getUserDashboard: () => {
        const userDashboardPromise = new Promise((resolve, reject) => {
          getUserDashboard()
            .then(data => {
              this.setState({ userDashboard: data });
              localStorage.setItem('agence', JSON.stringify(data));
              resolve(data);
            })
            .catch(reject);
        });

        return {
          pending: callback => {
            const { userDashboard: ud } = this.state;
            if (callback) callback(ud);
            return userDashboardPromise;
          },
          then: data => userDashboardPromise.then(data),
        };
      },
      getUserMeteo: () =>
        new Promise(resolve => {
          if (userMeteo) {
            resolve(userMeteo);
          } else if (userInfo) {
            getCodeINSEEByCP(userInfo.departCodePostal).then(codeINSEE => {
              if (codeINSEE.data.length) {
                userMeteo =
                  codeINSEE.data[0].code === '75056'
                    ? 75101
                    : codeINSEE.data[0].code;
                resolve(userMeteo);
              }
            });
          } else {
            getUserInformations().then(d => {
              getCodeINSEEByCP(d.departCodePostal).then(codeINSEE => {
                if (codeINSEE.data.length) {
                  userMeteo =
                    codeINSEE.data[0].code === '75056'
                      ? 75101
                      : codeINSEE.data[0].code;
                  resolve(userMeteo);
                }
              });
            });
          }
        }),
      getUserInfo: () =>
        new Promise(resolve => {
          if (userInfo) {
            resolve(userInfo);
          } else {
            getUserInformations().then(d => {
              this.setState({ userInfo: d });
              userInfo = d;
              resolve(userInfo);
            });
          }
        }),
    };
  }

  componentDidMount() {
    const { user, getUserDashboard: gud, getUserInfo } = this.state;

    if (user) {
      getUserInfo();
      gud().then(() => pushNotifications.init());
    }
  }

  render() {
    const { children } = this.props;
    return (
      <MonDossierContext.Provider value={{ ...this.state }}>
        {children}
      </MonDossierContext.Provider>
    );
  }
}

export default MonDossierContext;
export { MonDossierProvider };
