/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import SVG from 'react-inlinesvg';
import img from './img/carte-de-france-2017-hd.jpg';
import './StoreLocator.scss';
import AutoComplete from '../../../layout/Header/Shortcut/AutoComplete';
import { getAgenceByCp } from '../../../services/cygest';
import config from '../../../config';

import twitter from '../../../img/twitter.svg';
import facebook from '../../../img/facebook.svg';

class StoreLocator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notFound: false,
      value: null,
      modal: {
        status: false,
        component: null,
      },
      menuStatus: false,
      agence: null,
      closeAll: () => {
        this.setState(state => ({
          menuStatus: false,
          modal: {
            status: false,
            component: state.modal.component,
          },
        }));
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      getAgenceByCp(this.state.value.cp).then(agence => {
        this.setState({ agence });
      });
    }
  }

  render() {
    const { history } = this.props;
    const { closeAll, notFound, agence, value } = this.state;

    const retrieveValue = data => {
      this.setState({ value: data });
    };

    const redirect = () => {
      if (agence && agence.ssdomaine) {
        window.location = `https://${agence.ssdomaine.replace('www.', '')}`;
      } else if (config.isMinisite()) {
        window.location = `${config.url}/agences-demenagement/${value.cp}`;
      } else {
        history.push(`/agences-demenagement/${value.cp}`);
      }
    };

    return (
      <div className="Store__Locator">
        <div className="Store__Locator__image">
          <img src={img} alt="france" />
        </div>
        <div className="right">
          <div className="Store__search__button">
            <AutoComplete
              closeAll={closeAll}
              history={history}
              store
              retrieveValue={retrieveValue}
            />
          </div>
          {agence !== null && (
            <div
              className={`Store__locator__card ${notFound ? '-notFound' : ''}`}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                title="Partager sur Twitter"
                className="Agences__infos__twitter"
                href={`https://twitter.com/intent/tweet/?url=${encodeURIComponent(
                  window.location.href
                )}&amp;text=${encodeURIComponent(
                  'Je prépare mon déménagement avec Les déménageurs bretons !'
                )}`}
              >
                <SVG src={twitter} className="Agences__infos__svg" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                title="Partager sur Facebook"
                className="Agences__infos__facebook"
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  window.location.href
                )}`}
              >
                <SVG src={facebook} className="Agences__infos__svg" />
              </a>

              <h1 className="Agences__infos__title">
                {`Société ${agence.societe} à ${agence.ville}`}
              </h1>

              <p className="Agences__infos__addr">
                {`${agence.adresse1} ${agence.adresse2}`}
              </p>
              <p className="Agences__infos__addr">
                {`${agence.cp} ${agence.ville}, France`}
              </p>
              <a
                href={`tel:${agence.tel.replace('.', ' ')}`}
                className="Agences__infos__tel"
              >
                {agence.tel.replace('.', ' ')}
              </a>
              <a
                href={`mailto:${agence.email}`}
                className="Agences__infos__mail"
              >
                {agence.email}
              </a>

              <div
                className="Agences__infos__ouverture"
                dangerouslySetInnerHTML={{
                  __html: agence.horaires,
                }}
              />

              <div className="Agences__infos__status">
                Commerçant indépendant
              </div>
              <div className="Agences__infos__Button">
                <button
                  className="StoreLocator__button"
                  type="button"
                  onClick={redirect}
                >
                  Contacter cette agence
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default StoreLocator;
