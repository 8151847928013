import React, { Component } from 'react';
import { asField } from 'informed';

class CheckboxPartenaire extends Component {
  render() {
    const { fieldState, fieldApi, src, ...props } = this.props;
    const { value } = fieldState;
    const { setValue, setTouched } = fieldApi;
    const {
      children,
      disabled,
      forwardedRef,
      partnerName,
      type,
      field,
      onChange,
      ...rest
    } = props;
    const uniqid = `checkbox-${Math.round(Math.random() * 100000)}`;

    return (
      <div className="Form__group -checkboxPartenaire">
        <label htmlFor={uniqid}>
          <input
            type="checkbox"
            name={field}
            checked={!value && value !== 0 ? '' : value}
            onChange={e => {
              setValue(e.target.checked);
              setTouched();
              if (onChange) onChange(e);
            }}
            id={uniqid}
            disabled={disabled}
            {...rest}
          />
          <span className="checkboxPartenaire__content">
            <img src={src} alt={partnerName} />
            <span className="checkboxPartenaire__bottom">{partnerName}</span>
          </span>
        </label>
      </div>
    );
  }
}

export default asField(CheckboxPartenaire);
