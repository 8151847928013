import React from 'react';
import {
  Link,
  RichText,
  RawText,
  Img,
} from '../../../components/Prismic/Prismic';

const SummaryList = ({
  form,
  titles,
  index,
  logoPartenaire,
  textePartenaire,
  uid,
}) => (
  <div className={`DevisPartenaire__leftCol__summary -index-${index}`}>
    <div
      className={`DevisPartenaire__leftCol__summary__partenaire ${
        index !== 1 ? '-hidden' : ''
      }`}
    >
      <Img
        src={logoPartenaire}
        className="DevisPartenaire__leftCol__summary__partenaire__img"
      />
      <RichText className="DevisPartenaire__leftCol__summary__partenaire__text">
        {textePartenaire}
      </RichText>
    </div>
    <div className="DevisPartenaire__leftCol__summary__content">
      <div
        className={`DevisPartenaire__leftCol__summary__wrap ${
          index - 1 < 1 || index === 4 ? '-hidden' : ''
        }`}
      >
        <div className="DevisPartenaire__leftCol__summary__title">
          <Link to={`/devis/${uid}/etape-1`}>Étape 1</Link>
          {` - `}
          <RawText>{titles[0]}</RawText>
        </div>
        <div className="DevisPartenaire__leftCol__summary__table">
          <div>Vous êtes :</div>
          <div>{form.DDE_CH_TYPE}</div>
          {form.DDE_CH_TYPE === 'professionnel' ? (
            <>
              <div>Nom de l&apos;entreprise :</div>
              <div>{form.DDE_CH_MUTATION_ENTREPRISE}</div>
            </>
          ) : null}

          <div>Nom :</div>
          <div>{form.DDE_CH_NOM}</div>
          <div>Prénom :</div>
          <div>{form.DDE_CH_PRENOM}</div>
          <div>Téléphone :</div>
          <div>{form.DDE_CH_TEL}</div>
          <div>Email :</div>
          <div>{form.DDE_CH_EMAIL}</div>
          <div>Code postal de départ :</div>
          <div>{form.DDE_CH_CH_CP}</div>
          <div>Code postal d&apos;arrivée :</div>
          <div>{form.DDE_CH_LI_CP || "pas de code postal d'arrivé"}</div>
        </div>
      </div>
      <div
        className={`DevisPartenaire__leftCol__summary__wrap ${
          index - 1 < 2 || index === 4 ? '-hidden' : ''
        }`}
      >
        <div className="DevisPartenaire__leftCol__summary__title">
          <Link to={`/devis/${uid}/etape-2`}>Étape 2</Link>
          {` - `}
          <RawText>{titles[1]}</RawText>
        </div>
        <div className="DevisPartenaire__leftCol__summary__table">
          <div>Adresse :</div>
          <div>{form.DDE_CH_CH_ADR1}</div>
          <div>Code postal :</div>
          <div>{form.DDE_CH_CH_CP}</div>
          <div>Ville :</div>
          <div>{form.DDE_CH_CH_VILLE}</div>
          <div>Type de logement :</div>
          <div>{form.DDE_CH_CH_TYPE_LOGEMENT}</div>
          <div>Vous êtes ? :</div>
          <div>{form.DDE_CH_CH_LOCAPROPRIO}</div>
          <div>Superficie :</div>
          <div>{form.DDE_N_CH_SURFACE}</div>
          <div>Vos spécificités :</div>
          <div>{form.DDE_CH_SPEC || 'aucune'}</div>
        </div>
      </div>

      <div
        className={`DevisPartenaire__leftCol__summary__partenaire ${
          index !== 4 ? '-hidden' : ''
        }`}
      >
        <Img
          src={logoPartenaire}
          className="DevisPartenaire__leftCol__summary__partenaire__img"
        />
        <RichText className="DevisPartenaire__leftCol__summary__partenaire__text">
          {textePartenaire}
        </RichText>
      </div>
    </div>
  </div>
);

export default SummaryList;
