import React, { useContext } from 'react';
import Accordion, {
  AccItem,
  AccHeader,
  AccContent,
} from 'components/Accordion/Accordion';
import { Link } from 'components/Prismic/Prismic';
import roomList from 'services/roomList';
import ListContext from './ListeMobilierContext';

import './ListePiece.scss';

export default ({ pathname }) => {
  const listContext = useContext(ListContext);

  return (
    <Accordion className="ListePiece" allowMultipleOpen>
      {listContext.form.map((room, i) => {
        const { name } = roomList.getRoomById(room.roomTypeId);
        return (
          <AccItem {...room} key={i}>
            <AccHeader>
              <div className="ListePiece__question">
                <div className="ListePiece__status" />
                <div className="ListePiece__edit">
                  <div className="ListePiece__label">{room.label}</div>
                </div>
                <div className="ListePiece__statusContent">
                  <div className="ListePiece__length">
                    {listContext.getTotalElementsLength(room.id)}
                  </div>
                </div>
              </div>
            </AccHeader>
            <AccContent>
              <div className="ListePiece__reponse">
                <ul className="ListePiece__list">
                  {room.elements.map((elementRoom, ii) => {
                    const element = elementRoom.custom
                      ? elementRoom
                      : listContext.getElementById(elementRoom.id);
                    return (
                      <li className="ListePiece__list__item" key={ii}>
                        <div className="ListePiece__list__label">
                          {element.label || element.custom.label}
                        </div>
                        <div className="ListePiece__list__length">
                          {elementRoom.length}
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="ListePiece__buttons">
                  <button
                    type="button"
                    onClick={() => listContext.removeRoom(room.id)}
                    className="ListePiece__buttons__remove"
                  >
                    Supprimer la pièce
                  </button>
                  <Link
                    to={`${pathname}/${name}/${room.id}`}
                    className="ListePiece__buttons__update"
                  >
                    Modifier
                  </Link>
                </div>
              </div>
            </AccContent>
          </AccItem>
        );
      })}
    </Accordion>
  );
};
