import React, { useRef, useState } from 'react';
import Form, { Input, SubmitCaptcha, validator } from 'components/Form/Form';
import { Link } from 'components/Prismic/Prismic';
import { postCreateAccountForm } from 'services/cygest';
import Header from '../Header/Header';
import './PasswordForm.scss';

const ForgottenPassword = ({ homePath }) => {
  const apiRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState({
    status: false,
    type: 'success',
    success: {
      title: 'Vous allez recevoir un email afin de créer votre mot de passe.',
      element: <Link to={homePath}>Retour</Link>,
    },
    fail: {
      title: 'Une erreur est survenue.',
      message: 'Merci de réessayer plus tard.',
      element: (
        <button
          type="button"
          onClick={() =>
            setConfirm({ ...confirm, type: 'fail', status: false })
          }
        >
          Fermer
        </button>
      ),
    },
  });

  function submitted(e = {}) {
    setLoading(true);
    postCreateAccountForm(e)
      .then(() => {
        setLoading(false);
        setConfirm({ ...confirm, type: 'success', status: true });
      })
      .catch(err => {
        setLoading(false);
        console.error(err.response);
        setConfirm({ ...confirm, type: 'fail', status: true });
      });
  }

  return (
    <div className="PasswordForm">
      <Header
        title="Créez votre dossier client"
        // description="Saisissez votre email pour réinitialiser votre mot de passe."
      />
      <div className="PasswordForm__content">
        <Form
          className="PasswordForm__form"
          onSubmit={submitted}
          getApi={e => {
            apiRef.current = e;
          }}
          confirm={confirm}
        >
          <Input
            type="text"
            field="DDE_CH_NOM"
            label="Nom *"
            placeholder="Nom"
            validate={validator.required}
            validateOnBlur
          />
          <Input
            type="text"
            field="DDE_CH_PRENOM"
            label="Prénom *"
            placeholder="Prénom"
            validate={validator.required}
            validateOnBlur
          />
          <Input
            type="tel"
            field="DDE_CH_TEL"
            label="Téléphone *"
            placeholder="Téléphone"
            validate={validator.required}
            validateOnBlur
          />
          <Input
            type="email"
            field="DDE_CH_EMAIL"
            label="Email *"
            placeholder="jean.dupont@email.com"
            validate={validator.email}
            validateOnBlur
          />
          <Input
            type="text"
            field="DDEE_CH_CODE_POSTAL"
            label="Code postal *"
            placeholder="69300"
            validate={validator.required}
            validateOnBlur
          />

          <span className="PasswordForm__mandatory">* Champ obligatoire</span>

          <SubmitCaptcha
            field="captcha"
            disabled={loading}
            submitForm={() => apiRef.current.submitForm()}
          >
            Valider
          </SubmitCaptcha>
        </Form>
      </div>
    </div>
  );
};

export default ForgottenPassword;
