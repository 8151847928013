import React, { useState, useEffect, useContext } from 'react';
import { postNotificationRead } from 'services/cygest';
import Head from 'components/Head/Head';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Col from 'components/Col';
import { Link } from 'components/Prismic/Prismic';

import MonDossierContext from '../../MonDossierContext';
import MonProjet from './Modules/MonProjet/MonProjet';
import MonDemenageur from './Modules/MonDemenageur/MonDemenageur';
import MesNotifications from './Modules/MesNotifications/MesNotifications';
import Meteo from './Modules/Meteo/Meteo';
import './Dashboard.scss';

const Dashboard = ({ homeFolderPath, user }) => {
  const { error, disconnect, getUserDashboard, token } = useContext(
    MonDossierContext
  );
  const [dashboard, setDashboard] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUserDashboard()
      .pending(data => {
        if (data) setDashboard(data);
      })
      .then(data => {
        const { notifications } = data;
        if (notifications.list.reduce((a, v) => (a + v.new ? 1 : 0), 0))
          postNotificationRead();
        setDashboard(data);
        setLoading(false);
      })
      .catch(error);
  }, []);

  return (
    <div className={`Dashboard ${loading ? '-loading' : ''}`}>
      <div>
        <Head title="Votre dossier" description="" />
        <Breadcrumb title="Votre dossier" minimal />
      </div>
      <div className="Dashboard__header">
        <h1 className="Dashboard__header__title">
          Bienvenue {user.first_name} {user.last_name}
        </h1>
        <div className="Dashboard__header__group">
          <Link
            to={`${homeFolderPath}/modifier-mon-profil`}
            className="Dashboard__header__updateProfile"
          >
            Modifier mon profil
          </Link>
          <div
            className="Dashboard__header__deconnect"
            role="button"
            tabIndex="0"
            onClick={() => disconnect()}
          >
            Déconnexion
          </div>
        </div>
      </div>
      <div className="Dashboard__content">
        <div className="Dashboard__content__col">
          <Col
            colClassName="Dashboard__content__left"
            className="Dashboard__content__left__col"
          >
            <Col className="Dashboard__content__grid">
              <Col className="Dashboard__content__wrap">
                <Meteo />
              </Col>
              <Col className="Dashboard__content__wrap">
                <MonDemenageur
                  agence={dashboard.demenageur}
                  homeFolderPath={homeFolderPath}
                  biniou={dashboard.demenageur && dashboard.demenageur.biniou}
                  token={token}
                />
              </Col>
            </Col>
            {dashboard.demenageur && dashboard.demenageur.biniou && (
              <Col className="Dashboard__content__wrap">
                <MesNotifications
                  notifications={dashboard.notifications}
                  homeFolderPath={homeFolderPath}
                />
              </Col>
            )}
          </Col>
          <Col
            colClassName="Dashboard__content__right"
            className="Dashboard__content__wrap"
          >
            <MonProjet
              homeFolderPath={homeFolderPath}
              projet={dashboard.projet}
              agence={dashboard.demenageur}
            />
          </Col>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
