import React, { Component } from 'react';
import Head from '../../../components/Head/Head';
import Loading from '../../../components/Loading/Loading';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { getPageByUid } from '../../../services/prismic';
import DevisContext from '../DevisContext';
import NotFound from '../../../layout/NotFound/NotFound';
import Etape1 from './Etape1';
import Etape2 from './Etape2';
import Etape3 from './Etape3';
import Etape4 from './Etape4';
import SummaryList from './SummaryList';

class Devis extends Component {
  static contextType = DevisContext;

  constructor(props) {
    super(props);

    this.state = {
      notFound: false,
      datas: null,
    };

    const { match } = this.props;
    const { uid } = match.params;

    getPageByUid('pages_devis_reference', uid).then(datas => {
      if (!datas) this.setState({ notFound: true });
      else this.setState({ datas });
    });
  }

  render() {
    const { notFound, datas } = this.state;
    const { match, history } = this.props;
    const step = match.params.step || 'etape-1';
    const { form } = this.context;
    const { uid } = match.params;

    if (notFound) {
      return <NotFound />;
    }

    if (datas) {
      const { data } = datas;
      let index = 0;
      let formSteps = null;
      const titles = [data.step1_title, data.step2_title, data.step3_title];
      const {
        logo_reference: logoReference,
        texte_reference: texteReference,
      } = data;

      switch (step) {
        case 'etape-1':
          index = 1;
          formSteps = (
            <Etape1
              history={history}
              title={titles[0]}
              data={data}
              next={`/devis/reference/${uid}/etape-2`}
            />
          );
          break;

        case 'etape-2':
          if (!form.demandeId) history.push(`/devis/${uid}/etape-1`);
          index = 2;
          formSteps = (
            <Etape2
              history={history}
              title={titles[1]}
              data={data}
              next={`/devis/reference/${uid}/etape-3`}
            />
          );
          break;

        case 'etape-3':
          if (!form.demandeId) history.push(`/devis/reference/${uid}/etape-1`);
          index = 3;
          formSteps = (
            <Etape3
              history={history}
              title={titles[2]}
              data={data}
              prev={`/devis/reference/${uid}/etape-2`}
              next={`/devis/reference/${uid}/etape-4`}
              uid={uid}
            />
          );
          break;

        case 'etape-4':
          if (!form.demandeId) history.push(`/devis/reference/${uid}/etape-1`);
          index = 4;
          formSteps = <Etape4 history={history} datas={datas} />;
          break;

        default:
          return <NotFound />;
      }

      return (
        <div className="DevisReference">
          <Head title={data.seo_title} description={data.seo_title} />
          <Breadcrumb
            topic={{ link: `devis/reference/${uid}/`, name: data.seo_title }}
            title={`Étape ${index}`}
          />
          <div className="DevisReference__content">
            <div className="DevisReference__leftCol">
              <SummaryList
                form={form}
                titles={titles}
                index={index}
                data={data}
                logoPartenaire={logoReference}
                textePartenaire={texteReference}
                uid={uid}
              />
            </div>
            <div className="DevisReference__rightCol">
              <div>
                <div
                  className={`DevisReference__status ${
                    index === 4 ? '-hidden' : ''
                  }`}
                >
                  <div className="DevisReference__status__progress">
                    <div
                      className="DevisReference__status__progress__bar"
                      style={{
                        transform: `translateX(${index * 33.33333 - 100}%)`,
                      }}
                    />
                  </div>

                  <div className="DevisReference__steps">
                    <div
                      className={`DevisReference__step ${
                        index === 1 ? '-current' : ''
                      }`}
                    >
                      <span className="DevisReference__step__label">
                        Étape 1
                      </span>
                      <span className="DevisReference__step__info">
                        Vos informations personnelles
                      </span>
                    </div>

                    <div
                      className={`DevisReference__step ${
                        index === 2 ? '-current' : ''
                      }`}
                    >
                      <span className="DevisReference__step__label">
                        Étape 2
                      </span>
                      <span className="DevisReference__step__info">
                        Chargement - départ
                      </span>
                    </div>

                    <div
                      className={`DevisReference__step ${
                        index === 3 ? '-current' : ''
                      }`}
                    >
                      <span className="DevisReference__step__label">
                        Étape 3
                      </span>
                      <span className="DevisReference__step__info">
                        Livraison - arrivée
                      </span>
                    </div>
                  </div>
                </div>

                {formSteps}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <Loading />;
  }
}

export default Devis;
