import React, { Component, Suspense } from 'react';
import Swiper from 'react-id-swiper';

import Head from '../../components/Head/Head';
import { getAgenceByDomain } from '../../services/cygest';
import config from '../../config';
import { Link } from '../../components/Prismic/Prismic';
import slug from '../../components/slug/slug';

import Modules from '../../components/Modules/Modules';
import QuickEstimate from '../../components/Slice/QuickEstimate/QuickEstimate';
import GoogleMaps from './GoogleMaps';

import logo from './img/logo.png';
import './MiniSites.scss';

class MiniSites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false,
      agence: {
        societe: '',
        adresse1: '',
        adresse2: '',
        cp: '',
        ville: '',
        tel: '',
        email: '',
        latitude: '0',
        longitude: '0',
        horaires: '',
        nomAgence: '',
        metiers: [],
      },
      height: '350px',
      menu: false,
    };

    getAgenceByDomain(`www.${document.location.host}`).then(agence => {
      if (!agence) this.setState({ notFound: true });
      else this.setState({ agence });
    });
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.calc();
    window.onload = () => {
      requestAnimationFrame(() => this.calc());
    };
    window.addEventListener('resize', () => {
      requestAnimationFrame(() => this.calc());
    });
  }

  calc() {
    const bodyWidth = document.body.clientWidth;
    this.setState({ height: bodyWidth > 425 ? '350px' : '144px' });
  }

  render() {
    const { agence, height, notFound, menu } = this.state;
    const { match } = this.props;
    const currentTabId = match.params.tab || 'accueil';

    const params = {
      slidesPerView: 1,
      loop: true,
      spaceBetween: 10,
      autoHeight: true,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
    };

    const tabList = agence.metiers
      ? agence.metiers.filter(
          metier =>
            metier.nom === 'Déménagement de particuliers' ||
            metier.nom === 'Déménagement de professionnels' ||
            metier.nom === 'Garde-meubles' ||
            metier.nom === 'Self stockage' ||
            metier.nom === 'Savoir Faire'
        )
      : [];

    if (!agence.metiers) {
      agence.metiers = [];
    }

    const currentMetier = agence.metiers.filter(
      metier => slug(metier.nom) === currentTabId
    );

    const seo = {
      accueil: [
        `Déménagement ${agence.ville}: société ${agence.societe}`,
        `Contactez la société ${agence.societe} pour votre déménagement à ${
          agence.ville
        }. Devis gratuit. Prestation adaptée à vos besoins. Réseau certifié NF Service déménagement de Particuliers en France.`,
      ],
      'demenagement-de-particuliers': [
        `Déménagement particulier ${agence.ville}: société ${agence.societe}`,
        `Un projet de déménagement à ${agence.ville} ? Contactez la société ${
          agence.societe
        }. Devis gratuit. Prestation adaptée à vos besoins. Réseau certifié NF Service déménagement de Particuliers en France.`,
      ],
      'demenagement-de-professionnels': [
        `Déménagement entreprise ${agence.ville}: société ${agence.societe}`,
        `Contactez la société ${
          agence.societe
        } pour le déménagement de votre entreprise à ${
          agence.ville
        }. Transfert de bureaux ou industriel, archivage, mutation de collaborateurs.`,
      ],
      'garde-meubles': [
        `Garde-meubles ${agence.ville}: société ${agence.societe}`,
        `Besoin de place ? Contactez la société ${
          agence.societe
        } pour vos besoins en garde-meuble à ${
          agence.ville
        }. Stockage sécurisé pour un mois, un an ou plus.`,
      ],
      'self-stockage': [
        `Self stockage ${agence.ville}: société ${agence.societe}`,
        `Besoin de place et de disponibilité ? Contactez la société ${
          agence.societe
        } pour vos besoins de stockage à ${
          agence.ville
        }. Sécurité et liberté totale d'accès à vos effets.`,
      ],
      'savoir-faire': [
        `société ${agence.societe} à ${agence.ville}:  Notre savoir-faire`,
        `La société ${
          agence.societe
        } vous accompagne pour vos projets de déménagement particulier ou entreprise et besoins de stockage à ${
          agence.ville
        }. Contactez-nous !`,
      ],
    };

    const content = {
      texte: (
        <div className="">
          <h2>
            Bonjour et bienvenue chez Les déménageurs bretons
            {agence.nomAgence && ` à ${agence.nomAgence}`}
            &nbsp;!
          </h2>
          <ul>
            <li>
              <strong>
                Choisir la prestation la plus adaptée à vos besoins parmi nos 5
                prestations&nbsp;:{' '}
              </strong>
              de la plus simple à la plus complète.
            </li>
            <li>
              <strong>Réserver la date la plus adéquate</strong> à vos
              impératifs professionnels, familiaux et organisationnels.
            </li>
            <li>
              <strong>
                Gérer toutes vos demandes avec nos équipes commerciales et
                techniques{' '}
              </strong>
              pour faire de votre déménagement une réussite...
            </li>
            <li>
              <strong>
                Vous proposer des partenariats inédits, qui vous simplifieront
                le déménagement&nbsp;:{' '}
              </strong>
              financement, démarches administratives, nettoyage, travaux, nous
              avons des solutions pour tout.
            </li>
          </ul>
        </div>
      ),
      photos: [],
    };

    if (agence.photo1) {
      content.photos.push(agence.photo1);
    }
    if (agence.photo2) {
      content.photos.push(agence.photo2);
    }
    if (agence.photo3) {
      content.photos.push(agence.photo3);
    }
    if (agence.photo4) {
      content.photos.push(agence.photo4);
    }
    if (agence.photo5) {
      content.photos.push(agence.photo5);
    }

    if (currentMetier.length) {
      content.texte = (
        <div
          dangerouslySetInnerHTML={{
            __html: currentMetier[0].texte.replace(/\r/g, '<br />'),
          }}
        />
      );
    }

    return (
      <div className="MiniSites">
        <div className="MiniSites__header">
          <Head
            title={seo[currentTabId] && seo[currentTabId][0]}
            description={seo[currentTabId] && seo[currentTabId][1]}
          />
          <div className="MiniSites__header__container">
            <div className={`MiniSites__infos ${notFound ? '-notFound' : ''}`}>
              <h1 className="MiniSites__infos__title">
                {`Société ${agence.societe}`}
                {agence.nomAgence && ` à ${agence.nomAgence}`}
              </h1>

              <p className="MiniSites__infos__addr">
                {`${agence.adresse1} ${agence.adresse2}`}
              </p>
              <p className="MiniSites__infos__addr">
                {`${agence.cp} ${agence.ville}, France`}
              </p>
              <div className="MiniSites__infos__group">
                <Link to="/devis" className="MiniSites__infos__devis">
                  Demander un devis
                </Link>
                <a
                  href={`tel:${agence.tel.replace('.', ' ')}`}
                  className="MiniSites__infos__tel"
                >
                  {agence.tel.replace('.', ' ')}
                </a>
                <a
                  href={`mailto:${agence.email}`}
                  className="MiniSites__infos__mail"
                >
                  {agence.email}
                </a>
              </div>

              <div
                className="MiniSites__infos__ouverture"
                dangerouslySetInnerHTML={{
                  __html: agence.horaires,
                }}
              />

              <div className="MiniSites__infos__status">
                Commerçant indépendant
              </div>
            </div>
          </div>
          <Suspense fallback={<div>Loading...</div>}>
            <GoogleMaps
              defaultZoom={17}
              defaultPosition={{
                lat: parseFloat(agence.latitude.replace(',', '.')) || 0,
                lng: parseFloat(agence.longitude.replace(',', '.')) || 0,
              }}
              isMarkerShown
              googleMapURL={config.googleMaps.url}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </Suspense>
        </div>

        <div className="MiniSites__container">
          <div className="MiniSites__menu">
            <div className="MiniSites__menu__currentStatus" />
            <div
              className="MiniSites__menu__current"
              onClick={() => {
                this.setState({ menu: !menu });
              }}
              role="button"
              tabIndex="0"
            >
              {currentTabId}
            </div>
            <nav className={`MiniSites__menu__nav ${menu ? '-show' : ''}`}>
              <Link
                to="/"
                noRedirect
                className={`MiniSites__menu__tab ${
                  currentTabId === 'accueil' ? '-current' : ''
                }`}
                onClick={() => {
                  this.setState({ menu: false });
                }}
              >
                Accueil
              </Link>
              {tabList.map((item, i) => (
                <Link
                  to={`/${slug(item.nom)}`}
                  noRedirect
                  className={`MiniSites__menu__tab ${
                    slug(item.nom) === currentTabId ? '-current' : ''
                  }`}
                  key={i}
                  onClick={() => {
                    this.setState({ menu: false });
                  }}
                >
                  {item.nom}
                </Link>
              ))}
            </nav>
          </div>

          <div className="MiniSites__content">
            <div className="MiniSites__content__container">
              <div className="MiniSites__content__text">{content.texte}</div>
              <div className="MiniSites__content__carousel">
                {content.photos.length ? (
                  <Swiper {...params}>
                    {content.photos.map((photo, i) => (
                      <img src={photo} alt="" />
                    ))}
                  </Swiper>
                ) : null}
              </div>
            </div>
          </div>

          <div className="MiniSites__slice">
            <QuickEstimate />
            <div className="MiniSites__ads">
              <div className="MiniSites__ads__container">
                <div className="MiniSites__ads__left">
                  <img
                    src={logo}
                    alt="Les déménageurs bretons"
                    width="330 "
                    height="250"
                    className="MiniSites__ads__logo"
                  />
                </div>
                <div className="MiniSites__ads__right">
                  <div className="MiniSites__ads__title">
                    <span>n°</span> <b>1</b> en notoriété
                  </div>
                  <div className="MiniSites__ads__legal">
                    Selon l’IFOP - sondage 2016
                  </div>
                  <div className="MiniSites__ads__baseline">
                    la confiance à un nom
                  </div>
                  <div className="MiniSites__ads__text">
                    « <b>Unique réseau certifié</b> NF Service 144 Déménagement
                    de Particuliers au 1er janvier 2017. Liste des certifiés
                    disponible sur{' '}
                    <a
                      href="http://marque-nf.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.marque-nf.com
                    </a>{' '}
                    »
                  </div>
                </div>
              </div>
            </div>
            <Modules
              module={{ type: 'opinion_module', id: 'W_fSghIAAC0A1HLw' }}
            />
            <Modules
              module={{ type: 'partner_module', id: 'W_fRGBIAAC0A1GyM' }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MiniSites;
