import React, { useState, useContext, useEffect } from 'react';
import Col from 'components/Col';
import { ModalContext } from 'components/Modal/Modal';
import Form, {
  Input,
  Select,
  Option,
  validator,
  Submit,
} from 'components/Form/Form';
import roomList from 'services/roomList';

import Header from '../../Header/Header';
import ListContext from './ListeMobilierContext';
import ListeElement from './ListeElement';

import cuisine from '../img/cuisine.jpg';
import salleDeBain from '../img/salle-de-bain.jpg';
import salon from '../img/salon.jpg';
import salleAManger from '../img/salle-a-manger.jpg';
import chambre from '../img/chambre.jpg';
import bureau from '../img/bureau.jpg';
import garage from '../img/garage.jpg';
import exterieur from '../img/exterieur.jpg';
import entree from '../img/entree.jpg';
import cave from '../img/cave.jpg';
import grenier from '../img/grenier.jpg';
import dependance from '../img/dependance.jpg';
import salleDeJeu from '../img/salle-de-jeu.jpg';
import buanderie from '../img/buanderie.jpg';

const imgsRoom3d = {
  cuisine,
  'salle-de-bain': salleDeBain,
  salon,
  'salle-a-manger': salleAManger,
  chambre,
  bureau,
  garage,
  entree,
  exterieur,
  cave,
  grenier,
  dependance,
  'salle-de-jeu': salleDeJeu,
  buanderie,
};

export default ({ history, match, homeMaison3dPath }) => {
  const { params } = match;
  const { roomType, roomId } = params;
  const { modal } = useContext(ModalContext);
  const listContext = useContext(ListContext);
  const room = listContext.getRoomById(roomId);
  const [label, setLabel] = useState(roomList.getRoomByName(roomType).label);
  const [newElements, setNewElements] = useState([]);
  const id = room ? room.id : `${Date.now()}`;

  useEffect(() => {
    if (room && label !== room.label) {
      setLabel(room.label);
    }
    if (room && newElements.length !== room.elements.length) {
      const clonedElements = JSON.parse(JSON.stringify(room.elements));
      setNewElements(clonedElements);
    }
  }, [roomId]);

  useEffect(() => {
    if (listContext.loading && room && label !== room.label) {
      setLabel(room.label);
    }
    if (
      listContext.loading &&
      room &&
      newElements.length !== room.elements.length
    ) {
      const clonedElements = JSON.parse(JSON.stringify(room.elements));
      setNewElements(clonedElements);
    }
  });

  function addElement(element) {
    if (element.custom) {
      setNewElements([...newElements, element]);
    } else {
      let index = newElements.length + 1;
      newElements.forEach((e, i) => {
        if (e.id === element.id) index = i;
      });
      const oldElement = newElements.filter(e => e.id === element.id);
      const othersElements = newElements.filter(e => e.id !== element.id);
      othersElements.splice(index, 0, {
        id: element.id,
        length:
          oldElement[0] && oldElement[0].id === element.id
            ? oldElement[0].length + 1
            : 1,
      });
      setNewElements(othersElements);
    }
  }

  function increaseElement(elementId) {
    const newEl = newElements.map(element => {
      if (element.id === elementId) {
        const newElement = element;
        newElement.length += 1;
        newElement.remove = false;
        return newElement;
      }
      return element;
    });

    setNewElements(newEl);
  }

  function decreaseElement(elementId) {
    const newEl = newElements.reduce((accumulator, element) => {
      if (element.id === elementId && element.remove) {
        return accumulator;
      }

      if (element.id === elementId && element.length === 1) {
        const newElement = element;
        newElement.remove = true;
        accumulator.push(newElement);
        return accumulator;
      }

      if (element.id === elementId) {
        const newElement = element;
        newElement.length = newElement.length <= 0 ? 0 : newElement.length - 1;
        accumulator.push(newElement);
        return accumulator;
      }

      accumulator.push(element);
      return accumulator;
    }, []);

    setNewElements(newEl);
  }

  function addRoom() {
    listContext.addRoom({
      label,
      roomTypeId: roomList.getRoomByName(roomType).id,
      id,
      removed: false,
      elements: newElements,
    });
  }

  const prevNextRoom = listContext.getNextPreviousByRoomId(params.roomId);

  return (
    <div className="CubageRoom3d">
      <Header
        title={roomList.getRoomByName(roomType).label}
        callBack={() => addRoom()}
        previous={
          prevNextRoom.prevRoom &&
          `${homeMaison3dPath}/${
            roomList.getRoomById(prevNextRoom.prevRoom.roomTypeId).name
          }/${prevNextRoom.prevRoom.id}`
        }
        next={
          prevNextRoom.nextRoom &&
          `${homeMaison3dPath}/${
            roomList.getRoomById(prevNextRoom.nextRoom.roomTypeId).name
          }/${prevNextRoom.nextRoom.id}`
        }
      />
      <div className="CubageRoom3d__container">
        <div className="CubageRoom3d__grid">
          <Col
            colClassName="CubageRoom3d__grid__col1"
            className="CubageRoom3d__grid__col1__content"
          >
            <div className="CubageRoom3d__maison">
              <img
                src={imgsRoom3d[roomType]}
                className="CubageRoom3d__maison__img"
                alt=""
              />
              {roomList.getRoomByName(roomType).elements.map((element, i) => {
                const el = listContext.getElementById(
                  element.id || element.group
                );
                return (
                  <button
                    type="button"
                    className="CubageRoom3d__maison__button"
                    style={element.position}
                    onClick={() => {
                      if (element.group) {
                        const listGroup = listContext.getElementsByGroup(
                          element.group,
                          roomList.getRoomByName(roomType).id
                        );
                        modal.new({
                          style: {
                            maxWidth: '320px',
                            margin: 'auto',
                            height: 'auto',
                            maxHeight: '100%',
                            overflow: 'auto',
                            top: '50%',
                            transform: 'translateY(-50%)',
                          },
                          title: element.group,
                          datas: (
                            <div className="CubageRoom3d__customFields">
                              <Form
                                onSubmit={formState => {
                                  if (formState.elementId) {
                                    addElement(
                                      listContext.getElementById(
                                        parseInt(formState.elementId, 10)
                                      )
                                    );
                                  }
                                  modal.close();
                                }}
                              >
                                <Select
                                  field="elementId"
                                  type="text"
                                  label="Type"
                                  initialValue=""
                                  resetId={Math.round(
                                    Math.random() * 100000000
                                  )}
                                >
                                  <Option value="" disabled>
                                    Choisissez...
                                  </Option>
                                  {listGroup.map((e, ii) => (
                                    <Option value={e.id} key={ii}>
                                      {e.label}
                                    </Option>
                                  ))}
                                </Select>
                                <Submit>Valider</Submit>
                              </Form>
                            </div>
                          ),
                        });
                      } else {
                        addElement(element);
                      }
                    }}
                    key={i}
                  >
                    <span>{element.group ? el.group : el.label}</span>
                  </button>
                );
              })}
            </div>
          </Col>
          <Col
            colClassName="CubageRoom3d__grid__col2"
            className="CubageRoom3d__grid__col2__content"
          >
            <ListeElement
              params={params}
              newElements={newElements}
              increaseElement={increaseElement}
              decreaseElement={decreaseElement}
              label={label}
              setLabel={setLabel}
            />
            <button
              type="button"
              className="CubageRoom3d__list__button__custom"
              onClick={() =>
                modal.toggle({
                  id: 'customFurniture',
                  style: {
                    maxWidth: '640px',
                    margin: 'auto',
                    height: 'auto',
                    maxHeight: '100%',
                    overflow: 'auto',
                  },
                  title: 'Meuble personnalisé',
                  datas: (
                    <div className="CubageRoom3d__customFields">
                      <Form
                        onSubmit={formState => {
                          addElement({
                            id: `${Date.now()}`,
                            length: 1,
                            custom: {
                              label: formState.label,
                              width: formState.width,
                              height: formState.height,
                              depth: formState.depth,
                              weight: formState.weight,
                            },
                          });
                          modal.close();
                        }}
                      >
                        <Input
                          field="label"
                          type="text"
                          label="Nom *"
                          placeholder="Chesterfield fauteuil"
                          validate={validator.required}
                        />
                        <div className="CubageRoom3d__customFields__length">
                          <Input
                            field="height"
                            type="number"
                            label="Hauteur en cm"
                            placeholder="exemple : 180"
                          />
                          <Input
                            field="width"
                            type="number"
                            label="Largeur en cm"
                            placeholder="exemple : 80"
                          />
                          <Input
                            field="depth"
                            type="number"
                            label="Profondeur en cm"
                            placeholder="exemple : 40"
                          />
                          <Input
                            field="weight"
                            type="number"
                            label="Poids en Kg"
                            placeholder="exemple : 100"
                          />
                        </div>
                        <span className="CubageRoom3d__customFields__required">
                          * Champ obligatoire
                        </span>
                        <Submit>Valider</Submit>
                      </Form>
                    </div>
                  ),
                })
              }
            >
              Ajouter un meuble personnalisé
            </button>
            <div className="CubageRoom3d__list__buttons">
              <button
                type="button"
                className="CubageRoom3d__list__buttons__cancel"
                onClick={() => history.push(homeMaison3dPath)}
              >
                Annuler
              </button>
              <button
                type="button"
                className="CubageRoom3d__list__buttons__save"
                onClick={() => {
                  addRoom();
                  history.push(homeMaison3dPath);
                }}
              >
                Valider
              </button>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
};
