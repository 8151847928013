import React, { useState, useEffect, useContext, useRef } from 'react';
import SVG from 'react-inlinesvg';
import { ModalContext } from 'components/Modal/Modal';
import Form, {
  Input,
  RadioGroup,
  Radio,
  Select,
  Option,
  validator,
  Submit,
} from 'components/Form/Form';
import Accordion, {
  AccItem,
  AccHeader,
  AccContent,
} from 'components/Accordion/Accordion';
import ClickOutside from 'components/ClickOutside/';
import {
  getAllElements,
  getUserElementsValues,
  postUserElementsValues,
} from 'services/cygest';
import roomList from 'services/roomList';
import Header from '../Header/Header';
import MonDossierContext from '../../MonDossierContext';

import loader from './img/loader.svg';
import remove from './img/remove.svg';

import './DeclarationValeur.scss';

export default ({ history, homeFolderPath }) => {
  let formApi = null;
  const { error } = useContext(MonDossierContext);
  const { modal } = useContext(ModalContext);
  const [elements, setElements] = useState([]);
  const [userRooms, setUserRooms] = useState([]);
  const [addRoomVisible, setAddRoomVisible] = useState(false);
  const [pending, setPending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState({
    status: false,
    type: 'success',
    success: {
      title: 'Votre liste de mobilier a bien été enregistrée',
    },
    fail: {
      title: 'Impossible de sauvegarder votre liste de mobilier',
      message: 'Merci réessayer ultérieurement',
      element: (
        <button
          type="button"
          onClick={() => {
            setConfirm({ ...confirm, type: 'fail', status: false });
          }}
        >
          Fermer
        </button>
      ),
    },
  });

  useEffect(() => {
    getAllElements()
      .then(data => {
        setElements(data);
        return getUserElementsValues();
      })
      .then(data => {
        setPending(false);
        formApi.setValues({
          garantie: data.garantie || '',
        });
        setUserRooms(data.pieces);
      })
      .catch(error);
  }, []);

  const getElementById = elementId => {
    const element = elements.filter(
      e => e.group === elementId || e.id === elementId
    );
    return element[0] || { label: 'Élément inconnu' };
  };

  const getElementsByFilter = roomId => {
    const filteredElements = elements.filter(e => {
      if (e.roomFilter) return e.roomFilter.includes(roomId);
      return true;
    });
    return filteredElements || [{ label: 'Aucun élément trouvé...' }];
  };

  const numberWithSpace = number =>
    number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '0';

  const getTotal = () =>
    userRooms
      .reduce((a, v) => [...a, ...v.elements], [])
      .reduce((a, v) => a + v.value * v.length, 0);

  const getRoomList = () => userRooms.filter(room => !room.removed);

  const getTotalById = id =>
    userRooms
      .reduce((a, v) => (v.id === id ? [...a, ...v.elements] : a), [])
      .reduce((a, v) => a + v.value * v.length, 0);

  const removeRoom = id => {
    let index = userRooms.length + 1;
    userRooms.forEach((room, i) => {
      if (room.id === id) index = i;
    });
    const room = userRooms.filter(e => e.id === id)[0];
    room.removed = true;
    const othersRooms = userRooms.filter(e => e.id !== id);
    othersRooms.splice(index, 0, room);
    setUserRooms(othersRooms);
  };

  const addRoom = newRoom => {
    const formLength = userRooms.length;

    const { id: newRoomId } = roomList.getRoomById(newRoom.roomTypeId);
    const index = userRooms.reduce(
      (a, room, i) => (room.roomTypeId > newRoomId && formLength === a ? i : a),
      formLength
    );

    const otherRoom = userRooms.filter(e => e.id !== newRoom.id);
    otherRoom.splice(index, 0, newRoom);

    setUserRooms(otherRoom);
  };

  const changeRoomLabel = (label, id) => {
    let index = userRooms.length + 1;
    userRooms.forEach((room, i) => {
      if (room.id === id) index = i;
    });
    const room = userRooms.filter(e => e.id === id)[0];
    room.label = label;
    const othersRooms = userRooms.filter(e => e.id !== id);
    othersRooms.splice(index, 0, room);
    setUserRooms(othersRooms);
  };

  const changeElementValue = (roomId, elementId, value) => {
    const newValue = parseInt(value, 10) || 0;

    let indexRoom = userRooms.length + 1;
    userRooms.forEach((room, i) => {
      if (room.id === roomId) indexRoom = i;
    });
    const room = userRooms.filter(r => r.id === roomId)[0];

    room.elements.forEach((element, i) => {
      if (element.id === elementId) room.elements[i].value = newValue;
    });

    const othersRooms = userRooms.filter(r => r.id !== roomId);
    othersRooms.splice(indexRoom, 0, room);
    setUserRooms(othersRooms);
  };

  const increaseElement = (roomId, elementId) => {
    let indexRoom = userRooms.length + 1;
    userRooms.forEach((room, i) => {
      if (room.id === roomId) indexRoom = i;
    });
    const room = userRooms.filter(r => r.id === roomId)[0];

    room.elements.forEach((element, i) => {
      if (element.id === elementId) {
        room.elements[i].length += 1;
        room.elements[i].remove = false;
      }
    });

    const othersRooms = userRooms.filter(r => r.id !== roomId);
    othersRooms.splice(indexRoom, 0, room);
    setUserRooms(othersRooms);
  };

  const decreaseElement = (roomId, elementId) => {
    let indexRoom = userRooms.length + 1;
    userRooms.forEach((room, i) => {
      if (room.id === roomId) indexRoom = i;
    });
    const room = userRooms.filter(r => r.id === roomId)[0];

    room.elements = room.elements.reduce((accumulator, element) => {
      if (element.id === elementId && element.remove) {
        return accumulator;
      }

      if (element.id === elementId && element.length === 1) {
        const newElement = element;
        newElement.remove = true;
        accumulator.push(newElement);
        return accumulator;
      }

      if (element.id === elementId) {
        const newElement = element;
        newElement.length = newElement.length <= 0 ? 0 : newElement.length - 1;
        accumulator.push(newElement);
        return accumulator;
      }

      accumulator.push(element);
      return accumulator;
    }, []);

    const othersRooms = userRooms.filter(r => r.id !== roomId);
    othersRooms.splice(indexRoom, 0, room);
    setUserRooms(othersRooms);
  };

  const addNewElement = (roomId, newElement) => {
    let indexRoom = userRooms.length + 1;
    userRooms.forEach((room, i) => {
      if (room.id === roomId) indexRoom = i;
    });
    const room = userRooms.filter(r => r.id === roomId)[0];
    const sameElement = room.elements.filter(e => e.id === newElement.id);
    if (sameElement.length) {
      room.elements.forEach(e => {
        if (e.id === newElement.id) {
          e.length += 1;
        }
      });
    } else {
      room.elements.push(newElement);
    }
    const othersRooms = userRooms.filter(r => r.id !== roomId);
    othersRooms.splice(indexRoom, 0, room);
    setUserRooms(othersRooms);
  };

  const addElement = (roomId, roomTypeId) => {
    const uid = Math.round(Math.random() * 100000000);
    modal.toggle({
      style: {
        maxWidth: '640px',
        margin: 'auto',
        height: 'auto',
        maxHeight: '100%',
        overflow: 'auto',
      },
      title: 'Ajouter un élément',
      datas: (
        <div className="DeclarationValeur__customFields">
          <Form
            onSubmit={formState => {
              if (formState.elementId === 'custom') {
                addNewElement(roomId, {
                  id: `${Math.round(Math.random() * 1000000000)}`,
                  length: 1,
                  value: parseInt(formState.value, 10) || 0,
                  custom: { label: formState.label },
                });
              } else if (formState.elementId) {
                addNewElement(roomId, {
                  id: parseInt(formState.elementId, 10),
                  length: 1,
                  value: parseInt(formState.value, 10) || 0,
                });
              }
              modal.close();
            }}
          >
            {({ formState }) => {
              const { id } = roomList.getRoomById(roomTypeId);
              const filteredElements = getElementsByFilter(id);
              return (
                <>
                  <Select
                    field="elementId"
                    label="Élement"
                    initialValue=""
                    resetId={uid}
                  >
                    <Option value="" disabled>
                      Choisissez...
                    </Option>
                    <Option value="custom">Meuble personnalisé...</Option>
                    {filteredElements.map((element, i) => {
                      const e = getElementById(element.id);
                      return (
                        <Option value={e.id} key={i}>
                          {e.label}
                        </Option>
                      );
                    })}
                  </Select>
                  {formState.values.elementId === 'custom' && (
                    <Input
                      field="label"
                      type="text"
                      label="Nom *"
                      placeholder="Chesterfield fauteuil"
                      validate={validator.required}
                    />
                  )}
                  {formState.values.elementId && (
                    <Input
                      field="value"
                      type="number"
                      label="Valeur"
                      placeholder="125"
                      initialValue="0"
                      onKeyDown={e => {
                        if (
                          e.keyCode !== 27 &&
                          e.keyCode !== 37 &&
                          e.keyCode !== 39 &&
                          e.keyCode !== 8 &&
                          Number.isNaN(Number(e.key))
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  )}
                  {formState.values.elementId === 'custom' && (
                    <span className="DeclarationValeur__customFields__required">
                      * Champ obligatoire
                    </span>
                  )}
                  <Submit>Valider</Submit>
                </>
              );
            }}
          </Form>
        </div>
      ),
    });
  };

  const submitForm = formState => {
    setLoading(true);
    const pieces = getRoomList();

    postUserElementsValues({ pieces, ...formState })
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'dossierDeclarationValeur' });

        setConfirm({ ...confirm, type: 'success', status: true });
        setTimeout(() => {
          history.push(homeFolderPath);
        }, 2000);
        setLoading(false);
      })
      .catch(err => {
        error(err);
        setLoading(false);
      });
  };

  function setFormApi(e) {
    formApi = e;
  }

  return (
    <div
      className={`DeclarationValeur ${confirm.status !== false ? '-sent' : ''}`}
    >
      <Header
        title="Déclaration de valeur"
        description="Choisissez la garantie qui vous convient et remplissez la valeur de vos biens. La déclaration de valeur de votre patrimoine est essentielle."
      />
      <div className="DeclarationValeur__container">
        <div className="DeclarationValeur__addRoom">
          <ClickOutside onClick={() => setAddRoomVisible(false)}>
            <button
              type="button"
              className="DeclarationValeur__addRoom__button"
              onClick={() => setAddRoomVisible(!addRoomVisible)}
            >
              + Ajouter une pièce
            </button>
            <div
              className={`DeclarationValeur__addRoom__roomList ${addRoomVisible ? '-visible' : ''
                }`}
            >
              {roomList.map((room, i) => (
                <div
                  role="button"
                  tabIndex="0"
                  className="DeclarationValeur__addRoom__item"
                  key={i}
                  onClick={() => {
                    addRoom({
                      label: room.label,
                      roomTypeId: room.id,
                      id: `${Date.now()}`,
                      elements: [],
                      initialOpened: true,
                    });
                    setAddRoomVisible(false);
                  }}
                >
                  {room.label}
                </div>
              ))}
            </div>
          </ClickOutside>
        </div>
        <div style={{ clear: 'both' }} />

        <Form onSubmit={submitForm} confirm={confirm} getApi={setFormApi}>
          {pending ? (
            <div className="DeclarationValeur__pending" />
          ) : (
            <>
              <Accordion
                className="DeclarationValeur__accordion"
                allowMultipleOpen
              >
                {userRooms.map((userRoom, i) => (
                  <AccItem {...userRoom} key={i}>
                    <AccHeader>
                      <RoomHeader
                        userRoom={userRoom}
                        changeRoomLabel={changeRoomLabel}
                        numberWithSpace={numberWithSpace}
                        getTotalById={getTotalById}
                      />
                    </AccHeader>
                    <AccContent>
                      <RoomContent
                        userRoom={userRoom}
                        getElementById={getElementById}
                        decreaseElement={decreaseElement}
                        increaseElement={increaseElement}
                        changeElementValue={changeElementValue}
                        addElement={addElement}
                        removeRoom={removeRoom}
                      />
                    </AccContent>
                  </AccItem>
                ))}
              </Accordion>
              <div className="DeclarationValeur__total">
                <span>Total :</span>
                <span>{numberWithSpace(getTotal())} €</span>
              </div>
              <div className="DeclarationValeur__garanties">
                <RadioGroup field="garantie" validate={validator.required}>
                  <Radio value="AREM">
                    Je choisis la garantie{' '}
                    <span className="DeclarationValeur__garanties__arem">
                      AREM
                    </span>{' '}
                    et je liste tous les biens ayant une valeur unitaire
                    supérieure à 50€.
                  </Radio>
                  <Radio value="ARGANT">
                    Je choisis la garantie{' '}
                    <span className="declarationvaleur__garanties__argant">
                      ARGANT
                    </span>{' '}
                    et je liste tous les biens ayant une valeur unitaire
                    supérieure à 300€
                  </Radio>
                  <Radio value="AOUR">
                    Je choisis la garantie{' '}
                    <span className="declarationvaleur__garanties__aour">
                      AOUR
                    </span>{' '}
                    et je liste tous les biens ayant une valeur unitaire
                    supérieure à 600€
                  </Radio>
                </RadioGroup>
              </div>
              <div>
                Pour consulter nos conditions générales de vente {' '}
                <a
                  href="https://prismic-io.s3.amazonaws.com/demenageurs-bretons/a1e81364-25f9-4caf-bbda-4754529f7339_CGV+Particuliers+v6+%281%29.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  cliquez ici
                </a>
                .
              </div>
            </>
          )}

          <div className="DeclarationValeur__buttons">
            <button
              type="button"
              className="DeclarationValeur__back"
              onClick={() => history.push(homeFolderPath)}
            >
              Retour
            </button>
            <button
              type="submit"
              className="DeclarationValeur__submit"
              disabled={loading || pending}
            >
              Enregistrer
              <SVG
                src={loader}
                className={`DeclarationValeur__loading ${loading ? '-loading' : ''
                  }`}
              />
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

const RoomHeader = ({
  userRoom,
  changeRoomLabel,
  numberWithSpace,
  getTotalById,
}) => {
  const refInput = useRef();
  const [edit, setEdit] = useState(false);
  const [newLabel, setNewLabel] = useState(false);

  return (
    <div className="DeclarationValeur__accordion__question">
      <div className="DeclarationValeur__accordion__status" />
      <div className="DeclarationValeur__accordion__edit">
        <button
          type="button"
          className="DeclarationValeur__accordion__button"
          title="Modifier le nom"
          onClick={() => {
            setEdit(true);
            setTimeout(() => refInput.current && refInput.current.focus());
          }}
        />
        {edit ? (
          <input
            ref={refInput}
            type="text"
            className="CubageRoomList__accordion__input"
            value={newLabel || userRoom.label || ''}
            onChange={e => {
              const { value } = e.target;
              setNewLabel(value);
            }}
            onBlur={e => {
              const { value } = e.target;
              setTimeout(() => setEdit(false), 200);
              changeRoomLabel(value, userRoom.id);
            }}
            onKeyDown={e => e.keyCode === 13 && e.target.blur()}
          />
        ) : (
          <div className="DeclarationValeur__accordion__label">
            {userRoom.label}
          </div>
        )}
      </div>
      <div className="DeclarationValeur__accordion__statusContent">{`${numberWithSpace(
        getTotalById(userRoom.id)
      )} €`}</div>
    </div>
  );
};

const RoomContent = ({
  userRoom,
  addElement,
  getElementById,
  decreaseElement,
  increaseElement,
  changeElementValue,
  removeRoom,
}) => {

  return (
    <div className="DeclarationValeur__accordion__reponse">
      {!!userRoom.elements.length && (
        <div className="DeclarationValeur__elements">
          <div className="DeclarationValeur__element__title">
            Valeur unitaire
          </div>
          {userRoom.elements.map((elementRoom, ii) => {
            const element = elementRoom.custom
              ? elementRoom
              : getElementById(elementRoom.id);
            return (
              <div className="DeclarationValeur__element" key={ii}>
                <div className="DeclarationValeur__element__label">
                  {element.label || element.custom.label}
                </div>
                <div className="DeclarationValeur__element__right">
                  <div className="DeclarationValeur__element__buttons">
                    <button
                      type="button"
                      onClick={e => {
                        e.preventDefault();
                        decreaseElement(userRoom.id, element.id);
                      }}
                      className={`DeclarationValeur__element__button ${elementRoom.remove ? '-remove' : ''
                        }`}
                    >
                      {elementRoom.remove ? <SVG src={remove} /> : '-'}
                    </button>
                    <span className="DeclarationValeur__element__counter">
                      {elementRoom.length}
                    </span>
                    <button
                      type="button"
                      onClick={e => {
                        e.preventDefault();
                        increaseElement(userRoom.id, element.id);
                      }}
                      className="DeclarationValeur__element__button"
                    >
                      +
                    </button>
                  </div>
                  <div className="DeclarationValeur__element__value">
                    <input
                      type="number"
                      value={`${elementRoom.value}` || '0'}
                      onChange={({ target }) => {
                        changeElementValue(
                          userRoom.id,
                          element.id,
                          target.value
                        );
                      }}
                      pattern="[0-9]"
                      className="DeclarationValeur__element__input"
                      required
                      onKeyDown={e => {
                        if (
                          e.keyCode !== 27 &&
                          e.keyCode !== 37 &&
                          e.keyCode !== 39 &&
                          e.keyCode !== 8 &&
                          e.keyCode !== 9 &&
                          Number.isNaN(Number(e.key))
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    €
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="DeclarationValeur__edit">
        <button
          type="button"
          className="DeclarationValeur__addElement"
          onClick={() => addElement(userRoom.id, userRoom.roomTypeId)}
        >
          Ajouter un élément
        </button>
        <button
          type="button"
          className="DeclarationValeur__removeRoom"
          onClick={() => removeRoom(userRoom.id)}
        >
          Supprimer la pièce
        </button>
      </div>
    </div>
  );
};
