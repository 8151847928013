import React, { Component } from 'react';
import { Img, Link } from '../../../components/Prismic/Prismic';
import './Insert.scss';

class Insert extends Component {
  checkInsertEncart = data => {
    if (data.insert_300x250_image.url) {
      return data.insert_300x250_image;
    }
    if (data.insert_728x90_image.url) {
      return data.insert_728x90_image;
    }
    if (data.insert_728x225_image.url) {
      return data.insert_728x225_image;
    }
    return null;
  };

  handleVideo = () => {
    if (this.props.insert_video_mp4) {
      return (
        this.props.insert_video_mp4 &&
        this.props.insert_video_mp4.html.substring(
          this.props.insert_video_mp4.html.lastIndexOf('src') + 5,
          this.props.insert_video_mp4.html.lastIndexOf('frameborder') - 2
        )
      );
    }
    return null;
  };

  render() {
    const datas = this.props;

    if (
      !datas.insert_300x250_image.url &&
      !datas.insert_728x90_image.url &&
      !datas.insert_728x225_image.url
    ) {
      return null;
    }
    const inserEncart = this.checkInsertEncart(datas);

    return (
      <div
        className={`Insert ${
          !datas.insert_300x250_image.url ? '-insert-728x90' : ''
        }`}
      >
        <div className="Insert__container">
          <Link
            to={datas.insert_link}
            className="Insert__link"
            aria-label="banner"
          >
            {inserEncart !== null ? (
              <div>
                <Img
                  src={this.checkInsertEncart(datas)}
                  className="Insert__img"
                />
                {/* <img
                  src={inserEncart.url}
                  alt={inserEncart.alt}
                  width={inserEncart.dimensions.width}
                  height={inserEncart.dimensions.height}
                  className="Insert__img"
                /> */}
              </div>
            ) : (
              <div>
                <iframe
                  width="480"
                  title="iframe"
                  height="270"
                  src={this.handleVideo()}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                />
              </div>
            )}
          </Link>
        </div>
      </div>
    );
  }
}

export default Insert;
