import React, { Component } from 'react';
import './HeaderModal.scss';

class HeaderModal extends Component {
  render() {
    const { modal, closeModal } = this.props;
    const Comp = modal.component;

    return (
      <div className={`HeaderModal ${modal.status ? '-opened' : ''}`}>
        <div
          className="HeaderModal__back"
          onClick={() => closeModal()}
          role="button"
          tabIndex="0"
        />
        <div className="HeaderModal__content">
          <div
            className="HeaderModal__close"
            onClick={() => closeModal()}
            role="button"
            tabIndex="0"
          />
          {Comp ? <Comp {...this.props} /> : ''}
        </div>
      </div>
    );
  }
}

export default HeaderModal;
