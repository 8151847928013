import React, { useState, useEffect, useContext } from 'react';
import MonDossierContext from '../../../../MonDossierContext';
import './Meteo.scss';

export default () => {
  const { getUserMeteo } = useContext(MonDossierContext);
  const [code, setCode] = useState(null);

  useEffect(() => {
    getUserMeteo().then(res => {
      setCode(res || 75101);
    });
  }, []);

  if (!code) return null;

  return (
    <iframe
      className="MeteoHome"
      id="widget_autocomplete_preview"
      width="250"
      height="400"
      frameBorder="0"
      src={`https://meteofrance.com/widget/prevision/${code}0`}
      title="météo"
    />
  );
};
