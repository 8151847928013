import React, { useState, useEffect, useContext } from 'react';
import Form, { Input, Submit, validator } from 'components/Form/Form';
import { getUserInformations, postProfileUpdate } from 'services/cygest';
import MonDossierContext from '../../MonDossierContext';
import Header from '../Header/Header';
import './PasswordForm.scss';

const UpdatePassword = ({ history, homeFolderPath }) => {
  let formApi = null;
  const { error } = useContext(MonDossierContext);
  const [pending, setPending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState({
    status: false,
    type: 'success',
    success: {
      title: 'Vos modifications ont bien été enregistrées',
    },
    fail: {
      title: 'Impossible de sauvegarder vos modifications...',
      message: 'Merci réessayer ultérieurement',
      element: (
        <button
          type="button"
          onClick={() => {
            setConfirm({ ...confirm, type: 'fail', status: false });
          }}
        >
          Fermer
        </button>
      ),
    },
  });

  useEffect(() => {
    getUserInformations()
      .then(data => {
        setPending(false);
        formApi.setValues({
          login: data.email || '',
        });
      })
      .catch(error);
  }, []);

  function submitted(formState) {
    setLoading(true);
    postProfileUpdate(formState)
      .then(() => {
        setLoading(false);
        setConfirm({ ...confirm, type: 'success', status: true });
        setTimeout(() => {
          history.push(homeFolderPath);
        }, 3000);
      })
      .catch(err => {
        error(err);
        setLoading(false);
      });
  }

  function setFormApi(e) {
    formApi = e;
  }

  return (
    <div className="PasswordForm">
      <Header
        title="Modifier mon profil"
        description="Vous pouvez modifier votre identifiant et votre mot de passe"
      />
      <div className="PasswordForm__content">
        <Form
          className="PasswordForm__form"
          onSubmit={submitted}
          getApi={setFormApi}
          confirm={confirm}
          pending={pending}
        >
          <Input
            type="hidden"
            field="login"
            label="Identifiant"
            placeholder="jean.dupont@email.com"
            validate={validator.email}
            validateOnBlur
          />
          <Input
            type="password"
            field="newPassword"
            // initialValue="Azerty12345"
            label="Nouveau mot de passe"
            instruction="(8 caractères minimum dont 1 majuscule et 1 chiffre)"
            placeholder="Saisissez votre nouveau mot de passe"
            validate={validator.checkPassword}
            validateOnBlur
          />
          <span className="PasswordForm__mandatory">* Champ obligatoire</span>
          <div className="PasswordForm__buttons">
            <button
              type="button"
              className="PasswordForm__button"
              onClick={() => history.goBack()}
            >
              Annuler
            </button>
            <Submit className="PasswordForm__submit" disabled={loading}>
              Enregister mes modifications
            </Submit>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UpdatePassword;
