import React, { useState, useEffect, useContext } from 'react';
import { postValidRegister } from 'services/cygest';
import MonDossierContext from '../../MonDossierContext';
import Header from '../Header/Header';
import './PasswordForm.scss';

const ForgottenPassword = ({ match }) => {
  const context = useContext(MonDossierContext);
  const { connect } = context;
  const [response, setResponse] = useState();
  const { params } = match;
  useEffect(() => {
    postValidRegister(params)
      .then(user => {
        connect(user);
      })
      .catch(err => setResponse(err.response.data));
  }, []);

  return (
    <div className="PasswordForm">
      <Header
        title="Activation de votre compte."
        description="Vérification en cours..."
      />
      <div className="PasswordForm__content" style={{ textAlign: 'center' }}>
        {response}
      </div>
    </div>
  );
};
export default ForgottenPassword;
