import React, { Component } from 'react';
import { RichText, Link } from 'components/Prismic/Prismic';
import { getPartners } from 'services/cygest';
import DevisContext from '../DevisContext';

class Etape3 extends Component {
  static contextType = DevisContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      confirm: {
        status: false,
        type: 'success',
        success: {
          title: 'Demande envoyée !',
          message:
            'Votre demande a été transmise aux partenaires qui vous recontacterons rapidement.',
        },
        fail: {
          title: "Votre demande n'a pas pu être envoyée.",
          message: 'Merci de réessayer ultérieurement',
          element: (
            <button
              type="button"
              onClick={() => {
                const { confirm } = this.state;
                this.setState({
                  confirm: { ...confirm, status: false },
                });
              }}
            >
              Fermer
            </button>
          ),
        },
      },
      PAR_N_ID: [],
    };
  }

  async componentDidMount() {
    const partners = await getPartners();

    this.setState({ PAR_N_ID: partners.data });
  }

  render() {
    const { datas } = this.props;
    const { step4_title: step4Title, step4_text: step4Text } = datas.data;
    const { form } = this.context;

    return (
      <div>
        <RichText className="Devis__form__end__title">{step4Title}</RichText>
        <RichText className="Devis__form__text">{step4Text}</RichText>

        <div className="Devis__form__center">
          {form.registerToken ? (
            <Link
              to={`/votre-dossier/inscription/etape-5/${form.registerToken}/${
                form.uid
              }`}
              className="Devis__form__button"
            >
              Créer votre mot de passe
            </Link>
          ) : (
            <div>
              Votre compte a déja été créé.{' '}
              <Link to="/votre-dossier/">Accéder a mon compte</Link>.
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Etape3;
