import React, { Component } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { CookiesProvider } from 'react-cookie';
import TagManager from 'react-gtm-module';

import { getPageByTypes, indexedDB } from './services/prismic';

import Loading from './components/Loading/Loading';
import Modal, { ModalProvider } from './components/Modal/Modal';
import config from './config';

import Header from './layout/Header/Header';
import Footer from './layout/Footer/Footer';
import NotFound from './layout/NotFound/NotFound';

import Home from './pages/Home/Home';
import Particulier from './pages/Particulier/Particulier';
import Professionnel from './pages/Professionnel/Professionnel';
import Content from './pages/Content/Content';
import SavoirFaire from './pages/SavoirFaire/SavoirFaire';
import Landing from './pages/Landing/Landing';
import Template from './pages/Template/Template';
import Devis from './pages/Devis/Devis';
import DevisPartenaire from './pages/DevisPartenaire/DevisPartenaire';
import DevisReference from './pages/DevisReference/DevisReference';
import Agences from './pages/Agences/Agences';
import MiniSites from './pages/MiniSites/MiniSites';
import NewsHome from './pages/NewsHome/NewsHome';
import News from './pages/News/News';
import Sitemap from './pages/Sitemap/Sitemap';
import FaqHome from './pages/FaqHome/FaqHome';
import Faq from './pages/Faq/Faq';

import MonDossier from './pages/MonDossier/MonDossier';

// import Test from './pages/Test/Test';

import './App.scss';

const history = createBrowserHistory();

history.listen((location, action) => {
  if (!config.isMinisite()) {
    if (action === 'POP') return;
    window.scrollTo(0, 0);
  }
});

if (config.gtm.enable === 'true') {
  TagManager.initialize({ gtmId: config.gtm.id });
}

window.redirectTo = d => {
  console.log('redirectTo(', d, ')');
  history.push(d);
};

class App extends Component {
  constructor() {
    super();

    this.state = {
      ready: false,
      datas: null,
    };

    getPageByTypes([
      'know-how-page',
      'professionnel-home',
      'particulier-home',
      'landing-page',
      'template-page',
    ]).then(datas => {
      this.setState({ datas, ready: true });
    });
  }

  componentDidMount() {
    indexedDB()
      .init()
      .then(db => {
        const prismicObj = db.createObjectStore('prismic', { keyPath: 'id' });
        prismicObj.createIndex('id', 'id', { unique: true });
        prismicObj.createIndex('uid', 'uid', { unique: false });
        prismicObj.createIndex('type', 'type', { unique: false });

        const cygestAgencesObj = db.createObjectStore('cygestAgences', {
          keyPath: 'cp',
        });
        cygestAgencesObj.createIndex('cp', 'cp', { unique: true });
        cygestAgencesObj.createIndex('ssdomaine', 'ssdomaine', {
          unique: true,
        });

        db.createObjectStore('cygestAgenceList', {
          keyPath: 'cp',
        });
      });
  }

  dynamicsRoutes() {
    const { datas } = this.state;
    return datas.map((e, i) => {
      if (e.type === 'template-page') {
        return (
          <Route
            exact
            path={`/${e.uid}`}
            render={props => <Template uid={e.uid} {...props} />}
            key={i}
          />
        );
      }
      if (e.type === 'landing-page') {
        return (
          <Route
            exact
            path={`/${e.uid}`}
            render={props => <Landing uid={e.uid} {...props} />}
            key={i}
          />
        );
      }
      return null;
    });
  }

  dynamicsRedirect() {
    const { datas } = this.state;
    return datas.map((e, i) => {
      if (e.tags.includes('index')) {
        if (e.type === 'know-how-page') {
          return (
            <Redirect
              exact
              from="/notre-savoir-faire"
              to={`/notre-savoir-faire/${e.uid}`}
              key={i}
            />
          );
        }
        if (e.type === 'professionnel-home') {
          return (
            <Redirect
              exact
              from="/professionnel"
              to={`/professionnel/${e.uid}`}
              key={i}
            />
          );
        }
        if (e.type === 'particulier-home') {
          return (
            <Redirect
              exact
              from="/particulier"
              to={`/particulier/${e.uid}`}
              key={i}
            />
          );
        }
      }
      return null;
    });
  }

  routes() {
    if (config.isMinisite()) {
      return (
        <Switch>
          <Route exact path="/plan-du-site" component={Sitemap} />
          <Route exact path="/:tab?" component={MiniSites} />
          <Route component={NotFound} status={404} />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route exact path="/" component={Home} />

        <Route path="/votre-dossier" component={MonDossier} />

        <Route exact path="/plan-du-site" component={Sitemap} />

        <Route exact path="/foire-aux-questions" component={FaqHome} />
        <Route exact path="/foire-aux-questions/:uid" component={Faq} />

        <Route exact path="/actualites" component={NewsHome} />
        <Route exact path="/actualites/:uid" component={News} />

        <Route exact path="/notre-savoir-faire/:uid" component={SavoirFaire} />

        <Redirect exact from="/devis" to="/devis/etape-1" />
        <Route
          exact
          path="/devis/(etape-1|etape-2|etape-3|etape-4)?"
          component={Devis}
        />
        <Redirect exact from="/devis/:uid" to="/devis/:uid/etape-1" />
        <Route
          exact
          path="/devis/:uid?/:step(etape-1|etape-2|etape-3|etape-4)"
          component={DevisPartenaire}
        />

        <Redirect
          exact
          from="/devis/reference/:uid"
          to="/devis/reference/:uid/etape-1"
        />
        <Route
          exact
          path="/devis/reference/:uid?/:step(etape-1|etape-2|etape-3|etape-4)"
          component={DevisReference}
        />

        <Route
          exact
          path="/particulier/nos-prestations-de-demenagement"
          component={Particulier}
        />
        <Route
          exact
          path="/particulier/:uid"
          render={routeProps => (
            <Content
              {...routeProps}
              type="particulier-content"
              topic="particulier"
              name="Particulier"
            />
          )}
        />

        <Route
          exact
          path="/professionnel/nos-prestations-de-demenagement"
          component={Professionnel}
        />
        <Route
          exact
          path="/professionnel/:uid"
          render={routeProps => (
            <Content
              {...routeProps}
              type="professionnel-content"
              topic="professionnel"
              name="Professionnel"
            />
          )}
        />

        <Route
          exact
          path="/agences-demenagement/:cp([1-9][0-9]{4}|[0-9][1-9][0-9]{3}|[1-9][0-9]|[0-9][1-9])"
          component={Agences}
        />

        {this.dynamicsRoutes()}

        {this.dynamicsRedirect()}

        {/* <Route path="/test" component={Test} /> */}
        <Route
          path="/redirection/:path"
          render={e => {
            window.parent.redirectTo(
              e.location.pathname.replace('/redirection', '')
            );
            return <div>redirection...</div>;
          }}
        />
        <Route component={NotFound} status={404} />
      </Switch>
    );
  }

  render() {
    const { ready } = this.state;
    if (ready) {
      return (
        <CookiesProvider>
          <ModalProvider>
            <Router history={history}>
              <div className="container">
                <Header history={history} />
                {this.routes()}
                <Modal />
                <Footer storeLocator={history} />
              </div>
            </Router>
          </ModalProvider>
        </CookiesProvider>
      );
    }
    return <Loading />;
  }
}
export default App;
