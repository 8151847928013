import React, { Component } from 'react';
import { asField } from 'informed';

class Checkbox extends Component {
  render() {
    const { fieldState, fieldApi, ...props } = this.props;
    const { error, value } = fieldState;
    const { setValue, setTouched } = fieldApi;
    const {
      children,
      disabled,
      forwardedRef,
      type,
      field,
      onChange,
      className,
      ...rest
    } = props;
    const uniqid = `checkbox-${Math.round(Math.random() * 100000)}`;

    return (
      <div
        className={`Form__group -checkbox ${error ? '-error' : ''} ${
          disabled ? '-disabled' : ''
        } ${className}`}
      >
        <label htmlFor={uniqid}>
          <input
            type="checkbox"
            name={field}
            checked={!value && value !== 0 ? '' : value}
            onChange={e => {
              setValue(e.target.checked);
              setTouched();
              if (onChange) onChange(e);
            }}
            id={uniqid}
            disabled={disabled}
            {...rest}
          />
          <span>{children}</span>
        </label>
        {error ? <small className="Form__messageError">{error}</small> : null}
      </div>
    );
  }
}

export default asField(Checkbox);
