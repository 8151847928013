import React, { Component } from 'react';
import Head from '../../components/Head/Head';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { getPageByUid } from '../../services/prismic';
import Loading from '../../components/Loading/Loading';

import Slice from '../../components/Slice/Slice';

import './Particulier.scss';

class Particulier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      topicName: 'Particulier',
      pageType: 'particulier',
      datas: null,
    };

    getPageByUid('particulier-home', 'nos-prestations-de-demenagement').then(
      datas => {
        if (!datas) props.history.push('/404');
        else this.setState({ datas: datas.data });
      }
    );
  }

  render() {
    const { datas, topicName, pageType } = this.state;
    if (datas) {
      return (
        <div className="Particulier">
          <Head title={datas.seo_title} description={datas.seo_description} />
          <div
            className="Particulier__background"
            style={{
              backgroundImage: `url('${datas.back_img.url}')`,
            }}
          />
          <div className="Particulier__container">
            <Breadcrumb
              className="Particulier__breadcrumb"
              topic={{ name: topicName, link: pageType }}
              title={datas.seo_title}
              white
              h1
            />
            <div className="Particulier__slice">
              <Slice slice={datas.body} />
            </div>
          </div>
        </div>
      );
    }

    return <Loading />;
  }
}

export default Particulier;
