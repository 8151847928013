import React, { Component } from 'react';

import Loading from '../../components/Loading/Loading';
import { getAgenceByDomain } from '../../services/cygest';
import { Link } from '../../components/Prismic/Prismic';
import slug from '../../components/slug/slug';

class MiniSitemap extends Component {
  constructor() {
    super();

    this.state = {
      agence: null,
    };

    getAgenceByDomain(`www.${document.location.host}`).then(agence => {
      this.setState({ agence });
    });
  }

  render() {
    const { agence } = this.state;

    if (agence) {
      const tabList = agence.metiers.filter(
        metier =>
          metier.nom === 'Déménagement de particuliers' ||
          metier.nom === 'Déménagement de professionnels' ||
          metier.nom === 'Garde-meubles' ||
          metier.nom === 'Self stockage' ||
          metier.nom === 'Savoir Faire'
      );
      return (
        <ul className="Sitemap__ul -center">
          <li className="Sitemap__li">
            <ul className="Sitemap__subUl">
              <li className="Sitemap__subLi">
                <Link to="/" className="Sitemap__LinkTitle" noRedirect>
                  Page d&apos;accueil
                </Link>
              </li>

              {tabList.map((item, i) => (
                <li className="Sitemap__subLi" key={i}>
                  <Link
                    to={`/${slug(item.nom)}`}
                    className="Sitemap__LinkTitle"
                    noRedirect
                  >
                    {item.nom}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      );
    }

    return <Loading />;
  }
}

export default MiniSitemap;
