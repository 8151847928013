import React, { Component } from 'react';
import { RadioGroup as InRadioGroup } from 'informed';

class RadioGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      ready: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ ready: true }));
  }

  render() {
    const { error, ready } = this.state;
    const {
      className,
      children,
      disabled,
      label,
      validate,
      ...rest
    } = this.props;

    return (
      <div
        className={`${className || 'Form__group -radio'} ${
          error ? '-error' : ''
        } ${disabled ? '-disabled' : ''}`}
      >
        {label ? <div className="Form__label">{label}</div> : null}
        <div className={`${className ? `${className}__content` : ''}`}>
          <InRadioGroup
            disabled={disabled}
            validate={formState => {
              if (validate && ready) {
                const status = validate(formState);
                this.setState({ error: status });
                return status;
              }
              return undefined;
            }}
            {...rest}
          >
            {children}
          </InRadioGroup>
        </div>
        {error ? (
          <small
            className={`${
              className ? `${className}__messageError` : 'Form__messageError'
            }`}
          >
            {error}
          </small>
        ) : null}
      </div>
    );
  }
}

export default RadioGroup;
