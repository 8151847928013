import React, { Component } from 'react';
import { RichText, Link, Img } from '../../Prismic/Prismic';
import './TwoColumns.scss';

class TwoColumns extends Component {
  render() {
    const { datas } = this.props;
    return (
      <div className="TwoColumns">
        {datas.items.map((item, i) => (
          <div
            className={`TwoColumns__row ${
              item.reverse === 'true' ? '-reverse' : ''
            }`}
            key={i}
          >
            <div>
              <div className="TwoColumns__row__colLeft">
                <RichText className="TwoColumns__row__content">
                  {item.text}
                </RichText>
                <div className="TwoColumns__row__linkWrap">
                  <Link to={item.link} className="TwoColumns__row__link">
                    {item.button_name}
                  </Link>
                </div>
              </div>
            </div>

            <div>
              <div className="TwoColumns__row__colRight">
                <Img src={item.photo} />
              </div>
            </div>
          </div>
        ))}
        <Link to={datas.primary.link} className="TwoColumns__link">
          {datas.primary.link_name}
        </Link>
      </div>
    );
  }
}

export default TwoColumns;
