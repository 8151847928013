import React, { Component } from 'react';
import { asField } from 'informed';

class CheckboxPartenaireGroup extends Component {
  componentDidUpdate(prevProps) {
    const { fieldApi, initialValue } = this.props;
    const { setValue } = fieldApi;

    if (initialValue && prevProps.initialValue !== initialValue) {
      setValue(initialValue);
    }
  }

  render() {
    const { fieldState, fieldApi, ...props } = this.props;
    const { error } = fieldState;
    const { children, forwardedRef, ...etc } = props;

    return (
      <div className="CheckboxPartenaireGroup" {...etc}>
        <div className="CheckboxPartenaireGroup__content">{children}</div>
        {error ? <small className="Form__messageError">{error}</small> : null}
      </div>
    );
  }
}

export default asField(CheckboxPartenaireGroup);
