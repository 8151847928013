import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { DevisProvider } from './DevisContext';
import Etapes from './Etapes/Etapes';
import './Devis.scss';

const Devis = () => (
  <DevisProvider>
    <Switch>
      <Route exact path="/devis/:step" component={Etapes} />
    </Switch>
  </DevisProvider>
);

export default Devis;
