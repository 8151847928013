import React, { Component } from 'react';
import { asField } from 'informed';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
    };
    this.uniqid = `input-${Math.round(Math.random() * 100000)}`;
  }

  componentDidMount() {
    const { type } = this.props;
    this.setState({ type });
  }

  togglePassword() {
    this.setState(state => ({
      ...state,
      type: state.type === 'password' ? 'text' : 'password',
    }));
  }

  render() {
    const { fieldState, fieldApi, ...props } = this.props;
    const { value, error } = fieldState;
    const { type } = this.state;
    const { setValue, setTouched } = fieldApi;
    const {
      className,
      icon,
      label,
      onChange,
      onBlur,
      initialValue,
      forwardedRef,
      disabled,
      children,
      type: initialType,
      instruction,
      style,
      ...rest
    } = props;

    return (
      <div
        className={`Form__group -input ${className || ''} ${
          error ? '-error' : ''
        } ${disabled ? '-disabled' : ''} ${type === 'hidden' ? '-hidden' : ''}`}
        style={style}
      >
        <label htmlFor={this.uniqid}>
          {label && (
            <span className="Form__label">
              {label}{' '}
              {instruction && (
                <span className="Form__instruction">{instruction}</span>
              )}
            </span>
          )}

          <span className="Form__inputWrap">
            <input
              type={type}
              ref={forwardedRef}
              value={!value && value !== 0 ? '' : value}
              onChange={e => {
                setValue(e.target.value);
              }}
              onBlur={e => {
                if (e.target.value) setTouched();
              }}
              className={`${icon ? `-${icon}` : ''}`}
              id={this.uniqid}
              disabled={disabled}
              {...rest}
            />
            {initialType === 'password' && (
              <div
                className="Form__password"
                role="button"
                tabIndex="0"
                onClick={() => this.togglePassword()}
              >
                {type === 'password' ? 'afficher' : 'masquer'}
              </div>
            )}
          </span>
        </label>
        {error ? <small className="Form__messageError">{error}</small> : null}
      </div>
    );
  }
}

export default asField(Input);
