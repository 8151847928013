import React, { useState, useEffect } from 'react';
import { asField } from 'informed';
import { Input } from '../Form';

const Radio = ({ fieldState, fieldApi, ...props }) => {
  const [other, setOther] = useState(false);
  const { error, value } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const {
    className,
    icon,
    label,
    onChange,
    onBlur,
    initialValue,
    forwardedRef,
    disabled,
    children,
    type,
    index,
    field,
    ...rest
  } = props;

  useEffect(() => {
    children.forEach(child => {
      if (child.props.children === 'Autre' && value === child.props.value) {
        setOther(true);
      }
    });
  }, [value]);

  return (
    <div
      className={`Form__group -radio ${className || ''} ${
        error ? '-error' : ''
      } ${disabled ? '-disabled' : ''}`}
    >
      {label ? <div className="Form__label">{label}</div> : null}
      <div className={`${className ? `${className}__content` : ''}`}>
        {children.map((child, i) => (
          <label
            className="Form__radio"
            htmlFor={`radioOther-${index}-${i}`}
            key={i}
          >
            <input
              type="radio"
              name={field}
              onChange={() => {
                setOther(child.props.children === 'Autre');
                setValue(child.props.value);
              }}
              onBlur={setTouched}
              disabled={disabled}
              checked={value === child.props.value}
              id={`radioOther-${index}-${i}`}
              {...rest}
            />
            <span className="Form__radio__children">
              {child.props.children}
            </span>
          </label>
        ))}
        <Input
          type="text"
          field={`q-${index}[autre]`}
          disabled={!other}
          placeholder="Si autre préciser..."
          validate={val => (!val && other ? 'Ce champ est requis.' : undefined)}
          validateOnChange
          style={{ marginTop: '10px' }}
        />
      </div>
      {error ? <small className="Form__messageError">{error}</small> : null}
    </div>
  );
};

export default asField(Radio);
