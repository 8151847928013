import React, { Component } from 'react';

import Head from '../../components/Head/Head';
import Loading from '../../components/Loading/Loading';
import { getPageByUid } from '../../services/prismic';
import Slice from '../../components/Slice/Slice';
import { RichText, Text } from '../../components/Prismic/Prismic';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import './Faq.scss';

class Faq extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: null,
    };

    getPageByUid('faq-page', props.match.params.uid).then(page => {
      this.setState({ page });
    });
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.uid !== match.params.uid) {
      getPageByUid('faq-page', match.params.uid).then(page => {
        this.setState({ page });
      });
    }
  }

  render() {
    const { page } = this.state;
    if (page) {
      const { data } = page;

      return (
        <div className="Faq">
          <div className="Faq__header">
            <Head
              title={Text.asText(data.question)}
              description={data.seo_description}
            />
            <div
              className="Faq__background"
              style={{ backgroundImage: `url('${data.back_img.url}')` }}
            />
            <Breadcrumb
              className="Faq__breadcrumb"
              topic={{
                link: 'foire-aux-questions',
                name: 'foire aux questions',
              }}
              title={Text.asText(data.question)}
              white
            />
          </div>

          <div className="Faq__container">
            <div
              className="Faq__group"
              itemScope
              itemType="https://schema.org/QAPage"
            >
              <div
                itemProp="mainEntity"
                itemScope
                itemType="https://schema.org/Question"
              >
                <RichText className="Faq__question" itemProp="name">
                  {data.question}
                </RichText>

                <span className="Faq__count" itemProp="answerCount">
                  1
                </span>

                <div
                  itemProp="acceptedAnswer"
                  itemScope
                  itemType="https://schema.org/Answer"
                >
                  <RichText className="Faq__answer" itemProp="text">
                    {data.answer}
                  </RichText>
                </div>
              </div>
            </div>
            <div className="Faq__slice">
              <Slice slice={data.body} />
            </div>
          </div>
        </div>
      );
    }

    return <Loading />;
  }
}

export default Faq;
