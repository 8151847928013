import React, { Component } from 'react';

import Head from '../../components/Head/Head';
import Loading from '../../components/Loading/Loading';
import { getPageByType } from '../../services/prismic';
import { RichText } from '../../components/Prismic/Prismic';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import MasterSitemap from './MasterSitemap';
import MiniSitemap from './MiniSitemap';
import config from '../../config';

import './Sitemap.scss';

class Sitemap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: null,
    };

    getPageByType('sitemap-page').then(page => {
      this.setState({ page });
    });
  }

  render() {
    const { page } = this.state;
    if (page) {
      const { data } = page[0];

      return (
        <div className="Sitemap">
          <div className="Sitemap__header">
            <Head title={data.seo_title} description={data.seo_description} />
            <div className="Sitemap__background" />
            <Breadcrumb title={data.seo_title} white />
          </div>

          <div className="Sitemap__container">
            <RichText className="Sitemap__title">{data.title}</RichText>
            {config.isMinisite() ? <MiniSitemap /> : <MasterSitemap />}
          </div>
        </div>
      );
    }

    return <Loading />;
  }
}

export default Sitemap;
