import React, { Component, Suspense } from 'react';
import SVG from 'react-inlinesvg';

import Head from '../../components/Head/Head';
import { getAgenceByCp } from '../../services/cygest';
import config from '../../config';
import Loading from '../../components/Loading/Loading';

import Modules from '../../components/Modules/Modules';
import QuickEstimate from '../../components/Slice/QuickEstimate/QuickEstimate';
import GoogleMaps from './GoogleMaps';

import twitter from '../../img/twitter.svg';
import facebook from '../../img/facebook.svg';
import logo from './img/logo.png';
import './Agences.scss';

class Agences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false,
      agence: {
        cp_achete: false,
        societe: '',
        adresse1: '',
        adresse2: '',
        cp: '',
        ville: '',
        tel: '',
        email: '',
        latitude: '0',
        longitude: '0',
        ssdomaine: false,
      },
      height: '350px',
    };

    const { match } = this.props;
    const { cp } = match.params;

    getAgenceByCp(cp).then(agence => {
      if (!agence) this.setState({ notFound: true });
      else if (agence.ssdomaine)
        window.location.href = `https://${agence.ssdomaine.replace(
          'www.',
          ''
        )}`;
      else this.setState({ agence });
    });
  }

  componentWillMount() {
    this.calc();
    window.onload = () => {
      requestAnimationFrame(() => this.calc());
    };
    window.addEventListener('resize', () => {
      requestAnimationFrame(() => this.calc());
    });
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { cp } = match.params;
    if (prevProps.match.params.cp !== cp) {
      getAgenceByCp(cp).then(agence => {
        this.setState({ agence });
      });
    }
  }

  calc() {
    const bodyWidth = document.body.clientWidth;
    this.setState({ height: bodyWidth > 425 ? '350px' : '144px' });
  }

  render() {
    const { agence, height, notFound } = this.state;

    if (agence) {
      return (
        <div className="Agences">
          <div className="Agences__header">
            <Head
              title={`Déménagement ${agence.ville}: société ${agence.societe}`}
              description={`Contactez la société ${agence.societe} pour votre déménagement à ${agence.ville}. Devis gratuit. Prestation adaptée à vos besoins. Réseau certifié NF Service déménagement de Particuliers en France.`}
            />
            <div className="Agences__header__container">
              <div className="Agences__header__test">
                <div
                  className={`Agences__infos ${notFound ? '-notFound' : ''}`}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Partager sur Twitter"
                    className="Agences__infos__twitter"
                    href={`https://twitter.com/intent/tweet/?url=${encodeURIComponent(
                      window.location.href
                    )}&amp;text=${encodeURIComponent(
                      'Je prépare mon déménagement avec Les déménageurs bretons !'
                    )}`}
                  >
                    <SVG src={twitter} className="Agences__infos__svg" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Partager sur Facebook"
                    className="Agences__infos__facebook"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      window.location.href
                    )}`}
                  >
                    <SVG src={facebook} className="Agences__infos__svg" />
                  </a>

                  <h1 className="Agences__infos__title">
                    {`Société ${agence.societe} à ${agence.ville}`}
                  </h1>

                  <p className="Agences__infos__addr">
                    {`${agence.adresse1} ${agence.adresse2}`}
                  </p>
                  <p className="Agences__infos__addr">
                    {`${agence.cp} ${agence.ville}, France`}
                  </p>
                  <a
                    href={`tel:${agence.tel.replace('.', ' ')}`}
                    className="Agences__infos__tel"
                  >
                    {agence.tel.replace('.', ' ')}
                  </a>
                  <a
                    href={`mailto:${agence.email}`}
                    className="Agences__infos__mail"
                  >
                    {agence.email}
                  </a>

                  <div
                    className="Agences__infos__ouverture"
                    dangerouslySetInnerHTML={{
                      __html: agence.horaires,
                    }}
                  />

                  <div className="Agences__infos__status">
                    Commerçant indépendant
                  </div>
                </div>
              </div>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
              <GoogleMaps
                defaultZoom={17}
                defaultPosition={{
                  lat: parseFloat(agence.latitude.replace(',', '.')) || 0,
                  lng: parseFloat(agence.longitude.replace(',', '.')) || 0,
                }}
                isMarkerShown
                googleMapURL={config.googleMaps.url}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </Suspense>
          </div>

          <div className="Agences__container">
            <div className="Agences__slice">
              <QuickEstimate />
              <div className="Agences__ads">
                <div className="Agences__ads__container">
                  <div className="Agences__ads__left">
                    <img
                      src={logo}
                      alt="Les déménageurs bretons"
                      width="330 "
                      height="250"
                      className="Agences__ads__logo"
                    />
                  </div>
                  <div className="Agences__ads__right">
                    <div className="Agences__ads__title">
                      <span>n°</span> <b>1</b> en notoriété
                    </div>
                    <div className="Agences__ads__legal">
                      Selon l’IFOP - sondage 2016
                    </div>
                    <div className="Agences__ads__baseline">
                      la confiance à un nom
                    </div>
                    <div className="Agences__ads__text">
                      « <b>Unique réseau certifié</b> NF Service 144
                      Déménagement de Particuliers au 1er janvier 2017. Liste
                      des certifiés disponible sur{' '}
                      <a
                        href="http://marque-nf.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.marque-nf.com
                      </a>{' '}
                      »
                    </div>
                  </div>
                </div>
              </div>
              <Modules
                module={{ type: 'opinion_module', id: 'W_fSghIAAC0A1HLw' }}
              />
              <Modules
                module={{ type: 'partner_module', id: 'W_fRGBIAAC0A1GyM' }}
              />
            </div>
          </div>
        </div>
      );
    }
    return <Loading />;
  }
}

export default Agences;
