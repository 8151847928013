import React, { Component } from 'react';
import { getModuleById } from '../../../services/prismic';
import { RichText } from '../../Prismic/Prismic';
import TrustBox from '../../TrustBox';
import './Opinion.scss';

class Opinion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: null,
    };
  }

  componentWillMount() {
    const { id } = this.props;

    getModuleById(id).then(datas => {
      this.setState({ datas });
    });
  }

  render() {
    const { datas } = this.state;
    if (datas) {
      return (
        <div className="Opinion">
          <div className="Opinion__container">
            <RichText className="Opinion__title">{datas.data.title}</RichText>
            <TrustBox />
          </div>
        </div>
      );
    }
    return null;
  }
}

export default Opinion;
