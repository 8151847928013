import React, { Component } from 'react';
import {
  getCityListByCodeDepartement,
  getCityListByCity,
  getAgenceByCp,
} from '../../../services/cygest';
import config from '../../../config';
import './AutoComplete.scss';

class AutoComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: false,
      loading: false,
      filteredList: [],
    };

    this.setTimeout = null;
  }

  filterList(value) {
    clearTimeout(this.setTimeout);

    if (value.length < 2) {
      this.setState({ loading: false, status: false });
      return;
    }

    this.setState({ loading: true, status: false });

    this.setTimeout = setTimeout(() => {
      if (/^[0-9]*$/.test(value)) {
        let deptCode;
        if (value.startsWith('200') || value.startsWith('201')) {
          deptCode = '2A';
        } else if (value.startsWith('202') || value.startsWith('206')) {
          deptCode = '2B';
        } else {
          deptCode = value.substring(0, 2);
        }

        getCityListByCodeDepartement(deptCode).then(datas => {
          const rawList = [];

          /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
          for (let i = 0; i < datas.length; i++) {
            const data = datas[i];
            for (let ii = 0; ii < data.codesPostaux.length; ii++) {
              const codesPostal = data.codesPostaux[ii];
              if (data.nom !== 'Brest' || codesPostal !== '29000') {
                rawList.push({
                  label: `${codesPostal} - ${data.nom}`,
                  cp: codesPostal,
                });
              }
            }
          }

          const filteredList = rawList.filter(val => {
            const regex = new RegExp(value, 'gmi');
            return regex.test(val.label);
          });
          this.setState({ loading: false, status: true });
          this.setState({ filteredList });
        });
      } else {
        getCityListByCity(value).then(datas => {
          const filteredList = [];

          /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
          for (let i = 0; i < datas.length; i++) {
            const data = datas[i];
            for (let ii = 0; ii < data.codesPostaux.length; ii++) {
              const codesPostal = data.codesPostaux[ii];
              if (data.nom !== 'Brest' || codesPostal !== '29000') {
                filteredList.push({
                  label: `${codesPostal} - ${data.nom}`,
                  cp: codesPostal,
                });
              }
            }
          }

          this.setState({ loading: false, status: true });
          this.setState({ filteredList });
        });
      }
    }, 500);
  }

  render() {
    const { status, filteredList, loading } = this.state;
    const { closeAll, history, store, retrieveValue } = this.props;

    const handleClick = (event, value) => {
      if (retrieveValue) retrieveValue(value);
      if (!store) {
        event.preventDefault();
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'Recherche agence',
          cp: value.label,
        });
        closeAll();

        getAgenceByCp(value.cp).then(data => {
          if (data && data.ssdomaine) {
            window.location = `https://${data.ssdomaine.replace('www.', '')}`;
          } else if (config.isMinisite()) {
            window.location = `${config.url}/agences-demenagement/${value.cp}`;
          } else {
            history.push(`/agences-demenagement/${value.cp}`);
          }
        });
      }
    };

    return (
      <div className={`AutoComplete ${loading ? '-loading' : ''}`}>
        Trouver une agence
        <input
          type="text"
          placeholder="CP ou ville"
          className="AutoComplete__input"
          onChange={e => this.filterList(e.target.value)}
          onFocus={e => {
            this.setState({ status: !!e.target.value });
          }}
          onBlur={() => setTimeout(() => this.setState({ status: false }), 100)}
        />
        <div className={`AutoComplete__list ${status ? '-show' : ''}`}>
          {filteredList.length ? (
            filteredList.map((item, i) => (
              <div
                to=""
                className="AutoComplete__item"
                onClick={e => handleClick(e, item)}
                key={i}
                role="button"
                tabIndex="0"
              >
                {item.label}
              </div>
            ))
          ) : (
            <span className="AutoComplete__item -no-result">
              Aucun résultat...
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default AutoComplete;
