import React, { Component } from 'react';
import { Img } from '../../Prismic/Prismic';
import './Logo.scss';

class Logo extends Component {
  render() {
    const { datas } = this.props;
    return (
      <div className="Logo">
        <Img src={datas.primary.logo} />
      </div>
    );
  }
}

export default Logo;
