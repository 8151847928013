import React, { Component } from 'react';
import SVG from 'react-inlinesvg';
import user from '../img/user.svg';
import phone from '../img/phone.svg';
import Callback from './Callback';
import Estimate from './Estimate';
import AutoComplete from './AutoComplete';
import { Link } from '../../../components/Prismic/Prismic';
import './Shortcut.scss';

class ShortCut extends Component {
  render() {
    const { toggleModal, closeAll, history } = this.props;

    return (
      <div className="Shortcut">
        <div className="Shortcut__search">
          <div className="Shortcut__search__button" title="Trouver une agence">
            <AutoComplete closeAll={closeAll} history={history} />
          </div>
        </div>

        <div className="Shortcut__callback">
          <div
            className="Shortcut__callback__button"
            onClick={() => {
              toggleModal(Callback);
            }}
            title="Rappel immédiat"
            role="button"
            tabIndex="0"
          >
            <SVG src={phone} className="Shortcut__callback__icon" />
            <span className="Shortcut__callback__label">Rappel immédiat</span>
          </div>
        </div>

        <div className="Shortcut__estimation">
          <div
            className="Shortcut__estimation__button"
            onClick={() => {
              toggleModal(Estimate);
            }}
            title="Devis rapide"
            role="button"
            tabIndex="0"
          >
            <span className="Shortcut__estimation__label">Devis rapide</span>
          </div>
        </div>

        <div className="Shortcut__folder">
          <Link
            to="/votre-dossier"
            className="Shortcut__folder__button"
            onClick={closeAll}
          >
            <SVG src={user} className="Shortcut__folder__icon" />
            <span className="Shortcut__folder__label">Votre dossier</span>
          </Link>
        </div>
      </div>
    );
  }
}

export default ShortCut;
