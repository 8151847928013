import React, { Component } from 'react';
import { Link } from '../../components/Prismic/Prismic';
import './NotFound.scss';

class NotFound extends Component {
  render() {
    const { history, back } = this.props;
    return (
      <div className="NotFound">
        <div className="NotFound__back" />
        <div className="NotFound__content">
          <h1 className="NotFound__title">404</h1>
          <h2 className="NotFound__subtitle">Page non trouvée</h2>
          {back ? (
            <p className="NotFound__text">
              Nous vous invitions à revenir en arriere :{' '}
              <a href="#top" onClick={() => history.goBack()}>
                Retour
              </a>
            </p>
          ) : (
            <p className="NotFound__text">
              Nous vous invitions à retourner sur la page d’
              <Link to="/">accueil</Link>
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default NotFound;
