import React, { Component } from 'react';

import Head from '../../components/Head/Head';
import Loading from '../../components/Loading/Loading';
import { getPageByUid } from '../../services/prismic';
import Slice from '../../components/Slice/Slice';
import { RichText } from '../../components/Prismic/Prismic';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import './Template.scss';

class Template extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: null,
    };
    getPageByUid('template-page', props.uid).then(page => {
      this.setState({ page });
    });
  }

  componentDidUpdate(prevProps) {
    const { uid } = this.props;
    if (prevProps.uid !== uid) {
      getPageByUid('template-page', uid).then(page => {
        this.setState({ page });
      });
    }
  }

  render() {
    const { page } = this.state;
    if (page) {
      const { data } = page;
      const { history } = this.props;

      return (
        <div className="Template">
          <div className="Template__header">
            <Head title={data.seo_title} description={data.seo_description} />
            <div
              className="Template__background"
              style={{
                backgroundImage: `url('${data.back_img.url}')`,
              }}
            />
            <Breadcrumb
              className="Template__breadcrumb"
              title={data.seo_title}
              white
            />
            <RichText className="Template__header__title">
              {data.title}
            </RichText>
          </div>

          <div className="Template__container">
            <div className="Template__slice">
              <Slice slice={data.body} history={history} />
            </div>
          </div>
        </div>
      );
    }

    return <Loading />;
  }
}

export default Template;
