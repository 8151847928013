import React, { useState, useContext } from 'react';
import SVG from 'react-inlinesvg';
import Col from 'components/Col';
import { Link } from 'components/Prismic/Prismic';
import { FormModal } from 'components/Form/Form';
import roomList from 'services/roomList';
import { postUserCubage } from 'services/cygest';
import Header from '../../Header/Header';
import ListePiece from './ListePiece';
import ListContext from './ListeMobilierContext';
import MonDossierContext from '../../../MonDossierContext';

import './CubageRoom3d.scss';

import loader from '../img/loader.svg';
import maison3d from '../img/maison3d.jpg';

export default ({ history, homeListMobilierPath, homeFolderPath }) => {
  const { error } = useContext(MonDossierContext);
  const listContext = useContext(ListContext);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState({
    status: false,
    type: 'success',
    success: {
      title: 'Votre liste de mobilier a bien été enregistrée',
    },
    fail: {
      title: 'Impossible de sauvegarder votre liste de mobilier',
      message: 'Merci réessayer ultérieurement',
      element: (
        <button
          type="button"
          onClick={() => {
            setConfirm({ ...confirm, type: 'fail', status: false });
          }}
        >
          Fermer
        </button>
      ),
    },
  });

  function submitForm(e) {
    e.preventDefault();

    setLoading(true);

    postUserCubage(listContext.getAllRooms())
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'dossierListeMobilier' });

        setConfirm({ ...confirm, type: 'success', status: true });
        setTimeout(() => {
          history.push(homeFolderPath);
        }, 2000);
        setLoading(false);
      })
      .catch(err => {
        error(err);
        setLoading(false);
      });
  }

  return (
    <div className={`CubageRoom3d ${confirm.status !== false ? '-sent' : ''}`}>
      <Header
        title="Détail de vos biens"
        description="Cliquez sur les pièces et faites l'inventaire de tous vos biens mobiliers."
      />
      <div className="CubageRoom3d__container">
        <div className="CubageRoom3d__grid">
          <Col
            colClassName="CubageRoom3d__grid__col1"
            className="CubageRoom3d__grid__col1__content"
          >
            <div className="CubageRoom3d__maison">
              <img
                src={maison3d}
                className="CubageRoom3d__maison__img"
                alt=""
              />
              {roomList.map((room, i) => (
                <Link
                  to={`${homeListMobilierPath}/maison3d/${room.name}`}
                  className={`CubageRoom3d__maison__button -${room.name}`}
                  key={i}
                >
                  <span>{room.label}</span>
                </Link>
              ))}
            </div>
          </Col>
          <Col
            colClassName="CubageRoom3d__grid__col2"
            className="CubageRoom3d__grid__col2__content"
          >
            {(() => {
              if (listContext.loading) {
                return <div className="CubageRoom3d__list__loading" />;
              }
              if (listContext.getAllRooms().length) {
                return <ListePiece pathname={history.location.pathname} />;
              }
              return (
                <div className="CubageRoom3d__list__title">
                  <div>Pour débuter</div>
                  <div>Choisissez votre pièce</div>
                  <div className="CubageRoom3d__list__arrow" />
                </div>
              );
            })()}

            <div className="CubageRoom3d__list__buttons">
              <button
                type="button"
                className="CubageRoom3d__list__buttons__cancel"
                onClick={() => history.push(homeListMobilierPath)}
              >
                Annuler
              </button>
              <button
                type="button"
                className="CubageRoom3d__list__buttons__save"
                onClick={submitForm}
                disabled={loading || listContext.loading}
              >
                Enregistrer
                <SVG
                  src={loader}
                  className={`CubageRoom3d__loading ${
                    loading ? '-loading' : ''
                    }`}
                />
              </button>
            </div>
          </Col>
        </div>
      </div>
      <FormModal data={confirm} />
    </div>
  );
};
