import React from 'react';

import {
  Form as InFormed,
  Option,
  useFormState,
  useFieldState,
  useFormApi,
  useFieldApi,
} from 'informed';
import Fusion from './components/Fusion';
import RadioGroup from './components/RadioGroup';
import RadioGroupOther from './components/RadioGroupOther';
import Input from './components/Input';
import SuperTextArea from './components/SuperTextArea';
import TextArea from './components/TextArea';
import Checkbox from './components/Checkbox';
import CheckboxPartenaire from './components/CheckboxPartenaire';
import CheckboxPrestation from './components/CheckboxPrestation';
import CheckboxPartenaireGroup from './components/CheckboxPartenaireGroup';
import Radio from './components/Radio';
import RadioBasic from './components/RadioBasic';
import Select from './components/Select';
import File from './components/File';
import Submit from './components/Submit';
import SubmitCaptcha from './components/SubmitCaptcha';
import './Form.scss';

const validator = {
  CheckboxPartenaireValidator: (value, { PAR_N_ID = [] }) => {
    const checked = PAR_N_ID.reduce((accumulator, currentValue, i) => {
      if (currentValue) accumulator.push(i);
      return accumulator;
    }, []);

    return !checked.length
      ? 'Merci de selectionner un ou plusieurs partenaires.'
      : undefined;
  },
  required: val =>
    val === '' || val === undefined || val === null
      ? 'Ce champ est requis.'
      : undefined,
  upload: val => {
    if (val) {
      const totalSize = val.reduce(
        (accumulator, currentValue) => accumulator + currentValue.size,
        0
      );
      return totalSize > 10000000
        ? 'La totalité des pièces jointes ne doit pas depasser 10Mo'
        : undefined;
    }
    return undefined;
  },
  password: val => {
    const error = [];
    const errorTest = [
      // { label: '1 caractère spécial', status: /-|_/gm.test(val) },
      { label: '1 chiffre', status: /[0-9]/gm.test(val) },
      { label: '1 majuscule', status: /[A-Z]/gm.test(val) },
      { label: '1 minuscule', status: /[a-z]/gm.test(val) },
    ];

    if (!val) {
      return 'Ce champ est requis.';
    }
    if (val.length < 8) {
      error.push(
        <div key="1">Ce mot de passe nécessite 8 caractères minimum.</div>
      );
    }

    const test = errorTest.reduce((a, v) => {
      if (!v.status) a.push(v.label);
      return a;
    }, []);

    if (test.length) {
      const sep = ['', ' et ', ', '];
      const result = test.reduce(
        (a, v, i) => v + sep[i > sep.length - 1 ? sep.length - 1 : i] + a,
        ''
      );
      error.push(
        <div key="2">Ce mot de passe doit contenir au moins {result}.</div>
      );
    }

    return error.length ? error : undefined;
  },
  checkPassword: val => {
    const error = [];
    const errorTest = [
      // { label: '1 caractère spécial', status: /-|_/gm.test(val) },
      { label: '1 chiffre', status: /[0-9]/gm.test(val) },
      { label: '1 majuscule', status: /[A-Z]/gm.test(val) },
      { label: '1 minuscule', status: /[a-z]/gm.test(val) },
    ];

    if (!val) {
      return undefined;
    }
    if (val.length < 8) {
      error.push(
        <div key="1">Ce mot de passe nécessite 8 caractères minimum.</div>
      );
    }

    const test = errorTest.reduce((a, v) => {
      if (!v.status) a.push(v.label);
      return a;
    }, []);

    if (test.length) {
      const sep = ['', ' et ', ', '];
      const result = test.reduce(
        (a, v, i) => v + sep[i > sep.length - 1 ? sep.length - 1 : i] + a,
        ''
      );
      error.push(
        <div key="2">Ce mot de passe doit contenir au moins {result}.</div>
      );
    }

    return error.length ? error : undefined;
  },
  numberFusion: val =>
    !/^[0-9]+$/g.test(val)
      ? 'Le champ Code postal de départ est requis.'
      : undefined,
  number: val =>
    !/^[0-9]+$/g.test(val)
      ? 'Merci de renseigner ce champ correctement.'
      : undefined,
  phone: val =>
    !/^[0-9]{10}$/g.test(val)
      ? 'Merci de renseigner un numéro de téléphone valide. Ex : 0123456789'
      : undefined,
  email: val =>
    !/^([\w-]+(?:(\.|\+)[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-zA-Z]{2,6}(?:\.[a-zA-Z]{2})?)$/g.test(
      val
    )
      ? 'Merci de renseigner un email valide'
      : undefined,
  fusionRequired: (value, values) =>
    !value || values.fusion.filter(v => v).length !== 2
      ? 'Ces champs sont requis'
      : undefined,
  id: value =>
    value && value.length !== 5
      ? 'Ce champ doit contenir 5 chiffres'
      : undefined,
};

const FormModal = ({ data }) => (
  <div className="Form__sent">
    <div className="Form__sent__content">
      <div className="Form__sent__modal">
        <div
          className={`Form__sent__title ${
            data.type === 'success' ? '-success' : '-fail'
          }`}
        >
          {data.type === 'success'
            ? data.success.title || 'Message envoyé !'
            : data.fail.title || "Le message n'a pas pu être envoyé."}
        </div>
        {(data.success && (data.success.message || data.success.element)) ||
        (data.fail && (data.fail.message || data.fail.element)) ? (
          <div className="Form__sent__message">
            {data.type === 'success' ? data.success.message : data.fail.message}
            {data.type === 'success' ? data.success.element : data.fail.element}
          </div>
        ) : null}
      </div>
    </div>
  </div>
);

const SelectOrder = ({ answers, id }) => {
  const reponseId1Api = useFieldApi(`q-${id}[reponseId1]`);
  const reponseId2Api = useFieldApi(`q-${id}[reponseId2]`);
  const reponseId3Api = useFieldApi(`q-${id}[reponseId3]`);

  const fixSameAnswer = (answerId, index) => {
    if (reponseId1Api.getValue() === answerId && index !== 1)
      reponseId1Api.setValue(0);
    if (reponseId2Api.getValue() === answerId && index !== 2)
      reponseId2Api.setValue(0);
    if (reponseId3Api.getValue() === answerId && index !== 3)
      reponseId3Api.setValue(0);
  };

  return (
    <>
      <RadioGroup
        field={`q-${id}[reponseId1]`}
        validate={validator.required}
        validateOnChange
      >
        <div className="checkboxLimit__number">1</div>
        {answers.map((answer, ii) => (
          <Radio
            key={ii}
            value={answer.id}
            onChange={() => fixSameAnswer(answer.id, 1)}
          />
        ))}
      </RadioGroup>
      <RadioGroup
        field={`q-${id}[reponseId2]`}
        validate={validator.required}
        validateOnChange
      >
        <div className="checkboxLimit__number">2</div>
        {answers.map((answer, ii) => (
          <Radio
            key={ii}
            value={answer.id}
            onChange={() => fixSameAnswer(answer.id, 2)}
          />
        ))}
      </RadioGroup>
      <RadioGroup
        field={`q-${id}[reponseId3]`}
        validate={validator.required}
        validateOnChange
      >
        <div className="checkboxLimit__number">3</div>
        {answers.map((answer, ii) => (
          <Radio
            key={ii}
            value={answer.id}
            onChange={() => fixSameAnswer(answer.id, 3)}
          />
        ))}
      </RadioGroup>
    </>
  );
};

const Form = props => {
  const {
    pending = false,
    confirm = {
      status: false,
      type: 'success',
      success: {
        title: '',
        message: '',
        element: null,
      },
      fail: {
        title: '',
        message: '',
        element: null,
      },
    },
    form,
    children,
    ...other
  } = props;

  return (
    <div
      className={`Form ${confirm.status !== false ? '-sent' : ''} ${pending &&
        '-pending'}`}
    >
      <div className="Form__content">
        <InFormed {...other}>
          {form ? (
            <>
              {form.map((field, i) => (
                <React.Fragment key={i}>
                  <Input
                    type="hidden"
                    field={`q-${field.id}[questionId]`}
                    initialValue={field.id}
                  />
                  {field.type === 'title' && (
                    <div className="Form__title">{field.label}</div>
                  )}
                  {field.type === 'select' && (
                    <Select
                      field={`q-${field.id}[reponseId]`}
                      label={`${field.label} * `}
                      validate={validator.required}
                      validateOnBlur
                    >
                      <Option value="" disabled>
                        Choisissez...
                      </Option>
                      {field.answers.map((option, ii) => (
                        <Option key={ii} value={option.id}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  )}
                  {field.type === 'weather' && (
                    <RadioGroup
                      field={`q-${field.id}[reponseId]`}
                      label={`${field.label} * `}
                      validate={validator.required}
                      validateOnBlur
                      className="Weather"
                    >
                      {field.answers.map(key => (
                        <RadioBasic
                          className="Weather__radio"
                          type="radio"
                          value={key.id}
                          key={key.label}
                        >
                          <div
                            className="Weather__radio__icon"
                            icon={`weather-${key.label}`}
                          />
                          <div className="Weather__radio__status" />
                        </RadioBasic>
                      ))}
                    </RadioGroup>
                  )}
                  {field.type === 'radio_other' && (
                    <RadioGroupOther
                      field={`q-${field.id}[reponseId]`}
                      label={`${field.label} * `}
                      validate={validator.required}
                      validateOnChange
                      index={field.id}
                    >
                      {field.answers.map((radio, ii) => (
                        <div type="radio" value={radio.id} key={ii}>
                          {radio.label}
                        </div>
                      ))}
                    </RadioGroupOther>
                  )}
                  {field.type === 'select_order' && (
                    <div className="checkboxLimit">
                      <div className="Form__label">
                        {field.label}{' '}
                        <em>
                          (Choix ordonné limité à 3 réponses 1 étant le premier
                          choix)
                        </em>
                      </div>
                      <div className="checkboxLimit__content">
                        <div>
                          <div className="checkboxLimit__answer" />
                          {field.answers.map((answer, ii) => (
                            <div key={ii} className="checkboxLimit__answer">
                              {answer.label}
                            </div>
                          ))}
                        </div>
                        <SelectOrder answers={field.answers} id={field.id} />
                      </div>
                    </div>
                  )}
                  {field.type === 'radio' && (
                    <RadioGroup
                      field={`q-${field.id}[reponseId]`}
                      label={`${field.label} * `}
                      validate={validator.required}
                      // validateOnChange
                      validateOnBlur
                    >
                      {field.answers.map((radio, ii) => (
                        <Radio type="radio" value={radio.id} key={ii}>
                          {radio.label}
                        </Radio>
                      ))}
                    </RadioGroup>
                  )}
                  {field.type === 'checkbox' && (
                    <Checkbox
                      key={i}
                      field={`q-${field.id}[reponseId]`}
                      validate={validator.required}
                      validateOnChange
                    >
                      {`${field.label} * `}
                    </Checkbox>
                  )}
                  {field.type === 'textarea' && (
                    <TextArea
                      key={i}
                      field={`q-${field.id}[reponseId]`}
                      label={`${field.label} `}
                      maxLength="300"
                    />
                  )}
                  {field.type === 'input' && (
                    <Input
                      key={i}
                      type="text"
                      field={`q-${field.id}[reponseId]`}
                      label={`${field.label} * `}
                      validate={validator.required}
                      validateOnChange
                    />
                  )}
                  {field.type === 'email' && (
                    <Input
                      key={i}
                      type="email"
                      field={`q-${field.id}[reponseId]`}
                      label={`${field.label} * `}
                      validate={validator.email}
                      validateOnBlur
                    />
                  )}
                </React.Fragment>
              ))}
              {children}
            </>
          ) : (
            children
          )}
        </InFormed>
      </div>

      <div className="Form__pending" />
      <FormModal data={confirm} />
    </div>
  );
};

export default Form;
export {
  Input,
  SuperTextArea,
  TextArea,
  RadioGroup,
  Radio,
  RadioBasic,
  Checkbox,
  CheckboxPrestation,
  CheckboxPartenaire,
  CheckboxPartenaireGroup,
  Select,
  Option,
  File,
  SubmitCaptcha,
  Submit,
  Fusion,
  validator,
  useFormState,
  useFieldState,
  useFormApi,
  useFieldApi,
  FormModal,
};
