import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFound from 'layout/NotFound/NotFound';

import { ListProvider } from './ListeMobilierContext';

import CubageRoom3d from './CubageRoom3d';
import CubageRoomList from './CubageRoomList';
import CubageElement3d from './CubageElement3d';

const Router = ({ homeFolderPath }) => (
  <ListProvider>
    <Switch>
      <Route
        exact
        path={`${homeFolderPath}/liste-mobilier/maison3d`}
        render={props => (
          <CubageRoom3d
            homeFolderPath={homeFolderPath}
            homeListMobilierPath={`${homeFolderPath}/liste-mobilier`}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${homeFolderPath}/liste-mobilier/maison3d/:roomType/:roomId?`}
        render={props => (
          <CubageElement3d
            homeMaison3dPath={`${homeFolderPath}/liste-mobilier/maison3d`}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${homeFolderPath}/liste-mobilier/liste`}
        render={props => (
          <CubageRoomList
            homeFolderPath={homeFolderPath}
            homeListMobilierPath={`${homeFolderPath}/liste-mobilier`}
            {...props}
          />
        )}
      />
      <Route status={404} render={props => <NotFound back {...props} />} />
    </Switch>
  </ListProvider>
);
export default Router;
