import React, { useState, useContext, useRef } from 'react';
import SVG from 'react-inlinesvg';
import { ModalContext } from 'components/Modal/Modal';
import Form, {
  Input,
  Select,
  Option,
  validator,
  Submit,
  FormModal,
} from 'components/Form/Form';
import Accordion, {
  AccItem,
  AccHeader,
  AccContent,
} from 'components/Accordion/Accordion';
import ClickOutside from 'components/ClickOutside/';
import roomList from 'services/roomList';
import { postUserCubage } from 'services/cygest';

import ListContext from './ListeMobilierContext';
import Header from '../../Header/Header';
import MonDossierContext from '../../../MonDossierContext';

import loader from '../img/loader.svg';
import remove from '../img/remove.svg';

import './CubageRoomList.scss';

export default ({ history, homeListMobilierPath, homeFolderPath }) => {
  const listContext = useContext(ListContext);
  const { error } = useContext(MonDossierContext);
  const { modal } = useContext(ModalContext);
  const [addRoomVisible, setAddRoomVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState({
    status: false,
    type: 'success',
    success: {
      title: 'Votre liste de mobilier a bien été enregistrée',
    },
    fail: {
      title: 'Impossible de sauvegarder votre liste de mobilier',
      message: 'Merci réessayer ultérieurement',
      element: (
        <button
          type="button"
          onClick={() => {
            setConfirm({ ...confirm, type: 'fail', status: false });
          }}
        >
          Fermer
        </button>
      ),
    },
  });

  const addElement = (roomId, roomTypeId) => {
    const uid = Math.round(Math.random() * 100000000);
    modal.toggle({
      style: {
        maxWidth: '640px',
        margin: 'auto',
        height: 'auto',
        maxHeight: '100%',
        overflow: 'auto',
      },
      title: 'Ajouter un élément',
      datas: (
        <div className="CubageRoomList__customFields">
          <Form
            onSubmit={formState => {
              if (formState.elementId === 'custom') {
                listContext.addNewElement(roomId, {
                  id: `${Math.round(Math.random() * 1000000000)}`,
                  length: 1,
                  custom: {
                    label: formState.label,
                    width: formState.width,
                    height: formState.height,
                    depth: formState.depth,
                    weight: formState.weight,
                  },
                });
              } else if (formState.elementId) {
                listContext.addNewElement(roomId, {
                  id: parseInt(formState.elementId, 10),
                  length: 1,
                });
              }
              modal.close();
            }}
          >
            {({ formState }) => {
              const { id } = roomList.getRoomById(roomTypeId);
              return (
                <>
                  <Select
                    field="elementId"
                    label="Élement"
                    initialValue=""
                    resetId={uid}
                  >
                    <Option value="" disabled>
                      Choisissez...
                    </Option>
                    <Option value="custom">Meuble personnalisé...</Option>
                    {listContext.getElementsByFilter(id).map((e, ii) => (
                      <Option value={e.id} key={ii}>
                        {e.label}
                      </Option>
                    ))}
                  </Select>
                  {formState.values.elementId === 'custom' && (
                    <>
                      <Input
                        field="label"
                        type="text"
                        label="Nom *"
                        placeholder="Chesterfield fauteuil"
                        validate={validator.required}
                      />
                      <div className="CubageElementList__customFields__length">
                        <Input
                          field="height"
                          type="number"
                          label="Hauteur en cm"
                          placeholder="exemple : 180"
                        />
                        <Input
                          field="width"
                          type="number"
                          label="Largeur en cm"
                          placeholder="exemple : 80"
                        />
                        <Input
                          field="depth"
                          type="number"
                          label="Profondeur en cm"
                          placeholder="exemple : 40"
                        />
                        <Input
                          field="weight"
                          type="number"
                          label="Poids en Kg"
                          placeholder="exemple : 100"
                        />
                      </div>
                      <span className="CubageRoomList__customFields__required">
                        * Champ obligatoire
                      </span>
                    </>
                  )}
                  <Submit>Valider</Submit>
                </>
              );
            }}
          </Form>
        </div>
      ),
    });
  };

  const submitForm = e => {
    e.preventDefault();
    setLoading(true);
    postUserCubage(listContext.getAllRooms())
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'dossierListeMobilier' });

        setConfirm({ ...confirm, type: 'success', status: true });
        setTimeout(() => {
          history.push(homeFolderPath);
        }, 2000);
        setLoading(false);
      })
      .catch(err => {
        error(err);
        setLoading(false);
      });
  };

  return (
    <div
      className={`CubageRoomList ${confirm.status !== false ? '-sent' : ''}`}
    >
      <Header
        title="Détail de vos biens"
        description="Duis aliquip consectetur elit veniam occaecat esse do ipsum voluptate anim eu reprehenderit."
      />
      <div className="CubageRoomList__container">
        <div className="CubageRoomList__addRoom">
          <ClickOutside onClick={() => setAddRoomVisible(false)}>
            <button
              type="button"
              className="CubageRoomList__addRoom__button"
              onClick={() => setAddRoomVisible(!addRoomVisible)}
            >
              + Ajouter une pièce
            </button>
            <div
              className={`CubageRoomList__addRoom__roomList ${
                addRoomVisible ? '-visible' : ''
              }`}
            >
              {roomList.map((room, i) => (
                <div
                  role="button"
                  tabIndex="0"
                  className="CubageRoomList__addRoom__item"
                  key={i}
                  onClick={() => {
                    listContext.addRoom({
                      label: room.label,
                      roomTypeId: room.id,
                      id: `${Date.now()}`,
                      elements: [],
                      initialOpened: true,
                    });
                    setAddRoomVisible(false);
                  }}
                >
                  {room.label}
                </div>
              ))}
            </div>
          </ClickOutside>
        </div>
        <div style={{ clear: 'both' }} />
        {listContext.loading ? (
          <div className="CubageRoomList__pending" />
        ) : (
          <Accordion className="CubageRoomList__accordion" allowMultipleOpen>
            {listContext.form.map((userRoom, i) => (
              <AccItem {...userRoom} key={i}>
                <AccHeader>
                  <RoomHeader userRoom={userRoom} />
                </AccHeader>
                <AccContent>
                  <RoomContent userRoom={userRoom} addElement={addElement} />
                </AccContent>
              </AccItem>
            ))}
          </Accordion>
        )}

        <div className="CubageRoomList__buttons">
          <button
            type="button"
            className="CubageRoomList__back"
            onClick={() => history.push(homeListMobilierPath)}
          >
            Retour
          </button>
          <button
            type="button"
            className="CubageRoomList__submit"
            onClick={submitForm}
            disabled={loading || listContext.loading}
          >
            Enregistrer
            <SVG
              src={loader}
              className={`CubageRoomList__loading ${loading ? '-loading' : ''}`}
            />
          </button>
        </div>

        <FormModal data={confirm} />
      </div>
    </div>
  );
};

const RoomHeader = ({ userRoom }) => {
  const refInput = useRef();
  const [edit, setEdit] = useState(false);
  const [newLabel, setNewLabel] = useState(false);
  const listContext = useContext(ListContext);

  return (
    <div className="CubageRoomList__accordion__question">
      <div className="CubageRoomList__accordion__status" />
      <div className="CubageRoomList__accordion__edit">
        <button
          type="button"
          className="CubageRoomList__accordion__button"
          title="Modifier le nom"
          onClick={() => {
            setEdit(true);
            setTimeout(() => refInput.current && refInput.current.focus());
          }}
        />
        {edit ? (
          <input
            ref={refInput}
            type="text"
            className="CubageRoomList__accordion__input"
            value={newLabel || userRoom.label || ''}
            onChange={e => {
              const { value } = e.target;
              setNewLabel(value);
            }}
            onBlur={e => {
              const { value } = e.target;
              setTimeout(() => setEdit(false), 200);
              listContext.changeRoomLabel(value, userRoom.id);
            }}
            onKeyDown={e => e.keyCode === 13 && e.target.blur()}
          />
        ) : (
          <div className="CubageRoomList__accordion__label">
            {userRoom.label}
          </div>
        )}
      </div>
      <div className="CubageRoomList__accordion__statusContent">
        <div className="ListePiece__length">
          {listContext.getTotalElementsLength(userRoom.id)}
        </div>
      </div>
    </div>
  );
};

const RoomContent = ({ userRoom, addElement }) => {
  const listContext = useContext(ListContext);
  return (
    <div className="CubageRoomList__accordion__reponse">
      {!!userRoom.elements.length && (
        <div className="CubageRoomList__elements">
          {userRoom.elements.map((elementRoom, ii) => {
            const element = elementRoom.custom
              ? elementRoom
              : listContext.getElementById(elementRoom.id);
            return (
              <div className="CubageRoomList__element" key={ii}>
                <div className="CubageRoomList__element__label">
                  {element.label || element.custom.label}
                  {element.custom && (
                    <div className="ListElement__item__custom">
                      {element.custom &&
                        Object.keys(element.custom)
                          .reduce((a, k) => {
                            if (k === 'width' && element.custom[k])
                              a.push(`L: ${element.custom[k]}cm`);
                            if (k === 'height' && element.custom[k])
                              a.push(`H: ${element.custom[k]}cm`);
                            if (k === 'depth' && element.custom[k])
                              a.push(`P: ${element.custom[k]}cm`);
                            if (k === 'weight' && element.custom[k])
                              a.push(`Poids: ${element.custom[k]}Kg`);
                            return a;
                          }, [])
                          .join(' - ')}
                    </div>
                  )}
                </div>
                <div className="CubageRoomList__element__right">
                  <div className="CubageRoomList__element__buttons">
                    <button
                      type="button"
                      onClick={e => {
                        e.preventDefault();
                        listContext.decreaseElement(userRoom.id, element.id);
                      }}
                      className={`CubageRoomList__element__button ${
                        elementRoom.remove ? '-remove' : ''
                      }`}
                    >
                      {elementRoom.remove ? <SVG src={remove} /> : '-'}
                    </button>
                    <span className="CubageRoomList__element__counter">
                      {elementRoom.length}
                    </span>
                    <button
                      type="button"
                      onClick={e => {
                        e.preventDefault();
                        listContext.increaseElement(userRoom.id, element.id);
                      }}
                      className="CubageRoomList__element__button"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="CubageRoomList__edit">
        <button
          type="button"
          className="CubageRoomList__addElement"
          onClick={() => addElement(userRoom.id, userRoom.roomTypeId)}
        >
          Ajouter un élément
        </button>
        <button
          type="button"
          className="CubageRoomList__removeRoom"
          onClick={() => listContext.removeRoom(userRoom.id)}
        >
          Supprimer la pièce
        </button>
      </div>
    </div>
  );
};
