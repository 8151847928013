import React, { Component } from 'react';

import Head from '../../components/Head/Head';
import Loading from '../../components/Loading/Loading';
import { getPageByUid } from '../../services/prismic';
import Slice from '../../components/Slice/Slice';
import { Link, RichText } from '../../components/Prismic/Prismic';

import './Landing.scss';

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: null,
    };
    const { uid } = this.props;

    getPageByUid('landing-page', uid).then(page => {
      this.setState({ page });
    });
  }

  render() {
    const { page } = this.state;

    if (page) {
      const { data } = page;

      return (
        <div className="Landing">
          <div className="Landing__header">
            <Head title={data.seo_title} description={data.seo_description} />
            <div
              className="Landing__background"
              style={{
                backgroundImage: `url('${data.back_img.url}')`,
              }}
            >
              <RichText className="Landing__title">{data.title}</RichText>
              <Link to={data.button} className="Landing__link">
                {data.button_name}
              </Link>
            </div>
          </div>

          <div className="Landing__container">
            <Slice slice={data.body} />
          </div>
        </div>
      );
    }

    return <Loading />;
  }
}

export default Landing;
