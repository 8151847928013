const Animate = (params = {}, callback) =>
  new Promise(resolve => {
    let date = new Date();
    const duration = params.duration || 1000;
    const easing = params.easing || 'easeInOut';
    const power = params.power || 2;
    const fromTo = params.fromTo || [0, 1];
    const loop = params.loop || false;
    const update = () => {
      const timePassed = new Date() - date;
      let progress = timePassed / duration;
      let ease = null;

      if (progress > 1) progress = 1;

      if (easing === 'linear') {
        ease = progress;
      } else if (easing === 'easeIn') {
        ease = progress ** power;
      } else if (easing === 'easeOut') {
        ease = 1 - (1 - progress) ** power;
      } else if (easing === 'easeInOut') {
        if (progress <= 0.5) ease = (2 * progress) ** power / 2;
        else ease = (2 - (2 * (1 - progress)) ** power) / 2;
      }

      callback(ease * (fromTo[1] - fromTo[0]) + fromTo[0], ease);

      if (progress === 1 && loop) date = new Date();
      if (progress !== 1 || loop) requestAnimationFrame(update);
      if (progress === 1) resolve();
    };

    requestAnimationFrame(update);
  });

export default Animate;
