import React, { Component } from 'react';
import DevisInternationalForm from '../../DevisInternationalForm/DevisInternationalForm';
import './DevisInternational.scss';

class DevisInternational extends Component {
  render() {
    const { datas = {} } = this.props;
    const { primary = {} } = datas;
    const { dde_ch_type: ddeChType = '' } = primary;
    return (
      <div className="DevisInternational">
        <div className="DevisInternational__title">
          <span>€</span>
          Demandez un devis international
        </div>
        <div className="DevisInternational__form">
          <DevisInternationalForm ddeChType={ddeChType} />
        </div>
      </div>
    );
  }
}

export default DevisInternational;
