import React from 'react';
import SVG from 'react-inlinesvg';
import loader from '../img/loader.svg';

const Submit = ({ children, className, color, ...other }) => (
  <button
    type="submit"
    className={`Form__submit -${color || 'green'} ${className || ''}`}
    {...other}
  >
    {children}
    <span className={`Form__loading ${other.disabled ? '-loading' : ''}`}>
      <SVG src={loader} />
    </span>
  </button>
);

export default Submit;
