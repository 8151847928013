import React, { Component } from 'react';
import { RichText, Link, Img } from '../../Prismic/Prismic';
import './International.scss';

class International extends Component {
  render() {
    return (
      <div className="International">
        <RichText className="International__title">
          {this.props.datas.primary.title}
        </RichText>
        <div className="International__container">
          <div className="International__row">
            {this.props.datas.items.map((item, i) => (
              <div key={i}>
                <div className="International__col">
                  <div>
                    <div className="International__col__col">
                      <RichText className="International__text">
                        {item.text}
                      </RichText>
                      <Link to={item.link} className="International__link">
                        {item.button_name}
                      </Link>
                    </div>
                  </div>
                  <div>
                    <div className="International__col__col -img">
                      <Img src={item.photo} className="International__photo" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default International;
