import React, { Component } from 'react';
import QuickEstimateForm from '../../QuickEstimateForm/QuickEstimateForm';
import './QuickEstimate.scss';

class QuickEstimate extends Component {
  render() {
    const { datas = {} } = this.props;
    const { primary = {} } = datas;
    const { dde_ch_type: ddeChType = '' } = primary;

    return (
      <div className="QuickEstimate">
        <div className="QuickEstimate__title">
          <span>€</span>
          Demandez un devis rapide
        </div>
        <div className="QuickEstimate__form">
          <QuickEstimateForm ddeChType={ddeChType} />
        </div>
      </div>
    );
  }
}

export default QuickEstimate;
