import React, { Component } from 'react';
import { Link } from 'components/Prismic/Prismic';
import { getPageByType } from '../../services/prismic';
import './Header.scss';

import Callback from './Shortcut/Callback';
import Nav from './Nav/Nav';
import Socials from './Socials/Socials';
import Shortcut from './Shortcut/Shortcut';
import MenuPrimary from './MenuPrimary/MenuPrimary';
import MenuSecondary from './MenuSecondary/MenuSecondary';
import HeaderModal from './HeaderModal/HeaderModal';

class Header extends Component {
  constructor() {
    super();

    this.state = {
      modal: {
        status: false,
        component: null,
      },
      menuStatus: false,
      datas: {
        logo: {
          url: null,
          alt: '',
          dimensions: { width: 0, height: 0 },
        },
      },

      closeAll: () => {
        this.setState(state => ({
          menuStatus: false,
          modal: {
            status: false,
            component: state.modal.component,
          },
        }));
      },

      toggleMenu: () =>
        this.setState(state => ({ menuStatus: !state.menuStatus })),
      closeMenu: () => this.setState({ menuStatus: false }),
      openMenu: () => this.setState({ menuStatus: true }),

      toggleModal: component => {
        this.setState(state => ({
          modal: {
            status:
              component !== state.modal.component ? true : !state.modal.status,
            component:
              component !== state.modal.component
                ? component
                : state.modal.component,
          },
        }));
      },
      openModal: component =>
        this.setState({ modal: { status: true, component } }),
      closeModal: () =>
        this.setState(state => ({
          modal: {
            status: !state.modal.status,
            component: state.modal.component,
          },
        })),
    };

    getPageByType('header-layout').then(datas => {
      this.setState({ datas: datas[0].data });
    });
  }

  render() {
    const { menuStatus, toggleModal, closeAll, datas } = this.state;
    const { history } = this.props;

    return (
      <header className={`Header ${menuStatus ? '-opened' : ''}`}>
        <div className="Header__content">
          <div className="Header__nav">
            <Nav {...this.state} />
          </div>

          <div className="Header__menu">
            <div
              className="Header__menu__back"
              onClick={() => closeAll()}
              role="button"
              tabIndex="0"
            />
            <div className="Header__groups">
              <div className="Header__group1">
                <div className="Header__group1__socials">
                  <Socials {...this.state} />
                </div>
                <div className="Header__group1__menuSecondary">
                  <MenuSecondary
                    menu={datas.secondary_menu}
                    closeAll={closeAll}
                  />
                  <div className="Header__group1__buttons">
                    <button
                      type="button"
                      className="Header__group1__callback"
                      onClick={() => toggleModal(Callback)}
                    >
                      Rappel immédiat
                    </button>
                    <Link
                      to="/votre-dossier"
                      className="Header__group1__folder"
                      onClick={closeAll}
                    >
                      Votre dossier
                    </Link>
                  </div>
                </div>
              </div>

              <div className="Header__group2">
                <MenuPrimary menu={datas.primary_menu} closeAll={closeAll} />
                <Shortcut {...this.state} history={history} />
              </div>
            </div>
          </div>
        </div>

        <HeaderModal {...this.state} />
      </header>
    );
  }
}

export default Header;
