import React, { Component } from 'react';
import { format } from 'date-fns';

import Head from '../../components/Head/Head';
import Loading from '../../components/Loading/Loading';
import { getPageByType } from '../../services/prismic';
import { RichText, RawText, Img, Link } from '../../components/Prismic/Prismic';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import './NewsHome.scss';

class NewsHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: null,
      news: [],
    };
    getPageByType('news-home').then(page => {
      this.setState({ page });
    });
    getPageByType('news-page').then(news => {
      this.setState({ news });
    });
  }

  render() {
    const { page, news } = this.state;

    news.sort((a, b) => format(b.data.date, 'X') - format(a.data.date, 'X'));

    if (page) {
      const { data } = page[0];

      return (
        <div className="NewsHome">
          <div className="NewsHome__header">
            <Head title={data.seo_title} description={data.seo_description} />
            <div
              className="NewsHome__background"
              style={{
                backgroundImage: `url('${data.back_img.url}')`,
              }}
            />
            <Breadcrumb
              className="NewsHome__breadcrumb"
              title={data.seo_title}
              white
            />
          </div>
          <div className="NewsHome__container">
            <RichText className="NewsHome__header__title">
              {data.title}
            </RichText>
            {news.map((thisNews, i) => {
              const { data: thisData } = thisNews;
              return (
                <div className="NewsHome__item" key={i}>
                  <div className="NewsHome__colLeft">
                    {thisData.photo.thumb ? (
                      <Img
                        src={thisData.photo.thumb}
                        className="NewsHome__img"
                      />
                    ) : null}
                  </div>
                  <div className="NewsHome__colRight">
                    {thisData.date ? (
                      <div className="NewsHome__date">
                        {format(thisData.date, 'DD . MM . YYYY')}
                      </div>
                    ) : null}

                    <h2 className="NewsHome__title">
                      <RawText>{thisData.title}</RawText>
                    </h2>
                    <p className="NewsHome__content">
                      <RawText excerpt={150}>{thisData.content}</RawText>
                    </p>
                    <Link
                      to={`/actualites/${thisNews.uid}`}
                      className="NewsHome__link"
                    >
                      En savoir plus
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return <Loading />;
  }
}

export default NewsHome;
