import React, { Component } from 'react';
import { RichText, Link, Img } from '../../Prismic/Prismic';
import './DoubleColumns.scss';

class DoubleColumns extends Component {
  render() {
    const { datas } = this.props;
    return (
      <div className="DoubleColumns">
        {datas.items.map((item, i) => (
          <div className="DoubleColumns__item" key={i}>
            <div className="DoubleColumns__background" />
            <div className="DoubleColumns__container">
              <div
                className={`DoubleColumns__row ${
                  item.reverse === 'true' ? '-reverse' : ''
                }`}
              >
                <div className="DoubleColumns__row__colLeft">
                  <div className="DoubleColumns__row__colLeft__content">
                    <RichText className="DoubleColumns__row__text">
                      {item.text}
                    </RichText>
                    <div className="DoubleColumns__row__linkWrap">
                      <Link to={item.link} className="DoubleColumns__row__link">
                        {item.link_name}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="DoubleColumns__row__colRight">
                  <div className="DoubleColumns__row__colRight__content">
                    <div className="DoubleColumns__row__colRight__img">
                      <Img src={item.photo} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default DoubleColumns;
