import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import { Img, RichText, Link } from '../../Prismic/Prismic';
import 'react-id-swiper/lib/styles/scss/swiper.scss';
import './LinkCarousel.scss';

const Item = ({ photo, text, title, link, label, contain }) => (
  <div className="LinkCarousel__item">
    <div className="LinkCarousel__itemCenter">
      <Img
        src={photo}
        className={`LinkCarousel__item__img ${contain === 'true' ? '-contain' : ''
          }`}
      />
      <div className="LinkCarousel__item__content">
        <RichText className="LinkCarousel__item__title">{title}</RichText>
        <RichText className="LinkCarousel__item__excerpt">{text}</RichText>
        <Link to={link} className="LinkCarousel__item__link">
          {label}
        </Link>
      </div>
    </div>
  </div>
);

const List = props => {
  const { items, primary } = props;
  const style = {};
  if (items.length < 4) {
    style.maxWidth = `${360 * items.length}px`;
  }

  const params = {
    slidesPerView: 1,
    spaceBetween: 10,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      577: {
        slidesPerView: items.length >= 2 ? 2 : items.length,
      },
      768: {
        slidesPerView: items.length >= 3 ? 3 : items.length,
      },
      1024: {
        slidesPerView: items.length >= 4 ? 4 : items.length,
      },
    },
  };

  if (primary.slider_mode === 'false') {
    return (
      <div className="LinkCarousel__owl__list">
        {items.map((item, i) => (
          <Item {...item} key={i} contain={primary.contain_img} />
        ))}
      </div>
    );
  }

  return (
    <div className="LinkCarousel__owl__wrap" style={style}>
      <Swiper {...params}>
        {items.map((item, i) => (
          <div key={i}>
            <Item {...item} contain={primary.contain_img} />
          </div>
        ))}
      </Swiper>
    </div>
  );
};

class LinkCarousel extends Component {
  render() {
    const { list } = this.props;
    return (
      <div
        className={`LinkCarousel ${list.primary.enable_background === 'true' ? '-enableBackground' : ''
          }`}
      >
        <RichText className="LinkCarousel__title">
          {list.primary.title}
        </RichText>
        <div className="LinkCarousel__owl">
          <List {...list} />
        </div>
      </div>
    );
  }
}

export default LinkCarousel;
