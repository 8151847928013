import React, { Component } from 'react';

import Head from '../../components/Head/Head';
import Loading from '../../components/Loading/Loading';
import { getPageByType } from '../../services/prismic';
import Slice from '../../components/Slice/Slice';
import { RichText } from '../../components/Prismic/Prismic';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import './FaqHome.scss';

class FaqHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: null,
    };
    getPageByType('faq-home', props.uid).then(page => {
      this.setState({ page });
    });
  }

  render() {
    const { page } = this.state;
    if (page) {
      const { data } = page[0];

      return (
        <div className="FaqHome">
          <div className="FaqHome__header">
            <Head title={data.seo_title} description={data.seo_description} />
            <div
              className="FaqHome__background"
              style={{
                backgroundImage: `url('${data.back_img.url}')`,
              }}
            />
            <Breadcrumb
              className="FaqHome__breadcrumb"
              title={data.seo_title}
              white
            />
            <RichText className="FaqHome__header__title">{data.title}</RichText>
          </div>

          <div className="FaqHome__container">
            <div className="FaqHome__slice">
              <Slice slice={data.body} />
            </div>
          </div>
        </div>
      );
    }

    return <Loading />;
  }
}

export default FaqHome;
