import React, { Component } from 'react';
import { asField } from 'informed';
import ReCAPTCHA from 'react-google-recaptcha';
import SVG from 'react-inlinesvg';
import config from '../../../config';
import loader from '../img/loader.svg';

class SubmitCaptcha extends Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
  }

  render() {
    const { fieldState, fieldApi, ...props } = this.props;
    const {
      children,
      className,
      color,
      forwardedRef,
      getError,
      submitForm,
      ...other
    } = props;

    return (
      <>
        <ReCAPTCHA
          ref={this.recaptchaRef}
          size="invisible"
          sitekey={config.captcha}
          onChange={() => {
            const v = this.recaptchaRef.current.getValue();
            if (v) {
              fieldApi.setValue(v);
              submitForm();
            }
          }}
        />
        <button
          type="submit"
          className={`Form__submit -${color || 'green'} ${className || ''}`}
          onClick={e => {
            const value = this.recaptchaRef.current.getValue();

            if (value) {
              fieldApi.setValue(value);
            } else {
              e.preventDefault();
              this.recaptchaRef.current.execute();
            }
          }}
          {...other}
        >
          {children}
          <span className={`Form__loading ${other.disabled ? '-loading' : ''}`}>
            <SVG src={loader} />
          </span>
        </button>
      </>
    );
  }
}

export default asField(SubmitCaptcha);
