import React, { Component } from 'react';
import { asField } from 'informed';

class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholder: false,
    };
  }

  componentDidUpdate(oldProps) {
    const { resetId, fieldApi } = this.props;
    if (oldProps.resetId !== resetId) fieldApi.setValue('');
  }

  render() {
    const { placeholder } = this.state;
    const { fieldState, fieldApi, ...props } = this.props;
    const { setValue, setTouched } = fieldApi;
    const { error, value } = fieldState;
    const {
      children,
      disabled,
      forwardedRef,
      label,
      type,
      field,
      onChange,
      resetId,
      ...rest
    } = props;
    const uniqid = `radio-${Math.round(Math.random() * 100000)}`;

    return (
      <div
        className={`Form__group -select ${error ? '-error' : ''} ${
          placeholder || value ? null : '-placeholder'
          } ${disabled ? '-disabled' : ''}`}
      >
        <label htmlFor={uniqid}>
          {label ? <span className="Form__label">{label}</span> : null}
          <div className="Form__group__arrow">
            <select
              name={field}
              disabled={disabled}
              value={!value && value !== 0 ? '' : value}
              onChange={e => {
                setValue(e.target.value);
                if (e.target.value) setTouched();
                if (e.target.value) this.setState({ placeholder: true });
                if (onChange) onChange(e);
              }}
              {...rest}
            >
              {children}
            </select>
          </div>
        </label>
        {error ? <small className="Form__messageError">{error}</small> : null}
      </div>
    );
  }
}

export default asField(Select);
