import React, { Component } from 'react';
import { getModuleById } from '../../../services/prismic';
import { RichText, Img } from '../../Prismic/Prismic';
import Scrolling, { ScrollingItem } from '../../Scrolling/Scrolling';
import './Partner.scss';

class Partner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        left_title: '',
        left_galerie: [],
        right_title: '',
        right_galerie: [],
      },
    };
    const { id } = this.props;

    getModuleById(id).then(datas => {
      this.setState({ data: datas.data });
    });
  }

  render() {
    const { data } = this.state;
    return (
      <div className="Partner">
        <div className="Partner__container">
          <div className="Partner__trust">
            <RichText className="Partner__title">{data.left_title}</RichText>
            {data.left_galerie.length ? (
              <Scrolling>
                {data.left_galerie.map((d, i) => (
                  <ScrollingItem key={i}>
                    <Img src={d.left_photo} />
                  </ScrollingItem>
                ))}
              </Scrolling>
            ) : null}
          </div>

          <div className="Partner__partner">
            <RichText className="Partner__title">{data.right_title}</RichText>
            {data.right_galerie.length ? (
              <Scrolling>
                {data.right_galerie.map((d, i) => (
                  <ScrollingItem key={i}>
                    <Img src={d.right_photo} />
                  </ScrollingItem>
                ))}
              </Scrolling>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Partner;
