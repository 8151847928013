import React, { Component } from 'react';
import SVG from 'react-inlinesvg';
import twitter from '../../../img/twitter.svg';
import linkedin from '../../../img/linkedin.svg';
import facebook from '../../../img/facebook.svg';
import youtube from '../../../img/youtube.svg';
import tiktok from '../../../img/tiktok.svg';
import instagram from '../../../img/instagram.svg';
import './Socials.scss';

class Socials extends Component {
  render() {
    const { closeAll } = this.props;
    return (
      <div className="Socials">
        <a
          href="https://twitter.com/Lesdembretons?lang=fr"
          target="_blank"
          rel="noopener noreferrer"
          title="twitter"
          className="Socials__link"
          onClick={closeAll}
        >
          <SVG src={twitter} className="Socials__icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/demenageurs-bretons/"
          target="_blank"
          rel="noopener noreferrer"
          title="linkedin"
          className="Socials__link"
          onClick={closeAll}
        >
          <SVG src={linkedin} className="Socials__icon" />
        </a>
        <a
          href="https://www.facebook.com/Les.demenageurs.bretons"
          target="_blank"
          rel="noopener noreferrer"
          title="facebook"
          className="Socials__link"
          onClick={closeAll}
        >
          <SVG src={facebook} className="Socials__icon" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCwFra8gbhpv1sVUlUfnOikw"
          target="_blank"
          rel="noopener noreferrer"
          title="youtube"
          className="Socials__link"
          onClick={closeAll}
        >
          <SVG src={youtube} className="Socials__icon" />
        </a>
        <a
          href="https://www.instagram.com/lesdemenageursbretons_officiel/?hl=fr"
          target="_blank"
          rel="noopener noreferrer"
          title="instagram"
          className="Socials__link"
          onClick={closeAll}
        >
          <SVG src={instagram} className="Socials__icon" />
        </a>
        <a
          href="https://www.tiktok.com/@lesdembretons?lang=fr"
          target="_blank"
          rel="noopener noreferrer"
          title="tik tok"
          className="Socials__link"
          onClick={closeAll}
        >
          <SVG src={tiktok} className="Socials__icon" />
        </a>
      </div>
    );
  }
}

export default Socials;
