import React, { useState, useContext } from 'react';
import { postLoginCheckForm } from 'services/cygest';
import { Link } from 'components/Prismic/Prismic';
import Form, { Input, Submit, validator } from 'components/Form/Form';
import MonDossierContext from '../../MonDossierContext';
import Header from '../Header/Header';
import './PasswordForm.scss';

const Login = ({ match }) => {
  const context = useContext(MonDossierContext);
  const { connect } = context;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState({
    status: false,
    type: 'fail',
    fail: {
      title: 'Impossible de se connecter.',
      element: (
        <button
          type="button"
          onClick={() =>
            setConfirm({ ...confirm, type: 'fail', status: false })
          }
        >
          Fermer
        </button>
      ),
    },
  });

  function submitted(formState) {
    setLoading(true);
    postLoginCheckForm(formState)
      .then(user => {
        connect(user);
      })
      .catch(() => {
        setLoading(false);
        setConfirm({ ...confirm, status: true });
      });
  }

  return (
    <div className="PasswordForm">
      <Header
        title="Connexion"
        description="Saisissez vos identifiant et mot de passe pour accéder à votre
      dossier."
      />
      <div className="PasswordForm__content">
        <Form
          className="PasswordForm__form"
          onSubmit={submitted}
          confirm={confirm}
        >
          <Input
            type="text"
            field="username"
            label="Identifiant*"
            placeholder="jean.dupont@email.com"
            // initialValue="test@test.test"
            validate={validator.email}
            validateOnBlur
          />
          <Input
            type="password"
            field="password"
            label="Mot de passe*"
            placeholder="Saisissez votre mot de passe"
            // initialValue="Azerty12345"
            validate={validator.required}
            validateOnBlur
          />
          <span className="PasswordForm__mandatory">* Champ obligatoire</span>
          <Submit disabled={loading}>Accéder à mon dossier client</Submit>
        </Form>
        <Link
          to={`${path}/mot-de-passe-oublie`}
          className="PasswordForm__forgotten"
        >
          Mot de passe oublié
        </Link>
        <div className="PasswordForm__new--center">
          <Link
            to={`${path}/creer-un-dossier-client`}
            className="PasswordForm__new"
          >
            1<sup>ère</sup> visite ? créez votre dossier client en cliquant ici.
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
