import React, { useState, useContext, useEffect } from 'react';
import Form, { CheckboxPrestation, Submit } from 'components/Form/Form';
import { getUserPrestations, postUserPrestations } from 'services/cygest';
import Header from '../Header/Header';
import MonDossierContext from '../../MonDossierContext';
import './Prestations.scss';

const Stars = ({ length }) =>
  // eslint-disable-next-line prefer-spread
  Array.apply(null, { length }).map((e, i) => (
    <span className="Stars" key={i} />
  ));

const Prestations = ({ history, homeFolderPath }) => {
  let formApi = null;
  const { error } = useContext(MonDossierContext);
  const [pending, setPending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState({
    status: false,
    type: 'success',
    success: {
      title: 'Vos prestations ont bien été enregistrées',
    },
    fail: {
      title: 'Impossible de sauvegarder vos prestations',
      message: 'Merci réessayer ultérieurement',
      element: (
        <button
          type="button"
          onClick={() => {
            setConfirm({ ...confirm, type: 'fail', status: false });
          }}
        >
          Fermer
        </button>
      ),
    },
  });

  useEffect(() => {
    getUserPrestations()
      .then(data => {
        setPending(false);
        formApi.setValues(data);
      })
      .catch(error);
  }, []);

  function setFormApi(e) {
    formApi = e;
  }

  function submitted(formSate) {
    setLoading(true);

    postUserPrestations(formSate)
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'dossierPrestations' });

        setLoading(false);
        setConfirm({ ...confirm, type: 'success', status: true });
        setTimeout(() => {
          history.push(homeFolderPath);
        }, 2000);
      })
      .catch(err => {
        error(err);
        setLoading(false);
      });
  }

  return (
    <div className="Prestations">
      <Header
        title="Prestations de * à *****"
        titlePage={
          <span>
            Prestations de <Stars length={1} /> à <Stars length={5} />
          </span>
        }
        description="Choisissez dans la liste ci-dessous les prestations souhaitées pour votre déménagement"
      />
      <div className="Prestations__content">
        <Form
          onSubmit={submitted}
          confirm={confirm}
          pending={pending}
          getApi={setFormApi}
        >
          <CheckboxPrestation
            field="presta1"
            label="Emballage, transport et déballage du mobilier"
            help="Vous cherchez une prestation complète ? cochez ici. Si vous préférez vous occupez d’une partie de votre mobilier, ne cochez pas."
          />
          <CheckboxPrestation
            field="presta2"
            label="Transport des cartons"
            help="Désirez-vous que nous transportions vos cartons ?"
          />
          <CheckboxPrestation
            field="presta3"
            label="Emballage, transport et déballage du fragile"
            help="Pour manipuler les objets fragiles nous avons une expertise et du matériel spécifique, cochez si vous désirez en profiter."
          />
          <CheckboxPrestation
            field="presta4"
            label="Emballage de tous les effets"
            help="Désirez-vous que nous emballions tous vos effets ou préférez-vous préparer vous-même quelques cartons ?"
          />
          <CheckboxPrestation
            field="presta5"
            label="Déballage et remise en place de tous les effets"
            help="A la livraison, vous souhaitez que nous déballions tous les cartons ?"
          />

          <div className="Prestations__moreService">Service en +</div>

          <CheckboxPrestation
            field="presta6"
            label="Livraison des cartons à domicile"
            help="Vous préférez venir chercher vos cartons de déménagement dans notre boutique ou que nous vous les livrions à domicile ?"
          />
          <CheckboxPrestation
            field="presta7"
            label="Reprise des emballages en fin de déménagement"
            help="Suite à la livraison, voulez-vous que nous débarrassions vos cartons de déménagement vides ?"
          />
          <CheckboxPrestation
            field="presta8"
            label="Remise d’un aide-mémoire"
            help="Êtes-vous intéressé par notre « pense-bête » qui liste (presque) tout ce qu’il faut ne pas oublier pour faire un déménagement serein ?"
          />
          <CheckboxPrestation
            field="presta9"
            label="Aide à la recherche de logement à l’arrivée"
            help="Désirez-vous que nous vous aidions à trouver un logement près de votre destination ? Nous avons un partenaire dédié pour vous aider dans ces démarches."
          />
          <CheckboxPrestation
            field="presta10"
            label="Facilité de paiement"
            help="Êtes-vous intéressé par un étalement (gratuit !) du paiement de votre déménagement ?"
          />
          <div className="Prestations__buttons">
            <button
              type="button"
              className="Prestations__back"
              onClick={() => history.goBack()}
              disabled={loading || pending}
            >
              Retour
            </button>
            <Submit className="Prestations__submit" disabled={loading}>
              Enregistrer
            </Submit>
          </div>
        </Form>
      </div>
    </div>
  );
  // }
};

export default Prestations;
