import React, { Component } from 'react';
import { RichText } from '../../Prismic/Prismic';
import Form, {
  Input,
  Checkbox,
  RadioGroup,
  Radio,
  SubmitCaptcha,
  validator,
} from '../../Form/Form';
import { postFurnitureForm } from '../../../services/cygest';
import './FormGardeMeubles.scss';

class FormGardeMeubles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      confirm: {
        status: false,
        type: 'success',
        success: {
          title: 'Demande de devis envoyée !',
          message:
            'Votre demande de devis a été transmise à l’agence Les déménageurs bretons qui vous recontactera rapidement.',
        },
        fail: {
          title: "Votre demande de devis n'a pas pu être envoyée.",
          message: 'Merci de réessayer ultérieurement',
          element: (
            <button
              type="button"
              onClick={() => {
                const { confirm } = this.state;
                this.setState({
                  confirm: { ...confirm, status: false },
                });
              }}
            >
              Fermer
            </button>
          ),
        },
      },
    };
  }

  setFormApi(formApi) {
    this.formApi = formApi;
  }

  updateForm(formState) {
    const { confirm } = this.state;

    this.setState({ loading: true });

    postFurnitureForm(formState)
      .then(() => {
        this.setState({
          loading: false,
          confirm: {
            ...confirm,
            status: true,
            type: 'success',
          },
        });
        this.formApi.reset();
        setTimeout(() => {
          const { confirm: conf } = this.state;
          this.setState({ confirm: { ...conf, status: false } });
        }, 5000);
      })
      .catch(err => {
        this.setState({
          loading: false,
          confirm: {
            ...confirm,
            status: true,
            type: 'fail',
          },
        });
        console.error(err);
      });
  }

  render() {
    const { datas } = this.props;
    const { loading, confirm } = this.state;

    return (
      <div className="FormGardeMeubles">
        <div className="FormGardeMeubles__container">
          {datas.primary.title ? (
            <RichText className="FormGardeMeubles__title">
              {datas.primary.title}
            </RichText>
          ) : null}
          <Form
            onSubmit={e => this.updateForm(e)}
            getApi={e => this.setFormApi(e)}
            confirm={confirm}
          >
            <Input type="hidden" field="DDE_CH_TYPE" initialValue="gm" />
            <Input type="hidden" field="DDE_CH_TYPE2" initialValue="fr" />
            <RadioGroup
              field="DDE_CH_CIV"
              label="Civilité*"
              validate={validator.required}
              validateOnChange
            >
              <Radio value="mr">M.</Radio>
              <Radio value="mme">Mme</Radio>
            </RadioGroup>

            <div className="FormGardeMeubles__row">
              <div>
                <Input
                  type="text"
                  field="DDE_CH_NOM"
                  label="Nom*"
                  placeholder="Nom"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
              <div>
                <Input
                  type="text"
                  field="DDE_CH_PRENOM"
                  label="Prénom*"
                  placeholder="Prénom"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
            </div>

            <div className="FormGardeMeubles__row">
              <div>
                <Input
                  type="text"
                  field="DDE_CH_CH_CP"
                  label="Code postal*"
                  placeholder="Code postal"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
              <div>
                <Input
                  type="text"
                  field="DDE_CH_CH_VILLE"
                  label="Ville*"
                  placeholder="Ville"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
            </div>

            <div className="FormGardeMeubles__row">
              <div>
                <Input
                  type="tel"
                  field="DDE_CH_TEL"
                  label="Téléphone*"
                  placeholder="Téléphone"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
              <div>
                <Input
                  type="mail"
                  field="DDE_CH_EMAIL"
                  label="Email*"
                  placeholder="Email"
                  validate={validator.email}
                  validateOnBlur
                />
              </div>
            </div>

            <Checkbox
              field="acceptation"
              validate={validator.required}
              validateOnChange
            >
              J’accepte d’être recontacté par un membre du réseau Les
              déménageurs bretons afin de traiter ma demande
            </Checkbox>

            <div className="FormGardeMeubles__submit">
              <SubmitCaptcha
                field="captcha"
                color="green"
                disabled={loading}
                submitForm={() => this.formApi.submitForm()}
              >
                Valider
              </SubmitCaptcha>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default FormGardeMeubles;
