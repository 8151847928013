import React, { Component } from 'react';
import { format, addMonths } from 'date-fns';
import { RichText } from '../../Prismic/Prismic';
import Form, {
  Input,
  TextArea,
  Checkbox,
  RadioGroup,
  Radio,
  Select,
  Option,
  SubmitCaptcha,
  validator,
} from '../../Form/Form';
import { postRelocationForm } from '../../../services/cygest';
import './FormRelocation.scss';

class FormRelocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      confirm: {
        status: false,
        type: 'success',
        success: {
          title: 'Demande envoyée !',
          message:
            'Votre demande a été transmise à l’agence Les déménageurs bretons qui vous recontactera rapidement.',
        },
        fail: {
          title: "Votre demande n'a pas pu être envoyée.",
          message: 'Merci de réessayer ultérieurement',
          element: (
            <button
              type="button"
              onClick={() => {
                const { confirm } = this.state;
                this.setState({
                  confirm: { ...confirm, status: false },
                });
              }}
            >
              Fermer
            </button>
          ),
        },
      },
    };
  }

  setFormApi(formApi) {
    this.formApi = formApi;
  }

  updateForm(formState) {
    const { confirm } = this.state;

    this.setState({ loading: true });

    const newFormState = formState;
    [newFormState.DDE_CH_PERIODE_MOIS] = formState.DDE_CH_PERIODE.split(' ');
    [, newFormState.DDE_CH_PERIODE_ANNEE] = formState.DDE_CH_PERIODE.split(' ');

    delete newFormState.DDE_CH_PERIODE;

    postRelocationForm(newFormState)
      .then(() => {
        this.setState({
          loading: false,
          confirm: {
            ...confirm,
            status: true,
            type: 'success',
          },
        });
        this.formApi.reset();
        setTimeout(() => {
          const { confirm: conf } = this.state;
          this.setState({ confirm: { ...conf, status: false } });
        }, 5000);
      })
      .catch(err => {
        this.setState({
          loading: false,
          confirm: {
            ...confirm,
            status: true,
            type: 'fail',
          },
        });
        console.error(err);
      });
  }

  render() {
    const { datas } = this.props;
    const { loading, confirm } = this.state;
    const MonthList = [];
    const months = [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'décembre',
    ];

    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
    for (let i = 0; i < 13; i++) {
      const newDate = addMonths(new Date(), i);
      MonthList.push(
        `${months[parseInt(format(newDate, 'M'), 10) - 1]} ${format(
          newDate,
          'YYYY'
        )}`
      );
    }

    return (
      <div className="FormRelocation">
        <div className="FormRelocation__container">
          {datas.primary.title ? (
            <RichText className="FormRelocation__title">
              {datas.primary.title}
            </RichText>
          ) : null}
          <Form
            onSubmit={e => this.updateForm(e)}
            getApi={e => this.setFormApi(e)}
            confirm={confirm}
          >
            <Input type="hidden" field="DDE_CH_TYPE2" initialValue="fr" />
            <RadioGroup
              field="DDE_CH_CIV"
              label="Civilité*"
              validate={validator.required}
              validateOnChange
            >
              <Radio value="mr">M.</Radio>
              <Radio value="mme">Mme</Radio>
            </RadioGroup>
            <div className="FormRelocation__row">
              <div>
                <Input
                  type="text"
                  field="DDE_CH_NOM"
                  label="Nom*"
                  placeholder="Nom"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
              <div>
                <Input
                  type="text"
                  field="DDE_CH_PRENOM"
                  label="Prénom*"
                  placeholder="Prénom"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
            </div>

            <div className="FormRelocation__row">
              <div>
                <Input
                  type="tel"
                  field="DDE_CH_TEL"
                  label="Téléphone*"
                  placeholder="Téléphone"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
              <div>
                <Input
                  type="mail"
                  field="DDE_CH_EMAIL"
                  label="Email*"
                  placeholder="Email"
                  validate={validator.email}
                  validateOnBlur
                />
              </div>
            </div>

            <div className="FormRelocation__row">
              <div>
                <Select
                  field="DDE_CH_PERIODE"
                  label="Période*"
                  validate={validator.required}
                >
                  <Option value="" disabled>
                    Choisissez...
                  </Option>
                  {MonthList.map((date, i) => (
                    <Option value={date} key={i}>
                      {date}
                    </Option>
                  ))}
                </Select>
              </div>
              <div>
                <Select
                  field="DDE_CH_TYPE"
                  label="Vous êtes ?*"
                  validate={validator.required}
                >
                  <Option value="" disabled>
                    Choisissez...
                  </Option>
                  <Option value="Particulier">Particulier</Option>
                  <Option value="Professionnel">Professionnel</Option>
                </Select>
              </div>
            </div>

            <div className="FormRelocation__row">
              <div>
                <RadioGroup
                  field="DDE_CH_MUTATION"
                  label="S’agit il d’une mutation ?*"
                  validate={validator.required}
                  validateOnChange
                >
                  <Radio value="oui">Oui</Radio>
                  <Radio value="non">Non</Radio>
                </RadioGroup>
              </div>
              <div>
                <Input
                  type="text"
                  field="DDE_CH_MUTATION_ENTREPRISE"
                  label="Nom de votre entreprise*"
                  placeholder="Nom de votre entreprise"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
            </div>

            <TextArea
              field="DDE_CH_COMMENTAIRES"
              label="Commentaires"
              placeholder="Vos commentaires..."
            />

            <Checkbox
              field="acceptation"
              validate={validator.required}
              validateOnChange
            >
              J’accepte d’être recontacté par un membre du réseau Les
              déménageurs bretons afin de traiter ma demande
            </Checkbox>

            <div className="FormRelocation__submit">
              <SubmitCaptcha
                field="captcha"
                color="green"
                disabled={loading}
                submitForm={() => this.formApi.submitForm()}
              >
                Valider
              </SubmitCaptcha>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default FormRelocation;
