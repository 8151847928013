/* eslint-disable prettier/prettier */
const rooms = [
  {
    id: 1,
    name: 'cuisine',
    label: 'Cuisine',
    elements: [
      { group: 'Chaise', position: { top: '80%', left: '48%' } },
      { id: 38, position: { top: '55%', left: '28%' } },
      { group: 'Four', position: { top: '62%', left: '11%' } }, // -> group "Four" n'existe pas...
      { group: 'Frigo', position: { top: '41%', left: '53%' } },
      { id: 61, position: { top: '73%', left: '21%' } },
      { group: 'Meuble', position: { top: '25%', left: '39%' } },
      { group: 'Table', position: { top: '65%', left: '55%' } },
      { id: 139, position: { top: '34%', left: '26%' } },
      { group: 'Vin', position: { top: '58%', left: '19%' } },
    ]
  },
  {
    id: 2,
    name: 'salle-de-bain',
    label: 'Salle de bain',
    elements: [
      { id: 59, position: { top: '68%', left: '29%' } },
      { id: 60, position: { top: '48%', left: '29%' } },
      { group: 'Meuble', position: { top: '87%', left: '50%' } },
      { id: 84, position: { top: '30%', left: '40%' } },
      { id: 97, position: { top: '59%', left: '49%' } },
    ]
  },
  {
    id: 3,
    name: 'salon',
    label: 'Salon',
    elements: [
      { group: 'Bibliothèque', position: { top: '60%', left: '6%' } },
      { group: 'Meuble', position: { top: '23%', left: '46%' } },
      { group: 'Canapé', position: { top: '50%', left: '58%' } },
      { id: 47, position: { top: '85%', left: '65%' } },
      { id: 56, position: { top: '35%', left: '24%' } },
      { id: 58, position: { top: '35%', left: '37%' } },
      { group: 'Buffet', position: { top: '52%', left: '27%' } },
      { group: 'Meuble TV', position: { top: '89%', left: '20%' } },
      { group: 'Table basse', position: { top: '69%', left: '41%' } }, // -> group "Table basse" n'existe pas...
      { id: 118, position: { top: '24%', left: '68%' } },
      { id: 121, position: { top: '71%', left: '32%' } },
      { group: 'TV', position: { top: '73%', left: '21%' } }, // -> bug : aucun elements
      { id: 138, position: { top: '60%', left: '90%' } },
    ]
  },
  {
    id: 4,
    name: 'salle-a-manger',
    label: 'Salle à manger',
    elements: [
      { group: 'Buffet', position: { top: '68%', left: '73%' } },
      { group: 'Chaise', position: { top: '52%', left: '34%' } },
      { id: 58, position: { top: '27%', left: '40%' } },
      { group: 'Arbre à chat', position: { top: '60%', left: '92%' } }, // -> bug : aucun elements
      { group: 'Table', position: { top: '67%', left: '39%' } },
      { id: 118, position: { top: '18%', left: '18%' } },
      { id: 121, position: { top: '81%', left: '25%' } },
    ]
  },
  {
    id: 5,
    name: 'chambre',
    label: 'Chambre',
    elements: [
      { group: "Armoire", position: { top: '40%', left: '66%' } },
      { id: 33, position: { top: '49%', left: '32%' } },
      { group: 'Lit', position: { top: '74%', left: '60%' } },
      { id: 116, position: { top: '74%', left: '85%' } },
      { id: 141, position: { top: '37%', left: '49%' } },
      { id: 142, position: { top: '57%', left: '11%' } },
      { group: 'TV', position: { top: '33%', left: '30%' } }, // -> bug : aucun elements
    ]
  },
  {
    id: 9,
    name: 'bureau',
    label: 'Bureau',
    elements: [
      { group: 'Bibliothèque', position: { top: '30%', left: '43%' } },
      { group: 'Canapé', position: { top: '47%', left: '20%' } },
      { id: 86, position: { top: '58%', left: '64%' } },
      { group: 'Table basse', position: { top: '71%', left: '39%' } }, // -> group "Table basse" n'existe pas...
    ]
  },
  {
    id: 10,
    name: 'entree',
    label: 'Entrée',
    elements: [
      { group: 'Bibliothèque', position: { top: '64%', left: '79%' } },
      { id: 36, position: { top: '81%', left: '53%' } },
      { group: 'Meuble', position: { top: '55%', left: '38.7%' } },
      { id: 84, position: { top: '64%', left: '53%' } },
      { id: 95, position: { top: '44%', left: '15%' } },
      { id: 118, position: { top: '21%', left: '65%' } },
      { id: 121, position: { top: '72%', left: '31%' } },
    ]
  },
  {
    id: 11,
    name: 'garage',
    label: 'Garage',
    elements: [
      { id: 22, position: { top: '33%', left: '17%' } },
      { id: 32, position: { top: '40%', left: '95%' } },
      { id: 41, position: { top: '31%', left: '83%' } },
      { id: 44, position: { top: '40%', left: '70%' } },
      { id: 45, position: { top: '29%', left: '77%' } },
      { id: 72, position: { top: '47%', left: '88%' } },
      { group: 'Tondeuse', position: { top: '58%', left: '24%' } },
      { id: 148, position: { top: '63%', left: '52%' } },
      { group: 'Moto', position: { top: '60%', left: '35%' } }, // -> aucun group moto
      { id: 151, position: { top: '45%', left: '11%' } },
      { id: 94, position: { top: '40%', left: '35.5%' } },
      { id: 133, position: { top: '65%', left: '80%' } },
    ]
  },
  {
    id: 12,
    name: 'exterieur',
    label: 'Extérieur',
    elements: [
      { id: 8, position: { top: '77%', left: '82%' } },
      { id: 10, position: { top: '25%', left: '48%' } },
      { id: 15, position: { top: '44%', left: '14%' } },
      { id: 25, position: { top: '40%', left: '41%' } },
      { id: 39, position: { top: '14%', left: '54%' } },
      { id: 41, position: { top: '36%', left: '6%' } },
      { group: 'Table', position: { top: '33%', left: '63%' } }, // -> bug : aucun elements
      { id: 154, position: { top: '31%', left: '96%' } },
      { id: 155, position: { top: '70%', left: '25%' } },
    ]
  },
  {
    id: 13,
    name: 'cave',
    label: 'Cave',
    elements: [
      { id: 45, position: { top: '40%', left: '69%' } },
      { id: 94, position: { top: '74%', left: '9%' } },
      { group: 'Vin', position: { top: '42%', left: '34%' } },
      { id: 133, position: { top: '70%', left: '80%' } },
    ]
  },
  {
    id: 14,
    name: 'grenier',
    label: 'Grenier',
    elements: [
      { group: 'Canapé', position: { top: '47%', left: '39%' } }, // -> bug : aucun elements
      { id: 45, position: { top: '28%', left: '25%' } },
      { id: 47, position: { top: '70%', left: '66%' } },
      { group: 'Meuble', position: { top: '30%', left: '70%' } }, // -> bug : aucun elements
    ]
  },
  {
    id: 15,
    name: 'dependance',
    label: 'Dépendance',
    elements: [
      { id: 10, position: { top: '74%', left: '40%' } },
      { id: 15, position: { top: '45%', left: '43%' } },
      { id: 25, position: { top: '40%', left: '30%' } },
      { group: 'Table', position: { top: '55%', left: '20%' } },// -> bug : aucun elements
      { id: 153, position: { top: '40%', left: '54%' } },
      { id: 152, position: { top: '50%', left: '70%' } },
    ]
  },
  {
    id: 16,
    name: 'salle-de-jeu',
    label: 'Salle de jeu',
    elements: [
      { id: 143, position: { top: '70%', left: '43%' } },
      { id: 144, position: { top: '56%', left: '18%' } },
      { id: 145, position: { top: '53%', left: '71%' } },
      { id: 146, position: { top: '20%', left: '60%' } },
      { id: 147, position: { top: '30%', left: '30%' } },
    ],
  },
  {
    id: 17,
    name: 'buanderie',
    label: 'Buanderie',
    elements: [
      { id: 45, position: { top: '40%', left: '52%' } },
      { id: 59, position: { top: '67%', left: '26%' } },
      { id: 60, position: { top: '62%', left: '33%' } },
      { group: 'Meuble', position: { top: '69%', left: '67.5%' } },
      { id: 97, position: { top: '65%', left: '40%' } },
    ]
  },
];

Object.defineProperty(rooms, 'getRoomByName', {
  value(roomName) {
    const room = this.filter(e => e.name === roomName);
    return room[0] || {
      id: null,
      label: 'Pièce inconnue...',
      elements: [],
    };
  },
});

Object.defineProperty(rooms, 'getRoomById', {
  value(roomId) {
    const room = this.filter(e => e.id === roomId);
    return room[0] || {
      id: null,
      label: 'Pièce inconnue...',
      elements: [],
    };
  },
});

export default rooms;
