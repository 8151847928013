import React, { Component } from 'react';
import { RichText, Img } from '../../Prismic/Prismic';
import './MuliColumns.scss';

const More = props => {
  if (!props.more.length) return null;
  return (
    <div>
      <div className="MuliColumns__row__more">
        <RichText>{props.more}</RichText>
        <div className="MuliColumns__row__notabene">{props.nota_bene}</div>
      </div>
    </div>
  );
};

class MuliColumns extends Component {
  render() {
    const { items } = this.props.datas;
    return (
      <div className="MuliColumns">
        <div className="MuliColumns__row">
          {items.map((item, i) => (
            <div
              className={`MuliColumns__col ${
                item.reverse === 'true' ? '-reverse' : ''
              }`}
              key={i}
            >
              <div className="MuliColumns__col__content">
                <RichText className="MuliColumns__row__text">
                  {item.text}
                </RichText>
                <More {...item} />
                <Img src={item.photo} className="MuliColumns__row__img" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default MuliColumns;
