import React, { Component } from 'react';
import SVG from 'react-inlinesvg';
import { getPageByType } from '../../services/prismic';
import Loading from '../../components/Loading/Loading';
import { RichText, Link } from '../../components/Prismic/Prismic';
import './Footer.scss';
import contact from '../../img/contact.svg';
import Socials from '../Header/Socials/Socials';

class Footer extends Component {
  constructor() {
    super();

    this.state = {
      datas: null,
    };

    getPageByType('footer-layout').then(datas => {
      this.setState({ datas: datas[0].data });
    });
  }

  render() {
    const { datas } = this.state;
    const { storeLocator } = this.props;

    if (datas) {
      return (
        <div
          className={
            storeLocator &&
            storeLocator.location &&
            storeLocator.location.pathname === '/nos-agences-pres-de-chez-vous'
              ? 'Footer__Store'
              : 'Footer'
          }
        >
          <RichText className="Footer__baseline">{datas.title}</RichText>
          <div className="Footer__row">
            <div className="Footer__contact">
              <div className="Footer__contact__text">Contactez-nous</div>
              <a href="tel:0970330000" title="tel : 09 70 33 00 00">
                <SVG src={contact} className="Footer__contact__img" />
              </a>
              <div className="Footer__contact__text">Suivez-nous</div>
              <div className="Footer__contact__socials">
                <Socials />
              </div>
            </div>

            <nav className="Footer__nav">
              <div className="Footer__nav__primary">
                <RichText className="Footer__nav__primary__title">
                  {datas.menu1_title}
                </RichText>
                <ul className="Footer__nav__primary__ul">
                  {datas.submenu1.map((list, i) => (
                    <li className="Footer__nav__primary__li" key={i}>
                      <Link
                        to={list.submenu_link}
                        className="Footer__nav__primary__link"
                      >
                        {list.submenu_title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="Footer__nav__primary">
                <RichText className="Footer__nav__primary__title">
                  {datas.menu2_title}
                </RichText>
                <ul className="Footer__nav__primary__ul">
                  {datas.submenu2.map((list, i) => (
                    <li className="Footer__nav__primary__li" key={i}>
                      <Link
                        to={list.submenu_link}
                        className="Footer__nav__primary__link"
                      >
                        {list.submenu_title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="Footer__nav__primary">
                <RichText className="Footer__nav__primary__title">
                  {datas.menu3_title}
                </RichText>
                <ul className="Footer__nav__primary__ul">
                  {datas.submenu3.map((list, i) => (
                    <li className="Footer__nav__primary__li" key={i}>
                      <Link
                        to={list.submenu_link}
                        className="Footer__nav__primary__link"
                      >
                        {list.submenu_title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="Footer__nav__secondary">
                <ul className="Footer__nav__secondary__ul">
                  {datas.menu_secondary_list.map((list, i) => (
                    <li className="Footer__nav__secondary__li" key={i}>
                      <Link
                        to={list.link}
                        className="Footer__nav__secondary__link"
                      >
                        {list.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </div>

          <div className="Footer__nav__legals">
            <ul className="Footer__nav__legals__ul">
              {datas.menu_tertiary_list.map((list, i) => (
                <li className="Footer__nav__legals__li" key={i}>
                  <Link
                    to={list.link}
                    className="Footer__nav__legals__link"
                    noRedirect={list.no_redirect === 'true'}
                  >
                    {list.title}
                  </Link>
                </li>
              ))}

              <li className="Footer__nav__legals__li">
                <Link
                  to="/reglement-jeu-concours---enquete-complementaire"
                  className="Footer__nav__legals__link"
                >
                  reglement jeu-concours - enquete-complementaire
                </Link>
              </li>
            </ul>
          </div>
        </div>
      );
    }

    return <Loading />;
  }
}

export default Footer;
