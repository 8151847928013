import React from 'react';
import Partner from './Partner/Partner';
import Opinion from './Opinion/Opinion';

class Modules extends React.Component {
  render() {
    const { module } = this.props;

    // if (module.type === 'opinion_module') {
    //   return <Opinion id={module.id} />;
    // }

    if (module.type === 'partner_module') {
      return <Partner id={module.id} />;
    }

    return null;
  }
}

export default Modules;
