import React, { Component } from 'react';
import { RichText, Img } from '../../Prismic/Prismic';
import './ListServices.scss';

class ListServices extends Component {
  render() {
    return (
      <div className="ListServices">
        <div className="ListServices__container">
          <RichText className="ListServices__title">
            {this.props.list.primary.title}
          </RichText>
          <div className="ListServices__list">
            {this.props.list.items.map((item, i) => (
              <div key={i} className="ListServices__list__item">
                <Img src={item.picture} />
                <RichText className="ListServices__list__item__label">
                  {item.label}
                </RichText>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ListServices;
