import React, { Component } from 'react';
import { getModuleByIds } from '../../../services/prismic';
import { Link, RichText, RawText, Text } from '../../Prismic/Prismic';
import Accordion, {
  AccItem,
  AccHeader,
  AccContent,
} from '../../Accordion/Accordion';
import './FaqSlice.scss';

class FaqSlice extends Component {
  constructor(props) {
    super(props);

    this.state = { pages: null };

    const ids = props.datas.items.map(item => item.faq_pages.id);

    getModuleByIds(ids).then(pages => {
      this.setState({ pages });
    });
  }

  render() {
    const { pages } = this.state;
    const { datas } = this.props;
    if (pages) {
      return (
        <div className="FaqSlice">
          <div className="FaqSlice__container">
            <RichText className="FaqSlice__title">
              {datas.primary.title}
            </RichText>
            <Accordion>
              {pages.map((page, i) => (
                <AccItem id={i} key={i}>
                  <AccHeader>
                    <div className="Accordion__question">
                      <div className="Accordion__status"></div>
                      <div className="Accordion__label">{Text.asText(page.data.question)}</div>
                    </div>
                  </AccHeader>
                  <AccContent>
                    <div className="Accordion__reponse">
                      <RawText excerpt="150">{page.data.answer}</RawText>
                      <Link to={{ isBroken: false, link_type: "Document", ...page }}>En savoir plus</Link>
                    </div>
                  </AccContent>
                </AccItem>
              ))}
            </Accordion>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default FaqSlice;