import React, { Component } from 'react';

import Loading from '../../components/Loading/Loading';
import { getPageByTypes } from '../../services/prismic';
import { Link, RawText } from '../../components/Prismic/Prismic';

class MasterSitemap extends Component {
  constructor() {
    super();

    this.state = {
      datas: null,
    };

    getPageByTypes([
      'particulier-home',
      'particulier-content',
      'professionnel-home',
      'professionnel-content',
      'know-how-page',
      'template-page',
    ]).then(datas => {
      this.setState({ datas });
    });
  }

  render() {
    const { datas } = this.state;

    if (datas) {
      return (
        <ul className="Sitemap__ul">
          <li className="Sitemap__li">
            <Link to="/" className="Sitemap__LinkTitle">
              Page d&apos;accueil
            </Link>
          </li>

          <li className="Sitemap__li">
            <Link to="/particulier/" className="Sitemap__LinkTitle">
              Particulier
            </Link>
            <ul className="Sitemap__subUl">
              {datas.map((e, i) =>
                e.type === 'particulier-content' ? (
                  <li key={i} className="Sitemap__subLi">
                    <Link
                      to={`/particulier/${e.uid}`}
                      className="Sitemap__link"
                    >
                      <RawText>{e.data.title}</RawText>
                    </Link>
                  </li>
                ) : null
              )}
            </ul>
          </li>

          <li className="Sitemap__li">
            <Link to="/professionnel/" className="Sitemap__LinkTitle">
              Professionnel
            </Link>
            <ul className="Sitemap__subUl">
              {datas.map((e, i) =>
                e.type === 'professionnel-content' ? (
                  <li key={i} className="Sitemap__subLi">
                    <Link
                      to={`/professionnel/${e.uid}`}
                      className="Sitemap__link"
                    >
                      <RawText>{e.data.title}</RawText>
                    </Link>
                  </li>
                ) : null
              )}
            </ul>
          </li>

          <li className="Sitemap__li">
            <Link to="/notre-savoir-faire/" className="Sitemap__LinkTitle">
              Notre savoir-faire
            </Link>
            <ul className="Sitemap__subUl">
              {datas.map((e, i) =>
                e.type === 'know-how-page' ? (
                  <li key={i} className="Sitemap__subLi">
                    <Link
                      to={`/notre-savoir-faire/${e.uid}`}
                      className="Sitemap__link"
                    >
                      <RawText>{e.data.title}</RawText>
                    </Link>
                  </li>
                ) : null
              )}
            </ul>
          </li>

          <li className="Sitemap__li">
            <ul className="Sitemap__subUl">
              {datas.map((e, i) =>
                e.type === 'template-page' ? (
                  <li key={i} className="Sitemap__subLi">
                    <Link to={`/${e.uid}`} className="Sitemap__LinkTitle">
                      <RawText>{e.data.title}</RawText>
                    </Link>
                  </li>
                ) : null
              )}

              <li className="Sitemap__subLi">
                <Link to="/foire-aux-questions" className="Sitemap__LinkTitle">
                  Notre F.A.Q
                </Link>
              </li>

              <li className="Sitemap__subLi">
                <Link to="/devis" className="Sitemap__LinkTitle">
                  Devis
                </Link>
              </li>

              <li className="Sitemap__subLi">
                <Link to="/actualites" className="Sitemap__LinkTitle">
                  Nos actualités
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      );
    }

    return <Loading />;
  }
}

export default MasterSitemap;
