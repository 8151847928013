import React, { useState } from 'react';
import Form, { Input, Submit, validator } from 'components/Form/Form';
import { Link } from 'components/Prismic/Prismic';
import { postForgotPasswordForm } from 'services/cygest';
import Header from '../Header/Header';
import './PasswordForm.scss';

const ForgottenPassword = ({ homePath }) => {
  const [confirm, setConfirm] = useState({
    status: false,
    type: 'success',
    success: {
      title:
        'Vous allez recevoir un email afin de réinitialiser votre mot de passe.',
      element: <Link to={homePath}>Retour</Link>,
    },
    fail: {
      title: 'Une erreur est survenue.',
      message: 'Merci de réessayer plus tard.',
      element: (
        <button
          type="button"
          onClick={() =>
            setConfirm({ ...confirm, type: 'fail', status: false })
          }
        >
          Fermer
        </button>
      ),
    },
  });

  function submitted(e) {
    postForgotPasswordForm(e)
      .then(() => {
        setConfirm({ ...confirm, type: 'success', status: true });
      })
      .catch(err => {
        console.error(err.response);
        setConfirm({ ...confirm, type: 'fail', status: true });
      });
  }

  return (
    <div className="PasswordForm">
      <Header
        title="Mot de passe oublié"
        description="Saisissez votre email pour réinitialiser votre mot de passe."
      />
      <div className="PasswordForm__content">
        <Form
          className="PasswordForm__form"
          onSubmit={submitted}
          confirm={confirm}
        >
          <Input
            type="text"
            field="email"
            label="Email*"
            placeholder="jean.dupont@email.com"
            validate={validator.email}
            validateOnBlur
          />
          <span className="PasswordForm__mandatory">* Champ obligatoire</span>
          <Submit>Valider</Submit>
        </Form>
      </div>
    </div>
  );
};

export default ForgottenPassword;
