import React, { Component } from 'react';
import Ratio from '../../Ratio/Ratio';
import { RichText } from '../../Prismic/Prismic';
import './Video.scss';

class Video extends Component {
  render() {
    const { datas } = this.props;
    const { video, title } = datas.primary;

    return (
      <div className="Video">
        <RichText className="Video__title">{title}</RichText>

        <Ratio className="Video__player" ratio={1.5}>
            <div
              dangerouslySetInnerHTML={{
                __html: video.html.replace(
                  'feature=oembed',
                  'feature=oembed&rel=0'
                ),
              }}
            />
        </Ratio>
      </div>
    );
  }
}

export default Video;
