import React, { Component } from 'react';
import SVG from 'react-inlinesvg';
import Form, {
  Input,
  Checkbox,
  SubmitCaptcha,
  validator,
} from '../../../components/Form/Form';
import { postCallbackForm } from '../../../services/cygest';

import back from '../img/back.svg';
import contact from '../../../img/contact.svg';
import './Callback.scss';

class Callback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      confirm: {
        status: false,
        type: 'success',
        success: {
          title: 'Votre demande a bien été prise en compte.',
        },
        fail: {
          title: "Nous n'avons pas pu prendre en compte votre demande.",
          message: 'Merci de réessayer ultérieurement',
          element: (
            <button
              type="button"
              onClick={() => {
                const { confirm } = this.state;
                this.setState({
                  confirm: { ...confirm, status: false },
                });
              }}
            >
              Fermer
            </button>
          ),
        },
      },
    };
  }

  setFormApi(formApi) {
    this.formApi = formApi;
  }

  updateForm(formState) {
    const { confirm } = this.state;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Rappel immédiat',
    });

    this.setState({ loading: true });

    postCallbackForm(formState)
      .then(() => {
        this.setState({
          loading: false,
          confirm: {
            ...confirm,
            status: true,
            type: 'success',
          },
        });
        this.formApi.reset();
        setTimeout(() => {
          const { confirm: conf } = this.state;
          this.setState({ confirm: { ...conf, status: false } });
        }, 5000);
      })
      .catch(err => {
        this.setState({
          loading: false,
          confirm: {
            ...confirm,
            status: true,
            type: 'fail',
          },
        });
        console.error(err);
      });
  }

  render() {
    const { closeModal } = this.props;
    const { loading, confirm } = this.state;

    return (
      <div className="Callback">
        <div className="Callback__header">
          <button
            onClick={() => closeModal()}
            className="Callback__header__close"
            type="button"
          >
            <SVG src={back} className="Shortcut__header__icon" />
          </button>
          <span className="Callback__header__label">Rappel immédiat</span>
        </div>
        <div className="Callback__content">
          <p className="Callback__content__title">
            <strong>
              Un conseiller vous rappelle gratuitement pour répondre à vos
              questions&nbsp;:
            </strong>
          </p>

          <p className="Callback__content__subtitle">
            {`Du lundi au vendredi durant les heures d'ouverture de nos agences`}
          </p>

          <Form
            onSubmit={e => this.updateForm(e)}
            getApi={e => this.setFormApi(e)}
            confirm={confirm}
          >
            <div className="CallBack__row">
              <div>
                <Input
                  type="number"
                  field="DDEE_CH_CODE_POSTAL"
                  placeholder="Code postal"
                  icon="pin"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>

              <div>
                <Input
                  type="tel"
                  field="DDEE_CH_TELEPHONE"
                  placeholder="Numéro de téléphone"
                  icon="phone"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
            </div>

            <Checkbox
              field="acceptation"
              validate={validator.required}
              validateOnChange
            >
              J’accepte d’être recontacté par un membre du réseau Les
              déménageurs bretons afin de traiter ma demande
            </Checkbox>

            <div className="CallBack__submit">
              <SubmitCaptcha
                field="captcha"
                color="green"
                disabled={loading}
                submitForm={() => this.formApi.submitForm()}
              >
                Valider
              </SubmitCaptcha>
            </div>
          </Form>

          <strong className="Callback__content__text">
            Ou contactez-nous directement au :
          </strong>
          <a href="tel:0970330000" title="tel : 09 70 33 00 00">
            <SVG src={contact} className="Callback__content__contact" />
          </a>
        </div>
      </div>
    );
  }
}

export default Callback;
