import React from 'react';
import { Link } from 'components/Prismic/Prismic';
import './Steps.scss';

const handleFiles = (title, files) => {
  const data = files && files.reference && files.reference.documents;
  switch(title) {
    case 'Devis':
        return <div className="Steps__file">{data && data.devis && data.devis[0] && data.devis[0].filename.substr(5)}</div>;
    case 'Lettre de voiture':
      return <div className="Steps__file">{data && data.lettre && data.lettre[0] && data.lettre[0].filename.substr(5)}</div>;
    case 'Facture acquittée':
      return <div className="Steps__file">{data && data.facture && data.facture[0] && data.facture[0].filename.substr(5)}</div>;
    default:
      return null;
  }
}
export default ({ datas, disabled: PhaseDisabled, files}) => (
  <div className="Steps">
    <div className="Steps_items">
      {datas.map((step, i) => {
        const { to, title, completed, disabled, classOption = '', info } = step;
        return (
          <Link
            to={to}
            className={`Steps__item ${
              completed ? '-completed' : ''
            } ${classOption}`}
            key={i}
            disabled={PhaseDisabled || disabled}
          >
            <div className="Steps__item__image">
              <div />
            </div>
            <div className="Steps__item__title">
              {title}
              <br />
              {handleFiles(title, files)}
              {info && (
                <span className="Steps__item__info">
                  ?<div className="Steps__item__info__content">{info}</div>
                </span>
              )}
            </div>
          </Link>
        );
      })}
    </div>
  </div>
);
