import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Col from 'components/Col';
import { getDepositePayment, getDepositeUrl, getDevis } from 'services/cygest';
import Form, { Input, Submit } from 'components/Form/Form';
import MonDemenageur from '../../layout/MonDemenageur/MonDemenageur';
import Header from '../Header/Header';

import mastercard from '../../img/mastercard-logo.png';
import visa from '../../img/visa-logo.png';

import './Deposit.scss';

const numberWithSpace = number =>
  number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '0';

const PaymentItem = ({ datas }) => (
  <div className="PaymentItem">
    <div className="PaymentItem__grid">
      <Col className="PaymentItem__grid__left">
        <div className="PaymentItem__grid__title">
          {datas.arrhesPayementStatus === '' && 'Paiement en ligne'}
          {datas.arrhesPayementStatus === 'pending' && 'Paiement en ligne'}
          {datas.arrhesPayementStatus === 'paid' &&
            'Paiement en cours de validation...'}
        </div>
      </Col>
      <Col className="PaymentItem__grid__right">
        <b>{numberWithSpace(datas.arrhes)}€</b> <small>TTC (TVA incluse)</small>
      </Col>
    </div>
    <div className="PaymentItem__block">
      <div className="PaymentItem__block__text">
        Montant de vos arrhes selon votre devis
      </div>
    </div>
    <div className="PaymentItem__block">
      <img src={mastercard} alt="" className="PaymentItem__logo" />
      <img src={visa} alt="" className="PaymentItem__logo" />
      <div className="PaymentItem__block__text">Paiement sécurisé</div>
    </div>
  </div>
);

export default ({ currentPath, homeFolderPath }) => {
  let formApi = null;
  const [pending, setPending] = useState(true);
  const [quotation, setQuotation] = useState({});
  const [loading, setLoading] = useState(false);
  const [depositeUrl, setDepositeUrl] = useState(false);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    getDepositePayment().then(data => {
      setPending(false);
      if (formApi) formApi.setValues({ devisId: data.devisId });
      setQuotation(data);
      getDevis(data.devisId).then(d => {
        const blob = new Blob([d.data], { type: 'application/pdf' });
        const resurl = window.URL.createObjectURL(blob);
        setUrl(resurl);
      });
    });
  }, []);

  const setFormApi = e => {
    formApi = e;
  };

  return (
    <div className="Deposite">
      <Header title="Paiement de mes arrhes" />
      <div className="Deposite__container">
        <div className="Deposite__content">
          <div className="Deposite__grid">
            <Col className="Deposite__grid__left">
              <MonDemenageur />
            </Col>
            <Col className="Deposite__grid__right">
              <Switch>
                <Route
                  exact
                  path={`${currentPath}/accepte`}
                  render={props => (
                    <div className="Deposite__status">
                      <div className="Deposite__status__title">
                        Paiement accepté,
                      </div>
                      <div className="Deposite__status__subtitle">
                        Merci, nous avons bien réceptionné le paiement de vos
                        arrhes.
                      </div>
                      <button
                        type="button"
                        className="Deposite__home"
                        onClick={() => props.history.push(homeFolderPath)}
                      >
                        Votre dossier
                      </button>
                    </div>
                  )}
                />
                <Route
                  exact
                  path={`${currentPath}/annule`}
                  render={props => (
                    <div className="Deposite__status">
                      <div className="Deposite__status__title">
                        Paiement annulé,
                      </div>
                      <div className="Deposite__status__subtitle">
                        merci de contacter votre déménageur.
                      </div>
                      <button
                        type="button"
                        className="Deposite__home"
                        onClick={() => props.history.push(homeFolderPath)}
                      >
                        Votre dossier
                      </button>
                    </div>
                  )}
                />
                <Route
                  render={props => {
                    if (depositeUrl) {
                      return (
                        <iframe
                          src={depositeUrl}
                          title="payplug.com"
                          className="Deposite__iframe"
                        />
                      );
                    }
                    return (
                      <Form
                        onSubmit={() => {
                          setLoading(true);
                          getDepositeUrl().then(setDepositeUrl);
                        }}
                        pending={pending}
                        getApi={setFormApi}
                      >
                        <Input type="hidden" field="devisId" />
                        <a
                          href={url}
                          download={`devis-${quotation.reference}.pdf`}
                          className="Deposite__grid__quotation"
                        >
                          N° Devis-Contrat : {quotation.reference}
                        </a>
                        <PaymentItem datas={quotation} />
                        <div className="Deposite__buttons">
                          <button
                            type="button"
                            className="Deposite__back"
                            onClick={() => props.history.goBack()}
                          >
                            Retour
                          </button>
                          {quotation.arrhesPayementStatus !== 'paid' && (
                            <Submit
                              className="Deposite__save"
                              disabled={loading}
                            >
                              Valider et Payer
                            </Submit>
                          )}
                        </div>
                      </Form>
                    );
                  }}
                />
              </Switch>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
};
