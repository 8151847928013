import React, { useContext } from 'react';
import MonDossierContext from '../../MonDossierContext';
import './MonDemenageur.scss';

export default () => {
  const { userDashboard } = useContext(MonDossierContext);
  const { demenageur = {} } = userDashboard;

  return (
    <div className={`MonDemenageur ${!userDashboard ? '-loading' : ''}`}>
      <div className="MonDemenageur__content">
        <div className="MonDemenageur__text">
          {`Société ${demenageur.societe}`}{' '}
          {demenageur.nomAgence && ` à ${demenageur.nomAgence}`}
        </div>

        <div className="MonDemenageur__text">
          {`${demenageur.adresse1} ${demenageur.adresse2} ${demenageur.cp} ${
            demenageur.ville
          }, France`}
        </div>

        <div className="MonDemenageur__text">
          <p className="MonDemenageur__phone">
            {demenageur.tel && demenageur.tel.replace('.', ' ')}
          </p>
          <a
            href={`mailto:${demenageur.email}`}
            className="MonDemenageur__email"
          >
            {demenageur.email}
          </a>
        </div>
      </div>
    </div>
  );
};
