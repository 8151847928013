const config = {
  captcha: process.env.REACT_APP_CAPTCHA,
  gtm: {
    id: process.env.REACT_APP_GTM_ID,
    enable: process.env.REACT_APP_GTM_ENABLE,
  },
  domain: process.env.REACT_APP_DOMAIN,
  url: process.env.REACT_APP_URL,
  prismic: {
    apiEndpoint: process.env.REACT_APP_PRISMIC_APIENDPOINT,
    accessToken: process.env.REACT_APP_PRISMIC_ACCESSTOKEN,
  },
  middleware: {
    path: process.env.REACT_APP_MIDDLEWARE,
  },
  gouvAPI: {
    path: process.env.REACT_APP_GOUVAPI,
  },
  googleMaps: {
    url: process.env.REACT_APP_GOOGLEMAPS,
  },
  googlePlace: {
    url: process.env.REACT_APP_GOOGLEPLACE,
  },
  linkeo: {
    url: process.env.REACT_APP_LINKEO,
  },
  isMinisite: () => {
    const url = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }`;
    if (url === config.url) return false;
    return true;
  },
};

export default config;
