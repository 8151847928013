import React, { useState, useContext, useEffect } from 'react';
import { getUserElements, getAllElements } from 'services/cygest';
import roomList from 'services/roomList';
import MonDossierContext from '../../../MonDossierContext';

const ListContext = React.createContext();

const ListProvider = ({ children }) => {
  const { error } = useContext(MonDossierContext);
  const [form, setForm] = useState([]);
  const [elements, setElements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getAllElements()
      .then(data => {
        setElements(data);
        return getUserElements();
      })
      .then(data => {
        setForm(data);
        setLoading(false);
      })
      .catch(error);
  }, []);

  const getTotalElementsLength = roomId => {
    const room = form.filter(e => e.id === roomId);
    const elementsRoom = room.length ? room[0].elements : [];
    const elementLength = elementsRoom.reduce((a, e) => {
      const length = a + e.length;
      return length;
    }, 0);
    return elementLength;
  };

  const changeRoomLabel = (label, userRoomId) => {
    let index = form.length + 1;
    form.forEach((room, i) => {
      if (room.id === userRoomId) index = i;
    });
    const room = form.filter(e => e.id === userRoomId)[0];
    room.label = label;
    const othersRooms = form.filter(e => e.id !== userRoomId);
    othersRooms.splice(index, 0, room);
    setForm(othersRooms);
  };

  const addNewElement = (roomId, newElement) => {
    let indexRoom = form.length + 1;
    form.forEach((room, i) => {
      if (room.id === roomId) indexRoom = i;
    });
    const room = form.filter(r => r.id === roomId)[0];
    const sameElement = room.elements.filter(e => e.id === newElement.id);
    if (sameElement.length) {
      room.elements.forEach(e => {
        if (e.id === newElement.id) {
          e.length += 1;
        }
      });
    } else {
      room.elements.push(newElement);
    }
    const othersRooms = form.filter(r => r.id !== roomId);
    othersRooms.splice(indexRoom, 0, room);
    setForm(othersRooms);
  };

  const increaseElement = (roomId, elementId) => {
    let indexRoom = form.length + 1;
    form.forEach((room, i) => {
      if (room.id === roomId) indexRoom = i;
    });
    const room = form.filter(r => r.id === roomId)[0];

    room.elements.forEach((element, i) => {
      if (element.id === elementId) {
        room.elements[i].length += 1;
        if (room.elements[i].remove) room.elements[i].remove = false;
      }
    });

    const othersRooms = form.filter(r => r.id !== roomId);
    othersRooms.splice(indexRoom, 0, room);
    setForm(othersRooms);
  };

  const decreaseElement = (roomId, elementId) => {
    let indexRoom = form.length + 1;
    form.forEach((room, i) => {
      if (room.id === roomId) indexRoom = i;
    });
    const room = form.filter(r => r.id === roomId)[0];

    room.elements = room.elements.reduce((accumulator, element) => {
      if (element.id === elementId && element.remove) {
        return accumulator;
      }

      if (element.id === elementId && element.length === 1) {
        const newElement = element;
        newElement.remove = true;
        accumulator.push(newElement);
        return accumulator;
      }

      if (element.id === elementId) {
        const newElement = element;
        newElement.length = newElement.length <= 0 ? 0 : newElement.length - 1;
        accumulator.push(newElement);
        return accumulator;
      }

      accumulator.push(element);
      return accumulator;
    }, []);

    const othersRooms = form.filter(r => r.id !== roomId);
    othersRooms.splice(indexRoom, 0, room);
    setForm(othersRooms);
  };

  const removeRoom = roomId => {
    const newForm = form.map(room => {
      if (room.id === roomId) {
        const newRoom = room;
        newRoom.removed = true;
        return newRoom;
      }
      return room;
    });
    setForm(newForm);
  };

  const addRoom = newRoom => {
    const formLength = form.length;

    const { id: newRoomId } = roomList.getRoomById(newRoom.roomTypeId);
    const index = form.reduce(
      (a, room, i) => (room.roomTypeId > newRoomId && formLength === a ? i : a),
      formLength
    );

    const otherRoom = form.filter(e => e.id !== newRoom.id);
    otherRoom.splice(index, 0, newRoom);

    setForm(otherRoom);
  };

  const getAllRooms = () => form.filter(e => !e.removed);

  const getRoomById = roomId => {
    const room = form.filter(e => e.id === roomId);
    return room[0];
  };

  const getElementById = elementId => {
    const element = elements.filter(
      e => elementId && (e.group === elementId || e.id === elementId)
    );
    return element[0] || { label: 'Élément inconnu' };
  };

  const getElementsByFilter = roomId => {
    const filteredElements = elements.filter(element => {
      if (roomId && element.roomFilter) {
        return element.roomFilter.includes(roomId);
      }
      return false;
    });
    return filteredElements || [{ label: 'Aucun élément trouvé...' }];
  };

  const getElementsByGroup = (elementGroupId, roomFilter) => {
    const element = elements.filter(e => {
      if (roomFilter && e.roomFilter) {
        return e.group === elementGroupId && e.roomFilter.includes(roomFilter);
      }
      return e.group === elementGroupId;
    });
    return element || [{ label: 'Aucun élément trouvé...' }];
  };

  const getNextPreviousByRoomId = roomId => {
    const filteredForm = getAllRooms();
    const filteredFormLength = filteredForm.length;

    if (filteredFormLength > 1) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < filteredFormLength; index++) {
        const thisRoom = filteredForm[index];
        if (thisRoom.id === roomId) {
          const prevRoom =
            index === 0
              ? filteredForm[filteredFormLength - 1]
              : filteredForm[index - 1];
          const nextRoom =
            index === filteredFormLength - 1
              ? filteredForm[0]
              : filteredForm[index + 1];
          return { prevRoom, nextRoom };
        }
      }
    }

    const prevRoom = filteredForm[filteredFormLength - 1];
    const nextRoom = filteredForm[0];
    return { prevRoom, nextRoom };
  };

  return (
    <ListContext.Provider
      value={{
        form,
        elements,
        addRoom,
        changeRoomLabel,
        addNewElement,
        increaseElement,
        decreaseElement,
        removeRoom,
        getElementById,
        getElementsByFilter,
        getElementsByGroup,
        getTotalElementsLength,
        getNextPreviousByRoomId,
        getAllRooms,
        getRoomById,
        loading,
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

export default ListContext;
export { ListProvider };
