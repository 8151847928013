import Prismic from 'prismic-javascript';
import indexedDB from './indexedDB';
import config from '../config';

const resultStorage = {};

Object.defineProperties(resultStorage, {
  add: {
    value: datas => {
      datas.forEach(data => {
        resultStorage[data.id] = data;
      });
    },
  },
  getByIndex: {
    value: (indexName, values) => {
      const res = [];
      Object.keys(resultStorage).forEach(key => {
        values.forEach(value => {
          if (resultStorage[key][indexName] === value)
            res.push(resultStorage[key]);
        });
      });
      return res;
    },
  },
  getBy2Indexes: {
    value: (indexName, value, indexName2, value2) => {
      const res = [];
      Object.keys(resultStorage).forEach(key => {
        if (
          resultStorage[key][indexName] === value &&
          resultStorage[key][indexName2] === value2
        )
          res.push(resultStorage[key]);
      });
      return res;
    },
  },
});

const getApi = async () =>
  Prismic.api(config.prismic.apiEndpoint, {
    accessToken: config.prismic.accessToken,
  });

const getPageByUid = async (type, uid) =>
  new Promise(resolve => {
    const result = resultStorage.getBy2Indexes('type', type, 'uid', uid);
    if (result.length) {
      resolve(result[0]);
      return;
    }

    getApi()
      .then(api => {
        const data = api.query(Prismic.Predicates.at(`my.${type}.uid`, uid));

        data.then(({ results }) => {
          indexedDB('prismic').add(results);
          resultStorage.add(results);
          resolve(results[0]);
        });
      })
      .catch(() => {
        indexedDB('prismic')
          .getBy2Indexes('type', type, 'uid', uid)
          .then(results => resolve(results[0]));
      });
  });

const getPageByType = async type =>
  new Promise(resolve => {
    getApi()
      .then(api => {
        const data = api.query(Prismic.Predicates.at('document.type', type), {
          pageSize: 100,
        });

        data.then(({ results }) => {
          indexedDB('prismic').add(results);
          resultStorage.add(results);
          resolve(results);
        });
      })
      .catch(() => {
        indexedDB('prismic')
          .getByIndex('type', [type])
          .then(results => resolve(results));
      });
  });

const getPageByTypes = async types =>
  new Promise(resolve => {
    getApi()
      .then(api => {
        const data = api.query(Prismic.Predicates.any('document.type', types), {
          pageSize: 50,
        });

        data.then(({ results }) => {
          indexedDB('prismic').add(results);
          resultStorage.add(results);
          resolve(results);
        });
      })
      .catch(() => {
        indexedDB('prismic')
          .getByIndex('type', types)
          .then(results => resolve(results));
      });
  });

const getModuleById = async id =>
  new Promise(resolve => {
    const result = resultStorage.getByIndex('id', [id]);
    if (result.length) {
      resolve(result[0]);
      return;
    }

    getApi()
      .then(api => {
        const data = api.query(Prismic.Predicates.at(`document.id`, id));

        data.then(({ results }) => {
          indexedDB('prismic').add(results);
          resultStorage.add(results);
          resolve(results[0]);
        });
      })
      .catch(() => {
        indexedDB('prismic')
          .getByIndex('id', [id])
          .then(results => resolve(results[0]));
      });
  });

const getModuleByIds = async ids =>
  new Promise(resolve => {
    getApi()
      .then(api => {
        const data = api.query(Prismic.Predicates.any(`document.id`, ids));

        data.then(({ results }) => {
          indexedDB('prismic').add(results);
          resultStorage.add(results);
          resolve(results);
        });
      })
      .catch(() => {
        indexedDB('prismic')
          .getByIndex('id', ids)
          .then(results => resolve(results));
      });
  });

export default getApi;
export {
  getPageByUid,
  getPageByType,
  getPageByTypes,
  getModuleById,
  getModuleByIds,
  indexedDB,
};
