import React, { Component } from 'react';

import { getPageByType } from '../../services/prismic';
import { RichText, Link } from '../../components/Prismic/Prismic';
import Head from '../../components/Head/Head';
import Loading from '../../components/Loading/Loading';
import Slice from '../../components/Slice/Slice';

import Banner from './Banner/Banner';
import PrivatePro from './PrivatePro/PrivatePro';
import Figure from './Figure/Figure';
import Insert from './Insert/Insert';
import Meteo from './Meteo/Meteo';
import Modules from '../../components/Modules/Modules';

import './Home.scss';

class Home extends Component {
  constructor() {
    super();

    this.state = {
      datas: null,
      bannerHaute: null,
      bannerBasse: null,
    };

    Promise.all([
      getPageByType('home-page'),
      getPageByType('home_banner'),
      getPageByType('banniere_home_basse'),
    ]).then(([datas, bannerHaute, bannerBasse]) => {
      this.setState({
        datas: datas[0].data,
        bannerHaute: bannerHaute.length > 0 ? bannerHaute[0] : null,
        bannerBasse: bannerBasse.length > 0 ? bannerBasse[0] : null,
      });
    });
  }

  render() {
    const { datas, bannerHaute, bannerBasse } = this.state;
    if (datas) {
      return (
        <div className="Home">
          <Head title={datas.seo_title} description={datas.seo_description} />
          <div className="Home__container">
            <div className="Home__header">
              <div className="Home__header__container">
                <RichText className="Home__header__title">
                  {datas.title}
                </RichText>
              </div>
            </div>
            <PrivatePro {...datas} />
            <Figure {...datas} />
            <Insert {...datas} />
            {bannerHaute && <Banner datas={bannerHaute} />}
            <Meteo {...datas} />
            <div className="Home__faq">
              <Slice
                slice={[
                  {
                    primary: { title: datas.question_title },
                    slice_type: 'faq',
                    items: datas.question_list,
                  },
                ]}
              />
              <Link to={datas.question_link} className="Home__button">
                {datas.question_button}
              </Link>
            </div>
            {bannerBasse && <Banner datas={bannerBasse} />}
            <Modules module={datas.opinion_relationship_field} />
            <Modules module={datas.partner_relationship_field} />
          </div>
        </div>
      );
    }

    return <Loading />;
  }
}

export default Home;
