import React, { useState, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from 'layout/NotFound/NotFound';

import MonDossierContext from './MonDossierContext';

import Login from './pages/PasswordForm/Login';
import SignInByMail from './pages/PasswordForm/SignInByMail';
import ValidRegister from './pages/PasswordForm/ValidRegister';
import SignInByStep from './pages/PasswordForm/SignInByStep';
import ForgottenPassword from './pages/PasswordForm/ForgottenPassword';
import CreateAccount from './pages/PasswordForm/CreateAccount';
import UpdatePassword from './pages/PasswordForm/UpdatePassword';
import Dashboard from './pages/Dashboard/Dashboard';
import UpdateProfile from './pages/PasswordForm/UpdateProfile';
import InfosPerso from './pages/InfosPerso/InfosPerso';
import Prestations from './pages/Prestations/Prestations';
import ListeMobilier from './pages/ListeMobilier/ListeMobilier';
import DeclarationValeur from './pages/DeclarationValeur/DeclarationValeur';
import EnqueteSatisfaction from './pages/Enquetes/EnqueteSatisfaction';
import EnqueteComplementaireRouter from './pages/Enquetes/EnqueteComplementaireRouter';
import EnqueteLoggedOutSatisfaction from './pages/EnquetesLoggedOut/EnqueteLoggedOutSatisfaction';
import EnqueteLoggedOutComplementaireRouter from './pages/EnquetesLoggedOut/EnqueteLoggedOutComplementaireRouter';
import Signing from './pages/Signing/Signing';
import Deposit from './pages/Deposit/Deposit';
import InfoDates from './pages/InfoDates/InfoDates';
import Payment from './pages/Payment/Payment';
// import Messagerie from './pages/Messagerie/Messagerie';
import FileUpload from './pages/FileUpload/FileUpload';

const userMailContext = React.createContext();

const Router = ({ match }) => {
  const context = useContext(MonDossierContext);
  const { user } = context;
  const { userDashboard } = context;
  const { path: homeFolderPath } = match;
  const [userMail, setUserMail] = useState('');

  if (!user) {
    return (
      <userMailContext.Provider value={{ userMail, setUserMail }}>
        <Switch>
          <Route
            exact
            path={`${homeFolderPath}/creer-un-dossier-client`}
            component={CreateAccount}
          />
          <Route
            exact
            path={`${homeFolderPath}/mot-de-passe-oublie`}
            component={ForgottenPassword}
          />
          <Route
            exact
            path={`${homeFolderPath}/mot-de-passe-oublie/:token/:uid`}
            render={props => (
              <UpdatePassword
                homeFolderPath={homeFolderPath}
                user={user}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={`${homeFolderPath}/inscription/etape-5/:token/:uid`}
            component={SignInByStep}
          />
          <Route
            exact
            path={`${homeFolderPath}/inscription/validation/:token/:uid`}
            component={ValidRegister}
          />
          <Route
            exact
            path={`${homeFolderPath}/inscription/:token/:uid`}
            component={SignInByMail}
          />
          <Route
            exact
            path={`${homeFolderPath}/enquete-satisfaction/:agenceId`}
            render={props => (
              <EnqueteLoggedOutSatisfaction
                homeFolderPath={homeFolderPath}
                user={user}
                userMailContext={userMailContext}
                {...props}
              />
            )}
          />
          <Route
            path={`${homeFolderPath}/enquete-complementaire-avec-jeux-concours/:agenceId`}
            render={props => (
              <EnqueteLoggedOutComplementaireRouter
                homeEnquetePath={`${homeFolderPath}/enquete-complementaire-avec-jeux-concours/${props.match.params.agenceId}`}
                homeFolderPath={homeFolderPath}
                userMailContext={userMailContext}
                user={user}
                {...props}
              />
            )}
          />
          <Route component={Login} />
        </Switch>
      </userMailContext.Provider>
    );
  }

  return (
    <Switch>
      <Redirect
        from={`${homeFolderPath}/inscription/`}
        to={`${homeFolderPath}/`}
      />
      <Route
        exact
        path={`${homeFolderPath}/`}
        render={props => (
          <Dashboard homeFolderPath={homeFolderPath} user={user} {...props} />
        )}
      />
      <Route
        exact
        path={`${homeFolderPath}/prestations`}
        render={props => (
          <Prestations homeFolderPath={homeFolderPath} user={user} {...props} />
        )}
      />
      <Route
        path={`${homeFolderPath}/liste-mobilier`}
        render={props => (
          <ListeMobilier
            homeFolderPath={homeFolderPath}
            user={user}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${homeFolderPath}/modifier-mon-profil`}
        render={props => (
          <UpdateProfile
            homeFolderPath={homeFolderPath}
            user={user}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${homeFolderPath}/informations-personnelles`}
        render={props => (
          <InfosPerso homeFolderPath={homeFolderPath} user={user} {...props} />
        )}
      />
      <Route
        exact
        path={`${homeFolderPath}/declaration-de-valeur`}
        render={props => (
          <DeclarationValeur
            homeFolderPath={homeFolderPath}
            user={user}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${homeFolderPath}/enquete-satisfaction`}
        render={props => (
          <EnqueteSatisfaction
            homeFolderPath={homeFolderPath}
            user={user}
            {...props}
          />
        )}
      />
      <Route
        path={`${homeFolderPath}/enquete-complementaire-avec-jeux-concours`}
        render={props => (
          <EnqueteComplementaireRouter
            homeEnquetePath={`${homeFolderPath}/enquete-complementaire-avec-jeux-concours`}
            homeFolderPath={homeFolderPath}
            user={user}
            {...props}
          />
        )}
      />
      <Route
        path={`${homeFolderPath}/choix-de-mes-prestations-et-signature-de-mon-devis`}
        render={props => (
          <Signing
            homeFolderPath={homeFolderPath}
            user={user}
            currentPath={`${homeFolderPath}/choix-de-mes-prestations-et-signature-de-mon-devis`}
            {...props}
          />
        )}
      />
      <Route
        path={`${homeFolderPath}/devis`}
        render={props => (
          <FileUpload
            homeFolderPath={homeFolderPath}
            user={user}
            currentPath={`${homeFolderPath}/devis`}
            {...props}
            type="devis"
            userDashboard={userDashboard}
            props={props}
          />
        )}
      />
      <Route
        path={`${homeFolderPath}/lettre-de-voiture`}
        render={props => (
          <FileUpload
            homeFolderPath={homeFolderPath}
            user={user}
            currentPath={`${homeFolderPath}/lettre-de-voiture`}
            {...props}
            type="lettre"
            userDashboard={userDashboard}
            props={props}
          />
        )}
      />
      <Route
        path={`${homeFolderPath}/facture-acquittée`}
        render={props => (
          <FileUpload
            homeFolderPath={homeFolderPath}
            user={user}
            currentPath={`${homeFolderPath}/facture-acquittée`}
            {...props}
            type="facture"
            userDashboard={userDashboard}
            props={props}
          />
        )}
      />
      <Route
        path={`${homeFolderPath}/paiement-de-mes-arrhes`}
        render={props => (
          <Deposit
            homeFolderPath={homeFolderPath}
            user={user}
            currentPath={`${homeFolderPath}/paiement-de-mes-arrhes`}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${homeFolderPath}/information-dates`}
        render={props => (
          <InfoDates homeFolderPath={homeFolderPath} user={user} {...props} />
        )}
      />
      <Route
        path={`${homeFolderPath}/paiement-de-mon-solde`}
        render={props => (
          <Payment
            homeFolderPath={homeFolderPath}
            user={user}
            currentPath={`${homeFolderPath}/paiement-de-mon-solde`}
            {...props}
          />
        )}
      />
      {/* <Route
        exact
        path={`${homeFolderPath}/messagerie`}
        render={props => (
          <Messagerie homeFolderPath={homeFolderPath} user={user} {...props} />
        )}
      /> */}
      <Route status={404} render={props => <NotFound back {...props} />} />
    </Switch>
  );
};

export default Router;
