import React, { Component } from 'react';
import { Img, Link } from '../../../components/Prismic/Prismic';
import Estimate from '../Shortcut/Estimate';
import './Nav.scss';

class Nav extends Component {
  render() {
    const { closeAll, toggleModal, datas, toggleMenu } = this.props;
    return (
      <nav className="Nav">
        <Link
          to="/"
          className="Nav__link"
          onClick={closeAll}
          title="Page d'accueil"
        >
          <Img src={datas.logo} className="Nav__logo" />
        </Link>
        <div className="Nav__right">
          <button
            type="button"
            className="Nav__button"
            onClick={() => toggleModal(Estimate)}
          >
            Devis rapide
          </button>
          <button
            className="Nav__burger"
            onClick={toggleMenu}
            type="button"
            title="ouvrir le menu"
          >
            <div className="Nav__burger__anim">
              <span />
            </div>
          </button>
        </div>
      </nav>
    );
  }
}

export default Nav;
