import React, { Component } from 'react';
import { RichText, Link } from '../../Prismic/Prismic';
import './Table.scss';

const TableLabel = props => (
  <div className="Table__label">
    {props.items.map((item, i) => (
      <div
        className={`Table__label__cel ${
          item.separation === 'true' ? '-separation' : ''
        }`}
        key={i}
      >
        <span>{item.titre}</span>
      </div>
    ))}
  </div>
);

const TableHead = props => (
  <div className="Table__head">
    <div className="Table__head__name">
      {props.data[`${props.colName}_name`]}
    </div>
    <div className="Table__head__stars">
      {Array.apply(0, Array(props.data[`${props.colName}_stars`])).map(
        (x, i) => (
          <div key={i} className="Table__head__star" />
        )
      )}
    </div>
    <div className="Table__head__center">
      <div className="Table__head__center--wrap">
        <div className="Table__head__text">
          {props.data[`${props.colName}_text`]}
        </div>
        {props.data[`${props.colName}_price`].length ? (
          <RichText className="Table__head__price">
            {props.data[`${props.colName}_price`]}
          </RichText>
        ) : null}
      </div>
    </div>
  </div>
);

const TableData = props => (
  <div className={`Table__data ${props.type || 'separation'}`}>
    <span data-label={props.label} />
  </div>
);

const TableRow = props => (
  <div
    className={`Table__row ${
      props.data.primary[`${props.colName}_fusion`] === 'true' ? '-fusion' : ''
    } ${
      props.data.primary[`${props.colName}_highlight`] === 'true'
        ? '-highlight'
        : ''
    }`}
  >
    <TableHead data={props.data.primary} colName={props.colName} />
    {props.data.items.map((item, i) => (
      <TableData type={item[props.colName]} label={item.titre} key={i} />
    ))}
    <Link
      to={props.data.primary[`${props.colName}_link`]}
      className="Table__row__link"
    >
      {props.data.primary[`${props.colName}_link-name`]}
    </Link>
  </div>
);

class Table extends Component {
  render() {
    return (
      <div className="Table">
        <RichText className="Table__title">
          {this.props.table.primary.table_title}
        </RichText>
        <div className="Table__content">
          <TableLabel items={this.props.table.items} />
          <div className="Table__body">
            {Array.apply(0, Array(this.props.table.primary.col_length)).map(
              (x, i) => (
                <TableRow
                  data={this.props.table}
                  colName={`col-${i + 1}`}
                  key={i}
                />
              )
            )}
          </div>
        </div>

        <div>
          <div className="Table__legend">
            <span className="Table__legend__icon include" /> : inclus
            <span className="Table__legend__icon not-include" /> : non inclus
            <span className="Table__legend__icon option" /> : en option
          </div>
          <div className="Table__ps">{this.props.table.primary.ps_1}</div>
          <div className="Table__ps">{this.props.table.primary.ps_2}</div>
          <Link
            to={this.props.table.primary.button_link}
            className="Table__link"
          >
            {this.props.table.primary.button_name}
          </Link>
        </div>
      </div>
    );
  }
}

export default Table;
