import React, { useState, useEffect, useContext } from 'react';
import { getUserEmail, postRegisterUserForm } from 'services/cygest';
import Form, { Input, Submit, validator } from 'components/Form/Form';
import MonDossierContext from '../../MonDossierContext';
import Header from '../Header/Header';
import './PasswordForm.scss';

const Login = ({ match }) => {
  const context = useContext(MonDossierContext);
  const { connect } = context;
  const { token, uid } = match.params;
  const [pending, setPending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState({
    status: false,
    type: 'fail',
    fail: {
      title: 'Impossible de créer le compte.',
      message: 'Merci réessayer ultérieurement',
      element: (
        <button
          type="button"
          onClick={() =>
            setConfirm({ ...confirm, type: 'fail', status: false })
          }
        >
          Fermer
        </button>
      ),
    },
  });

  let formApi = null;
  function setFormApi(FormApi) {
    formApi = FormApi;
  }

  useEffect(() => {
    getUserEmail(token, uid)
      .then(d => {
        setPending(false);
        formApi.setValue('login', d.data.email);
      })
      .catch(e => {
        setPending(false);
        setConfirm({
          ...confirm,
          fail: {
            ...confirm.fail,
            title: 'Erreur lors de la récupération des informations ',
            message: !!e.response && e.response.data,
          },
          type: 'fail',
          status: true,
        });
      });
  }, []);

  function submitted(formState) {

    setLoading(true);

    postRegisterUserForm(formState)
      .then(user => {
        connect(user);
      })
      .catch(d => {
        console.error(d.reponse);
        setLoading(false);
        setConfirm({
          ...confirm,
          status: true,
        });
      });
  }

  return (
    <div className="PasswordForm">
      <Header
        title="Créer un compte"
        description="Merci de finaliser la création de votre compte, en saisissant votre
        mot de passe."
      />
      <div className="PasswordForm__content">
        <Form
          className="PasswordForm__form"
          getApi={setFormApi}
          onSubmit={submitted}
          confirm={confirm}
          pending={pending}
        >
          <Input type="hidden" field="token" initialValue={token} />
          <Input type="hidden" field="uid" initialValue={uid} />
          <Input type="text" field="login" label="identifiant" disabled />
          <Input
            type="password"
            field="password"
            label="Mot de passe*"
            instruction="(8 caractères minimum dont 1 majuscule et 1 chiffre)"
            placeholder="Mot de passe"
            validate={validator.password}
            validateOnBlur
          />
          <span className="PasswordForm__mandatory">* Champ obligatoire</span>
          <Submit disabled={loading}>Accéder à mon dossier client</Submit>
        </Form>
      </div>
    </div>
  );
};
export default Login;
