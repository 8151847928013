import React from 'react';
import Head from 'components/Head/Head';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { Link } from 'components/Prismic/Prismic';
import './Header.scss';

export default ({
  title,
  titlePage,
  description,
  callBack,
  previous,
  next,
}) => {
  const titleContent = `${title ? `${title} - ` : ''}Les déménageurs bretons`;

  return (
    <div className="MonDossier__Header">
      <Head title={titleContent} />
      <Breadcrumb
        topic={{ name: 'Votre dossier', link: 'votre-dossier' }}
        title={title}
        minimal
      />
      <div className="MonDossier__Header__content">
        <div className="MonDossier__Header__navigation">
          {previous && (
            <Link
              noChildren
              to={previous}
              onClick={callBack}
              title="pièce précédente"
              className="MonDossier__Header__prev"
            />
          )}
          <h1 className="MonDossier__Header__title">{titlePage || title}</h1>
          {next && (
            <Link
              noChildren
              to={next}
              onClick={callBack}
              title="pièce suivante"
              className="MonDossier__Header__next"
            />
          )}
        </div>
        {description && (
          <p className="MonDossier__Header__text">{description}</p>
        )}
      </div>
    </div>
  );
};
