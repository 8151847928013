import React, { Component } from 'react';
import { Img } from '../../../components/Prismic/Prismic';
import './Figure.scss';

class Figure extends Component {
  render() {
    const datas = this.props;
    return (
      <div className="Figure">
        <div className="Figure__container">
          <ul className="Figure__ul">
            {datas.figure_list.map((figure, i) => (
              <li key={`figure-${i}`} className="Figure__li">
                <p className="Figure__circle">
                  <Img src={figure.icon} className="Figure__icon" />
                  {/* <img
                    src={figure.icon.url}
                    alt={figure.icon.alt}
                    width={figure.icon.dimensions.width}
                    height={figure.icon.dimensions.height}
                    className="Figure__icon"
                  /> */}
                </p>

                <div className="Figure__text">
                  <div className="Figure__figure">
                    <sup className="Figure__more">+de</sup>
                    {figure.figure1}
                  </div>
                  <div className="Figure__label">{figure.label}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Figure;
