import React, { useState, useEffect, useContext } from 'react';
import { RichText, Link } from 'components/Prismic/Prismic';
import {
  postUserInformations,
  getPartners,
  postPartners,
} from 'services/cygest';
import Form, {
  Input,
  RadioGroup,
  Radio,
  Select,
  Option,
  TextArea,
  Checkbox,
  CheckboxPartenaireGroup,
  CheckboxPartenaire,
  File,
  Submit,
  validator,
} from 'components/Form/Form';
import Header from '../Header/Header';
import MonDossierContext from '../../MonDossierContext';
import './InfosPerso.scss';

export default ({ history, homeFolderPath }) => {
  let formApi = null;
  const { error, userDashboard, getUserInfo } = useContext(MonDossierContext);
  const [partners, setPartners] = useState([]);
  const [saveButton, setSaveButton] = useState(false);
  const [form, setForm] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [required, setRequired] = useState(true);
  const [pending, setPending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState({
    status: false,
    type: 'success',
    success: {
      title: 'Vos informations personnelles ont bien été enregistrées',
    },
    fail: {
      title: 'Impossible de sauvegarder vos informations personnelles',
      message: 'Merci réessayer ultérieurement',
      element: (
        <button
          type="button"
          onClick={() => {
            setConfirm({ ...confirm, type: 'fail', status: false });
          }}
        >
          Fermer
        </button>
      ),
    },
  });
  const [loadingPartenaires, setLoadingPartenaires] = useState(false);
  const [confirmPartenaires, setConfirmPartenaires] = useState({
    status: false,
    type: 'success',
    success: {
      title: 'Demande envoyée !',
      message:
        'Votre demande a été transmise aux partenaires qui vous recontacterons rapidement.',
    },
    fail: {
      title: "Votre demande n'a pas pu être envoyée.",
      message: 'Merci de réessayer ultérieurement',
      element: (
        <button
          type="button"
          onClick={() =>
            setConfirmPartenaires({
              ...confirmPartenaires,
              type: 'fail',
              status: false,
            })
          }
        >
          Fermer
        </button>
      ),
    },
  });

  useEffect(() => {
    getPartners().then(e => setPartners(e.data));
  }, []);

  useEffect(() => {
    getUserInfo()
      .then(data => {
        setPending(false);
        setForm(data);
        setDisabled(data.destinationPasDeLogement);
        setRequired(!data.destinationPasDeLogement);

        formApi.setValues({
          ...data,
          departSurface: data.departSurface || '',
        });

        if (
          !data.destinationPasDeLogement &&
          (data.destinationPasDeLogement ||
            validator.required(data.destinationAdresse1) ||
            validator.required(data.destinationVille) ||
            validator.required(data.destinationTypeLogement))
        ) {
          setSaveButton(true);
        }
      })
      .catch(error);
  }, []);

  function setFormApi(e) {
    formApi = e;
  }

  function submitted(formSate) {
    setLoading(true);

    postUserInformations({
      departAdresse1: formSate.departAdresse1,
      departVille: formSate.departVille,
      departTypeLogement: formSate.departTypeLogement,
      departLocaOuProprio: formSate.departLocaOuProprio,
      departSurface: formSate.departSurface,
      departSpecification: formSate.departSpecification,
      destinationPasDeLogement: formSate.destinationPasDeLogement,
      destinationAdresse1: formSate.destinationAdresse1 || '',
      destinationVille: formSate.destinationVille || '',
      destinationCodePostal: formSate.destinationCodePostal || '',
      destinationTypeLogement: formSate.destinationTypeLogement || '',
      files: formSate.files,
      fileNew: formSate.fileNew,
    })
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'dossierInfosPersos' });

        setLoading(false);
        setConfirm({ ...confirm, type: 'success', status: true });
        setTimeout(() => {
          history.push(homeFolderPath);
        }, 2000);
      })
      .catch(err => {
        error(err);
        setLoading(false);
      });
  }

  function updateForm(formState) {
    setLoadingPartenaires(true);
    const finalForm = {
      ...formState,
      PAR_N_ID: formState.PAR_N_ID.reduce((accumulator, value, i) => {
        if (value) accumulator.push(`${i}`);
        return accumulator;
      }, []),
      DDE_N_ID: form.demandeId,
      nom: form.nom,
      prenom: form.prenom,
      departAdresse1: form.departAdresse1,
      departCodePostal: form.departCodePostal,
      departVille: form.departVille,
      email: form.email,
      tel: form.tel,
    };

    postPartners(finalForm)
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'dossierPartenaires' });

        setLoadingPartenaires(false);
        setConfirmPartenaires({
          ...confirmPartenaires,
          status: true,
          type: 'success',
        });
      })
      .catch(err => {
        error(err);
        setLoadingPartenaires(false);
        setConfirmPartenaires({
          ...confirmPartenaires,
          status: true,
          type: 'fail',
        });
      });
  }

  return (
    <div className="InfosPerso">
      <Header
        title="Informations personnelles"
        description="Paramétrez vos informations personnelles"
      />
      <div className="InfosPerso__content">
        <Form
          onSubmit={submitted}
          getApi={setFormApi}
          pending={pending}
          confirm={confirm}
          className="InfosPerso__form"
        >
          <RichText className="InfosPerso__form__title">Mon projet :</RichText>

          <RadioGroup field="type" disabled>
            <Radio value="particulier" disabled>
              Un particulier*
            </Radio>
            <Radio value="professionnel" disabled>
              Un professionnel*
            </Radio>
          </RadioGroup>

          <h3 className="InfosPerso__form__subtitle">
            Informations personnelles
          </h3>

          <div
            className="InfosPerso__form__2col"
            style={{
              display: form.type === 'professionnel' ? 'block' : 'none',
            }}
          >
            <div>
              <Input
                type="text"
                field="mutationEntreprise"
                label="Nom de l'entreprise*"
                disabled
              />
            </div>
          </div>

          <div className="InfosPerso__form__2col">
            <div>
              <Input type="text" field="nom" label="Nom*" disabled />
            </div>
            <div>
              <Input type="text" field="prenom" label="Prénom*" disabled />
            </div>
          </div>

          <div className="InfosPerso__form__2col">
            <div>
              <Input type="tel" field="tel" label="Téléphone*" disabled />
            </div>
            <div>
              <Input type="email" field="email" label="Email*" disabled />
            </div>
          </div>

          <RichText className="InfosPerso__form__title">
            Étape 2 - Chargement - adresse de départ
          </RichText>

          <Input
            type="text"
            field="departAdresse1"
            label="Adresse*"
            placeholder="Adresse"
            validate={validator.required}
            validateOnBlur
            disabled={!!form.departAdresse1}
          />

          <div className="InfosPerso__form__2colFixed">
            <div>
              <Input
                type="text"
                field="departCodePostal"
                label="Code postal*"
                disabled
              />
            </div>
            <div>
              <Input
                type="text"
                field="departVille"
                label="Ville*"
                placeholder="Ville"
                validate={validator.required}
                validateOnBlur
                disabled={!!form.departVille}
              />
            </div>
          </div>

          <div className="InfosPerso__form__3col">
            <div>
              <Select
                field="departTypeLogement"
                label="Type de logement*"
                validate={validator.required}
                validateOnBlur
                disabled={!!form.departTypeLogement}
              >
                <Option value="" disabled>
                  Choisissez...
                </Option>
                <Option value="appartement">Appartement</Option>
                <Option value="maison">Maison</Option>
              </Select>
            </div>
            <div>
              <Select
                field="departLocaOuProprio"
                label="Vous êtes ?*"
                validate={validator.required}
                validateOnBlur
                disabled={!!form.departLocaOuProprio}
              >
                <Option value="" disabled>
                  Choisissez...
                </Option>
                <Option value="locataire">Locataire</Option>
                <Option value="propriétaire">Propriétaire</Option>
              </Select>
            </div>
            <div>
              <Input
                type="number"
                field="departSurface"
                label="Superficie*"
                placeholder="Superficie"
                validate={validator.number}
                validateOnBlur
                disabled={!!form.departSurface}
              />
            </div>
          </div>
          <TextArea
            field="departSpecification"
            label="Vos spécificités"
            placeholder="Merci d’indiquer tout élément que vous pensez important pour votre déménagement"
            disabled={!!form.departAdresse1}
          />

          <File
            field="files"
            initialValue={form.files || ''}
            validate={validator.upload}
            validateOnChange
            disabled={!!form.departAdresse1}
          />

          <div className="InfosPerso__thumbnails">
            {form &&
              form.piecesjointesChargement &&
              form.piecesjointesChargement.map(({ url, filename }, i) => (
                <div className="InfosPerso__thumbnails__item" key={i}>
                  <img
                    src={url}
                    alt=""
                    className="InfosPerso__thumbnails__item__img"
                  />
                  <span className="InfosPerso__thumbnails__item__filename">
                    {filename}
                  </span>
                </div>
              ))}
          </div>

          <RichText className="InfosPerso__form__title">
            Étape 3 - Livraison - adresse d’arrivée
          </RichText>

          <Checkbox
            field="destinationPasDeLogement"
            onChange={({ target }) => {
              setDisabled(target.checked);
              setRequired(!target.checked);
            }}
            validateOnChange
            disabled={form.destinationAdresse1 || form.destinationPasDeLogement}
          >
            Je n&apos;ai pas encore de logement à l&apos;arrivée
          </Checkbox>

          <Input
            type="text"
            field="destinationAdresse1"
            label="Nouvelle adresse*"
            placeholder="Nouvelle adresse"
            validate={formState =>
              required ? validator.required(formState) : undefined
            }
            disabled={form.destinationAdresse1 || disabled}
            validateOnBlur
          />

          <div className="InfosPerso__form__2colFixedNew">
            <div>
              <Input
                type="text"
                field="destinationCodePostal"
                label="Nouveau code postal*"
                placeholder="Nouveau code postal"
                validate={formState =>
                  required ? validator.number(formState) : undefined
                }
                validateOnBlur
                disabled={form.destinationCodePostal || disabled}
              />
            </div>
            <div>
              <Input
                type="text"
                field="destinationVille"
                label="Nouvelle ville*"
                placeholder="Nouvelle ville"
                validate={formState =>
                  required ? validator.required(formState) : undefined
                }
                disabled={form.destinationVille || disabled}
                validateOnBlur
              />
            </div>
          </div>

          <div className="InfosPerso__form__2col">
            <div>
              <Select
                field="destinationTypeLogement"
                label="Nouveau type de logement*"
                validate={formState =>
                  required ? validator.required(formState) : undefined
                }
                validateOnBlur
                disabled={form.destinationTypeLogement || disabled}
              >
                <Option value="" disabled>
                  Choisissez...
                </Option>
                <Option value="appartement">Appartement</Option>
                <Option value="maison">Maison</Option>
              </Select>

              <File
                field="fileNew"
                initialValue={form.fileNew || ''}
                validate={validator.upload}
                validateOnChange
                disabled={disabled}
              />

              <div className="InfosPerso__thumbnails">
                {form &&
                  form.piecesjointesLivraison &&
                  form.piecesjointesLivraison.map(({ url, filename }, i) => (
                    <div className="InfosPerso__thumbnails__item" key={i}>
                      <img
                        src={url}
                        alt=""
                        className="InfosPerso__thumbnails__item__img"
                      />
                      <span className="InfosPerso__thumbnails__item__filename">
                        {filename}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="InfosPerso__form__mandatory">*Champ obligatoire</div>

          <div className="InfosPerso__buttons">
            <button
              type="button"
              className="InfosPerso__back"
              onClick={() => history.goBack()}
            >
              Retour
            </button>
            {userDashboard.demenageur && userDashboard.demenageur.biniou && (
              <Link
                to={`mailto:${userDashboard.demenageur.email}`}
                className="InfosPerso__error"
              >
                Signaler une erreur
              </Link>
            )}
            {saveButton && (
              <Submit className="InfosPerso__save" disabled={loading}>
                Enregistrer
              </Submit>
            )}
          </div>
          <div style={{ clear: 'both' }} />
        </Form>
        {!!partners.length && (
          <Form
            onSubmit={updateForm}
            confirm={confirmPartenaires}
            className="Devis__form__step4"
          >
            <div className="Devis__form__text">
              Nous voulons également vous présentez quelques partenaires qui
              peuvent vous intéresser, si c’est le cas cliquez sur leur bouton,
              vos coordonnées leurs seront transmises.
            </div>

            <CheckboxPartenaireGroup
              field="CheckboxPartenaireGroup"
              validate={validator.CheckboxPartenaireValidator}
            >
              {partners.map((partner, i) => (
                <CheckboxPartenaire
                  field={`PAR_N_ID[${partner.partnerId}]`}
                  partnerName={partner.partnerName}
                  src={partner.partnerLogo}
                  key={i}
                />
              ))}
            </CheckboxPartenaireGroup>

            <Checkbox
              field="acceptation"
              validate={validator.required}
              validateOnChange
              className="Devis__form__checkbox"
            >
              J’accepte la transmission de mes données personnelles au(x)
              partenaire(s) sélectionné(s)*
            </Checkbox>

            <Submit
              field="captcha"
              color="transGreen"
              disabled={loadingPartenaires}
            >
              Envoyer
            </Submit>
          </Form>
        )}
      </div>
    </div>
  );
};
