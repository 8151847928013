import React, { Component } from 'react';
import { RichText } from '../../Prismic/Prismic';
import Form, {
  Input,
  Checkbox,
  SubmitCaptcha,
  validator,
} from '../../Form/Form';
import { postMutationForm } from '../../../services/cygest';
import './FormMutationPro.scss';

class FormMutationPro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      confirm: {
        status: false,
        type: 'success',
        success: {
          title: 'Demande envoyée !',
          message:
            'Merci de votre demande, nous allons vous contacter très rapidement.',
        },
        fail: {
          title: "Votre demande n'a pas pu être envoyée.",
          message: 'Merci de réessayer ultérieurement.',
          element: (
            <button
              type="button"
              onClick={() => {
                const { confirm } = this.state;
                this.setState({
                  confirm: { ...confirm, status: false },
                });
              }}
            >
              Fermer
            </button>
          ),
        },
      },
    };
  }

  setFormApi(formApi) {
    this.formApi = formApi;
  }

  updateForm(formState) {
    this.setState({ loading: true });

    postMutationForm(formState)
      .then(() => {
        const { confirm } = this.state;
        this.setState({
          loading: false,
          confirm: {
            ...confirm,
            status: true,
            type: 'success',
          },
        });
        this.formApi.reset();
        setTimeout(() => {
          const { confirm: conf } = this.state;
          this.setState({ confirm: { ...conf, status: false } });
        }, 5000);
      })
      .catch(err => {
        const { confirm } = this.state;
        this.setState({
          loading: false,
          confirm: {
            ...confirm,
            status: true,
            type: 'fail',
          },
        });
        console.error(err);
      });
  }

  render() {
    const { datas } = this.props;
    const { loading, confirm } = this.state;

    return (
      <div className="FormMutationPro">
        <div className="FormMutationPro__container">
          {datas.primary.title ? (
            <RichText className="FormMutationPro__title">
              {datas.primary.title}
            </RichText>
          ) : null}
          <Form
            onSubmit={e => this.updateForm(e)}
            getApi={e => this.setFormApi(e)}
            confirm={confirm}
          >
            <Input type="hidden" field="DDE_CH_TYPE" initialValue="mutation" />
            <Input type="hidden" field="DDE_CH_MUTATION" initialValue="oui" />
            <div className="FormMutationPro__row">
              <div>
                <Input
                  type="text"
                  field="DDE_CH_NOM"
                  placeholder="Nom"
                  label="Nom"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>

              <div>
                <Input
                  type="text"
                  field="DDE_CH_PRENOM"
                  placeholder="Prénom"
                  label="Prénom"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
            </div>
            <div className="FormMutationPro__row">
              <div>
                <Input
                  type="text"
                  field="DDE_CH_MUTATION_ENTREPRISE"
                  placeholder="Entreprise"
                  label="Entreprise"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>

              <div>
                <Input
                  type="text"
                  field="DDE_CH_FONCTION"
                  placeholder="Fonction"
                  label="Fonction"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
            </div>
            <div className="FormMutationPro__row">
              <div>
                <Input
                  type="tel"
                  field="DDE_CH_TEL"
                  placeholder="Téléphone"
                  label="Téléphone"
                  validate={validator.required}
                  validateOnBlur
                />
              </div>

              <div>
                <Input
                  type="mail"
                  field="DDE_CH_EMAIL"
                  placeholder="Email"
                  label="Email"
                  validate={validator.email}
                  validateOnBlur
                />
              </div>
            </div>

            <Checkbox
              field="acceptation"
              validate={validator.required}
              validateOnChange
            >
              J’accepte d’être recontacté par un membre du réseau Les
              déménageurs bretons afin de traiter ma demande
            </Checkbox>

            <div className="FormMutationPro__submit">
              <SubmitCaptcha
                field="captcha"
                color="green"
                disabled={loading}
                submitForm={() => this.formApi.submitForm()}
              >
                Valider
              </SubmitCaptcha>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default FormMutationPro;
