import axios from 'axios';
import indexedDB from './indexedDB';
import config from '../config';

let jsonWebToken = localStorage.getItem('jsonWebToken');

const ParseJWT = token => {
  if (!token) return null;
  const tokenSplited = token.split('.');
  const userJson = atob(tokenSplited[1]);
  const user = JSON.parse(userJson);
  return user;
};

const saveJWT = token => {
  jsonWebToken = token;
  localStorage.setItem('jsonWebToken', token);
};

function autoRenewToken() {
  if (jsonWebToken) {
    const timer = setInterval(() => {
      const user = ParseJWT(jsonWebToken);
      if (user) {
        const now = Math.round(Date.now() / 1000);
        const remainingTime = user.exp - now;
        if (remainingTime < 0) {
          clearInterval(timer);
        } else if (remainingTime < 120) {
          axios
            .post(`${config.middleware.path}/auth/renew-token`, null, {
              headers: { Authorization: `Bearer ${jsonWebToken}` },
            })
            .then(e => {
              const { token } = e.data;
              saveJWT(token);
            })
            .catch(err => {
              console.error(err.response);
              clearInterval(timer);
            });
        }
      } else {
        clearInterval(timer);
      }
    }, 60000);
  }
}

autoRenewToken();

const saveAndParseJWT = token => {
  saveJWT(token);
  autoRenewToken();
  return ParseJWT(token);
};

const formData = datas => {
  const formdata = new FormData();
  Object.keys(datas).forEach(name => {
    if (Array.isArray(datas[name])) {
      datas[name].forEach((data, i) => formdata.append(`${name}[${i}]`, data));
    } else {
      formdata.append(name, datas[name]);
    }
  });
  return formdata;
};

const postReset = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/devis/reset`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      .then(e => resolve(e.data || {}))
      .catch(reject);
  });

/* GET */

const getCPByGeolocalisation = async () =>
  new Promise(resolve => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          axios
            .get(
              `${config.googlePlace.url}&latlng=${coords.latitude},${coords.longitude}&result_type=postal_code`
            )
            .then(res => {
              try {
                resolve(res.data.results[0].address_components[0].long_name);
              } catch {
                resolve();
              }
            })
            .catch(() => resolve());
        },
        () => resolve()
      );
    } else {
      resolve();
    }
  });

const getCodeINSEEByCP = async cp =>
  axios.get(`https://geo.api.gouv.fr/communes?codePostal=${cp}`);

const getMeteoByCodeINSEE = async codeINSEE =>
  axios.get(`${config.middleware.path}/meteo/${codeINSEE}0`);

const getAgenceList = async () =>
  new Promise(resolve =>
    axios
      .get(`${config.middleware.path}/Agence`)
      .then(results => {
        indexedDB('cygestAgenceList').add(results.data);
        resolve(results.data);
      })
      .catch(() => {
        indexedDB('cygestAgenceList')
          .getAll()
          .then(results => resolve(results));
      })
  );

const getCityListByCodeDepartement = async zipCode =>
  new Promise((resolve, reject) =>
    axios
      .get(
        `${config.gouvAPI.path}/communes?codeDepartement=${zipCode}&fields=nom,codesPostaux,code&format=json&geometry=centre`
      )
      .then(e => resolve(e.data || {}))
      .catch(err => reject(err.response || {}))
  );

const getCityListByCity = async city =>
  new Promise((resolve, reject) =>
    axios
      .get(
        `${config.gouvAPI.path}/communes?nom=${city}&fields=nom,codesPostaux&format=json&geometry=centre`
      )
      .then(e => resolve(e.data || {}))
      .catch(err => reject(err.response || {}))
  );

const getAgenceByDomain = async domain =>
  new Promise(resolve => {
    const ssdomaine = domain;
    axios
      .get(`${config.middleware.path}/Agence/Ville/${ssdomaine}`)
      .then(results => {
        indexedDB('cygestAgences').add([results.data]);
        resolve(results.data);
      })
      .catch(() => {
        indexedDB('cygestAgences')
          .getByIndex('ssdomaine', [ssdomaine])
          .then(results => resolve(results[0]));
      });
  });

const getAgenceByCp = async cp =>
  new Promise(resolve =>
    axios
      .get(`${config.middleware.path}/Agence/${cp}`)
      .then(results => {
        indexedDB('cygestAgences').add([results.data]);
        resolve(results.data);
      })
      .catch(() => {
        indexedDB('cygestAgences')
          .getByIndex('cp', [cp])
          .then(results => resolve(results[0]));
      })
  );

const getPartners = () => axios.get(`${config.middleware.path}/Partners`);

const getUserEmail = (token, uid) =>
  axios.get(
    `${config.middleware.path}/user/check-register?token=${token}&uid=${uid}`
  );

const getDevis = devisId =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/devis/${devisId}/pdf`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
        responseType: 'blob',
      })
      .then(e => resolve(e || {}))
      .catch(reject);
  });

const getFacture = devisId =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/devis/${devisId}/facture`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
        responseType: 'blob',
      })
      .then(e => resolve(e || {}))
      .catch(reject);
  });

/* 

{
  demenageur: {
    societe: 'Société ADEM',
    adresse1: '47 Avenue Paul SANTY',
    adresse2: '',
    cp: '69008',
    ville: 'LYON 8ème',
    tel: '04 72 73 08 05',
    email: 'lyon@demenageurs-bretons.fr',
    horaires: 'Du lundi au vendredi - de 9h à 12h et de 13h30 à 17h30',
    nomAgence: 'LYON 2 3 5',
    biniou: true,
    newMessage: 2,
  },
  notifications: {
    list: [
      {
        title: 'Confirmation de la signature du devis',
        new: true,
        link: 'signature',
      },
      {
        title: 'Votre devis',
        new: false,
        link: null,
      },
      {
        title: 'Visite technique prévue le 12/02/2019',
        new: false,
        link: null,
      },
      {
        title: 'Cedric, bienvenue dans votre dossier personnel',
        new: false,
        link: null,
      },
    ],
  },
  
  projet: {
    phase1: {},
    phase2: { date: null },
    phase3: { devis: false },
    phase4: { date: null },
    phase5: {
      survey1: false,
      survey2: false,
    },
  },

  projet: {
    phase1: {},
    phase2: { date: 'Mercredi 20 février 2019 à 10:00' },
    phase3: {
      phaseActive: true,
      devisActive: true,
      arrhesActive: true,
      paymentActive: true,
    },
    phase4: { 
      date: {
        chargement: 'Mercredi 5 mars 2019 à 8:00',
        livraison: 'Mercredi 5 mars 2019 à 8:00',
      },
    },
    phase5: {
      survey1: true,
      survey2: true,
    },
  }

*/
const getUserDashboard = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/dashboard`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      .then(e => resolve(e.data || {}))
      .catch(reject);
  });

/* 
{
  DDE_CH_TYPE: 'professionnel',
  DDE_CH_MUTATION_ENTREPRISE: 'MB corp.',
  DDE_CH_NOM: 'Bruno',
  DDE_CH_PRENOM: 'Mathieu',
  DDE_CH_TEL: '0687265896',
  DDE_CH_EMAIL: test@test.fr`,
  DDE_CH_CH_CP: '69005',

  DDE_CH_CH_ADR1: '7 avenue adolphe max',
  DDE_CH_CH_VILLE: 'lyon',
  DDE_CH_CH_TYPE_LOGEMENT: 'appartement',
  DDE_CH_CH_LOCAPROPRIO: 'locataire',
  DDE_N_CH_SURFACE: '97',
  DDE_CH_SPEC: 'Lorem ipsum dolor si amet',

  DDE_N_PAS_DE_LOGEMENT: false,
  DDE_CH_LI_CP: '05010',
  DDE_CH_LI_ADR1: '40 chemin du panorama',
  DDE_CH_LI_VILLE: 'Saint-Remy-en-Bouzemont-Saint-Genest-et-Isson',
  DDE_CH_LI_TYPE_LOGEMENT: 'appartement',

  DDE_N_PAS_DE_LOGEMENT: true,
  DDE_CH_LI_ADR1: '',
  DDE_CH_LI_VILLE: '',
  DDE_CH_LI_TYPE_LOGEMENT: '',

  DDE_N_PAS_DE_LOGEMENT: false,
  DDE_CH_LI_ADR1: '',
  DDE_CH_LI_VILLE: '',
  DDE_CH_LI_TYPE_LOGEMENT: '',
  
}

*/
const getUserInformations = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/contact`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      .then(e => resolve(e.data || {}))
      .catch(reject);
  });

/*  

[
  {
    label: 'Chaise haute',
    id: '1',
    group: 'Chaise',
    roomFilter: [1, 2],
  },
] 

*/
const getAllElements = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/cubage/mobiliers`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      .then(e => resolve(e.data || {}))
      .catch(reject);
  });

/*

[
  {
    label: 'Cuisine',
    roomTypeId: 1,
    id: 'cuisine-demo',
    elements: [
      {
        id: 2,
        length: 1,
      },
      {
        id: 'x',
        length: 1,
        custom: {
          label: 'Élément personnalisé',
          width: 20,
          height: 30,
          depth: 50,
          weight: 2,
        },
      },
    ],
  },
]
        
*/
const getUserElements = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/cubage/biens`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      .then(e =>
        resolve(
          e.data && e.data.length
            ? e.data
            : [
                {
                  label: 'Cuisine',
                  id: 'cuisine-demo',
                  roomTypeId: 1,
                  elements: [],
                },
                {
                  label: 'Salle de bain',
                  id: 'salle-de-bain-demo',
                  roomTypeId: 2,
                  elements: [],
                },
                {
                  label: 'Salon',
                  id: 'salon-demo',
                  roomTypeId: 3,
                  elements: [],
                },
                {
                  label: 'Salle à manger',
                  id: 'salle-a-manger-demo',
                  roomTypeId: 4,
                  elements: [],
                },
                {
                  label: 'Chambre',
                  id: 'chambre-demo',
                  roomTypeId: 5,
                  elements: [],
                },
              ]
        )
      )
      .catch(reject);
  });

/* 

[
  {
    label: 'Cuisine',
    roomTypeId: 1,
    id: 'cuisine-demo',
    elements: [
      {
        id: 2,
        length: 1,
        value: 1750,
      },
      {
        id: 'x',
        length: 3,
        value: 220,
        custom: {
          label: 'Élément personnalisé',
        },
      },
    ],
  },
]
*/
const getUserElementsValues = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/statement/values`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      .then(e => {
        const data = e.data || {};
        const defaultPiece = [
          {
            label: 'Cuisine',
            roomTypeId: 1,
            id: 'cuisine-demo',
            elements: [],
          },
          {
            label: 'Salle de bain',
            roomTypeId: 2,
            id: 'salle-de-bain-demo',
            elements: [],
          },
          {
            label: 'Salon',
            roomTypeId: 3,
            id: 'salon-demo',
            elements: [],
          },
          {
            label: 'Salle à manger',
            roomTypeId: 4,
            id: 'salle-a-manger-demo',
            elements: [],
          },
          {
            label: 'Chambre',
            roomTypeId: 5,
            id: 'chambre-demo',
            elements: [],
          },
        ];
        const result = {
          garantie: data.garantie,
          pieces:
            data.pieces && data.pieces.length ? data.pieces : defaultPiece,
        };
        resolve(result);
      })
      .catch(reject);
  });

const getUserPrestations = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/contact/prestations`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      .then(e => resolve(e.data || {}))
      .catch(reject);
  });

/* GET cubage */
const getUserMethodeType = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/cubage/method`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      .then(e => resolve(e.data || {}))
      .catch(reject);
  });

const getQuotationsList = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/devis`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      /* .then(e =>
        resolve([
          {
            devisId: '2359741',
            price: 599,
            expirationDate: '2019-12-08T12:00:00+00:00',
            name: 'Petit dem',
            pdfUrl:
              'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png',
            yousignUrl: '',
            status: '',
          },
          {
            devisId: '12345',
            price: 1099,
            expirationDate: '2019-12-08T12:00:00+00:00',
            name: 'Economique',
            pdfUrl:
              'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png',
            yousignUrl: '',
            status: '',
          },
        ])
      ) */
      .then(e => resolve(e.data || []))
      .catch(reject);
  });

const getSigningUrl = (devisId, phone) =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${config.middleware.path}/auth/devis/${devisId}/sign`,
        { phone },
        {
          headers: { Authorization: `Bearer ${jsonWebToken}` },
        }
      )
      .then(e => resolve(e.data || ''))
      .catch(reject);
  });

const getDepositePayment = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/devis/signed`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      /* .then(e =>
        resolve({
          devisId: '1',
          arrhes: 100,
          arrhesPayementStatus: 'pending',
        })
      ) */
      .then(e => resolve(e.data || {}))
      .catch(reject);
  });

const getDepositeUrl = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/devis/arrhe_payment`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      .then(e => resolve(e.data || ''))
      .catch(reject);
  });

const getRestPayment = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/devis/signed`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      /* .then(e =>
        resolve({
          devisId: '2359741',
          solde: 200,
          arrhesPayementStatus: '',
        })
      ) */
      .then(e => resolve(e.data || []))
      .catch(reject);
  });

const getPaymentUrl = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/devis/solde_payment`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      .then(e => resolve(e.data || ''))
      .catch(reject);
  });

/* 
  
[
  {
    label: 'Votre satisfaction sur la prestation globale ?',
    id: 1,
    type: 'weather',
    answers: [4, 3, 2, 1],
  },
  {
    label: 'Vous êtes',
    id: 2,
    type: 'select',
    answers: [
      { id: 1, label: 'Un homme' },
      { id: 2, label: 'Une femme' },
    ],
  },
]
  
*/
const getSatisfactionSurvey = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/survey/1`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      .then(e => resolve(e.data || {}))
      .catch(reject);
  });

const getLoggedOutSatisfactionSurvey = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/survey/1`)
      .then(e => resolve(e.data || {}))
      .catch(reject);
  });

/*  

[
  {
    url: 'mieux-vous-connaitre',
    title: 'Mieux vous connaitre',
    text: 'Cillum irure mollit pariatur aute aute fugiat cupidatat pariatur laboris non quis nulla cupidatat.',
    fields: [
      {
        label: 'Vous êtes',
        id: 100,
        type: 'select',
        answers: [
          { id: 1, label: 'Célibataire' },
          { id: 4, label: 'En couple' },
        ],
      },
      {
        label: 'radio other label',
        id: 102,
        type: 'radio_other',
        answers: [
          { id: 8, label: 'Une maison' },
          { id: 2, label: 'Appartement' },
          { id: 3, label: 'Autre' },
        ],
      },
      {
        label: 'Pourquoi avez-vous choisi Les déménageurs bretons (choix ordonné limité à 3. 1 étant le premier choix)',
        id: 104,
        type: 'checkbox_limit',
        answers: [
          { id: 1, label: 'Notoriété' },
          { id: 2, label: 'Bouche à oreille' },
          { id: 3, label: 'Prix' },
          { id: 4, label: 'Ancien client' },
          { id: 5, label: 'Proximité' },
          { id: 6, label: 'Certification NF service' },
        ],
      },
    ],
  },
  {
    url: 'satisfaction-partie-commerciale',
    title: 'Satisfaction partie commerciale',
    text:
      'Eu et ut excepteur dolore voluptate sit commodo irure irure exercitation quis aliqua.',
    fields: [
      {
        label:
          'Nos  garanties étendues vous ont-elles été présentées par notre conseiller en déménagement ?',
        id: 106,
        type: 'radio',
        answers: [{ id: 8, label: 'Oui' }, { id: 2, label: 'Non' }],
      },
      {
        label:
          'Veuillez nous indiquer votre degré de satisfaction pour les questions suivantes :',
        type: 'title',
      },
      {
        label:
          'Qu’avez-vous pensé de la visite et les explications du conseiller à votre domicile ?',
        id: 107,
        type: 'weather',
        answers: [4, 3, 2, 1, 0],
      },
      {
        label: 'Ajouter une case commentaires (limite 300 caractères)',
        id: 105,
        type: 'textarea',
      },
      {
        label: 'E-mail',
        id: 106,
        type: 'email',
      },
    ],
  },
]

*/
const getComplementarySurvey = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/survey/2`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      .then(e => resolve(e.data || []))
      .catch(reject);
  });
const getLoggedOutComplementarySurvey = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/survey/2`)
      .then(e => resolve(e.data || {}))
      .catch(reject);
  });

const getMessages = () =>
  //       {
  //         author: 'ldb',
  //         date: '1562079711226',
  //         message:
  //           'Lorem ipsum dolor sit amet, consectetur adipiscing elit.  Maecenas varius tortor nibh, sit amet tempor nibh finibus',
  //         files: [],
  //       },
  new Promise((resolve, reject) => {
    axios
      .get(`${config.middleware.path}/auth/messagerie`, {
        headers: { Authorization: `Bearer ${jsonWebToken}` },
      })
      .then(e => resolve(e.data || []))
      .catch(reject);
  });

/* POST */

const postCallbackForm = datas =>
  new Promise((resolve, reject) => {
    axios
      .post(`${config.middleware.path}/contact-callback`, formData(datas))
      .then(() =>
        axios.get(
          `${config.middleware.path}/Agence/${datas.DDEE_CH_CODE_POSTAL}`
        )
      )
      .then(agence =>
        axios.get(
          `${config.linkeo.url}?t=call&codeBouton=${agence.data.canalcallback}&callee=${datas.DDEE_CH_TELEPHONE}`
        )
      )
      .then(() => {
        resolve();
      })
      .catch(reject);
  });

const postQuickEstimateForm = datas =>
  axios.post(`${config.middleware.path}/short-quotation`, formData(datas));

const postEstimateReferenceFormStep1 = datas =>
  axios.post(
    `${config.middleware.path}/quotation/step-1/reference`,
    formData(datas)
  );

const postEstimateFormStep1 = datas =>
  axios.post(`${config.middleware.path}/quotation/step-1`, formData(datas));

const postEstimateFormStep2 = datas =>
  axios.post(`${config.middleware.path}/quotation/step-2`, formData(datas));

const postEstimateFormStep3 = datas =>
  axios.post(`${config.middleware.path}/quotation/step-3`, formData(datas));

const postEstimateFormStep4 = datas =>
  axios.post(`${config.middleware.path}/quotation/step-4`, formData(datas));

const postFurnitureForm = datas =>
  axios.post(`${config.middleware.path}/furniture-quotation`, formData(datas));

const postMutationForm = datas =>
  axios.post(`${config.middleware.path}/mutation-quotation`, formData(datas));

const postRelocationForm = datas =>
  axios.post(`${config.middleware.path}/relocation-quotation`, formData(datas));

const postRegisterByStepUserForm = datas =>
  axios.post(`${config.middleware.path}/user/2step-register`, formData(datas));

const postForgotPasswordForm = datas =>
  axios.post(`${config.middleware.path}/user/forgot-password`, formData(datas));

const postCreateAccountForm = datas =>
  axios.post(`${config.middleware.path}/user/create-account`, datas);

const postChangePasswordForm = ({ token, uid, password }) =>
  axios.post(
    `${config.middleware.path}/user/change-password`,
    formData({ token, uid, password })
  );

const postRegisterUserForm = data =>
  new Promise((resolve, reject) => {
    axios
      .post(`${config.middleware.path}/user/register`, formData(data))
      .then(e => {
        const { token } = e.data;
        resolve(saveAndParseJWT(token));
      })
      .catch(reject);
  });

const postValidRegister = data =>
  new Promise((resolve, reject) => {
    axios
      .post(`${config.middleware.path}/user/valid-register`, formData(data))
      .then(e => {
        const { token } = e.data;
        resolve(saveAndParseJWT(token));
      })
      .catch(reject);
  });

const postLoginCheckForm = ({ username, password }) =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${config.middleware.path}/login_check`,
        JSON.stringify({ username, password }),
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then(e => {
        const { token } = e.data;
        resolve(saveAndParseJWT(token));
      })
      .catch(reject);
  });

const postLogout = () => {
  jsonWebToken = null;
  localStorage.removeItem('jsonWebToken');
};

const postNotificationRead = () =>
  axios.post(`${config.middleware.path}/auth/notification/read`, null, {
    headers: { Authorization: `Bearer ${jsonWebToken}` },
  });

const postUserPrestations = data =>
  axios.post(
    `${config.middleware.path}/auth/contact/prestations`,
    formData(data),
    { headers: { Authorization: `Bearer ${jsonWebToken}` } }
  );

const postUserInformations = data =>
  axios.post(`${config.middleware.path}/auth/contact/edit`, formData(data), {
    headers: { Authorization: `Bearer ${jsonWebToken}` },
  });

const postUserMethodeType = data =>
  axios.post(`${config.middleware.path}/auth/cubage/method`, data, {
    headers: { Authorization: `Bearer ${jsonWebToken}` },
  });

const postUserVolume = data =>
  axios.post(`${config.middleware.path}/auth/cubage/volume`, data, {
    headers: { Authorization: `Bearer ${jsonWebToken}` },
  });

const postUserCubage = data =>
  axios.post(
    `${config.middleware.path}/auth/cubage/biens`,
    { pieces: data },
    { headers: { Authorization: `Bearer ${jsonWebToken}` } }
  );

const postUserElementsValues = data =>
  axios.post(`${config.middleware.path}/auth/statement/values`, data, {
    headers: { Authorization: `Bearer ${jsonWebToken}` },
  });

const postSatisfactionSurvey = data =>
  axios.post(`${config.middleware.path}/auth/survey/1/submit`, data, {
    headers: { Authorization: `Bearer ${jsonWebToken}` },
  });
const postLoggedOutSatisfactionSurvey = data =>
  axios.post(`${config.middleware.path}/survey/1/submit`, data);

const postComplementarySurvey = data =>
  axios.post(`${config.middleware.path}/auth/survey/2/submit`, data, {
    headers: { Authorization: `Bearer ${jsonWebToken}` },
  });
const postLoggedOutComplementarySurvey = data =>
  axios.post(`${config.middleware.path}/survey/2/submit`, data);

const postPartners = data =>
  axios.post(`${config.middleware.path}/auth/partners/register`, data, {
    headers: { Authorization: `Bearer ${jsonWebToken}` },
  });

const postProfileUpdate = data =>
  axios.post(`${config.middleware.path}/auth/user/edit`, data, {
    headers: { Authorization: `Bearer ${jsonWebToken}` },
  });

const postPushNotification = token =>
  axios.post(
    `${config.middleware.path}/auth/push_token`,
    { token },
    {
      headers: { Authorization: `Bearer ${jsonWebToken}` },
    }
  );

const postMessage = data =>
  axios.post(`${config.middleware.path}/auth/messagerie`, formData(data), {
    headers: { Authorization: `Bearer ${jsonWebToken}` },
  });

const postFiles = (datas, type) =>
  axios.post(
    `${config.middleware.path}/auth/contact/files/reference/${type}`,
    formData(datas),
    {
      headers: { Authorization: `Bearer ${jsonWebToken}` },
    }
  );

const postMessageLu = () =>
  axios.get(`${config.middleware.path}/auth/messagerie/lu`, {
    headers: { Authorization: `Bearer ${jsonWebToken}` },
  });

const deleteFile = (type, id) =>
  axios.delete(
    `${config.middleware.path}/auth/contact/files/reference/${type}/${id}`,
    {
      headers: { Authorization: `Bearer ${jsonWebToken}` },
    }
  );
export {
  deleteFile,
  getDevis,
  getFacture,
  postReset,
  getCPByGeolocalisation,
  getCodeINSEEByCP,
  getMeteoByCodeINSEE,
  getAgenceList,
  getCityListByCodeDepartement,
  getCityListByCity,
  getAgenceByDomain,
  getAgenceByCp,
  getPartners,
  getUserEmail,
  getAllElements,
  getUserElements,
  getUserElementsValues,
  getUserDashboard,
  getUserPrestations,
  getUserInformations,
  getUserMethodeType,
  getQuotationsList,
  getSigningUrl,
  getDepositePayment,
  getDepositeUrl,
  getRestPayment,
  getPaymentUrl,
  getSatisfactionSurvey,
  getComplementarySurvey,
  getLoggedOutSatisfactionSurvey,
  getLoggedOutComplementarySurvey,
  getMessages,
  // getUserAgency,
  postCallbackForm,
  postQuickEstimateForm,
  postEstimateFormStep1,
  postEstimateFormStep2,
  postEstimateFormStep3,
  postEstimateFormStep4,
  postFurnitureForm,
  postMutationForm,
  postRelocationForm,
  postRegisterUserForm,
  postRegisterByStepUserForm,
  postForgotPasswordForm,
  postCreateAccountForm,
  postLoginCheckForm,
  postLogout,
  postChangePasswordForm,
  postNotificationRead,
  postUserPrestations,
  postValidRegister,
  postUserInformations,
  postUserMethodeType,
  postUserVolume,
  postUserCubage,
  postUserElementsValues,
  postSatisfactionSurvey,
  postComplementarySurvey,
  postLoggedOutSatisfactionSurvey,
  postLoggedOutComplementarySurvey,
  postPartners,
  postProfileUpdate,
  postPushNotification,
  postMessage,
  postMessageLu,
  indexedDB,
  postEstimateReferenceFormStep1,
  postFiles,
};
