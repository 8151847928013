import React, { Component } from 'react';
import { getPageByUid } from '../../services/prismic';
import { Img, RichText } from '../../components/Prismic/Prismic';
import Head from '../../components/Head/Head';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import NotFound from '../../layout/NotFound/NotFound';
import Loading from '../../components/Loading/Loading';
import './Content.scss';
import Slice from '../../components/Slice/Slice';

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notFound: false,
      topicName: props.name,
      pageType: props.topic,
      datas: null,
    };

    getPageByUid(props.type, props.match.params.uid).then(datas => {
      if (!datas) this.setState({ notFound: true });
      else this.setState({ datas: datas.data });
    });
  }

  componentDidUpdate(prevProps) {
    const { match, type } = this.props;
    const { uid } = match.params;
    if (prevProps.match.params.uid !== uid) {
      getPageByUid(type, uid).then(datas => {
        if (!datas) this.setState({ notFound: true });
        else this.setState({ datas: datas.data });
      });
    }
  }

  render() {
    const { notFound, datas, topicName, pageType } = this.state;
    if (notFound) {
      return <NotFound />;
    }
    if (datas) {
      return (
        <div className="Content">
          <Head title={datas.seo_title} description={datas.seo_description} />
          <div
            className="Content__background"
            style={{ backgroundImage: `url('${datas.back_img.url}')` }}
          />
          <div className="Content__container">
            <Breadcrumb
              className="Content__breadcrumb"
              topic={{ name: topicName, link: pageType }}
              title={datas.seo_title}
              white
            />
            <div className="Content__header">
              <RichText className="Content__header__title">
                {datas.title}
              </RichText>
              <div className="Content__header__thumb">
                {datas.thumb.url ? <Img src={datas.thumb} /> : null}
              </div>
            </div>
            <div className="Content__slice">
              <Slice slice={datas.body} />
            </div>
          </div>
        </div>
      );
    }

    return <Loading />;
  }
}

export default Content;
