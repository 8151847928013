import React from 'react';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';
import './Banner.scss';

const Banner = ({ datas }) => {
  const params = {
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: {
      delay: datas.data.transition_duration
        ? datas.data.transition_duration
        : 2500,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  const datasDisplayed =
    !datas.data.images || datas.data.images.length === 0
      ? null
      : datas.data.images.filter(elt => {
          const startDate = new Date(elt.start).setHours(0, 0, 0, 0);
          const toDate = new Date(elt.to).setHours(0, 0, 0, 0);
          const today = new Date().setHours(0, 0, 0, 0);

          return startDate <= today && toDate >= today;
        });

  if (datasDisplayed === null || datasDisplayed.length === 0) return null;
  if (datasDisplayed.length === 1) {
    if (datasDisplayed[0].link && datasDisplayed[0].link.link_type === 'Web') {
      return (
        <a
          href={datasDisplayed[0].link.url}
          target={datasDisplayed[0].link.target}
        >
          <div className="BannerImage">
            <img
              className="BannerImage__mobile"
              src={datasDisplayed[0].image_mobile.url}
              alt={datasDisplayed[0].image_mobile.alt}
              width={datasDisplayed[0].image_mobile.dimensions.width}
              height={datasDisplayed[0].image_mobile.dimensions.height}
            />
            <img
              className="BannerImage__desktop"
              src={datasDisplayed[0].image_desktop.url}
              alt={datasDisplayed[0].image_desktop.alt}
              width={datasDisplayed[0].image_desktop.dimensions.width}
              height={datasDisplayed[0].image_desktop.dimensions.height}
            />
          </div>
        </a>
      );
    }
    return (
      <div className="BannerImage">
        <img
          className="BannerImage__mobile"
          src={datasDisplayed[0].image_mobile.url}
          alt={datasDisplayed[0].image_mobile.alt}
          width={datasDisplayed[0].image_mobile.dimensions.width}
          height={datasDisplayed[0].image_mobile.dimensions.height}
        />
        <img
          className="BannerImage__desktop"
          src={datasDisplayed[0].image_desktop.url}
          alt={datasDisplayed[0].image_desktop.alt}
          width={datasDisplayed[0].image_desktop.dimensions.width}
          height={datasDisplayed[0].image_desktop.dimensions.height}
        />
      </div>
    );
  }

  return (
    <div className="BannerSwiper">
      <Swiper {...params}>
        {datasDisplayed.map((item, i) => {
          if (item.link && item.link.link_type === 'Web')
            return (
              <a href={item.link.url} key={i} target={item.link.target}>
                <div className="BannerImage">
                  <img
                    className="BannerImage__mobile"
                    src={item.image_mobile.url}
                    alt={item.image_mobile.alt}
                    width={item.image_mobile.dimensions.width}
                    height={item.image_mobile.dimensions.height}
                  />
                  <img
                    className="BannerImage__desktop"
                    src={item.image_desktop.url}
                    alt={item.image_desktop.alt}
                    width={item.image_desktop.dimensions.width}
                    height={item.image_desktop.dimensions.height}
                  />
                </div>
              </a>
            );
          return (
            <div className="BannerImage" key={i}>
              <img
                className="BannerImage__mobile"
                src={item.image_mobile.url}
                alt={item.image_mobile.alt}
                width={item.image_mobile.dimensions.width}
                height={item.image_mobile.dimensions.height}
              />
              <img
                className="BannerImage__desktop"
                src={item.image_desktop.url}
                alt={item.image_desktop.alt}
                width={item.image_desktop.dimensions.width}
                height={item.image_desktop.dimensions.height}
              />
            </div>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Banner;
