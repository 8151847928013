import React from 'react';
import { Helmet } from 'react-helmet';

export default ({ title, description }) => {
  const titleContent = `${title ? `${title} - ` : ''}Les déménageurs bretons`;
  return (
    <Helmet>
      <title>{titleContent}</title>
      <meta name="description" content={description || ''} />

      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="page" />
      <meta property="og:title" content={titleContent} />
      <meta property="og:description" content={description || ''} />
      <meta
        property="og:image"
        content={`${window.location.protocol}//${window.location.hostname}${
          window.location.port ? `:${window.location.port}` : ''
        }/thumbnail.jpg`}
      />
    </Helmet>
  );
};
