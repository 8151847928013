import React, { useState, useEffect, useRef } from 'react';
import Animate from 'components/Animate/Animate';
import './Accordion.scss';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default ({ children, className = '', allowMultipleOpen = false }) => {
  const newChildren = children.length === undefined ? [children] : children;
  const newChildrenWithoutRemoved = newChildren.filter(v => !v.props.removed);
  const oldChildrenWithoutRemoved = usePrevious(newChildrenWithoutRemoved);
  const newChildrendLength = newChildrenWithoutRemoved
    ? newChildrenWithoutRemoved.length
    : undefined;
  const oldChildrenLength = oldChildrenWithoutRemoved
    ? oldChildrenWithoutRemoved.length
    : undefined;
  const [itemsStatus, setItemsStatus] = useState([]);

  const toggle = id => {
    const result = itemsStatus.map(item =>
      item.id === id
        ? {
            ...item,
            status: !item.status,
            isNewItem: false,
            isRemoveItem: false,
            isClicked: true,
          }
        : {
            ...item,
            status: allowMultipleOpen ? item.status : false,
            isClicked: allowMultipleOpen ? item.isClicked : item.status,
          }
    );
    setItemsStatus(result);
  };

  const setValue = (id, values = {}) => {
    setItemsStatus(
      itemsStatus.map(item => (item.id === id ? { ...item, ...values } : item))
    );
  };

  const getItem = id => {
    const result = itemsStatus.filter(v => v.id === id);
    return result[0] || {};
  };

  const getIdByDiff = (obj1, obj2) => {
    const obj = obj1
      .filter(v => !obj2.filter(y => y.props.id === v.props.id).length)
      .map(e => e.props.id);
    return obj;
  };

  useEffect(() => {
    if (newChildrendLength > oldChildrenLength) {
      /* new item */
      const newId = getIdByDiff(
        newChildrenWithoutRemoved,
        oldChildrenWithoutRemoved
      );
      setItemsStatus(
        newChildren.map(v => ({
          id: v.props.id,
          status: newId.includes(v.props.id)
            ? newChildrendLength - oldChildrenLength === 1
            : getItem(v.props.id).status || false,
          isRemoveItem: false,
          isNewItem: newId.includes(v.props.id),
          isRemoved: newId.includes(v.props.id)
            ? false
            : getItem(v.props.id).isRemoved || false,
        }))
      );
    } else if (newChildrendLength < oldChildrenLength) {
      /* remove item */
      const oldId = getIdByDiff(
        oldChildrenWithoutRemoved,
        newChildrenWithoutRemoved
      );
      setItemsStatus(
        newChildren.map(v => ({
          id: v.props.id,
          status: getItem(v.props.id).status || false,
          isClicked: false,
          isRemoveItem: oldId.includes(v.props.id),
          isNewItem: false,
          isRemoved: getItem(v.props.id).isRemoved || false,
        }))
      );
    } else {
      /* same items */
      setItemsStatus(
        newChildren.map(v => ({
          id: v.props.id,
          status: false,
          isClicked: false,
          isRemoved: getItem(v.props.id).isRemoved || false,
          isRemoveItem: false,
          isNewItem: false,
        }))
      );
    }
  }, [newChildrendLength]);

  return (
    <div className={`Accordion ${className}`}>
      {newChildren.map((child, i) => {
        const { type: Component, props } = child;
        return (
          <Component
            {...props}
            key={i}
            className={className}
            state={{ ...getItem(props.id), ...props }}
            api={{
              toggle: () => toggle(props.id),
              setValue: value => setValue(props.id, value),
            }}
          />
        );
      })}
    </div>
  );
};

/**
 * item
 */
const AccItem = ({ children, className, state, api }) => {
  const oldNewItem = usePrevious(state.isNewItem);
  const oldRemoveItem = usePrevious(state.isRemoveItem);
  const contentEl = useRef(null);
  const wrapEl = useRef(null);

  let style = {};
  if (state.isNewItem || state.isNewItem === undefined || state.isRemoved) {
    style = { height: '0px' };
  } else {
    style = { height: 'auto', opacity: 1 };
  }

  useEffect(() => {
    if (
      (oldNewItem !== state.isNewItem && state.isNewItem) ||
      (oldRemoveItem !== state.isRemoveItem && state.isRemoveItem)
    ) {
      const $content = contentEl.current;
      const $wrap = wrapEl.current;
      const stylesWrap = getComputedStyle($wrap);
      const marginTop = parseInt(stylesWrap.marginTop, 10);
      const marginBottom = parseInt(stylesWrap.marginBottom, 10);
      const wrapHeight = $wrap.clientHeight + marginTop + marginBottom;

      let fromTo = [];
      if (state.isNewItem) fromTo = [0, wrapHeight];
      else fromTo = [wrapHeight, 0];

      Animate({ duration: 300, fromTo }, (data, status) => {
        if (data === wrapHeight) {
          $content.style.height = 'auto';
          $content.style.opacity = state.isRemoveItem ? 0 : 1;
        } else {
          $content.style.height = `${data}px`;
          $content.style.opacity = state.isRemoveItem
            ? Math.abs(status - 1)
            : status;
        }
      }).then(() => {
        if (state.isRemoveItem)
          api.setValue({ isRemoved: true, isRemoveItem: false });
      });

      api.setValue({ isNewItem: false, isRemoveItem: false });
    }
  });

  return (
    <div className="Accordion__item" ref={contentEl} style={style}>
      <div
        className={`Accordion__wrap ${className ? ` ${className}__wrap` : ''}${
          state.removed ? ' -removed' : ''
        }${
          state.isNewItem || state.isNewItem === undefined ? ' -newItem' : ''
        }${state.isRemoveItem ? ' -removeItem' : ''}${
          state.isRemoved ? ' -isRemoved' : ''
        }${state.status ? ' -isOpen' : ''}`}
        ref={wrapEl}
      >
        {children.map((Child, i) => {
          const { type: Component, props } = Child;
          return <Component {...props} state={state} api={api} key={i} />;
        })}
      </div>
    </div>
  );
};

/**
 * header d'un item
 */
const AccHeader = ({ children, api }) => (
  <div
    className="Accordion__tab"
    role="button"
    tabIndex="0"
    onClick={e => {
      if (
        (e && e.target.tagName === 'INPUT') ||
        (e && e.target.tagName === 'BUTTON')
      )
        return false;
      return api.toggle();
    }}
    onKeyDown={e => {
      if (e.keyCode === 13 && e.target.tagName !== 'INPUT') {
        return api.toggle();
      }
    }}
  >
    {children}
  </div>
);

/**
 * Contenu flexible d'un item
 */
const AccContent = ({ children, api, state }) => {
  const contentEl = useRef(null);
  const wrapEl = useRef(null);
  const oldStatus = usePrevious(state.status);
  const style =
    (state.status && !state.isClicked) || (!state.status && state.isClicked)
      ? { height: 'auto' }
      : { height: '0px' };

  useEffect(() => {
    if (oldStatus !== state.status && state.isClicked) {
      const $content = contentEl.current;
      const contentHeight = $content.clientHeight;
      const $wrap = wrapEl.current;
      const wrapHeight = $wrap.clientHeight;

      let fromTo = [];
      if (state.status) fromTo = [contentHeight, wrapHeight];
      else fromTo = [contentHeight, 0];

      Animate({ duration: 300, fromTo }, data => {
        if (data === wrapHeight) {
          $content.style.height = 'auto';
        } else {
          $content.style.height = `${data}px`;
        }
      });
      api.setValue({ isClicked: false });
    }
  });

  return (
    <div className="Accordion__content" ref={contentEl} style={style}>
      <div ref={wrapEl}>{children}</div>
    </div>
  );
};

export { AccHeader, AccContent, AccItem };
