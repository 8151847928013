import React from 'react';
import { Link } from 'components/Prismic/Prismic';
import './Pending.scss';

export default ({
  date,
  text,
  chargement,
  livraison,
  button,
  disabled,
  ...etc
}) => (
  <div className="Pending" disabled={disabled} {...etc}>
    {date && <div className="Pending__date">{date}</div>}
    {chargement && (
      <p>
        Date prévisionnelle* de chargement&nbsp;: <b>{chargement}</b>
      </p>
    )}
    {livraison && (
      <p>
        Date prévisionnelle* de livraison&nbsp;: <b>{livraison}</b>
      </p>
    )}
    <div className="Pending__text">{text}</div>
    {button && (
      <Link to={button.link} disabled={disabled} className="Pending__button">
        {button.label}
      </Link>
    )}
  </div>
);
