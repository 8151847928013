import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import NotFound from '../../layout/NotFound/NotFound';
import Head from '../../components/Head/Head';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { getPageByType } from '../../services/prismic';
import Loading from '../../components/Loading/Loading';
import Slice from '../../components/Slice/Slice';

import { RichText, Img, Link } from '../../components/Prismic/Prismic';
import { ModalContext } from '../../components/Modal/Modal';
import 'react-id-swiper/lib/styles/scss/swiper.scss';
import './SavoirFaire.scss';

const ItemMod = ({ video_id: videoId, title }) => (
  <iframe
    width="560"
    height="315"
    src={`https://www.youtube.com/embed/${videoId}?rel=0`}
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title={title}
    className="KnowHow__modal__iframe"
  />
);

class SavoirFaire extends Component {
  static contextType = ModalContext;

  constructor(props) {
    super(props);

    this.state = { datas: [] };

    getPageByType('know-how-page').then(datas => {
      this.setState({ datas });
    });
  }

  render() {
    const { datas } = this.state;
    const { match } = this.props;
    const { modal } = this.context;
    const othersPages = datas.filter(e => e.uid !== match.params.uid);
    const { length } = othersPages;

    const params = {
      slidesPerView: 2,
      spaceBetween: 10,
      breakpoints: {
        0: {
          slidesPerView: 2,
        },
        577: {
          slidesPerView: length >= 2 ? 2 : length,
        },
        768: {
          slidesPerView: length >= 3 ? 3 : length,
        },
        1024: {
          slidesPerView: length >= 4 ? 4 : length,
        },
      },
    };

    if (datas.length) {
      const page = datas.filter(e => e.uid === match.params.uid);

      if (!page.length) {
        return <NotFound />;
      }
      const { data } = page[0];

      return (
        <div className="SavoirFaire">
          <div className="SavoirFaire__header">
            <Head title={data.seo_title} description={data.seo_description} />
            <div
              className="SavoirFaire__background"
              style={{
                backgroundImage: `url('${data.back_img.url}')`,
              }}
            />
            <Breadcrumb
              className="SavoirFaire__breadcrumb"
              topic={{ name: 'Notre savoir-faire', link: 'notre-savoir-faire' }}
              title={data.seo_title}
              white
            />
          </div>

          <div className="SavoirFaire__container">
            <div className="KnowHow__content">
              <RichText className="KnowHow__title">{data.title}</RichText>
              <div className="KnowHow__room">
                <Img src={data.room_photo} className="KnowHow__img" />
                {data.buttons.map((item, i) => (
                  <div
                    className="KnowHow__button"
                    style={{ top: `${item.top}%`, left: `${item.left}%` }}
                    role="button"
                    tabIndex="0"
                    key={i}
                    onClick={() =>
                      modal.toggle({
                        title: item.room_title,
                        datas: <ItemMod {...item} />,
                      })
                    }
                  />
                ))}
              </div>
              <div className="KnowHow__otherPage">
                <Swiper {...params}>
                  {othersPages.map((page, i) => (
                    <div className="KnowHow__otherPage__item" key={i}>
                      <Link
                        to={{ link_type: 'Document', ...page }}
                        className="KnowHow__otherPage__link"
                      >
                        <Img
                          src={page.data.room_photo}
                          className="KnowHow__otherPage__img"
                        />
                        <RichText className="KnowHow__otherPage__text">
                          {page.data.title}
                        </RichText>
                      </Link>
                    </div>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className="SavoirFaire__slice">
              <Slice slice={data.body} />
            </div>
          </div>
        </div>
      );
    }

    return <Loading />;
  }
}

export default SavoirFaire;
