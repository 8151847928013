import React, { useContext, useEffect, useRef } from 'react';
import SVG from 'react-inlinesvg';
import remove from '../img/remove.svg';
import ListContext from './ListeMobilierContext';

import './ListeElements.scss';

export default ({
  params,
  newElements,
  decreaseElement,
  increaseElement,
  label,
  setLabel,
}) => {
  const inputEl = useRef();
  const listContext = useContext(ListContext);
  const { roomId } = params;
  const room = listContext.getRoomById(roomId);

  useEffect(() => {
    if (!room && inputEl.current) {
      inputEl.current.focus();
      inputEl.current.select();
    }
  }, []);

  function getTotalElementsLength() {
    return newElements.reduce((a, e) => a + e.length, 0);
  }

  if (roomId && listContext.loading) {
    return <div className="Maison3d__list__loading" />;
  }

  return (
    <div className="ListElement">
      <div className="ListElement__item__title">
        <input
          ref={inputEl}
          type="text"
          value={label}
          onChange={e => setLabel(e.target.value)}
          className="ListElement__label"
        />
        <div className="ListElement__totalCounter">
          {getTotalElementsLength()}
        </div>
      </div>
      <div className="ListElement__items">
        {newElements.map((elementRoom, i) => {
          const element = elementRoom.custom
            ? elementRoom
            : listContext.getElementById(elementRoom.id);
          return (
            <div className="ListElement__item" key={i}>
              <div>
                <div>{element.label || element.custom.label}</div>
                {element.custom && (
                  <div className="ListElement__item__custom">
                    {element.custom &&
                      Object.keys(element.custom)
                        .reduce((a, k) => {
                          if (k === 'width' && element.custom[k])
                            a.push(`L: ${element.custom[k]}cm`);
                          if (k === 'height' && element.custom[k])
                            a.push(`H: ${element.custom[k]}cm`);
                          if (k === 'depth' && element.custom[k])
                            a.push(`P: ${element.custom[k]}cm`);
                          if (k === 'weight' && element.custom[k])
                            a.push(`Poids: ${element.custom[k]}Kg`);
                          return a;
                        }, [])
                        .join(' - ')}
                  </div>
                )}
              </div>
              <div className="ListElement__item__right">
                <button
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    decreaseElement(element.id);
                  }}
                  className={`ListElement__item__button ${
                    elementRoom.remove ? '-remove' : ''
                  }`}
                >
                  {elementRoom.remove ? <SVG src={remove} /> : '-'}
                </button>
                <span className="ListElement__item__counter">
                  {elementRoom.length}
                </span>
                <button
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    increaseElement(element.id);
                  }}
                  className="ListElement__item__button"
                >
                  +
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
