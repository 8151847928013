import React, { Component } from 'react';
import Form, {
  Input,
  RadioGroup,
  Radio,
  Fusion,
  Checkbox,
  SubmitCaptcha,
  validator,
} from '../../../components/Form/Form';
import { RichText } from '../../../components/Prismic/Prismic';
import { postEstimateFormStep1 } from '../../../services/cygest';
import DevisContext from '../DevisContext';

class Etape1 extends Component {
  static contextType = DevisContext;

  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
      loading: false,
      ready: false,
    };
  }

  componentDidMount() {
    const { form, reset } = this.context;
    const { data, uid } = this.props;
    const { default_type: defaultType } = data;
    reset().then(() => {
      this.formApi.setValues({
        DDE_CH_TYPE: defaultType === 'aucun' ? '' : defaultType,
        ...form,
        DDE_CH_TYPE2: uid,
      });
      this.setState({
        hidden: this.formApi.getValue('DDE_CH_TYPE') !== 'professionnel',
      });
    });
  }

  setFormApi(formApi) {
    this.formApi = formApi;
  }

  render() {
    const { history, title, next } = this.props;
    const { hidden, loading } = this.state;
    const { form, update } = this.context;

    const updateForm = formState => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'Demande de devis Partenaire - step 1',
        type: formState.type,
      });

      this.setState({ loading: true });

      postEstimateFormStep1({ ...formState, type: 'devisPartenaire' })
        .then(result => {
          const { demandeId, token, uid } = result.data;
          update({ demandeId, token, uid, ...formState });
          history.push(next);
        })
        .catch(err => {
          console.error(err.response);
          if (err.response && typeof err.response.data === 'object') {
            const errors = [];
            Object.keys(err.response.data).forEach(e => {
              errors.push(`${e}: ${err.response.data[e]}`);
            });
            alert(errors.join('\n'));
          } else {
            alert(
              "Impossible d'envoyer ce formulaire.\nMerci de réessayer ultérieurement."
            );
          }
          this.setState({ loading: false });
        });
    };
    return (
      <div>
        <RichText className="Devis__form__title">{title}</RichText>
        <Form onSubmit={updateForm} getApi={e => this.setFormApi(e)}>
          <div className="Devis__form__maxwidth">
            <Input
              type="hidden"
              field="DDE_CH_TYPE2"
              initialValue={form.DDE_CH_TYPE2 || ''}
            />
            <RadioGroup
              field="DDE_CH_TYPE"
              initialValue={form.DDE_CH_TYPE || ''}
              validate={formState => {
                this.setState({ hidden: formState !== 'professionnel' });
                return validator.required(formState);
              }}
              validateOnChange
            >
              <Radio value="particulier">Un particulier*</Radio>
              <Radio value="professionnel">Un professionnel*</Radio>
            </RadioGroup>

            <h3 className="Devis__form__subtitle">Informations personnelles</h3>

            <div
              className="Devis__form__2col"
              style={{ display: hidden ? 'none' : 'block' }}
            >
              <div>
                <Input
                  type="text"
                  field="DDE_CH_MUTATION_ENTREPRISE"
                  label="Nom de l'entreprise*"
                  placeholder="Nom de l'entreprise"
                  initialValue={form.DDE_CH_MUTATION_ENTREPRISE || ''}
                  validate={formState =>
                    hidden ? undefined : validator.required(formState)
                  }
                  validateOnBlur
                />
              </div>
            </div>

            <div className="Devis__form__2col">
              <div>
                <Input
                  type="text"
                  field="DDE_CH_NOM"
                  label="Nom*"
                  placeholder="Nom"
                  initialValue={form.DDE_CH_NOM || ''}
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
              <div>
                <Input
                  type="text"
                  field="DDE_CH_PRENOM"
                  label="Prénom*"
                  placeholder="Prénom"
                  initialValue={form.DDE_CH_PRENOM || ''}
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
            </div>

            <div className="Devis__2col">
              <div>
                <Input
                  type="tel"
                  field="DDE_CH_TEL"
                  label="Téléphone*"
                  placeholder="Téléphone"
                  initialValue={form.DDE_CH_TEL || ''}
                  validate={validator.required}
                  validateOnBlur
                />
              </div>
              <div>
                <Input
                  type="email"
                  field="DDE_CH_EMAIL"
                  label="Email*"
                  placeholder="Email"
                  initialValue={form.DDE_CH_EMAIL || ''}
                  validate={validator.email}
                  validateOnBlur
                />
              </div>
            </div>

            <Fusion>
              <Input
                type="text"
                field="DDE_CH_CH_CP"
                placeholder="Code postal de départ*"
                initialValue={form.DDE_CH_CH_CP || ''}
                icon="start"
                validate={validator.numberFusion}
                validateOnBlur
              />
              <Input
                type="text"
                field="DDE_CH_LI_CP"
                placeholder="Code postal d’arrivée"
                initialValue={form.DDE_CH_LI_CP || ''}
                icon="pin"
              />
            </Fusion>

            <Checkbox
              field="acceptation"
              initialValue={form.acceptation || ''}
              validate={validator.required}
              validateOnChange
            >
              J&apos;accepte d&apos;être recontacté par un membre du réseau Les
              déménageurs bretons afin de traiter ma demande*
            </Checkbox>

            <div className="Devis__form__mandatory">*Champ obligatoire</div>
          </div>

          <SubmitCaptcha
            className="Devis__form__next"
            field="captcha"
            disabled={loading}
            submitForm={() => this.formApi.submitForm()}
          >
            Suivant
          </SubmitCaptcha>
          <div style={{ clear: 'both' }} />
        </Form>
      </div>
    );
  }
}

export default Etape1;
