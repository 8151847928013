import React, { useState, useEffect } from 'react';
import Col from 'components/Col';
import MonDemenageur from '../../layout/MonDemenageur/MonDemenageur';
import Header from '../Header/Header';

import './InfoDates.scss';

export default ({ history }) => {
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setDatas({
        quotationId: '0002359741',
      });
    }, 1000);
  }, []);

  return (
    <div className="InfoDates">
      <Header
        title="Information dates"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet augue accumsan tincidunt."
      />
      <div className="InfoDates__container">
        <div className="InfoDates__content">
          <div className="InfoDates__grid">
            <Col className="InfoDates__grid__left">
              <MonDemenageur />
            </Col>
            <Col className="InfoDates__grid__right">
              <a href="/" className="InfoDates__quotation">
                N° Devis: {datas.quotationId}
              </a>

              <div className="InfoDates__dates">
                <div className="InfoDates__start">
                  <p>Chargement prévu le 07/07/2019 </p>
                  <p>8 impasse du baillot</p>
                  <p>69009, Lyon</p>
                </div>

                <div className="InfoDates__end">
                  <p>Livraison prévue le 07/07/2019</p>
                  <p>21 chemin de la cleurie,</p>
                  <p>83510, Citytown</p>
                </div>

                <div className="InfoDates__end">
                  <p>Livraison prévue le 08/07/2019</p>
                  <p>7 avenue Adolphe Max,</p>
                  <p>69005, Lyon</p>
                </div>
              </div>
              <div className="InfoDates__buttons">
                <button
                  type="button"
                  className="InfoDates__back"
                  onClick={() => history.goBack()}
                >
                  Retour
                </button>
              </div>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
};
