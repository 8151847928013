import React, { useState, useEffect } from 'react';
import Form, { Submit } from 'components/Form/Form';
import { Link } from 'components/Prismic/Prismic';
import { getSatisfactionSurvey, postSatisfactionSurvey } from 'services/cygest';
import Header from '../Header/Header';
import './Enquetes.scss';

export default ({ history, homeFolderPath }) => {
  const [form, setForm] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState({
    status: false,
    type: 'success',
    success: {
      title: 'Merci, vos réponses ont bien été enregistrées.',
      element: (
        <div>
          <p className="Enquetes__next__info">
            Remplissez notre enquête complémentaire « mieux vous connaître » et{' '}
            <strong>tentez de gagner 1000€</strong> de chèque cadeau TirGroupé
            by Sodexo.
          </p>
          <Link
            to={`${homeFolderPath}/enquete-complementaire-avec-jeux-concours`}
            className="Enquetes__next__button"
          >
            Enquête complémentaire
          </Link>
        </div>
      ),
    },
    fail: {
      title: "Impossible d'envoyer vos réponses",
      message: 'Merci réessayer ultérieurement',
      element: (
        <button
          type="button"
          onClick={() => {
            setConfirm({ ...confirm, type: 'fail', status: false });
          }}
        >
          Fermer
        </button>
      ),
    },
  });

  useEffect(() => {
    getSatisfactionSurvey().then(data => {
      setForm(data);
    });
  }, []);

  const submitted = formState => {
    setLoading(true);

    const formatedFormState = Object.keys(formState).map(key => formState[key]);

    postSatisfactionSurvey({ reponses: formatedFormState })
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'dossierEnqueteSatisfaction' });

        setLoading(false);
        setConfirm({ ...confirm, type: 'success', status: true });
      })
      .catch(err => {
        console.error(err.response);
        setLoading(false);
        setConfirm({ ...confirm, type: 'fail', status: true });
      });
  };

  return (
    <div className="Enquetes">
      <Header
        title="Enquête satisfaction"
        description="Vous avez récemment déménagé et dans le cadre de l’amélioration permanente de nos services, nous souhaitons recueillir votre satisfaction globale en quelques clics."
      />
      <div className="Enquetes__container">
        <div className="Enquetes__content">
          {!form.length ? (
            <div className="Enquetes__loading" />
          ) : (
            <Form form={form} onSubmit={submitted} confirm={confirm}>
              <div className="Devis__form__mandatory">* champ obligatoire</div>
              <div className="Enquetes__buttons">
                <button
                  type="button"
                  className="Enquetes__back"
                  onClick={() => history.goBack()}
                  disabled={loading}
                >
                  Retour
                </button>
                <Submit
                  className="Enquetes__submit"
                  color="green"
                  disabled={loading}
                >
                  Valider
                </Submit>
              </div>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};
