import React, { Component } from 'react';
import SVG from 'react-inlinesvg';
import QuickEstimateForm from '../../../components/QuickEstimateForm/QuickEstimateForm';
import './Estimate.scss';
import back from '../img/back.svg';

class Estimate extends Component {
  render() {
    const { closeModal, closeAll } = this.props;
    return (
      <div className="Estimate">
        <div className="Estimate__header">
          <div
            onClick={() => closeModal()}
            className="Estimate__header__close"
            role="button"
            tabIndex="0"
          >
            <SVG src={back} className="Estimate__header__icon" />
          </div>
          <span className="Estimate__header__label">
            Établir un devis rapide
          </span>
        </div>
        <QuickEstimateForm closeAll={closeAll} />
      </div>
    );
  }
}

export default Estimate;
