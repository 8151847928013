import React, { Component } from 'react';
import { asField } from 'informed';
import SVG from 'react-inlinesvg';
import ImgUploaded from './ImgUpload';
import next from '../img/next.svg';

class File extends Component {
  constructor(props) {
    super(props);
    this.uniqid = `file-${Math.round(Math.random() * 100000)}`;
  }

  render() {
    const { fieldState, fieldApi, ...props } = this.props;
    const { value, error } = fieldState;
    const { setValue, setTouched } = fieldApi;
    const {
      className,
      onChange,
      onBlur,
      initialValue,
      forwardedRef,
      disabled,
      children,
      type,
      basic,
      ...rest
    } = props;

    return (
      <div
        className={`Form__group -file ${error ? '-error' : ''} ${
          disabled ? '-disabled' : ''
        } ${className || ''}`}
      >
        <label
          htmlFor={this.uniqid}
          data-length={Array.isArray(value) ? value.length : ''}
        >
          <input
            type="file"
            ref={forwardedRef}
            onChange={({ target }) => {
              const { files } = target;
              if (error) setValue(Object.keys(files).map(key => files[key]));
              else
                setValue([
                  ...(value || []),
                  ...Object.keys(files).map(key => files[key]),
                ]);

              if (value && value.length) setTouched();
            }}
            multiple
            disabled={disabled}
            id={this.uniqid}
            accept="image/png, image/jpeg, image/jpg, image/gif, application/pdf, .txt, xls, .xlsx, .doc, .docx, .xml, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            {...rest}
          />
          {!basic && (
            <>
              <SVG src={next} className="Form__group__uploadArrow" />
              Choisir des photos
            </>
          )}
        </label>
        {error ? <small className="Form__messageError">{error}</small> : null}
        {!basic && !!value && !disabled && !!value.length && (
          <div className="files">
            {value.map((file, index) => (
              <div className="files__item" title={file.name} key={index}>
                <ImgUploaded file={file} />
                <span className="files__item__label">{file.name}</span>
                <div
                  className="files__item__remove"
                  onClick={() => {
                    setValue(value.filter((e, i) => i !== index));
                  }}
                  role="button"
                  tabIndex="0"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default asField(File);
