import React, { useState } from 'react';
import Form, { Input, Submit, validator } from 'components/Form/Form';
import { Link } from 'components/Prismic/Prismic';
import { postChangePasswordForm } from 'services/cygest';
import Header from '../Header/Header';
import './PasswordForm.scss';

const UpdatePassword = ({ match, homeFolderPath }) => {
  const { params } = match;
  const [confirm, setConfirm] = useState({
    status: false,
    type: 'success',
    success: {
      title: 'Le mot de passe a été changé.',
      message: ' ',
      element: (
        <Link to={`${homeFolderPath}`} className="PasswordForm__button">
          Connexion
        </Link>
      ),
    },
    fail: {
      title: 'Une erreur est survenue.',
      message: 'Impossible de changer le mot de passe.',
      element: (
        <button
          type="button"
          onClick={() =>
            setConfirm({ ...confirm, type: 'fail', status: false })
          }
        >
          Fermer
        </button>
      ),
    },
  });

  function submitted(formState) {
    postChangePasswordForm(formState)
      .then(() => {
        setConfirm({ ...confirm, type: 'success', status: true });
      })
      .catch(err => {
        console.error(err.response);
        setConfirm({ ...confirm, type: 'fail', status: true });
      });
  }

  return (
    <div className="PasswordForm">
      <Header
        title="Réinitialisation du mot de passe"
        description="Saisissez un nouveau mot de passe."
      />
      <div className="PasswordForm__content">
        <Form
          className="PasswordForm__form"
          onSubmit={submitted}
          confirm={confirm}
        >
          <Input type="hidden" field="token" initialValue={params.token} />
          <Input type="hidden" field="uid" initialValue={params.uid} />
          <Input
            type="password"
            field="password"
            label="Nouveau mot de passe*"
            instruction="(8 caractères minimum dont 1 majuscule et 1 chiffre)"
            placeholder="Saisissez votre nouveau mot de passe"
            validate={validator.password}
            validateOnBlur
          />
          <span className="PasswordForm__mandatory">* Champ obligatoire</span>
          <Submit>Valider</Submit>
        </Form>
      </div>
    </div>
  );
};

export default UpdatePassword;
