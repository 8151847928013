import React, { Component } from 'react';
import { format } from 'date-fns';

import Head from '../../components/Head/Head';
import Loading from '../../components/Loading/Loading';
import { getPageByUid } from '../../services/prismic';
import Slice from '../../components/Slice/Slice';
import { RichText, Img, Media } from '../../components/Prismic/Prismic';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import './News.scss';

class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: null,
    };

    getPageByUid('news-page', props.match.params.uid).then(page => {
      this.setState({ page });
    });
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.uid !== match.params.uid) {
      getPageByUid('news-page', match.params.uid).then(page => {
        this.setState({ page });
      });
    }
  }

  render() {
    const { page } = this.state;
    if (page) {
      const { data } = page;

      return (
        <div className="News">
          <div className="News__header">
            <Head title={data.seo_title} description={data.seo_description} />
            <div
              className="News__background"
              style={
                data.back_img
                  ? { backgroundImage: `url('${data.back_img.url}')` }
                  : {}
              }
            />
            <Breadcrumb
              className="News__breadcrumb"
              topic={{ name: 'Actualités', link: 'actualites' }}
              title={data.seo_title}
              white
            />
          </div>

          <div className="News__container">
            {data.date ? (
              <div className="News__date">
                {format(data.date, 'DD . MM . YYYY')}
              </div>
            ) : null}
            <RichText className="News__title">{data.title}</RichText>
            <RichText className="News__content">{data.content}</RichText>
            <Img className="News__photo" src={data.photo} />
            <Media className="News__media" src={data.media} />
          </div>
          <div className="News__slice">
            <Slice slice={data.body} />
          </div>
        </div>
      );
    }

    return <Loading />;
  }
}

export default News;
