import React from 'react';
import nf from '../../../../img/nf.png';
import './MonDemenageur.scss';

export default ({ agence = { loading: true }, homeFolderPath, biniou }) => (
  <div className={`MonDemenageur ${agence.loading ? '-loading' : ''}`}>
    <h2 className="MonDemenageur__title">Mon agence Les déménageurs bretons</h2>

    <div className="MonDemenageur__content">
      <p className="MonDemenageur__text">
        {`Société ${agence.societe}`}{' '}
        {agence.nomAgence && ` à ${agence.nomAgence}`}
      </p>

      <p className="MonDemenageur__text">
        {`${agence.adresse1} ${agence.adresse2} ${agence.cp} ${agence.ville}, France`}
      </p>

      <div className="MonDemenageur__col">
        <p className="MonDemenageur__text">
          {agence.tel && agence.tel.replace('.', ' ')}
          <br />
          {agence.email}
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: agence.horaires,
            }}
          />
          <br />
          Commerçant indépendant
        </p>

        {agence.certification && (
          <p className="MonDemenageur__text">
            <img src={nf} alt="" className="MonDemenageur__nf" />
          </p>
        )}
      </div>
      {/* {biniou ? (
        <Link
          to={`${homeFolderPath}/messagerie`}
          className="MonDemenageur__button"
        >
          Contacter mon déménageur
          {!!agence.newMessage && (
            <span className="MonDemenageur__notif">+{agence.newMessage}</span>
          )}
        </Link>
      ) : (
        <a href={`mailto: ${agence.email}`} className="MonDemenageur__button">
          Contacter mon déménageur
        </a>
      )} */}
    </div>
  </div>
);
