import React, { Component } from 'react';
import { RichText, Link } from '../../../components/Prismic/Prismic';
import './MenuPrimary.scss';

class MenuPrimary extends Component {
  static defaultProps = {
    menu: [],
  };

  render() {
    const { menu, closeAll } = this.props;
    return (
      <ul className="MenuPrimary">
        {menu.map((item, i) => (
          <li className="MenuPrimary__li" key={`prim-${i}`}>
            <Link
              to={item.link}
              className="MenuPrimary__link"
              onClick={closeAll}
            >
              <RichText>{item.label}</RichText>
            </Link>
          </li>
        ))}
      </ul>
    );
  }
}

export default MenuPrimary;
