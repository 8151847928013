import React from 'react';
import { Link } from 'components/Prismic/Prismic';
import './MesNotifications.scss';

export default ({ homeFolderPath, notifications = { loading: true } }) => (
  <div
    className={`MesNotifications ${notifications.loading ? '-loading' : ''}`}
  >
    <h2 className="MesNotifications__title">Mes notifications</h2>
    <ul className="MesNotifications__ul">
      {notifications.list ? (
        notifications.list.map((notif, i) => (
          <li
            className={`MesNotifications__list ${notif.new ? '-new' : ''}`}
            key={i}
          >
            <div className="MesNotifications__list__title">{notif.title}</div>
            <div className="MesNotifications__list__group">
              <div className="MesNotifications__list__date">{notif.date}</div>
              {notif.link && (
                <Link
                  to={`${homeFolderPath}/${notif.link}`}
                  className="MesNotifications__list__button"
                >
                  Consulter
                </Link>
              )}
            </div>
          </li>
        ))
      ) : (
        <>
          <li className="MesNotifications__list">
            <div className="MesNotifications__list__title" />
            <div className="MesNotifications__list__group">
              <div className="MesNotifications__list__date" />
            </div>
          </li>
          <li className="MesNotifications__list">
            <div className="MesNotifications__list__title" />
            <div className="MesNotifications__list__group">
              <div className="MesNotifications__list__date" />
            </div>
          </li>
          <li className="MesNotifications__list">
            <div className="MesNotifications__list__title" />
            <div className="MesNotifications__list__group">
              <div className="MesNotifications__list__date" />
            </div>
          </li>
        </>
      )}
    </ul>
  </div>
);
