import React, { useRef, useEffect } from 'react';

const TrustBox = () => {
  const trustBoxRef = useRef();

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustBoxRef.current, true);
    }
  }, [trustBoxRef]);

  return (
    <div
      ref={trustBoxRef}
      className="trustpilot-widget"
      data-locale="fr-FR"
      data-template-id="53aa8912dec7e10d38f59f36"
      data-businessunit-id="54ca2ce10000ff00057d0fe3"
      data-style-height="130px"
      data-style-width="100%"
      data-theme="light"
      data-stars="4,5"
    >
      <a
        href="https://fr.trustpilot.com/review/example.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};

export default TrustBox;
