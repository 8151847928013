import React, { Component } from 'react';
import { RichText, Link } from '../../Prismic/Prismic';
import './Paragraph1.scss';

class Paragraph1 extends Component {
  render() {
    const { datas } = this.props;
    return (
      <div className="Paragraph1">
        <RichText className="Paragraph1__title">{datas.primary.title}</RichText>
        <RichText className="Paragraph1__text">{datas.primary.text}</RichText>
        <Link to={datas.primary.link} className="Paragraph1__link">
          {datas.primary.button_name}
        </Link>
      </div>
    );
  }
}

export default Paragraph1;
