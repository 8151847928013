import React, { Component } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

// const GoogleMaps = props => {
class GoogleMaps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
    };
  }

  componentWillMount() {
    this.calc();
    window.onload = () => {
      requestAnimationFrame(() => this.calc());
    };
    window.addEventListener('resize', () => {
      requestAnimationFrame(() => this.calc());
    });
  }

  calc() {
    const bodyWidth = document.body.clientWidth;
    this.setState({ offset: bodyWidth >= 640 ? -0.002 : 0 });
  }

  render() {
    const { offset } = this.state;
    const { isMarkerShown, defaultZoom, defaultPosition } = this.props;

    return (
      <GoogleMap
        defaultZoom={defaultZoom}
        defaultCenter={defaultPosition}
        center={{ lat: defaultPosition.lat, lng: defaultPosition.lng + offset }}
        defaultOptions={{ disableDefaultUI: true }}
      >
        {isMarkerShown && <Marker position={defaultPosition} />}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(GoogleMaps));
